import React, { useState } from "react";
import useBoolean from "../../../../hooks/useBoolean";
import { getPvPlantAPI } from "../../../pv_plant_management/PvPlantManagementRepository";
import {
  getAdminpvPlantsList,
  getPreventativeTaskOptions,
  getPreventativeTasks,
  unarchiveTaskAPI,
  archiveTaskAPI,
  getPreventativeTask,
  deletePreventativeTask
} from "./PreventativeTaskRepository";
import { getTableViewSettings } from "../../../../repositories/ViewRepository";

export const PreventativeTaskContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 50,
};

const initialFilterParams = {
  selectedTaskStatus: [],
  selectedAssignedEngineer: [],
  selectedMonthFilter: [],
  selectedPlant: [],
  isArchiveSelected: false,
  taskText: "",
  startDate: "",
  resolvedDate: "",
  taskYear: "",
  sortingOrder: "",
  sortingField: "",
  dashboard: "",
};

export const PreventativeTaskProvider = (props) => {
  const [preventativeTasks, setPreventativeTasks] = useState([]);
  const [plants, setPlants] = useState([]);
  const [slas, setSlas] = useState([]);
  const [assetCategories, setAssetCategories] = useState([]);
  const [tasksColumnSettings, setTasksColumnSettings] = useState([]);

  const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams));
  const [engineers, setEngineers] = useState([]);
  const [plantsList, setPlantsList] = React.useState([]);
  const [currentViewTask, setCurrentViewTask] = useState({});
  const [plantId, setPlantId] = useState("");

  //Loaders
  const [preventativeTaskLoading, preventativeTaskState] = useBoolean(false);
  const [dashboardFilterStatus, setDashboardFilterStatus] = useBoolean(false);
  const [plantLoading, plantState] = useBoolean(false);
  const [loadTasks, setLoadingTasks] = useBoolean(false)
  const [currentPlant, setCurrentPlant] = useState({});

  const [loadingTasks, tlState] = useBoolean(false);
  const [isLoadingFilters, ilfState] = useBoolean(false);

  //CLOSE FILTER
  const [showFilter, sfState] = useBoolean(false);

  const date = new Date();
  const startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const getPreventativeListFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      selectedTaskStatus,
      selectedAssignedEngineer,
      selectedMonthFilter,
      selectedPlant,
      isArchiveSelected,
      taskText,
      startDate,
      resolvedDate,
      taskYear,
      sortingOrder,
      sortingField,
      dashboard
      // table,
    } = filterParams;

    if (selectedTaskStatus.length) queryParams["selectedTaskStatus"] = selectedTaskStatus;
    if (selectedPlant.length) queryParams["selectedPlant"] = selectedPlant;
    if (selectedAssignedEngineer.length) queryParams["selectedAssignedEngineer"] = selectedAssignedEngineer;
    if (taskText) queryParams["taskText"] = taskText;
    if (isArchiveSelected != null) queryParams["isArchiveSelected"] = isArchiveSelected;
    if (startDate) queryParams["taskStartDate"] = startDate;
    if (resolvedDate) queryParams["taskResolvedDate"] = resolvedDate;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (taskYear) queryParams["taskYear"] = taskYear;
    if (dashboard) queryParams["dashboard"] = dashboard;
    if (selectedMonthFilter) queryParams["selectedMonthFilter"] = selectedMonthFilter;
    return queryParams;
  };
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  
  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));
  const checkIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedPlant.length != 0 ||
      filterParams.selectedAssignedEngineer.length != 0 ||
      filterParams.selectedMonthFilter.length != 0 ||
      filterParams.taskYear ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();
  const subCheckIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedAssignedEngineer.length != 0 ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isSubFilterApplied = subCheckIsFilterApplied();

  const getTableSettings = async (entityType) => {
    try {
      ilfState.on()
      const settings = await getTableViewSettings(entityType);
      setTasksColumnSettings(settings)
      const constructFilterParams = {
        selectedTaskStatus: settings.filters.selectedTaskStatus ?? "",
        isArchiveSelected: settings.filters.isArchiveSelected ?? false,
        sortingOrder: settings.filters.sortingOrder ?? "",
        sortingField: settings.filters.sortingField ?? "",
        selectedPlant: settings.filters.selectedPlant ?? "",
        selectedAssignedEngineer: settings.filters.selectedAssignedEngineer ?? "",
        startDate: settings.filters.startDate ?? "",
        resolvedDate: settings.filters.resolvedDate ?? "",
        taskYear: settings.filters.taskYear ?? "",
        selectedMonthFilter: settings.filters.selectedMonthFilter ?? "",
        size:settings.filters.size ?? null
      };
      handleApplyFilter(constructFilterParams)
      return settings;
    } catch (error) {
      throw error;
    } finally {
      ilfState.off()
    }
  };


  const loadPreventativeTasks = async (settings, plantId, status, dashboard) => {
    preventativeTaskState.on();
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status] });
    }

    if (dashboard) {
      filterParams.dashboard = "yes";
    }
    try {
      const filterParams = getPreventativeListFilterParams();
      if (status) {
        filterParams.selectedTaskStatus = status;
      }
      if (plantId) {
        filterParams["selectedPlant"] = plantId;
        // filterParams.startDate = startDateOfMonth;
      }
      
      const attributes = settings?.fields?.filter((f) => f.isChecked).map((field) => field.field_key);
      const queryParams = Object.assign(filterParams, {
        ...settings.filters,
      });
      const response = await getPreventativeTasks(queryParams, { attributes });
      setPreventativeTasks(response.preventativeTasks);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      preventativeTaskState.off();
    }
  };

  const loadPvPlant = async (plantId) => {
    try {
      const response = await getPvPlantAPI(plantId);
      setCurrentPlant(response.plant);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const loadOptions = async () => {
    try {
      const response = await getPreventativeTaskOptions();
      setEngineers(response.assignedEngineer);
      setPlants(response.plants);
      setSlas(response.slas);
      setAssetCategories(response.assetCategories);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    }
  };
  const loadPvPlantList = async () => {
    plantState.on();

    try {
      const response = await getAdminpvPlantsList();
      setPlantsList(response);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      plantState.off();
    }
  };

  //ARCHIVE TASK
  const archiveTask = async (taskId) => {
    try {
      tlState.on();
      await archiveTaskAPI(taskId);
      loadPreventativeTasks({
        ...tasksColumnSettings,
        filters: { ...tasksColumnSettings.filters },
      })
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  //UNARCHIVE TASK
  const unarchiveTask = async (taskId, plantId) => {

    try {
      tlState.on();
      await unarchiveTaskAPI(taskId);
      loadPreventativeTasks({
        ...tasksColumnSettings,
        filters: { ...tasksColumnSettings.filters },
      })
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  const loadCurrentTask = async (currentTaskId) => {
    preventativeTaskState.on();
    try {
      const response = await getPreventativeTask(currentTaskId);
      if (Object.keys(response).length) {
        setCurrentViewTask(response.task);
        setPlantId(response.task.plantId);
      }
    }
    catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      preventativeTaskState.off();
    }
  };

  // DELETE TASK
  const deleteTask = async (taskId, plantId) => {
    try {
      await deletePreventativeTask(taskId);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const mContext = {
    pagination,
    preventativeTasks,
    setPreventativeTasks,
    plants,
    engineers,
    isFilterApplied,
    showFilter,
    checkIsFilterApplied,
    sfState,
    filterParams,
    slas,
    assetCategories,
    preventativeTaskLoading,
    loadPreventativeTasks,
    loadOptions,
    handleApplyFilter,
    isSubFilterApplied,
    subCheckIsFilterApplied,
    resetClientListFilter,
    setDashboardFilterStatus,
    dashboardFilterStatus,
    plantLoading,
    loadPvPlantList,
    plantsList,
    loadPvPlant,
    currentPlant,
    loadingTasks,
    archiveTask,
    unarchiveTask,
    loadCurrentTask,
    currentViewTask,
    deleteTask,
    getTableSettings,
    isLoadingFilters,
    setTasksColumnSettings,
    tasksColumnSettings,
    preventativeTaskState,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <PreventativeTaskContext.Provider value={mContext}>{props.children}</PreventativeTaskContext.Provider>;
};

export const withPreventativeTaskProvider = (Container, containerProps) => (props) =>
(
  <PreventativeTaskProvider>
    <Container {...containerProps} {...props} />
  </PreventativeTaskProvider>
);
