import { useContext } from "react";
import Dropzone from "react-dropzone"
import { Col, Button, Icon } from "../Component"
import { Spinner } from "reactstrap"
import { getFile, singleUploadOnCreation } from "../../repositories/FileRepository";
import { errorToast, successToast } from "../toastify/Toastify";
import useBoolean from "../../hooks/useBoolean";
import { ClientManagementContext } from "../../pages/client_management/ClientManagementProvider";

const LogoAttachment = (props) => {
    const [loading, setLoading] = useBoolean(false)

    const { attachmentFiles } = props;
    const { setLogoAttachment, logoAttachment, logoId } = useContext(ClientManagementContext)

    const setAttachmentLoading = () => {
        setLoading.on();
    };
    const setAttachmentLoadComplete = () => {
        setLoading.off();
    };

    const handleDropChange = async (acceptedFiles) => {
        const fileTypeArray = ["image/png", "image/jpg", "image/svg", "image/gif", "image/jpeg"
        ]

        if (!fileTypeArray.includes(acceptedFiles.type)) {
            errorToast({ description: "Invalid file format" });
        }
        else {
            setAttachmentLoading();
            try {
                const fileUpload = await singleUploadOnCreation(acceptedFiles);
                const previewUrl = await getFile(fileUpload.files.key);
                attachmentFiles(fileUpload)
                setLogoAttachment(previewUrl)
                setAttachmentLoadComplete()
                successToast({ description: "Logo uploaded successfully" });
            } catch (e) {
                errorToast({ description: "Error happened while uploading" });
            }

        }
    };
 
    const onLogoDelete = async () => {
            setLogoAttachment("")
            attachmentFiles("")
    }

    return (<>
        <div className="d-flex justify-content-between">
            {logoAttachment ?
                <>
                    <Col md='4' lg='4'    >
                        <div className="d-flex justify-content-end">
                            <Icon style={{ cursor: "pointer" }} name="cross" onClick={() => onLogoDelete()} />
                        </div>

                        <div style={{ marginTop: "30px" }} className="d-flex justify-content-center">
                            <img className="logo-dark logo-img" w={'5px'} h={'5px'} fontSize={'5px'}
                                src={logoAttachment} />
                        </div>
                    </Col >

                    <Col md='8' lg='8'>

                        <Dropzone
                            onDrop={(acceptedFiles) => handleDropChange(acceptedFiles[0])}
                            maxFiles={1}
                            multiple={false}

                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                        <input {...getInputProps()} />
                                        {
                                            <div className="dz-message">
                                                <span className="dz-message-text">Drag and drop file</span>
                                                <span className="dz-message-or">or</span>
                                                <Button type="button" color="primary" disabled={loading}>
                                                    {loading ? <Spinner /> : "Select"}
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Col>
                </>
                : <Col md='12' lg='12'>

                    <Dropzone
                        onDrop={(acceptedFiles) => handleDropChange(acceptedFiles[0])}
                        maxFiles={1}
                        multiple={false}

                    >
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                    <input {...getInputProps()} />
                                    {
                                        <div className="dz-message">
                                            <span className="dz-message-text">Drag and drop file</span>
                                            <span className="dz-message-or">or</span>
                                            <Button type="button" color="primary" disabled={loading}>
                                                {loading ? <Spinner /> : "Select"}
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Col>
            }
        </div >
    </>
    )
}

export default LogoAttachment