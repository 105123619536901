/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../components/Component";
import download from "js-file-download";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { exportClientAPI } from "../ClientMangementRepository";

const CLIENT_FIELDS = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Phone Number",
    value: "phoneNumber",
  },
  {
    label: "Mobile Number",
    value: "mobileNumber",
  },
  {
    label: "Identifier",
    value: "identifier",
  },
  {
    label: "Address",
    value: "addressLine1",
  },
  {
    label: "City",
    value: "city",
  },
  {
    label: "Postal Code",
    value: "postalCode",
  },
  {
    label: "Website",
    value: "website",
  },
  {
    label: "Status",
    value: "statuses",
  },
  {
    value:"attachments",
    label:"Attachments"
  }
];
const ExportForm = (props) => {
  
  const [value, setValue] = useState([]);
  useEffect(() => {
    if (CLIENT_FIELDS) {
      const data = CLIENT_FIELDS.map((client) => {
        return client.value;
      });
      setValue(data);
    }
  }, [CLIENT_FIELDS]);

  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async (formData) => {
    try {
      setExportLoading(true);
      const response = await exportClientAPI({ ...props.filterParams, headerData: value });
      download(response, `client.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="client-export-field-btn"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Client Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0 ">
                {CLIENT_FIELDS &&
                  CLIENT_FIELDS.map((plant, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${plant} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0">
                              <input
                                key={`${plant}-${key}`}
                                type="checkbox"
                                checked={value.includes(plant.value)}
                                id={`${plant}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([plant.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === plant.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${plant}-${key}`} check>
                                {plant.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;
