import apiService from "../../services/ApiService";
import {
  USER_MANAGEMENT_ARCHIVE_API,
  USER_MANAGEMENT_LIST_API,
  USER_MANAGEMENT_LIST_FILTER_OPTIONS_API,
  USER_MANAGEMENT_UNARCHIVE_API,
  USER_MANAGEMENT_UPDATE_STATUS_API,
  USER_MANAGEMENT_VIEW_API,
  USER_MANAGEMENT_DELETE_API,
  USER_MANAGEMENT_PASSWORD_API,
  USER_MANAGEMENT_USER_ROLES_API,
  USER_MANAGEMENT_UPDATE_API,
  EXPORT_USER_API,
  USER_MANAGEMENT_CREATE_API,
} from "../../constants/endpoints";

export const getAdminUserList = async (filterParams) => {
  const response = await apiService.get(USER_MANAGEMENT_LIST_API, {
    queryParams: filterParams,
  });

  return response;
};

export const getUserAPI = async (userId) => {
  const response = await apiService.get(USER_MANAGEMENT_VIEW_API, {
    pathParams: { userId },
  });
  return response;
};

export const getAdminUserListFilterOptions = async (filterParams) => {
  const response = await apiService.get(USER_MANAGEMENT_LIST_FILTER_OPTIONS_API);
  return response;
};

export const archiveUserAPI = async (userId, params) => {
  const response = await apiService.put(USER_MANAGEMENT_ARCHIVE_API, {
    body: params,
    pathParams: { userId },
  });
  return response;
};
export const updateUserPassword = async (userId, params) => {
  const response = await apiService.put(USER_MANAGEMENT_PASSWORD_API, {
    body: params,
    pathParams: { userId },
  });
  return response;
};

export const unarchiveUserAPI = async (userId, params) => {
  const response = await apiService.put(USER_MANAGEMENT_UNARCHIVE_API, {
    body: params,
    pathParams: { userId },
  });
  return response;
};

export const updateUserAPI = async (userId, params) => {
  const response = await apiService.put(USER_MANAGEMENT_UPDATE_API, {
    body: params,
    pathParams: { userId },
  });
  return response;
};

export const createUserAPI = async (params) => {
  const response = await apiService.post(USER_MANAGEMENT_CREATE_API, {
    body: params,
  });
  return response;
};

/**
 *
 * @param {Object} params
 * @param {status} params.status - ACTIVE, INACTIVE, SUSPENDED
 * @returns
 */
export const updateUserStatusAPI = async (userId, params) => {
  const response = await apiService.put(USER_MANAGEMENT_UPDATE_STATUS_API, {
    pathParams: { userId },
    body: params,
  });
  return response;
};

export const deleteUserAPI = async (userId) => {
  const response = await apiService.delete(USER_MANAGEMENT_DELETE_API, {
    pathParams: { userId },
  });
  return response;
};

export const getAllRoles = async () => {
  const response = await apiService.get(USER_MANAGEMENT_USER_ROLES_API);
  return response;
};
export const exportUserAPI = async (filterParams) => {
  const response = await apiService.get(EXPORT_USER_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
