import React, { useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../../components/Component";
import { sortOption } from "../../../../utils/Utils";
import { ManufacturerContext } from "../ManufacturerProvider";

const ManufacturerListFilter = () => {
  const manufacturerContext = React.useContext(ManufacturerContext);
  const { filterOptions } = manufacturerContext;
  const { filterParams, handleApplyFilter, checkIsFilterApplied, sfState, showFilter } = manufacturerContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    manufacturerContext.resetManufacturerListFilter();
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    updateTableSm(false);
    checkIsFilterApplied();
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleManufacturerFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedManufacturers: selectedOption.value });
  };

  const selectedManufacturerOption =
    filterOptions.manufacturers?.find((o) => o.value === selectedFilter.selectedManufacturers) || null;

  const handleEmailFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedEmail: selectedOption.value });
  };

  const selectedEmailOption = filterOptions.emails?.find((o) => o.value === selectedFilter.selectedEmail) || null;

  return (
    <div className="vh-100  ">
      <div className="dropdown-head">
        <span className="sub-title dropdown-title fs-16px">Filter Options</span>
        <div onClick={sfState.off}>
          <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
        </div>
      </div>
      <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Corporation Brand</label>
                <RSelect
                  options={sortOption(filterOptions.manufacturers)}
                  placeholder="Any Manufacturer"
                  onChange={handleManufacturerFilterSelection}
                  value={selectedManufacturerOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Email</label>
                <RSelect
                  options={sortOption(filterOptions.emails)}
                  placeholder="Any Email"
                  onChange={handleEmailFilterSelection}
                  value={selectedEmailOption}
                />
              </FormGroup>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                {" "}
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </UncontrolledDropdown>
    </div>
  );
};

export default ManufacturerListFilter;
