/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Button, Col, Row, Spinner } from "reactstrap";
import useBoolean from "../../hooks/useBoolean";
import { deleteFile, getFile, singleUploadOnCreation } from "../../repositories/FileRepository";
import { getFileSizeText } from "../../utils/Utils";
import {
  Block,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../Component";
import { confirmationSweetAlert } from "../sweetAlert/SweetAlert";
import { errorToast, successToast } from "../toastify/Toastify";
import Card from "./Card";

const CreateFormAttachments = (props) => {
  const { attachmentFiles } = props;
  const [attachment, setAttachment] = useState([]);

  const [files, setFiles] = useState([]);
  const [view, setView] = useState(true);
  const [loading, setLoading] = useBoolean();
  const [deleteLoading, setDeleteLoading] = useBoolean();
  const [fileId, setFileId] = useState();

  useEffect(() => {
    const setPreviewUrl = async () => {
      for (let i = 0; i < attachment?.length; i++) {
        try {
          const previewUrl = await getFile(attachment[i].files.key);
          files.push(previewUrl);
          setFiles([...files]);
        } catch (e) {
          errorToast({ description: "Error happened on getting the file " });
        }
      }
    };
    if (!files.length) setPreviewUrl();
  }, []);

  const setAttachmentLoading = () => {
    setLoading.on();
  };
  const setAttachmentLoadComplete = () => {
    setLoading.off();
  };

  const handleDropChange = async (acceptedFiles) => {
    setAttachmentLoading();
    try {
      const fileUpload = await singleUploadOnCreation(acceptedFiles[0]);
      const previewUrl = await getFile(fileUpload.files.key);
      files.push(previewUrl);
      setAttachmentLoadComplete();
      attachment.push(fileUpload);
      setFiles([...files]);
      attachmentFiles(attachment);
      setAttachment([...attachment]);
      successToast({ description: "File uploaded successfully" });
    } catch (e) {
      errorToast({ description: "Error happened while uploading" });
    }
  };
  const handleDelete = async (file) => {
    try {
      setFileId(file.id);
      setDeleteLoading.on();
      await deleteFile(file.key);
      successToast({ description: "File deleted successfully" });
    } catch (e) {
      errorToast({ description: "Error happened while deleting" });
    }
    setDeleteLoading.off();
    let index = attachment.findIndex((e) => e.files.key === file.key);
    files.splice(index, 1);
    setFiles([...files]);
    attachment.splice(index, 1);
    setAttachment([...attachment]);
  };
  const toggleAttachmentView = () => {
    setView(!view);
  };
  const handleConfirmationDeleteAttachment = async (file) => {
    confirmationSweetAlert({
      id: file,
      handleConfirmation: handleDelete,
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <BlockTitle tag="h6" className="mb-1">
            Attachments
          </BlockTitle>
        </div>
        {
          <div className="d-flex justify-content-end mb-2">
            {!view && (
              <Dropzone
                onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
                maxFiles={1}
                multiple={false}
                disabled={loading}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="upload-zone dz-clickable justify-content-center align-items-center"
                    >
                      <input {...getInputProps()} />
                      {
                        <div className="dz-message">
                          <Button color="primary" disabled={loading}>
                            {loading ? <Spinner /> : <Icon name="plus" />}
                          </Button>
                        </div>
                      }
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
            <Button outline color="primary" onClick={toggleAttachmentView} className="ml-3">
              {(view && <Icon name="view-list-fill" />) || <Icon name="menu-squared" />}
            </Button>
          </div>
        }
      </div>
      {!attachment?.length && view && (
        <Dropzone
          onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
          maxFiles={1}
          multiple={false}
          disabled={loading}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                <input {...getInputProps()} />
                {
                  <div className="dz-message">
                    <span className="dz-message-text">Drag and drop file</span>
                    <span className="dz-message-or">or</span>
                    <Button type="button" color="primary" disabled={loading}>
                      {loading ? <Spinner /> : "Select"}
                    </Button>
                  </div>
                }
              </div>
            </section>
          )}
        </Dropzone>
      )}
      {view && (
        <div className="mt-2">
          <Row>
            {attachment?.map((item, key) => {
              return (
                <Col lg="3" md="4" sm="6" xs="12" className="mb-4">
                  <Card
                    key={`${files[key]}-attachment-${key}`}
                    type={item.files.mimetype}
                    src={files[key]}
                    file={item.files}
                    handleDelete={handleConfirmationDeleteAttachment}
                    deleteLoading={deleteLoading}
                    fileId={fileId}
                  />
                </Col>
              );
            })}
            {(!attachment?.length && <></>) || (
              <Col lg="3" md="4" sm="6" xs="12" className="mb-4">
                <div className="team  border border-light p-3 rounded-lg h-100">
                  <Dropzone
                    onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
                    maxFiles={1}
                    multiple={false}
                    disabled={loading}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="h-100">
                        <div
                          {...getRootProps()}
                          className="h-100 dropzone upload-zone dz-clickable d-flex justify-content-center align-items-center"
                        >
                          <input {...getInputProps()} />
                          {
                            <div className="dz-message">
                              <Button color="primary" disabled={loading}>
                                {loading ? <Spinner /> : <Icon name="plus" />}
                              </Button>
                            </div>
                          }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
      {!view && (
        <>
          <Block className={"py-3"}>
            <DataTable className="card-stretch">
              <DataTableBody compact className={"border-top  border-bottom border-light"}>
                <DataTableHead>
                  <DataTableRow className="font-weight-bold">
                    <span className="sub-text">Name</span>
                  </DataTableRow>
                  <DataTableRow className="font-weight-bold">
                    <span className="sub-text">Size</span>
                  </DataTableRow>
                  <DataTableRow className="font-weight-bold">
                    <span className="sub-text">Created By</span>
                  </DataTableRow>
                  <DataTableRow className="font-weight-bold">
                    <span className="sub-text">Created At</span>
                  </DataTableRow>
                  <DataTableRow className="font-weight-bold">
                    <span className="sub-text" style={{ textAlign: "end" }}>
                      Actions
                    </span>
                  </DataTableRow>
                </DataTableHead>
                {attachment &&
                  attachment?.map((item, key) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow>
                          <span>
                            <a href={files[key]} target="_blank" rel="noreferrer">
                              {item.files.name || "-"}
                            </a>
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span>{getFileSizeText(item.files.size) || "-"}</span>
                        </DataTableRow>
                        <DataTableRow>
                          {item.files.user?.firstName || item.files.user?.lastName ? (
                            <span>
                              {item.files.user?.firstName} {item.files.user?.lastName}
                            </span>
                          ) : (
                            <span>{"-"}</span>
                          )}
                        </DataTableRow>
                        <DataTableRow>
                          <span>{format(new Date(item?.created_at || null), "dd/MM/yyyy k:m aaa")}</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1" style={{ justifyContent: "center" }}>
                            {!deleteLoading && (
                              <li>
                                <Icon name="na" onClick={() => handleConfirmationDeleteAttachment(item.files)}></Icon>
                              </li>
                            )}
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })}
              </DataTableBody>
            </DataTable>
          </Block>
        </>
      )}
    </>
  );
};

export default CreateFormAttachments;
