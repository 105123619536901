const RECORD_TYPE = [
  { label: "Add", value: "ADD" },
  { label: "Subtract", value: "SUBTRACT" },
  { label: "Transfer", value: "TRANSFER" },
];
const CAUSE = [
  { label: "Addition", value: "Addition" },
  { label: "Transportation", value: "Transportation" },
  { label: "Installation", value: "Installation" },
  { label: "Uninstall", value: "Uninstall" },
  { label: "Service", value: "Service" },
  { label: "Return", value: "Return" },
  { label: "Other", value: "Other" },
];
const STATUSES = [
  { label: "Less than contractual quantity", value: "less" },
  { label: "Equal to contractual quantity", value: "equal" },
  { label: "Greater than contractual quantity", value: "greater" },
];

const WARE_HOUSE_OPTIONS = [
  { label: "Installed at site", value: "INSTALLED_AT_SITE" },
  { label: "Spares at site", value: "SPARES_AT_SITE" },
  { label: "External warehouse", value: "EXTERNAL_WAREHOUSE" },
  { label: "BGE Warehouse", value: "BGE_WAREHOUSE" },
  { label: "Supplier premises", value: "SUPPLIER_PREMISES" },
];
export { RECORD_TYPE, CAUSE, STATUSES, WARE_HOUSE_OPTIONS };

export const TRANSFER = "TRANSFER";
