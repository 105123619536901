import { format } from "date-fns";
import React, { useContext } from "react";
import { Collapse, Media } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
} from "../../../components/Component";
import { ActivityLogContext } from "../../../providers/ActivtiyLogProvider";

const ActivityLog = (props) => {
  const activityLogContext = useContext(ActivityLogContext);
  const { activityLogsList, pagination } = activityLogContext;
  const [isOpen, setIsOpen] = React.useState();
  const currentItems = activityLogsList?.items || [];

  return (
    <React.Fragment>
      <Block className={"bg-white p-4"}>
        <BlockHead size="md">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4"> Activity Log Lists</BlockTitle>
              <BlockDes>
                <p>You have a total of {activityLogsList?.pagination?.totalCount || 0} activity logs.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {currentItems && currentItems.length > 0 ? (
            currentItems.map((item, key) => {
              if (
                (Object.keys(item.detail).length && item.action === "UPDATE") ||
                item.action === "CREATE" ||
                item.action === "DELETE"
              ) {
                return (
                  <div className="accordion " key={key + 1}>
                    <div className="accordion-item">
                      <div
                        className={[`accordion-head${isOpen !== key ? " collapsed" : ""}`]}
                        onClick={() => {
                          if (isOpen === key) {
                            setIsOpen("");
                          } else {
                            setIsOpen(key);
                          }
                        }}
                      >
                        <h6 className="title">
                          {item.user.firstName} {item.user.lastName} {item.action}D {item.entityType} at{" "}
                          {format(new Date(item?.created_at || null), "dd/MM/yyyy HH:mm")}
                        </h6>
                        <span className="accordion-icon"></span>
                      </div>
                      <Collapse className="accordion-body" isOpen={isOpen === key ? true : false}>
                        <div className="accordion-inner">
                          {item.action === "UPDATE" ? (
                            item.detail &&
                            Object.keys(item.detail).length &&
                            Object.entries(item.detail).map(([key, val], value) => {
                              return (
                                <Media key={`${value}-details`}>
                                  <Media left>
                                    <Icon name="chevron-right"></Icon>
                                  </Media>
                                  {key === "onboardedAt" ? (
                                    <Media body>
                                      Changed <b>{key}</b> Value from
                                      <span className="font-weight-bolder ml-1">
                                        {val.old_value ? format(new Date(val.old_value || null), "dd MM yyyy") : "-"} to{" "}
                                        {val.new_value ? format(new Date(val.new_value || null), "dd MM yyyy") : "-"}
                                      </span>
                                    </Media>
                                  ) : Array.isArray(val.new_value) || Array.isArray(val.old_value) ? (
                                    key === "attachments" ? (
                                      <Media body>
                                        Uploaded <b>{key}</b> File
                                        <span className="font-weight-bolder ml-1">
                                          {val.new_value.map((newValue) => {
                                            return <span>{newValue},</span>;
                                          })}
                                        </span>
                                      </Media>
                                    ) : (
                                      <Media body>
                                        Changed <b>{key}</b>
                                        {
                                          <span className="font-weight-bolder ml-1">
                                            {val.old_value?.length
                                              ? val.old_value.map((newValue) => {
                                                  return <span>{newValue}, </span>;
                                                })
                                              : "no data"}
                                          </span>
                                        }
                                        to
                                        <span className="font-weight-bolder ml-1">
                                          {val.new_value?.length
                                            ? val.new_value.map((newValue) => {
                                                return <span>{newValue}, </span>;
                                              })
                                            : "no data"}
                                        </span>
                                      </Media>
                                    )
                                  ) : (
                                    <Media body>
                                      <b>{key}</b> Value
                                      <span className="font-weight-bolder ml-1">
                                        {val.old_value} is changed to {val.new_value}
                                      </span>
                                    </Media>
                                  )}
                                </Media>
                              );
                            })
                          ) : (
                            <Media body>
                              <span>
                                {item.user.firstName} {item.user.lastName} {item.action}D {item.entityType}{" "}
                              </span>
                            </Media>
                          )}
                        </div>
                      </Collapse>
                    </div>
                  </div>
                );
              } else {
                <span>
                  {item.user.firstName} {item.user.lastName} {item.action}D {item.entityType}{" "}
                </span>;
              }
            })
          ) : (
            <></>
          )}
          <div className="card-inner p-0 pt-2">
            {activityLogsList?.pagination && activityLogsList?.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={activityLogsList.pagination.totalCount}
                paginate={activityLogContext.updateShowListPage}
                paginatePage={activityLogContext.updateShowListSize}
                currentPage={activityLogsList.pagination.currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            )}
          </div>
        </Block>
      </Block>
    </React.Fragment>
  );
};
export default ActivityLog;
