import { useState } from "react";
import { Block } from "../../../components/Component";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card } from "reactstrap";
import CctvForm from "./cctvForm";
import classnames from "classnames";
import SystemForm from "./systemForm";

const CctvSystem = () => {
  const [activeTab, setActiveTab] = useState("7");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Block>
      <Nav tabs className="ml-4">
        <NavItem>
          <NavLink
            href="#tab"
            className={classnames({ active: activeTab === "1" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggle("1");
            }}
          >
            CCTV
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#tab"
            className={classnames({ active: activeTab === "2" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggle("2");
            }}
          >
            System
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card className="card-bordered p-2">
            <CctvForm />
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <Card className="card-bordered p-2">
            <SystemForm />
          </Card>
        </TabPane>
      </TabContent>
    </Block>
  );
};

export default CctvSystem;
