import React, { useContext } from "react";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
} from "../../../components/Component";
import { AuthContext } from "../../../providers/AuthContext.provider";
import { format } from "date-fns";

const UserProfileRegularPage = () => {
  const authContext = useContext(AuthContext);
  const { currentUser } = authContext;

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
            <BlockDes>
              <p>Basic info, like name and contact information on the BGE.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <div className="nk-data data-list data-list-s2">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item">
            <div className="data-col ">
              <Col md="6" lg="6">
                <span className="data-label">Full Name</span>
              </Col>
              <Col md="6" lg="6">
                {currentUser?.firstName || currentUser?.lastName ? (
                  <span className="data-value ">
                    {currentUser?.firstName} {currentUser?.lastName}
                  </span>
                ) : (
                  <span>{"-"}</span>
                )}
              </Col>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <Col md="6" lg="6">
                <span className="data-label">Category</span>
              </Col>
              <Col md="6" lg="6">
                <span className="data-value">{currentUser?.category || "-"}</span>
              </Col>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <Col md="6" lg="6">
                <span className="data-label">Email</span>
              </Col>
              <Col md="6" lg="6">
                <span className="data-value data-col-center">{currentUser?.email || "-"}</span>
              </Col>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <Col md="6" lg="6">
                <span className="data-label">Phone Number</span>
              </Col>
              <Col md="6" lg="6">
                <span className="data-value ">{currentUser?.phoneNumber || "-"}</span>
              </Col>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <Col md="6" lg="6">
                <span className="data-label">Mobile Number</span>
              </Col>
              <Col md="6" lg="6">
                <span className="data-value">{currentUser?.mobileNumber || "-"}</span>
              </Col>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col ">
              <Col md="6" lg="6">
                <span className="data-label">Status</span>
              </Col>
              <Col md="6" lg="6">
                <span className="data-value ">{currentUser?.status || "-"}</span>
              </Col>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <Col md="6" lg="6">
                <span className="data-label">CreatedAt</span>
              </Col>
              <Col md="6" lg="6">
                <span className="profile-ud-value">
                  {currentUser.created_at ? format(new Date(currentUser?.created_at || null), "dd/MM/yyyy HH:mm") : "-"}
                </span>
              </Col>
            </div>
          </div>
        </div>
      </Block>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
