/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ReactFormBuilder, ReactFormGenerator, Registry } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  RSelect,
} from "../../../components/Component";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { FORM_PAGE } from "../../../constants/routes";
import { FormManagementContext } from "../FormManagementProvider";

const statusOptions = [
  { label: "DRAFT", value: "DRAFT" },
  { label: "PUBLISHED", value: "PUBLISHED" },
  { label: "UNPUBLISHED", value: "UNPUBLISHED" },
];

const MultiFileUpload = (props) => {
  const formManagementContext = useContext(FormManagementContext);
  const {
    multiData,
    setMultidata,
    compressedFile,
    loadFormSubmissionList,
    submittedForms,
    setSingleFileUploads,
    singleFileUpload,
  } = formManagementContext;
  const fileInputRef = React.useRef(null);

  const [fileUploads, setFileUploads] = useState([]);
  const { formId, id } = useParams();
  useEffect(() => {
    loadFormSubmissionList(formId, id);
  }, []);
  let previewURL;
  if (submittedForms?.items?.length > 0) {
    previewURL = submittedForms?.items[id]?.rawData?.find((data) => data?.name?.includes("my_input") === true);
  }

  const displayFileUpload = () => {
    const files = Array.from(fileInputRef.current.files);
    if (files) {
      const modifyProps = props?.data;
      modifyProps["required"] = false;
    }
    setFileUploads(files);
    setMultidata(files);
  };

  const clearFileUpload = () => {
    setFileUploads([]);
    const modifyProps = props?.data;
    modifyProps["required"] = false;

    fileInputRef.current.value = null;
  };

  const saveFiles = async (e) => {
    e.preventDefault();
    const sourceUrl = props.defaultValue;

    if (fileUploads.length > 0) {
      for (let i = 0; i < fileUploads.length; i++) {
        const file = fileUploads[i];
      }
    }
  };

  let baseClasses = "SortableItem rfb-item";
  const name = props.data.field_name;
  const fileInputStyle = fileUploads.length > 0 ? { display: "none" } : null;
  if (props.data.pageBreakBefore) {
    baseClasses += " alwaysbreak";
  }

  const downloadFile = (event) => {
    event.preventDefault();
    const url = previewURL?.value;

    fetch(url)
      .then((response) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "filename";
        link.click();
      })
      .catch((error) => {
        errorToast({ description: "Error while downloading file" });
      });
  };

  return (
    <div style={{ ...props.style }} className={baseClasses}>
      <div className="form-group">
        {props.read_only === true && props.defaultValue && props.defaultValue.length > 0 ? (
          <div>
            <button className="btn btn-default" onClick={saveFiles}>
              <i className="fas fa-download"></i> Download Files
            </button>
          </div>
        ) : (
          <div className="image-upload-container">
            <div style={fileInputStyle}>
              {!props?.disabled && (
                <input
                  ref={fileInputRef}
                  name={name}
                  type="file"
                  accept={props.data.fileType || "*"}
                  className="image-upload"
                  onChange={displayFileUpload}
                  multiple
                />
              )}

              {!props?.disabled ? (
                <div className="image-upload-control">
                  <div className="btn btn-default">
                    <i className="fas fa-file"></i> Upload Files
                  </div>
                  <p>Select one or more files from your computer or device.</p>
                </div>
              ) : previewURL?.value ? (
                <a href={previewURL?.value}>{previewURL?.value}</a>
              ) : (
                "No Data Found"
              )}
            </div>

            {fileUploads.length > 0 && (
              <div>
                {fileUploads.map((file, index) => (
                  <div key={index} className="file-upload-preview">
                    <div style={{ display: "inline-block", marginRight: "5px" }}>{`Name: ${file.name}`}</div>
                    <div style={{ display: "inline-block", marginLeft: "5px" }}>
                      {file.size > 1024 * 1024
                        ? `Size:  ${Math.ceil(file.size / (1024 * 1024))} mb`
                        : `Size:  ${Math.ceil(file.size / 1024)} kb`}
                    </div>
                  </div>
                ))}
                <br />
                <div className="btn btn-file-upload-clear" onClick={clearFileUpload}>
                  <i className="fas fa-times"></i> Clear Files
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const SingleFileUpload = (props) => {
  const formManagementContext = useContext(FormManagementContext);
  const {
    multiData,
    setMultidata,
    compressedFile,
    loadFormSubmissionList,
    submittedForms,
    setSingleFileUploads,
    singleFileUpload,
  } = formManagementContext;
  const fileInputRef = React.useRef(null);

  const [fileUpload, setFileUpload] = useState(null);
  const { formId, id } = useParams();
  useEffect(() => {
    loadFormSubmissionList(formId, id);
  }, []);
  let previewURL;
  if (submittedForms?.items?.length > 0) {
    previewURL = submittedForms?.items[0]?.rawData?.find((data) => data?.name?.includes("my_fileupload_") === true);
  }

  const displayFileUpload = () => {
    const file = fileInputRef.current.files[0];
    setFileUpload(file);
    if (file) {
      const modifyProps = props?.data;
      modifyProps["required"] = false;
    }
    setSingleFileUploads(file);
  };

  const clearFileUpload = () => {
    const modifyProps = props?.data;
    modifyProps["required"] = true;
    setFileUpload(null);
    fileInputRef.current.value = null;
  };

  const saveFile = async (e) => {
    e.preventDefault();
    const sourceUrl = props.defaultValue;
  };

  let baseClasses = "SortableItem rfb-item";
  const name = props.data.field_name;
  const fileInputStyle = fileUpload ? { display: "none" } : null;
  if (props.data.pageBreakBefore) {
    baseClasses += " alwaysbreak";
  }

  const downloadFile = (event) => {
    event.preventDefault();
    const url = previewURL?.value;

    fetch(url)
      .then((response) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "filename";
        link.click();
      })
      .catch((error) => {
        errorToast({ description: "Error while downloading file" });
      });
  };

  return (
    <div style={{ ...props.style }} className={baseClasses}>
      <div className="form-group">
        {props.read_only === true && props.defaultValue && props.defaultValue.length > 0 ? (
          <div>
            <button className="btn btn-default" onClick={saveFile}>
              <i className="fas fa-download"></i> Download File
            </button>
          </div>
        ) : (
          <div className="image-upload-container">
            <div style={fileInputStyle}>
              {!props?.disabled && (
                <input
                  ref={fileInputRef}
                  name={name}
                  type="file"
                  accept={props.data.fileType || "*"}
                  className="image-upload"
                  onChange={displayFileUpload}
                />
              )}

              {!props?.disabled ? (
                <div className="image-upload-control">
                  <div className="btn btn-default">
                    <i className="fas fa-file"></i> Upload File
                  </div>
                  <p>Select a file from your computer or device.</p>
                </div>
              ) : previewURL?.value ? (
                <a href={previewURL?.value}>{previewURL?.value}</a>
              ) : (
                "no data found"
              )}
            </div>

            {fileUpload && (
              <div>
                <div className="file-upload-preview">
                  <div style={{ display: "inline-block", marginRight: "5px" }}>{`Name: ${fileUpload.name}`}</div>
                  <div style={{ display: "inline-block", marginLeft: "5px" }}>
                    {fileUpload.size > 1024 * 1024
                      ? `Size:  ${Math.ceil(fileUpload.size / (1024 * 1024))} mb`
                      : `Size:  ${Math.ceil(fileUpload.size / 1024)} kb`}
                  </div>
                </div>
                <br />
                <div className="btn btn-file-upload-clear" onClick={clearFileUpload}>
                  <i className="fas fa-times"></i> Clear File
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
const DatePicker = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled } = props;
  return (
    <input
      type="date"
      name={name}
      ref={ref}
      dateFormat="dd/MM/yyyy"
      defaultValue={defaultValue}
      disabled={disabled}
      className="form-control"
    />
  );
});

Registry.register("MultifileUpload", MultiFileUpload);
Registry.register("SingleFileUpload", SingleFileUpload);
Registry.register("datepicker", DatePicker);
const items = [
  {
    key: "MultifileUpload",
    element: "CustomElement",
    component: MultiFileUpload,
    type: "custom",
    forwardRef: true,
    field_name: "my_input_",
    name: "Multi file Upload",
    icon: "fa fa-cog",
    label: "Multi File Upload",
  },
  {
    key: "SingleFileUpload",
    element: "CustomElement",
    component: SingleFileUpload,
    type: "custom",
    forwardRef: true,
    field_name: "my_fileupload_",
    name: "Single File Upload",
    icon: "fa fa-cog",
    required: false,
    label: "Single File Upload",
  },

  {
    key: "datepicker",
    element: "CustomElement",
    component: DatePicker,
    type: "custom",
    forwardRef: true,
    field_name: "my_date_picker",
    name: "Date",
    icon: "fa fa-cog",
    required: false,
    label: "Date",
  },
  {
    key: "Header",
  },
  {
    key: "TextInput",
  },
  {
    key: "Label",
  },

  {
    key: "TextArea",
  },
  {
    key: "RadioButtons",
  },
  {
    key: "Checkboxes",
  },

  {
    key: "TwoColumnRow",
  },
  {
    key: "ThreeColumnRow",
  },
  {
    key: "PhoneNumber",
  },
  {
    key: "Range",
  },

  {
    key: "Camera",
  },
  {
    key: "LineBreak",
  },
  {
    key: "Dropdown",
  },
  {
    key: "Tags",
  },
  {
    key: "NumberInput",
  },
  {
    key: "Rating",
  },

  {
    key: "EmailInput",
  },
];

const AddForm = (props) => {
  const history = useHistory();

  const formManagementContext = useContext(FormManagementContext);
  const { currentViewForm } = formManagementContext;

  const [formContent, setFormContent] = useState({});
  const [status, setStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { formId } = useParams();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (formId) {
      formManagementContext.loadForm(formId).catch((e) => {
        errorToast({ description: "Error happened while loading Forms list" });
      });
    }
  }, []);

  const handleFormSubmit = () => {
    const formData = {
      title: currentViewForm?.title,
      status: currentViewForm.status ? currentViewForm.status : status,
      description: currentViewForm?.description,
      data: formContent,
    };
    formManagementContext
      .updateForm(currentViewForm.id, formData)
      .then((res) => {
        history.replace(FORM_PAGE);
        successToast({ description: "Successfully Updated" });
        if (props.onSuccessfulModal) props.onSuccessfulModal();
      })
      .catch((e) => {
        errorToast({ description: `${e.message}` });
      });
  };
  const onPost = (data) => {
    setFormContent(data.task_data);
  };

  return (
    <>
      <Block size="lg" className={"bg-white p-4"}>
        <BlockHead>
          <BlockHeadContent>
            <Row md="12" lg="12">
              <Col md="12" lg="12">
                <BlockTitle tag="h3" page>
                  Form Update
                </BlockTitle>
              </Col>
              <Col md="7" lg="7" className="mt-1">
                <h4>{currentViewForm?.title || "-"}</h4>
              </Col>
              <Col md="2" lg="2" className="pl-0">
                <Button color="outline-primary" onClick={toggleModal}>
                  Preview
                </Button>
              </Col>
              <Col md="3" lg="3" className="pl-0">
                {" "}
                {currentViewForm && (
                  <RSelect
                    id="status"
                    placeholder="Select the Status "
                    options={statusOptions}
                    onChange={(o) => setStatus(o.value)}
                    defaultValue={statusOptions.find((e) => e.value === currentViewForm.status)}
                    width="60%"
                  />
                )}
              </Col>
            </Row>
          </BlockHeadContent>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <div className="clearfix">
              <ReactFormBuilder toolbarItems={items} onPost={onPost} />
              <Button color="outline-primary" className={"my-3 mr-3"} onClick={() => history.replace(FORM_PAGE)}>
                Cancel
              </Button>
              <Button className={"my-3"} onClick={handleFormSubmit} color="primary">
                Save Form
              </Button>
            </div>
          </PreviewCard>
        </Block>
      </Block>
      <Modal isOpen={isOpen} toggle={toggleModal} size="md">
        <ModalHeader
          toggle={toggleModal}
          close={
            <button className="close" onClick={toggleModal}>
              <Icon name="cross" />
            </button>
          }
        >
          Preview
        </ModalHeader>
        <ModalBody>
          <div className="overflow-auto h-max-450px">
            <Block className={"bg-white overflow-auto"}>
              <Block>
                <div className="nk-data data-list">
                  <div>
                    <PreviewCard>
                      <ReactFormGenerator
                        download_path=""
                        back_action="/"
                        back_name="Back"
                        answer_data={{}}
                        action_name="Save"
                        form_action="/"
                        form_method="POST"
                        read_only={true}
                        hide_actions={true}
                        data={formContent}
                      />
                    </PreviewCard>
                  </div>
                </div>
              </Block>
            </Block>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddForm;
