/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import AssetForm from "../components/AssetFrom";
import { AssetContext, withAssetProvider } from "../AssetProvider";

const AssetFormContainer = (props) => {
  const assetContext = useContext(AssetContext);
  const { currentViewAsset, updateAsset, loadAsset } = assetContext;
  const { loadAllAssetCategories, loadAllManufacturers, getIdentifier } = assetContext;

  useEffect(() => {
    if (props.assetId) loadAsset(props.assetId);
  }, [props.assetId]);

  useEffect(() => {
    loadAllAssetCategories();
    loadAllManufacturers();
    getIdentifier();
  }, []);

  return (
    <React.Fragment>
      <AssetForm
        assetId={props.assetId}
        currentViewAsset={currentViewAsset}
        updateAsset={updateAsset}
        onSuccessfulModal={props.onSuccessfulModal}
      />
    </React.Fragment>
  );
};

export default withAssetProvider(AssetFormContainer);
