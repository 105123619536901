import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useHistory } from "react-router-dom";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Tooltip, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilteredBy";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import {
  USER_MANAGEMENT_CREATE_PAGE,
  USER_MANAGEMENT_EDIT_PAGE,
  USER_MANAGEMENT_VIEW_PAGE,
} from "../../../constants/routes";
import { findUpper } from "../../../utils/Utils";
import ModalViewer from "../../maintenance/ModalViewer";
import { RoleManagementContext } from "../../role_management/RoleManagementProvider";
import { UserManagementContext } from "../UserManagementProvider";
import ExportForm from "./ExportForm";
import UserListFilter from "./UserListFilter";
import { userType } from "../../../constants/constants";

const UserListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const history = useHistory();

  const userManagementContext = useContext(UserManagementContext);
  const roleManagementContext = useContext(RoleManagementContext);

  const {
    usersList,
    pagination,
    filterParams,
    handleApplyFilter,
    showFilter,
    isFilterApplied,
    sfState,
    filterOptions,
  } = userManagementContext;
  const { archiveUser, unarchiveUser, suspendUser, deleteUser, userLoading } = userManagementContext;

  const { allRoles } = roleManagementContext;

  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);
  const [tooltipOpen, setOpen] = useState(false);

  const toggleTooltip = () => {
    setOpen(!tooltipOpen);
  };

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };
  // function that loads the want to editted data
  const onEditClick = (id) => {
    history.push(USER_MANAGEMENT_EDIT_PAGE.replace(":userId", id));
  };

  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  const onSuccessfulModal = () => {
    toggleExportModal();
  };
  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
    return <>Edit</>;
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleArchive = async (userId) => {
    archiveUser(userId)
      .then((res) => {
        successToast({ description: "User Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive User" });
      });
  };
  const handleUnArchive = async (userId) => {
    unarchiveUser(userId)
      .then((res) => {
        successToast({ description: "User UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive User" });
      });
  };

  const handleSuspendUser = async (userId) => {
    suspendUser(userId)
      .then((res) => {
        successToast({ description: "User Suspended successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while suspended user" });
      });
  };

  const handleCreateUser = () => {
    history.push(USER_MANAGEMENT_CREATE_PAGE);
  };

  const handleDeleteUser = async (userId) => {
    deleteUser(userId)
      .then((res) => {
        successToast({ description: "User is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting User" });
      });
  };
  const handleDeleteConfirmation = async (userId) => {
    confirmationSweetAlert({
      id: userId,
      handleConfirmation: handleDeleteUser,
    });
  };

  const statusObj = {
    SUSPENDED: "danger",
    ACTIVE: "success",
    INACTIVE: "warning",
  };
  const currentItems = usersList?.items || [];

  let roles = {};

  for (let i = 0; i < allRoles?.length; i++) {
    roles[allRoles[i].value] = allRoles[i].label;
  }

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const selectedUserRoleOption = filterOptions.userRoles?.find((o) => o.value === filterParams.selectedUserRole);
  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Users Lists
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {usersList?.pagination?.totalCount} users.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {selectedUserRoleOption ? (
                <FilteredByList data={selectedUserRoleOption.label} title={"Role"} isStr={true} isNotMap={true} />
              ) : null}
              <FilteredByList data={filterParams.selectedUserStatus} title={"Status"} isStr={true} isNotMap={true} />
              <FilteredByList data={filterParams.searchText} title={"Searched by"} isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getUsers", "createUser", "updateUser", "updateUser", "updateUser"]}>
                    <li>
                      <Button
                        disabled={usersList?.pagination?.totalCount === 0}
                        className="btn btn-white btn-outline-light"
                        type="export"
                        isLoading={exportLoading}
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </Check>
                  <li className="nk-block-tools-opt">
                    <Check permission={["createUser"]}>
                      <Button color="primary" className="btn-icon" onClick={handleCreateUser}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </Check>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle">
            <div className="card-title-group">
              <div className="card-tools">
                <div className="form-inline flex-nowrap gx-3"></div>
              </div>
              <div className="card-tools mr-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                    {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                    <Icon name="filter-alt"></Icon>
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by name, email and phoneNumber.enter to search "
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact>
            <DataTableHead>
            <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "firstName"
                        ? handleNameSort("DESC", "firstName")
                        : handleNameSort("ASC", "firstName");
                    }}
                  >
                    User
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "firstName");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "firstName"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "firstName");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "firstName"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>

              <DataTableRow size="md">
                <span className="sub-text">User Type</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Role</span>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "email"
                        ? handleNameSort("DESC", "email")
                        : handleNameSort("ASC", "email");
                    }}
                  >
                    Email
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "email");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "email"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "email");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "email"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "phoneNumber"
                        ? handleNameSort("DESC", "phoneNumber")
                        : handleNameSort("ASC", "phoneNumber");
                    }}
                  >
                    Landline
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "phoneNumber");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "phoneNumber"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "phoneNumber");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "phoneNumber"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "mobileNumber"
                        ? handleNameSort("DESC", "mobileNumber")
                        : handleNameSort("ASC", "mobileNumber");
                    }}
                  >
                    Phone
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "mobileNumber");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "mobileNumber"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "mobileNumber");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "mobileNumber"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "lastActiveAt"
                        ? handleNameSort("DESC", "lastActiveAt")
                        : handleNameSort("ASC", "lastActiveAt");
                    }}
                  >
                  Last Active
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "lastActiveAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "lastActiveAt"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "lastActiveAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "lastActiveAt"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                        ? handleNameSort("DESC", "status")
                        : handleNameSort("ASC", "status");
                    }}
                  >
                    Status
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "status"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
            </DataTableHead>
            <div>
              <Sidebar toggleState={showFilter}>
                <div>
                  <UserListFilter />
                </div>
              </Sidebar>
            </div>
            {/*Head*/}
            {userLoading ? (  
              <></>
            ) : (
              currentItems.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <Link to={`${USER_MANAGEMENT_VIEW_PAGE}`.replace(":userId", item.id)}>
                        <div className="user-card">
                          <UserAvatar
                            theme={"primary"}
                            className="xs"
                            text={findUpper(item.firstName)}
                            image={item.image}
                          />
                          <div className="user-name">
                            <span data-toggle="tooltip"
                              title={item.description}
                              style={{
                                display: "inline-block",
                                width: (item.firstName+item.lastName)?.length > 50 ? "350px" : "",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }} className="tb-lead">
                              {item.firstName} {item.lastName}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>{userType(item.category) || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item?.userRoles
                          .map((userRole) => roles[userRole.roleId])
                          .join(", ")}
                        style={{
                          display: "inline-block",
                          width:
                            item?.userRoles
                              .map((userRole) => roles[userRole.roleId])
                              .join(", ").length > 50
                              ? "350px"
                              : "",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item.userRoles && item.userRoles[0] && roles[item.userRoles[0].roleId]}
                        {item.userRoles.length > 1 && `, `}
                        {item.userRoles && item.userRoles[1] && roles[item.userRoles[1].roleId]}
                        {item.userRoles.length > 2 && (
                          <>
                            <Badge pill color={"primary"} id="id" className="ml-1">
                              +{item.userRoles.length - 2}
                            </Badge>
                            <Tooltip placement="right" isOpen={tooltipOpen} target="id" toggle={toggleTooltip}>
                              {item?.userRoles
                                .map((userRole) => roles[userRole.roleId])
                                .join(", ")}
                            </Tooltip>
                          </>
                        )}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span data-toggle="tooltip"
                        title={item.email}
                        style={{
                          display: "inline-block",
                          width: item.email?.length > 50 ? "350px" : "",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}>{item.email || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{item.phoneNumber || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{item.mobileNumber || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span className="noWrap">
                        {item.lastActiveAt
                          ? new Date(item.lastActiveAt).toLocaleString("en-GB", { hour12: false })
                          : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow>
                      <span>
                        <Badge className="text-capitalize" color={statusObj[item.status]} pill>
                          {item.status}
                        </Badge>
                      </span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        {usersList.currentUser !== item.id && <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <React.Fragment>
                                  <Check permission={["updateUser"]}>
                                    <li /*className="nk-tb-action-hidden"*/ onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit User</span>
                                      </DropdownItem>
                                    </li>

                                    {item.isArchived ? (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => handleUnArchive(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#unarchive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="na"></Icon>
                                            <span>UnArchive User </span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    ) : (
                                      usersList.currentUser !== item.id && (
                                        <React.Fragment>
                                          <li className="divider"></li>
                                          <li onClick={() => handleArchive(item.id)}>
                                            <DropdownItem
                                              tag="a"
                                              href="#archive"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="na"></Icon>
                                              <span>Archive User</span>
                                            </DropdownItem>
                                          </li>
                                        </React.Fragment>
                                      )
                                    )}
                                  </Check>

                                  {item.status !== "SUSPENDED" && usersList.currentUser !== item.id && (
                                    <>
                                      <Check permission={["deleteUser"]}>
                                        <li className="divider"></li>
                                        <li onClick={() => handleDeleteConfirmation(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#delete"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Delete User</span>
                                          </DropdownItem>
                                        </li>
                                      </Check>
                                      <Check permission={["updateUser"]}>
                                        <li className="divider"></li>
                                        <li onClick={() => handleSuspendUser(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#suspend"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="cross-circle"></Icon>
                                            <span>Suspend User</span>
                                          </DropdownItem>
                                        </li>
                                      </Check>
                                    </>
                                  )}
                                </React.Fragment>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>}
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {userLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {usersList.pagination && usersList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={usersList.pagination.totalCount}
                paginate={userManagementContext.updateShowListPage}
                currentPage={usersList.pagination.currentPage}
                paginatePage={userManagementContext.updateShowListSize}
              />
            ) : (
              !userLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="User - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default UserListCompact;
