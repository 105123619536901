import download from "js-file-download";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  TooltipComponent,
} from "../../../../components/Component";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { PLANT_OWNED_FILTERS } from "../../../../constants/PreferenceKeys";
import ModalViewer from "../../../maintenance/ModalViewer";
import { PlantOwnedInventoryContext } from "../PlantOwnedProvider";
import { exportPlantOwnedInventory } from "../PlantOwnedRepository";
import PlantOwnedFormContainer from "../containers/PlantOwnedFormContainer";
import PlantOwnedMoveSparesContainer from "../containers/PlantOwnedMoveSpareContainer";
import PlantOwnedViewContainer from "../containers/PlantOwnedViewContainer";
import PlantOwnedListFilter from "./PlantOwnedListFilter";

const PlantOwnedCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const plantOwnedInventoryContext = useContext(PlantOwnedInventoryContext);

  const { plantOwnedInventoryList, pagination } = plantOwnedInventoryContext;
  const {
    filterParams,
    handleApplyFilter,
    filterOptions,
    loadPlantOwnedInventoryList,
    deleteInventory,
    plantOwnedInventoryLoading,
  } = plantOwnedInventoryContext;

  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [inventory, setInventory] = useState("");
  const [view, setView] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const supplier = {};
  const category = {};
  const plant = {};
  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
    MOVE: "MOVE",
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const filters = localStorage.getItem(PLANT_OWNED_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({ ...parseFilters });
    }
  }, []);
  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };
  const currentItems = plantOwnedInventoryList?.items || [];

  for (let i = 0; i < filterOptions.assetCategory?.length; i++) {
    category[filterOptions.assetCategory[i].value] = filterOptions.assetCategory[i].label;
  }
  for (let i = 0; i < filterOptions.manufacturer?.length; i++) {
    supplier[filterOptions.manufacturer[i].value] = filterOptions.manufacturer[i].label;
  }
  for (let i = 0; i < filterOptions.plant?.length; i++) {
    plant[filterOptions.plant[i].value] = filterOptions.plant[i].label;
  }
  const exportInventoryFuntion = async () => {
    try {
      setExportLoading(true);
      const response = await exportPlantOwnedInventory({ filterParams });
      download(response, `plantOwnedInventory.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };
  const viewInventory = (taskId) => {
    setInventory(taskId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editInventory = (taskId) => {
    setInventory(taskId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createInventory = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const moveSpareInventory = () => {
    setView(VIEW.MOVE);
    // setIsOpen(!isOpen)
  };

  const onSuccessfulModal = () => {
    toggleModal();
    loadPlantOwnedInventoryList();
  };
  const handleDeleteInventory = async (inventoryId) => {
    deleteInventory(inventoryId)
      .then((res) => {
        successToast({ description: "Inventory is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Inventory" });
      });
  };
  const handleDeleteConfirmation = async (inventoryId) => {
    confirmationSweetAlert({
      id: inventoryId,
      handleConfirmation: handleDeleteInventory,
    });
  };
  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <PlantOwnedViewContainer
          inventoryId={inventory}
          setIsOpen={setIsOpen}
          editInventory={editInventory}
          moveSpareInventory={moveSpareInventory}
        />
      );
    } else if (view === VIEW.CREATE) {
      return <PlantOwnedFormContainer onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.EDIT) {
      return <PlantOwnedFormContainer inventoryId={inventory} onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.MOVE) {
      return <PlantOwnedMoveSparesContainer inventoryId={inventory} onSuccessfulModal={onSuccessfulModal} />;
    }
  };
  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Plant Owned Inventory Lists
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {plantOwnedInventoryList?.pagination?.totalCount} Plant Owned Inventories.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getInventory", "manageInventory"]}>
                    <li>
                      <Button
                        className="btn btn-white btn-outline-light"
                        type="export"
                        isLoading={exportLoading}
                        onClick={() => exportInventoryFuntion()}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </Check>
                  <Check permission={["manageInventory"]}>
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={createInventory}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </Check>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools">
                {/* <div className="form-inline flex-nowrap gx-3">
                  <div className="form-wrap">
                    <RSelect
                      options={bulkActionOptions}
                      className="w-130px"
                      placeholder="Bulk Action"
                      onChange={(e) => onActionText(e)}
                    />
                  </div>
                  <div className="btn-wrap">
                    <span className="d-none d-md-block">
                      <Button
                        color="light"
                        outline
                        className="btn-dim"
                        onClick={(e) => onActionClick(e)}
                      >
                        Apply
                      </Button>
                    </span>
                    <span className="d-md-none">
                      <Button
                        color="light"
                        outline
                        className="btn-dim  btn-icon"
                        onClick={(e) => onActionClick(e)}
                      >
                        <Icon name="arrow-right"></Icon>
                      </Button>
                    </span>
                  </div>
                </div> */}
              </div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <PlantOwnedListFilter />
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by Equipment name.enter to search"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                    }}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <span className="sub-text">Product Id</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Equipment Name</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Code</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Category</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Total Spares</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Contracted QTY</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Key Component</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Plant Name</span>
              </DataTableRow>
              <DataTableRow></DataTableRow>
            </DataTableHead>
            {/*Head*/}
            {currentItems.length > 0 ? (
              currentItems.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <div
                        role="button"
                        tabIndex="0"
                        className="user-card"
                        onClick={() => {
                          viewInventory(item.id);
                        }}
                      >
                        <div className="user-name">
                          <span className="tb-lead">{item.id}</span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <div
                        role="button"
                        tabIndex="0"
                        className="user-card"
                        onClick={() => {
                          viewInventory(item.id);
                        }}
                      >
                        <span>{item.assets.model}</span>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <span>{item.partNumber}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{category[item.assets.assetCategoryId]}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.quantity}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.contractualQuantity}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.isKeyComponent ? "True" : "False"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{plant[item.plantId]}</span>
                    </DataTableRow>
                    <Check permission={["manageInventory"]}>
                      <DataTableRow className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">
                          <li className="nk-tb-action-hidden" onClick={() => editInventory(item.id)}>
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-trigger btn-icon"
                              id={"edit" + item.id}
                              icon="edit-alt-fill"
                              direction="top"
                              text="Edit"
                            />
                          </li>

                          {item.status !== "SUSPENDED" && (
                            <li className="nk-tb-action-hidden" onClick={() => handleDeleteConfirmation(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"delete-" + item.id}
                                icon="na"
                                direction="top"
                                text="Delete"
                              />
                            </li>
                          )}
                        </ul>
                      </DataTableRow>
                    </Check>
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>
          <div className="card-inner">
            {plantOwnedInventoryList.pagination && plantOwnedInventoryList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={plantOwnedInventoryList.pagination.totalCount}
                paginate={plantOwnedInventoryContext.updateShowListPage}
                currentPage={plantOwnedInventoryList.pagination.currentPage}
              />
            ) : (
              !plantOwnedInventoryLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {plantOwnedInventoryLoading && <Skeleton count={10} className="w-100" />}
      </Block>
      <ModalViewer
        size={view === VIEW.MOVE ? "md" : "xl"}
        title="Plant Owned Inventory"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
    </React.Fragment>
  );
};
export default PlantOwnedCompact;
