/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  Icon,
  PreviewCard,
} from "../../../components/Component";
import { PREVENTATIVE_SCHEDULE_EDIT, PVPLANT_MANAGEMENT_PREVENTATIVE_SCHEDULE } from "../../../constants/routes";
import { ScheduleContext } from "../ScheduleProvider";
import PreventativeTaskList from "./preventativeTasks/PreventativeTaskList";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Check from "../../../components/check_roles/check";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { modules } from "../../../components/ReactQuillEditor";


const PreventativeScheduleView = ({ plantId, preventativeScheduleId }) => {
  const [plant, setPlant] = useState({});
  const scheduleContext = useContext(ScheduleContext);
  const { setPreventativeTasks, getPreventativeScheduleById } = scheduleContext;

  const [currentPreventativeSchedule, setCurrentPreventativeSchedule] = useState({});
  const getPlant = async () => {
    const res = await scheduleContext.getPlant(plantId);
    setPlant(res);
  };

  const history = useHistory();

  useEffect(() => {
    if (plantId) {
      getPlant();
    }
  }, [plantId]);

  useEffect(() => {
    const getSchedule = async () => {
      try {
        const res = await getPreventativeScheduleById(preventativeScheduleId);
        setCurrentPreventativeSchedule(res.preventativeSchedule);
        setPreventativeTasks(res.tasks);
      } catch (err) {
        //handle err
      }
    };
    if (preventativeScheduleId) {
      getSchedule();
    }
  }, [preventativeScheduleId]);

  return (
    <>
      <Block size="lg">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <div style={{ marginBottom: "10px" }}>
                <Link
                  to={`${PVPLANT_MANAGEMENT_PREVENTATIVE_SCHEDULE}`.replace(":plantId", plantId)}
                  style={{ color: "white" }}
                >
                  <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                    <Icon name="arrow-left"></Icon>
                    <span>Back</span>
                  </Button>
                </Link>
              </div>

              <BlockTitle tag="h3" page>
                Preventative Schedule for {plant.name}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className="d-flex justify-content-end align-items-center">
              <Check permission={["manageTickets"]}>
                <a
                  href={PREVENTATIVE_SCHEDULE_EDIT.replace(":plantId", plantId).replace(
                    ":preventativeScheduleId",
                    preventativeScheduleId
                  )}
                  className="btn btn-primary mr-3"
                >
                  <Icon name="edit-alt-fill"></Icon>
                  <span>Edit</span>
                </a>
              </Check>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {Object.keys(currentPreventativeSchedule).length ? (
          <PreviewCard>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Row className="g-4">
                <Col lg="4" md="4">
                  <FormGroup>
                    <Label className="form-label" htmlFor="name">
                      <span style={{ color: "indianred" }}>&#42;</span>Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={currentPreventativeSchedule.name}
                        disabled={true}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="4" md="4">
                  <FormGroup className="form-group">
                    <Label className="form-label" htmlFor="name">
                      <span style={{ color: "indianred" }}>&#42;</span>Start Date
                    </Label>
                    <div className="form-control-wrap">
                      <ReactDatePicker
                        dateFormat="dd-MM-yyyy"
                        selected={
                          currentPreventativeSchedule.startDate ? new Date(currentPreventativeSchedule.startDate) : null
                        }
                        className="form-control date-picker"
                        disabled={true}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="4" md="4">
                  <FormGroup className="form-group">
                    <Label className="form-label" htmlFor="name">
                      <span style={{ color: "indianred" }}>&#42;</span>End Date
                    </Label>
                    <div className="form-control-wrap">
                      <ReactDatePicker
                        dateFormat="dd-MM-yyyy"
                        selected={
                          currentPreventativeSchedule.endDate ? new Date(currentPreventativeSchedule.endDate) : null
                        }
                        disabled={true}
                        className="form-control date-picker"
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 g-4">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Description
                  </Label>
                  <div className="form-control-wrap">
                    <ReactQuill
                      style={{ backgroundColor: "#f5f6fa"}}
                      readOnly={true}
                      name={"description"}
                      modules={modules}
                      theme="snow"
                      value={currentPreventativeSchedule.description ? currentPreventativeSchedule.description : ""}
                    />
                  </div>
                </FormGroup>
              </Row>
              <Row className="mt-3">
                <Col size="6">
                  <div className="custom-control custom-control-sm custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      id="isArchived-form"
                      checked={currentPreventativeSchedule.isArchived}
                      disabled={true}
                    />
                    <label className="custom-control-label" htmlFor="isArchived-form">
                      Archived
                    </label>
                  </div>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        ) : (
          <Spinner />
        )}
        <PreventativeTaskList preventativeScheduleId={preventativeScheduleId} />
      </Block>
    </>
  );
};
export default PreventativeScheduleView;
