/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { ScheduleContext, withPreventativeScheduleProvider } from "../ScheduleProvider";
import ExportForm from "../components/preventativeTasks/ExportForm";

const ExportContainer = (props) => {
  const { plantId } = props;
  const { filterParams } = useContext(ScheduleContext);

  return (
    <React.Fragment>
      <ExportForm plantId={plantId} filterParams={filterParams} onSuccessfulModal={props.onSuccessfulModal} />
    </React.Fragment>
  );
};

export default withPreventativeScheduleProvider(ExportContainer);
