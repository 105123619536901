import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import PreventativeScheduleForm from "../components/PreventativeScheduleForm";
import { ScheduleContext, withPreventativeScheduleProvider } from "../ScheduleProvider";

const PreventativeScheduleFormContainer = (props) => {
  const { getOptions } = useContext(ScheduleContext);
  const { plantId, preventativeScheduleId } = useParams();
  useEffect(() => {
    getOptions();
  }, []);
  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative Schedule | Edit" />
      <Content>
        <PreventativeScheduleForm plantId={plantId} preventativeScheduleId={preventativeScheduleId} />
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeScheduleProvider(PreventativeScheduleFormContainer);
