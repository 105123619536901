/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect, BlockTitle } from "../../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../../hooks/useBoolean";
import { MaintenanceContext } from "../../../../../providers/Maintenance.provider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { errorToast, successToast } from "../../../../../components/toastify/Toastify";
import Attachments from "../../../../../components/attachments/Attachment";
import { TASK } from "../../../../../constants/PreferenceKeys";
import { createCorrectiveSubtask, updateSubtaskAPI } from "../../CorrectiveRepository";
import CreateFormAttachments from "../../../../../components/attachments/CreateFormAttachment";
import { CorrectiveContext } from "../../CorrectiveProvider";
import { sortOption } from "../../../../../utils/Utils";
import { format } from "date-fns";
import { TextEditor } from "../../../../../components/ReactQuillEditor";

const createSchema = yup.object({
  title: yup.string().required().default(""),
  priority: yup.string().notRequired(),
  plantId: yup.number().required().default(""),
  status: yup.string().trim().required().default(""),
  startedAt: yup.date().required().default(""),
  resolvedAt: yup.date().min(yup.ref("startedAt")).nullable(),
  assignedToIds: yup.array().of(yup.number()).min(1).required(),
  fieldEngineerIds: yup.array().of(yup.number()).min(1).required(),
  description: yup.string().notRequired().nullable(),
  slaId: yup.number().notRequired().default(),
  assetCategoryId: yup.number().notRequired().default(),
  parentId: yup.number().notRequired().nullable(),
  comment: yup.string().notRequired().nullable(),
  labourHours: yup.number().notRequired().nullable(),
});

const updateSchema = yup.object({
  title: yup.string().required().default(""),
  priority: yup.string().notRequired(),
  plantId: yup.number().required().default(""),
  status: yup.string().trim().required().default(""),
  startedAt: yup.date().required().default(""),
  resolvedAt: yup.date().min(yup.ref("startedAt")).nullable(),
  assignedToIds: yup.array().of(yup.number()).min(1).required(),
  fieldEngineerIds: yup.array().of(yup.number()).min(1).required(),
  description: yup.string().notRequired().nullable(),
  slaId: yup.number().notRequired().default(),
  assetCategoryId: yup.number().notRequired().default(),
  parentId: yup.number().notRequired().nullable(),
  comment: yup.string().notRequired().nullable(),
  labourHours: yup.number().notRequired().nullable(),
});

const SubtaskForm = (props) => {
  const { filterOptions, loadFilterOptions } = useContext(MaintenanceContext);

  const { correctivetasks, loadAllCorrectiveTasks } = useContext(CorrectiveContext);

  const [isEditMode, updateEditMode] = useBoolean(false);
  const [loading, setLoading] = useBoolean(false);
  const [plantId, setPlantId] = useState();
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  const userFormRef = useForm({
    resolver: yupResolver(props.taskId ? updateSchema : createSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = userFormRef;

  useEffect(() => {
    if (props.plantId) {
      onChangePlant(parseInt(props.plantId));
    }
    if (!props.currentSubTask) {
      reset({
        startedAt: new Date(),
        status: "OPEN",
        plantId: parseInt(props.plantId),
        parentId: parseFloat(props.parentId),
      });
    }

    //to get the parent task dropdown based on plantId and currentTask
    loadAllCorrectiveTasks(props?.task?.plantId);

    updateEditMode.off();
    if (props.currentSubTask) {
      setPlantId(props.currentSubTask.plantId);
      props.currentSubTask.startedAt = new Date(props.currentSubTask.startedAt);

      if (props.currentSubTask.resolvedAt !== null) {
        props.currentSubTask.resolvedAt = new Date(props.currentSubTask.resolvedAt);
      }
      reset({
        ...props.currentSubTask,
        comment: props.currentSubTask.comment,
        parentId: props.currentSubTask.parentId,
      });
      updateEditMode.on();
    }
  }, [props.currentSubTask, props?.currentSubTask?.attachment]);

  const handleFormSubmit = async (formData) => {

    // Only date is required so removed timestamp which double converts to UTC;
    if (formData.resolvedAt) {
      formData.resolvedAt = format(formData.resolvedAt, 'yyyy-MM-dd')
    }
    if (formData.startedAt) {
      formData.startedAt = format(formData.startedAt, 'yyyy-MM-dd')
    }
    // ***


    if (props.taskId) {
      try {
        setLoading.on();
        await updateSubtaskAPI(props.taskId, { ...formData, parentId: props.parentId });
        successToast({ description: "Successfully Updated" });
        if (props.onSuccessfulModal) props.onSuccessfulModal();
      } catch (err) {
        errorToast({ description: "Error happened while updating Corrective Follow-upTask" });
      } finally {
        setLoading.off();
      }
    } else {
      setLoading.on();
      try {
        await createCorrectiveSubtask({ ...formData, parentId: props.parentId, attachments });
        successToast({ description: "Successfully Created" });
        if (props.onSuccessfulModal) props.onSuccessfulModal();
      } catch (err) {
        errorToast({ description: "Error happened while updating Corrective Follow-upTask" });
      } finally {
        setLoading.off();
      }
    }
  };
  let engineers = [];
  filterOptions.plants?.map((item) => {
    if (item.value === plantId) {
      for (let i = 0; i < filterOptions.assignedEngineer.length; i++) {
        if (item.fieldEngineerIds?.includes(filterOptions.assignedEngineer[i].value)) {
          engineers.push(filterOptions.assignedEngineer[i]);
        }
      }
    }
  });
  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button color="primary" size="lg" isLoading={loading} onClick={loading ? null : handleSubmit(handleFormSubmit)}>
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return isDirty ? (
      <Button color="primary" isLoading={loading} size="lg" onClick={loading ? null : handleSubmit(handleFormSubmit)}>
        Save Information
      </Button>
    ) : (
      <Button size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
        Cancel
      </Button>
    );
  };

  const attachmentFiles = (files) => {
    if (files) {
      setAttachments(files);
    }
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  const onChangePlant = (value) => {
    setPlantId(value);
    setValue("plantId", value);
    setValue("fieldEngineerIds", []);
    setValue("assignedToIds", []);
    setValue("startedAt", new Date());
    setValue("status", "OPEN");
  };

  const onChangeParentTask = (value) => {
    setValue("parentId", value);
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <BlockTitle tag="h5">{props.currentUser}'s follow-up task</BlockTitle>
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>Task Name
                  </Label>
                  <div id="subtask-task-name-input" className="form-control-wrap">
                    <input {...register("title")} type="text" name="title" className="form-control" />
                  </div>
                  {errors.title && (
                    <span className="invalid" style={{ color: "red" }}>
                      Title is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup id="subtask-priority-select">
                  <Label className="form-label" htmlFor="fv-full-name">
                    Priority
                  </Label>
                  <Controller
                    control={control}
                    name="priority"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = filterOptions.priorityStatuses;
                      const selectedValue = options.find((e) => e.value === field.value);
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={options}
                          onChange={(o) => setValue("priority", o.value)}
                        />
                      );
                    }}
                  />
                  {errors?.priority && (
                    <span className="invalid" style={{ color: "red" }}>
                      Priority is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup id="subtask-sla-select">
                  <Label className="form-label" htmlFor="fv-full-name">
                    SLA
                  </Label>
                  <Controller
                    control={control}
                    name="slaId"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = filterOptions.slas;
                      const selectedValue = options.find((e) => e.value === field.value);
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={sortOption(options)}
                          placeholder="Select the SLA"
                          onChange={(o) => setValue("slaId", o.value)}
                        />
                      );
                    }}
                  />
                  {errors?.slaId && (
                    <span className="invalid" style={{ color: "red" }}>
                      SLA is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup id="subtask-status-select" className="form-group">
                  <label className="form-label">
                    <span style={{ color: "indianred" }}>&#42;</span>Status
                  </label>
                  <Controller
                    control={control}
                    name="status"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = filterOptions.taskStatuses;
                      const selectedValue = options.find((e) => e.value === field.value);
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={options}
                          defaultValue={options[0]}
                          onChange={(o) => setValue("status", o.value)}
                        />
                      );
                    }}
                  />
                  {errors?.status && (
                    <span className="invalid" style={{ color: "red" }}>
                      Status is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup id="subtask-plant-name-select">
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>Plant Name
                  </Label>
                  <Controller
                    control={control}
                    name="plantId"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = filterOptions.plants;
                      const selectedValue = options.find((e) => e.value === field.value);
                      return (
                        <RSelect {...field} ref={ref} value={selectedValue} options={sortOption(options)} isDisabled />
                      );
                    }}
                  />
                  {errors?.plantId && (
                    <span className="invalid" style={{ color: "red" }}>
                      Plant name is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup id="subtask-field-engineer-select" className="form-group">
                  <label className="form-label">
                    <span style={{ color: "indianred" }}>&#42;</span>Field Engineer
                  </label>
                  <div>
                    <Controller
                      control={control}
                      name="fieldEngineerIds"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = engineers;
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                        return (
                          <RSelect
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            isMulti
                            options={sortOption(options)}
                            onChange={(o) =>
                              setValue(
                                "fieldEngineerIds",
                                o.map((item) => item.value),
                                { shouldValidate: true }
                              )
                            }
                          />
                        );
                      }}
                    />
                  </div>
                  {errors?.fieldEngineerIds && (
                    <span className="invalid" style={{ color: "red" }}>
                      Field Engineer is required
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup id="subtask-startdate-select">
                  <Label className="form-label" htmlFor="fv-full-name">
                    Start Date
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="startedAt"
                      render={({ field }) => {
                        return (
                          <DatePicker
                            ref={field.ref}
                            dateFormat="dd/MM/yyyy"
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control date-picker"
                          />
                        );
                      }}
                    />
                  </div>
                  {errors?.startedAt && <span className="invalid">Start Date is required</span>}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup id="subtask-assignto-select" className="form-group">
                  <label className="form-label">
                    <span style={{ color: "indianred" }}>&#42;</span>Assigned To
                  </label>
                  <Controller
                    control={control}
                    name="assignedToIds"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = engineers;
                      const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          isMulti
                          options={sortOption(options)}
                          onChange={(o) => {
                            setValue(
                              "assignedToIds",
                              o.map((item) => item.value),
                              { shouldValidate: true }
                            );
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.assignedToIds && (
                    <span className="invalid" style={{ color: "red" }}>
                      Assigned To is required
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup id="subtask-resolve-select" className="form-group">
                  <label className="form-label">Resolved Date</label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="resolvedAt"
                      render={({ field, ref }) => {
                        return (
                          <DatePicker
                            timeInputLabel="Time:"
                            dateFormat="dd/MM/yyyy h:mm aa"
                            showTimeInput
                            selected={field.value ? field.value : 0}
                            onChange={(date) => {
                              setValue("resolvedAt", date, { shouldValidate: true, shouldDirty: true });
                            }}
                            className="form-control date-picker"
                          />
                        );
                      }}
                    />
                  </div>
                  {errors?.resolvedAt && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.resolvedAt.message.includes("later") &&
                        `Resolved Date should be greater than Start Date`}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup id="subtask-assetcategory-select" className="form-group">
                  <label className="form-label">Asset Category</label>
                  <Controller
                    control={control}
                    name="assetCategoryId"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = filterOptions.assetCategories;
                      const selectedValue = options.find((e) => e.value === field.value);
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={sortOption(options)}
                          placeholder="Select the Asset category "
                          onChange={(o) => setValue("assetCategoryId", o.value)}
                        />
                      );
                    }}
                  />
                  {errors?.assetCategoryId && (
                    <span className="invalid" style={{ color: "red" }}>
                      Asset Category is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup id="subtask-labour-input">
                  <Label className="form-label" htmlFor="labourHours">
                    Labour Hours
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      {...register("labourHours", {
                        setValueAs: (v) => (v ? parseInt(v) : parseInt(0)),
                      })}
                      type="number"
                      name="labourHours"
                      className="form-control"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup id="subtask-description-input" className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field, ref }) => {
                        return (
                          <TextEditor
                            field={field}
                            setValue={setValue}
                            fieldName={"description"}
                          />

                        );
                      }}
                    />
                  </div>
                  {errors?.description && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.description.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup id="subtask-comment-input" className="form-group">
                  <label className="form-label">Comment</label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="comment"
                      render={({ field, ref }) => {
                        return (
                          <TextEditor
                            field={field}
                            setValue={setValue}
                            fieldName={"comment"}
                          />

                        );
                      }}
                    />
                  </div>
                  {errors?.comment && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.comment.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup id="subtask-parenttask-select">
                  <Label className="form-label" htmlFor="fv-full-name">
                    Parent Task
                  </Label>
                  <Controller
                    control={control}
                    name="parentId"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = correctivetasks;
                      const selectedValue = options.find((e) => e.value === field.value);
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={sortOption(options)}
                          onChange={(o) => {
                            onChangeParentTask(o.value);
                          }}
                          isDisabled
                        />
                      );
                    }}
                  />
                  {errors?.parentId && <span className="invalid"></span>}
                </FormGroup>
              </Col>
              {isEditMode && props.currentSubTask && (
                <Col lg="12">
                  <FormGroup className="form-group">
                    <div className="form-control-wrap">
                      <Attachments
                        module={TASK}
                        id={props.currentSubTask.id}
                        attachments={props.currentSubTask.attachment}
                        subModule={""}
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}
              {!isEditMode && (
                <Col lg="12">
                  <FormGroup className="form-group">
                    <div className="form-control-wrap">
                      <CreateFormAttachments attachmentFiles={attachmentFiles} />
                    </div>
                  </FormGroup>
                </Col>
              )}
              <Col className="justify-end" xl="12">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default SubtaskForm;
