import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import PreventiveMaintenanceList from "../components/PreventativeMaintenanceList";
import { withPreventativeProvider, PreventativeContext } from "../PreventativeProvider";

const PreventiveMaintenanceContainer = (props) => {
  const { loadPreventativeMasters } = useContext(PreventativeContext);
  const { pagination, filterParams } = useContext(PreventativeContext);
  const { preventiveMasterListId } = useParams();

  useEffect(() => {
    loadPreventativeMasters(preventiveMasterListId);
  }, [pagination, filterParams]);

  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative Master" />
      <Content>
        <PreventiveMaintenanceList />
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeProvider(PreventiveMaintenanceContainer);
