import React, { useContext } from "react";
import { HomeContext } from "./HomeProvider";
import { Row, Col, Card, CardTitle, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  CORRECTIVE_MAINTENANCE_PAGE,
  PREVENTATIVE_TASKS_PAGE,
  REMEDIAL_MAINTENANCE_PAGE,
  STRING_MAINTENANCE,
} from "../../constants/routes";
import Skeleton from "react-loading-skeleton";

const HomePage = (props) => {
  const history = useHistory();

  const { dashboardData, error, isLoadingDashboard } = useContext(HomeContext);

  const header = (props) => {
    switch (props) {
      case "correctiveTaskTotal":
        return "Corrective";
      case "preventativeTaskTotal":
        return "Preventative (Current month)";
      case "remedialTaskTotal":
        return "Remedial";
      case "stringTotal":
        return "String";
      default:
        return "n/a";
    }
  };

  const titleData = (props) => {
    switch (props) {
      case "OPEN":
        return <div style={{ color: "red" }}>New</div>;
      case "IN_PROGRESS":
        return <div style={{ color: "green" }}>In progress</div>;
      case "READY_FOR_APPROVAL":
        return <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>Ready for approval</div>;
      case "COMPLETED":
        return <div style={{ color: "blue" }}>Closed</div>;
      case "VALIDATE":
        return <div style={{ color: "deeppink", display: "flex", textAlign: "center" }}>Ready for approval</div>;
      case "CLOSED":
        return <div style={{ color: "blue" }}>Closed</div>;
      default:
        return "n/a";
    }
  };
  const switchRoutes = (props) => {
    switch (props) {
      case "correctiveTaskTotal":
        return CORRECTIVE_MAINTENANCE_PAGE;
      case "preventativeTaskTotal":
        return PREVENTATIVE_TASKS_PAGE;
      case "remedialTaskTotal":
        return REMEDIAL_MAINTENANCE_PAGE;
      case "stringTotal":
        return STRING_MAINTENANCE;
      default:
        return "n/a";
    }
  };

  const dashboardDataKeys = Object.entries(dashboardData);

  return (
    <>
      <h3>Dashboard</h3>
      {isLoadingDashboard ? (
        <></>
      ) : (
        <div>
          {error ? (
            <Card className="card-bordered m-5" style={{ alignItems: "center" }}>
              <h4 className="p-5 ">You do not have permission to access the dashboard</h4>
            </Card>
          ) : (
            <Row>
              {dashboardDataKeys &&
                dashboardDataKeys.map((taskData, index) => {
                  return (
                    <Col md="6" lg="6" sm="12" className="p-2" m="2" key={`dashboard-${index}`}>
                      <Card>
                        <CardTitle className="m-3" style={{ fontWeight: "bolder", fontSize: "20px" }}>
                          {header(taskData[0])}
                        </CardTitle>
                        <CardBody>
                          <Row className="match-height form-row">
                            {taskData[1].length &&
                              taskData[1].map((data, index) => {
                                return (
                                  <Col xs="6" sm="3" md="6" lg="3" key={`data-${index}`}>
                                    <Card
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        history.push({
                                          pathname: switchRoutes(taskData[0]),
                                          search: `?status=${data.status}&dashboard=yes`,
                                        });
                                      }}
                                    >
                                      {data.status === "OPEN" && (
                                        <CardTitle
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: "40px",
                                            color: "red",
                                          }}
                                        >
                                          {data.count}
                                        </CardTitle>
                                      )}
                                      {(data.status === "COMPLETED" || data.status === "CLOSED") && (
                                        <CardTitle
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: "40px",
                                            color: "blue",
                                          }}
                                        >
                                          {data.count}
                                        </CardTitle>
                                      )}
                                      {(data.status === "READY_FOR_APPROVAL" || data.status === "VALIDATE") && (
                                        <CardTitle
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: "40px",
                                            color: "deeppink",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {data.count}
                                        </CardTitle>
                                      )}
                                      {data.status === "IN_PROGRESS" && (
                                        <CardTitle
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: "40px",
                                            color: "green",
                                          }}
                                        >
                                          {data.count}
                                        </CardTitle>
                                      )}
                                      <CardBody
                                        style={{ display: "flex", justifyContent: "center", fontWeight: "bolder" }}
                                        className="p-0"
                                      >
                                        {titleData(data.status)}
                                      </CardBody>
                                    </Card>
                                  </Col>
                                );
                              })}
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          )}
        </div>
      )}
      {isLoadingDashboard && <Skeleton count={1} className="w-100 " height={200} />}
    </>
  );
};
export default HomePage;
