/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import HealthAndSafetyForm from "../components/HealthAndSafetyForm";
import { HealthAndSafetyContext, withHealthAndSafetyProvider } from "../HealthAndSafetyProvider";

const HealthAndSafetyFormContainer = (props) => {
  const healthAndSafetyContext = useContext(HealthAndSafetyContext);
  const { currentViewHealthAndSafety, updateHealthAndSafety, loadHealthAndSafety } = healthAndSafetyContext;

  useEffect(() => {
    if (props.healthAndSafetyId) loadHealthAndSafety(props.healthAndSafetyId);
  }, [props.healthAndSafetyId]);

  return (
    <React.Fragment>
      <HealthAndSafetyForm
        healthAndSafetyId={props.healthAndSafetyId}
        currentViewHealthAndSafety={currentViewHealthAndSafety}
        updateHealthAndSafety={updateHealthAndSafety}
        onSuccessfulModal={props.onSuccessfulModal}
        onHealthAndSafetySubmit ={props.onHealthandSafetyCreate}
      />
    </React.Fragment>
  );
};

export default withHealthAndSafetyProvider(HealthAndSafetyFormContainer);
