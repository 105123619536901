import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PaginationComponent,
  PreviewCard,
  Row,
} from "../../../components/Component";
import { FORM_SUBMISSION } from "../../../constants/routes";
import { FormManagementContext } from "../FormManagementProvider";
import Skeleton from "react-loading-skeleton";

const FormListCompact = () => {
  const formManagementContext = useContext(FormManagementContext);
  const { formItems, formLoading, pagination, updateShowListSize, updateShowListPage } = formManagementContext;

  const [sm, updateSm] = useState(false);

  const currentItems = formItems?.items || [];

  return (
    <React.Fragment>
      {
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Form Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total of {formItems?.pagination?.totalCount || 0} forms.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      }
      <Block>
        <PreviewCard>
          {formLoading ? (
            <></>
          ) : (
            <Row>
              {currentItems.length > 0
                ? currentItems.map((item, key) => {
                    return (
                      <Col lg="12" md="12" sm="12" key={key}>
                        <Card className="card-bordered mb-4">
                          <CardBody className="card-inner mb-0 p-2">
                            <BlockBetween>
                              <BlockContent>
                                <CardTitle tag="h5">{item?.title || "-"}</CardTitle>
                                <CardText>{item?.description || "-"}</CardText>
                              </BlockContent>
                              <BlockContent>
                                <Link to={FORM_SUBMISSION.replace(":formId", item.id)}>
                                  <Button color="primary">
                                    {" "}
                                    <Icon name="note-add-fill"></Icon>
                                    <span>Add Response</span>
                                  </Button>
                                </Link>
                              </BlockContent>
                            </BlockBetween>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })
                : "No Record"}
            </Row>
          )}
          {formLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {formItems.pagination && formItems.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={formItems.pagination.totalCount}
                paginate={updateShowListPage}
                currentPage={formItems.pagination.currentPage}
                paginatePage={updateShowListSize}
              />
            ) : (
              !formLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </PreviewCard>
      </Block>
    </React.Fragment>
  );
};
export default FormListCompact;
