/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import SlaForm from "../components/SlaForm";
import { SlaContext, withSlaProvider } from "../SlaProvider";

const SlaFormContainer = (props) => {
  const slaContext = useContext(SlaContext);

  const { currentViewSla, updateSla, loadSla } = slaContext;

  useEffect(() => {
    if (props.slaId) loadSla(props.slaId);
  }, [props.slaId]);

  return (
    <React.Fragment>
      <SlaForm
        slaId={props.slaId}
        currentViewSla={currentViewSla}
        updateSla={updateSla}
        onSuccessfulModal={props.onSuccessfulModal}
      />
    </React.Fragment>
  );
};

export default withSlaProvider(SlaFormContainer);
