import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import { getSlaList, updateSlaAPI, createSlaAPI, getSlaAPI, deleteSlaAPI, getSlaFilterListAPI } from "./SlaRepository";

export const SlaContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterParams = {
  searchText: "",
  sortingField: "name",
  sortingOrder: "ASC",
  createdAt: "",
  updatedAt: "",
};

export const SlaProvider = (props) => {
  const [slaList, setSlaList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [currentViewSla, setCurrentViewSla] = React.useState();
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  //Loaders

  const [slaLoading, slaState] = useBoolean(false);
  const [getSlaLoading, getSlaState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);

  const getListFilterParams = () => {
    const queryParams = { ...pagination };
    const { searchText, sortingField, sortingOrder ,createdAt,
    updatedAt} = filterParams;
    if (searchText) queryParams["slaSearchText"] = searchText;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (createdAt) queryParams["createdAt"] = createdAt;
    if (updatedAt) queryParams["updatedAt"] = updatedAt;
    return queryParams;
  };

  //LIST ALL SLA
  const loadSlaList = async () => {
    slaState.on();
    try {
      const filterParams = getListFilterParams();
      const response = await getSlaList(filterParams);
      setSlaList(response.slasList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      slaState.off();
    }
  };

  //CREATE SLA

  const createSla = async (createParams) => {
    try {
      slaState.on();
      await createSlaAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      slaState.off();
    }
  };

  // VIEW SLA

  const loadSla = async (slaId) => {
    getSlaState.on();
    try {
      const response = await getSlaAPI(slaId);
      setCurrentViewSla(response.sla);
    } catch (e) {
      throw e;
    } finally {
      getSlaState.off();
    }
  };

  // UPDATE SLA

  const updateSla = async (slaId, updateParams) => {
    slaState.on();
    try {
      const response = await updateSlaAPI(slaId, updateParams);
      setCurrentViewSla(response.sla);
    } catch (e) {
      throw e;
    } finally {
      slaState.off();
    }
  };

  // DELETE SLA

  const deleteSla = async (slaId) => {
    try {
      await deleteSlaAPI(slaId);
      loadSlaList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const checkIsFilterApplied = () => {
    if (filterParams.createdAt || filterParams.updatedAt) {
      return true;
    } else return false;
  };

  const isFilterApplied = checkIsFilterApplied();
  const resetSlaListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));
  const mContext = {
    pagination,
    slaList,
    loadSlaList,
    currentViewSla,
    slaLoading,
    deleteSla,
    createSla,
    updateSla,
    loadSla,
    handleApplyFilter,
    filterParams,
    getSlaLoading,
    showFilter,
    sfState,
    isFilterApplied,
    resetSlaListFilter,
    checkIsFilterApplied,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <SlaContext.Provider value={mContext}>{props.children}</SlaContext.Provider>;
};

export const withSlaProvider = (Container, containerProps) => (props) =>
  (
    <SlaProvider>
      <Container {...containerProps} {...props} />
    </SlaProvider>
  );
