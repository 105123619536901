/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewAsset from "../components/AssetView";
import { AssetContext, withAssetProvider } from "../AssetProvider";

const AssetViewContainer = props => {
    const { assetId, setIsOpen, editAsset } = props

    const assetContext = useContext(AssetContext)
    const { loadAsset } =assetContext

    useEffect(() => {
        if (assetId) loadAsset(assetId)
    }, [assetId])

    return (
        <React.Fragment>
            <ViewAsset setIsOpen={setIsOpen} editAsset={editAsset} />
        </React.Fragment>
    );
}

export default withAssetProvider(AssetViewContainer);