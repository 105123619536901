import { format } from "date-fns";
import React, { useContext, useEffect } from "react";
import { Col, FormGroup, Spinner, Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  Icon,
  Button,
  BlockTitle,
} from "../../../../../components/Component";
import { useHistory } from "react-router-dom";
import { MaintenanceContext } from "../../../../../providers/Maintenance.provider";
import { PreventativeTaskContext } from "../PreventativeTaskProvider";
import Attachments from "../../../../../components/attachments/Attachment";
import { TASK } from "../../../../../constants/PreferenceKeys";
import { errorToast, successToast } from "../../../../../components/toastify/Toastify";
import { confirmationSweetAlert } from "../../../../../components/sweetAlert/SweetAlert";
import Comments from "../../../../../components/comments/Comments";
import ActivityLog from "../../../../../components/activity_log/components/ActivityLog";
import { HOME_PAGE } from "../../../../../constants/routes";

const VIEW = {
  SUB_TASKS: "SUB_TASKS",
  EQUIPMENTS: "EQUIPMENTS",
};

const ViewPreventativeTask = ({ sm, updateSm, currentTaskId }) => {
  const history = useHistory();

  const taskContext = useContext(PreventativeTaskContext);
  const { filterOptions } = useContext(MaintenanceContext);
  const { currentViewTask, deleteTask } = taskContext;

  let engineers = {};
  let plantNames = {};
  let priority = {};
  let status = {};
  let slas = {};
  let assetCategories = {};
  for (let i = 0; i < filterOptions.taskStatuses?.length; i++) {
    status[filterOptions.taskStatuses[i].value] = filterOptions.taskStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }
  for (let i = 0; i < filterOptions.plants?.length; i++) {
    plantNames[filterOptions.plants[i].value] = filterOptions.plants[i].label;
  }
  for (let i = 0; i < filterOptions.priorityStatuses?.length; i++) {
    priority[filterOptions.priorityStatuses[i].value] = filterOptions.priorityStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.slas?.length; i++) {
    slas[filterOptions.slas[i].value] = filterOptions.slas[i].label;
  }
  for (let i = 0; i < filterOptions.assetCategories?.length; i++) {
    assetCategories[filterOptions.assetCategories[i].value] = filterOptions.assetCategories[i].label;
  }

  if (!currentViewTask) return <Spinner />;

  const handleDeletePreventative = async (currentTaskId) => {
    deleteTask(currentTaskId)
      .then((res) => {
        history.push(HOME_PAGE);
        successToast({ description: "Preventative task is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Preventative task" });
      });
  };

  const handleDeleteConfirmation = async (currentTaskId) => {
    confirmationSweetAlert({
      id: currentTaskId,
      handleConfirmation: handleDeletePreventative,
    });
  };
  return (
    <>
      <Block className={"bg-white p-4"}>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Preventative task</BlockTitle>
              <BlockDes>
                <p>Basic info.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-end">
              <Button
                color="danger"
                onClick={() => {
                  handleDeleteConfirmation(currentViewTask.id);
                }}
              >
                <Icon name="na" />
                <span>Delete</span>
              </Button>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block size="lg">
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Ticket Name</span>
                <span className="profile-ud-value">{currentViewTask.title || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Identifier</span>
                <span className="profile-ud-value">{currentViewTask.identifier || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Status</span>
                <span className="profile-ud-value">{status[currentViewTask.status] || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Plant Name</span>
                <span className="profile-ud-value">{plantNames[currentViewTask.plantId] || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Start Date</span>
                <span className="profile-ud-value">
                  {" "}
                  {currentViewTask?.startedAt
                    ? format(new Date(currentViewTask?.startedAt || null), "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Resolved Date</span>
                <span className="profile-ud-value">
                  {" "}
                  {currentViewTask?.resolvedAt
                    ? format(new Date(currentViewTask?.resolvedAt || null), "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Priority</span>
                <span className="profile-ud-value"> {priority[currentViewTask.priority] || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">SLA</span>
                <span className="profile-ud-value">{slas[currentViewTask.slaId] || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Code</span>
                <span className="profile-ud-value">{currentViewTask.code || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Month</span>
                <span className="profile-ud-value">{currentViewTask.month || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Year</span>
                <span className="profile-ud-value">{currentViewTask.year || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Asset Category</span>
                <span className="profile-ud-value">{slas[currentViewTask.slaId] || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Assigned Engineer</span>
                <span className="profile-ud-value">
                  {currentViewTask?.assignedEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ") || "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Field Engineer</span>
                <span className="profile-ud-value">
                  {currentViewTask?.fieldEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ") || "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Frequency</span>
                <span className="profile-ud-value">{currentViewTask.frequency || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Description</span>
                <span className="profile-ud-value">{currentViewTask.description || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Comment</span>
                <span className="profile-ud-value">{currentViewTask.comments || "-"}</span>
              </div>
            </div>
          </div>
          <div className="nk-data data-list">
            {
              <Col lg="12 pt-2">
                <Attachments
                  module={TASK}
                  id={currentTaskId}
                  attachments={currentViewTask.attachment}
                  subModule={"view"}
                />
              </Col>
            }
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              CreatedAt: {format(new Date(currentViewTask?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              UpdatedAt: {format(new Date(currentViewTask?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
        </Block>
        <Block>
          <ActivityLog id={currentViewTask.id} entityType="TASK" />
        </Block>
      </Block>
    </>
  );
};
export default ViewPreventativeTask;
