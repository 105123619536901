/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import ViewForm from "../components/viewSubmittedForm";
import { FormManagementContext, withFormManagementProvider } from "../FormManagementProvider";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const FormManagementViewContainer = (props) => {
  const location = useLocation().search;
  const latestVersion = new URLSearchParams(location).get("version");
  const params = useParams();
  const { formId } = params;

  const { loadSubmittedForm, loadForm, filterParams, pagination } = useContext(FormManagementContext);

  useEffect(() => {
    if (formId) {
      loadForm(formId, latestVersion);
      loadSubmittedForm(formId, latestVersion);
    }
  }, [formId, filterParams, pagination]);

  return (
    <React.Fragment>
      <Head title={`Brighter App | ${formId} - Form Management`} />
      <Content>
        <ViewForm />
      </Content>
    </React.Fragment>
  );
};

export default withFormManagementProvider(FormManagementViewContainer);
