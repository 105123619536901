import React, { useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../../components/Component";
import { AssetContext } from "../AssetProvider";
import { sortOption } from "../../../../utils/Utils";

const AssetListFilter = () => {
  const assetContext = React.useContext(AssetContext);
  const { filterOptions } = assetContext;
  const { filterParams, handleApplyFilter, checkIsFilterApplied, sfState, showFilter } = assetContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    assetContext.resetAssetListFilter();
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    updateTableSm(false);
    checkIsFilterApplied();
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };
  const handleModelFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedModel: selectedOption.value });
  };

  const handleManufacturerFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedManufacturer: selectedOption.value });
  };
  const handleAssetCategoryFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedAssetCategory: selectedOption.value });
  };

  const selectedManufacturerOption =
    filterOptions.manufacturers?.find((o) => o.value === selectedFilter.selectedManufacturer) || null;
  const selectedAssetCategoryOption =
    filterOptions.assetCategories?.find((o) => o.value === selectedFilter.selectedAssetCategory) || null;

  const selectedModelOption = filterOptions.models?.find((o) => o.value === selectedFilter.selectedModel) || null;

  return (
    <div className="vh-100  ">
      <div className="dropdown-head">
        <span className="sub-title dropdown-title fs-16px">Filter Options</span>
        <div onClick={sfState.off}>
          <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
        </div>
      </div>
      <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Model</label>
                <RSelect
                  options={sortOption(filterOptions.models)}
                  placeholder="Any Model"
                  onChange={handleModelFilterSelection}
                  value={selectedModelOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Manufacturer</label>
                <RSelect
                  options={sortOption(filterOptions.manufacturers)}
                  placeholder="Any Manufacturer"
                  onChange={handleManufacturerFilterSelection}
                  value={selectedManufacturerOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Asset Category</label>
                <RSelect
                  options={sortOption(filterOptions.assetCategories)}
                  placeholder="Any Asset Category"
                  onChange={handleAssetCategoryFilterSelection}
                  value={selectedAssetCategoryOption}
                />
              </FormGroup>
            </Col>

            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                {" "}
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </UncontrolledDropdown>
    </div>
  );
};

export default AssetListFilter;
