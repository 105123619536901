/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import EquipmentAndSpareList from "../components/PvPlantEquipmentList";
import {
  EquipmentAndSpareContext,
  withEquipmentAndSpareProvider,
} from "../../equipment_spare/EquipmentAndSpareProvider";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";

const EquipmentAndSpareListContainer = (props) => {
  const { loadEquipmentList, loadFilterOptions, filterParams, pagination, getTableSettings } = useContext(EquipmentAndSpareContext);
  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const { currentViewPvPlant } = pvPlantManagementContext;
  const { id } = currentViewPvPlant;

  
  const fetchEquipmentsData = async () => {
    const settings = await getTableSettings({
      entityType: "EQUIPMENT_PLANT",
    });
    if(settings && id){
      await loadEquipmentList(settings,id);
    }
  };
  useEffect(() => {
    fetchEquipmentsData();
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Content>
        <EquipmentAndSpareList plantId={id} />
      </Content>
    </React.Fragment>
  );
};

export default withEquipmentAndSpareProvider(EquipmentAndSpareListContainer);
