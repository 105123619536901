import { format } from "date-fns";
import React, { useContext } from "react";
import { Spinner } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, Button, Icon } from "../../../../components/Component";
import Check from "../../../../components/check_roles/check";
import Head from "../../../../layout/head/Head";
import { AssetCategoryContext } from "../AssetCategoryProvider";

const ViewAssetCategory = ({ sm, updateSm, editAssetCategory }) => {
  const assetCategoryContext = useContext(AssetCategoryContext);
  const { currentViewAssetCategory } = assetCategoryContext;

  return (
    <div className="overflow-auto h-max-450px">
      <Head title="Brighter App | Asset Category | View" />
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md" className="d-flex justify-content-end">
          <BlockBetween>
            <BlockHeadContent className="align-self-end">
              <Check permission={["manageEntity"]}>
                <Button
                  id="edit-category"
                  size="sm"
                  color="primary"
                  className="m-2"
                  onClick={() => {
                    editAssetCategory(currentViewAssetCategory.id);
                  }}
                >
                  <Icon name="edit" />
                  <span>Edit</span>
                </Button>
              </Check>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {!currentViewAssetCategory ? (
          <div className="m-3" style={{ display: "flex", justifyContent: "center" }}>
            <Spinner />
          </div>
        ) : (
          <Block>
            <div className="nk-data data-list">
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Category</span>
                  <span className="data-value">{currentViewAssetCategory.name} </span>
                </div>
              </div>
              <div className="m-2 ml-3">
                <small class="text-muted">
                  CreatedAt: {format(new Date(currentViewAssetCategory?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
                </small>
              </div>
              <div className="m-2 ml-3">
                <small class="text-muted">
                  UpdatedAt: {format(new Date(currentViewAssetCategory?.updated_at || null), "dd/MM/yyyy HH:mm")}{" "}
                </small>
              </div>
            </div>
          </Block>
        )}
      </Block>
    </div>
  );
};
export default ViewAssetCategory;
