/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, Row, Tooltip } from "reactstrap";
import { Block, Icon, PreviewCard, RSelect } from "../../../components/Component";
import Attachments from "../../../components/attachments/Attachment";
import useBoolean from "../../../hooks/useBoolean";
import Head from "../../../layout/head/Head";
import { PvPlantManagementContext } from "../../pv_plant_management/PvPlantManagementProvider";
import { EquipmentAndSpareContext } from "../EquipmentAndSpareProvider";
import { createSchema } from "../validation";
import { TextEditor } from "../../../components/ReactQuillEditor";

const EquipmentAndSpareFormComponent = (props) => {
  const { filterOptions, createEquipment, isLoading, currentEquipment, updateEquipment, getAllTaskOption, loadEquipmentList, getTableSettings } =
    useContext(EquipmentAndSpareContext);
  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const currentPvPLantData = pvPlantManagementContext?.currentViewPvPlant;
  const currentPvPLant = currentPvPLantData?.id;
  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
    MOVE: "MOVE",
  };

  const equipmentFormRef = useForm({
    resolver: yupResolver(props.taskId ? createSchema : createSchema),
  });

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });
  const [isCategory, updateIsCategory] = useState("");
  const [supplier, setSupplier] = useState();
  const [isPlantOwned, ipoState] = useBoolean(true);
  const [isKeyComponent, ikcState] = useBoolean(false);
  const [assetId, setAssetId] = useState(null);

  let manufacturer = {};
  for (let i = 0; i < filterOptions?.manufacturer?.length; i++) {
    manufacturer[filterOptions?.manufacturer[i].value] = filterOptions?.manufacturer[i].label;
  }

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = equipmentFormRef;

  const QuantityCountArray = watch([
    "bgeWarehouseQuantity",
    "externalWarehouseQuantity",
    "supplierPremisesQuantity",
    "spareQuantity",
  ]);
  const quantity = QuantityCountArray.reduce((a, b) => +a + +b, 0);

  useEffect(() => {
    if (!isNaN(quantity)) setValue("quantity", quantity);
  }, [quantity]);

  let asset = {};

  const fetchEquipmentsData = async () => {
    const settings = await getTableSettings({
      entityType: "EQUIPMENT_AND_SPARES",
    });
    if(settings){
      await loadEquipmentList(settings);
    }
  };

  filterOptions.asset?.map((item) => {
    if (asset[item.assetCategoryId]) {
      asset[item.assetCategoryId].push({
        value: item.value,
        label: item.label,
        assetCategoryId: item.assetCategoryId,
        manufacturerId: item.manufacturerId,
      });
    } else {
      asset[item.assetCategoryId] = [
        {
          value: item.value,
          label: item.label,
          assetCategoryId: item.assetCategoryId,
          manufacturerId: item.manufacturerId,
        },
      ];
    }
  });
  const [tooltipOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!tooltipOpen);
  };
  useEffect(() => {
    getAllTaskOption();
  }, []);

  useEffect(() => {
    // reset(props.currentViewInventory);
    if (props.equipmentId && Object.keys(currentEquipment).length) {
      setSupplier(currentEquipment.assets.manufacturerId);
      if (currentEquipment.ownership === "PLANT") {
        ipoState.on();
      }
      reset({
        ownership: currentEquipment.ownership,
        plantId: currentEquipment.plantId,
        contractualQuantity: currentEquipment.contractualQuantity || 0,
        quantity: currentEquipment.quantity || 0,
        description: currentEquipment.description,
        isKeyComponent: currentEquipment.isKeyComponent || false,
        category: currentEquipment.assets.assetCategoryId,
        equipmentName: currentEquipment.assets.id,
        partNumber: currentEquipment.partNumber || "",
        installedQuantity: currentEquipment.installedQuantity || 0,
        spareQuantity: currentEquipment.spareQuantity || 0,
        bgeWarehouseQuantity: currentEquipment.bgeWarehouseQuantity || 0,
        externalWarehouseQuantity: currentEquipment.externalWarehouseQuantity || 0,
        supplierPremisesQuantity: currentEquipment.supplierPremisesQuantity || 0,
      });
      setAssetId(currentEquipment.assetId);
      currentEquipment.ownership === "PLANT" ? ipoState.on() : ipoState.off();
      currentEquipment.isKeyComponent === true ? ikcState.on() : ikcState.off();
    } else {
      reset({
        ownership: "PLANT",
        plantId: null,
        contractualQuantity: 0,
        quantity: 0,
        description: "",
        isKeyComponent: false,
        category: "",
        equipmentName: "",
        partNumber: "",
        installedQuantity: 0,
        spareQuantity: 0,
        bgeWarehouseQuantity: 0,
        externalWarehouseQuantity: 0,
        supplierPremisesQuantity: 0,
      });
    }
  }, [currentEquipment]);

  useEffect(() => {
    if (isPlantOwned === false) {
      reset({
        contractualQuantity: 0,
        quantity: 0,
        description: "",
        isKeyComponent: false,
        category: "",
        equipmentName: "",
        partNumber: "",
        installedQuantity: 0,
        spareQuantity: 0,
        bgeWarehouseQuantity: 0,
        externalWarehouseQuantity: 0,
        supplierPremisesQuantity: 0,
      });
    }
  }, [isPlantOwned]);

  const handleFormSubmit = (formData) => {
    const { equipmentName, category, ...rest } = formData;
    const data = { ...rest, assetId: parseInt(assetId) };
    if (props.mode === VIEW.CREATE) {
      createEquipment({ ...data })
        .then((res) => {
          // successToast({ description: "Successfully created" });
          if (!currentPvPLant) {
            fetchEquipmentsData()
          }
          if (props.onSuccessfulModal) {
            props.onSuccessfulModal();
          }
        })
        .catch((e) => {
          // errorToast({ description: `Error, ${e.message}` });
        });
    } else {
      updateEquipment(props.equipmentId, { ...data })
        .then((res) => {
          // successToast({ description: "Successfully Updated" });
          if (!currentPvPLant) {
            fetchEquipmentsData()
          }
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          // errorToast({ description: `Error, ${e.message}` });
        });
    }
  };
  useEffect(() => {
    if (currentPvPLant) {
      reset({
        plantId: currentPvPLant,
        contractualQuantity: 0,
        installedQuantity: 0,
        spareQuantity: 0,
        bgeWarehouseQuantity: 0,
        externalWarehouseQuantity: 0,
        supplierPremisesQuantity: 0,
      });
    }
  }, []);
  const renderCreateButton = () => {
    return (
      <Button color="primary" size="lg" disabled={isLoading} onClick={handleSubmit(handleFormSubmit)}>
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <Row justifyContent="end">
        <Button
          className="mr-2"
          color="outline-primary"
          size="lg"
          disabled={isLoading}
          onClick={() => {
            if (props.setMode) {
              props.setMode("VIEW");
            }
          }}
        >
          Cancel
        </Button>
        <Button color="primary" size="lg" disabled={isLoading} onClick={handleSubmit(handleFormSubmit)}>
          Update
        </Button>
      </Row>
    );
  };

  const renderSaveButton = () => {
    if (props.mode === VIEW.EDIT) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  const renderBGEOwned = () => {
    setValue("ownership", "BGE");
    return (
      <Row className="g-4">
        <Col lg="12">
          <FormGroup>
            <Label className="form-label" htmlFor="bgeCategory">
              <span style={{ color: "indianred" }}>&#42;</span> Category
            </Label>
            <Controller
              control={control}
              name="bgeCategory"
              rules={{ required: true }}
              render={({ field, ref }) => {
                const options = filterOptions.assetCategory;
                const selectedValue = options?.find((e) => e.value === field.value);
                return (
                  <RSelect
                    id="category-bge-owned"
                    defaultValue={
                      props.mode === VIEW.EDIT
                        ? {
                            label: currentEquipment?.assets?.assetCategories?.name,
                            value: currentEquipment?.assets?.assetCategoryId,
                          }
                        : null
                    }
                    {...field}
                    ref={ref}
                    value={selectedValue}
                    options={options}
                    onChange={(o) => {
                      setValue("category", o.value, { shouldValidate: true });
                      updateIsCategory(getValues("category"));
                      setValue("equipmentName", null);
                    }}
                  />
                );
              }}
            />
            {errors?.category && (
              <span className="invalid" style={{ color: "red" }}>
                Category is required
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-label" htmlFor="equipmentName">
              <span style={{ color: "indianred" }}>&#42;</span> Equipment Name
            </Label>
            <Controller
              control={control}
              name="equipmentName"
              rules={{ required: true }}
              render={({ field, ref }) => {
                const options = asset[isCategory] || asset[currentEquipment?.assets?.assetCategoryId] || [];
                const selectedValue = options?.find((e) => e.value === field.value);
                return (
                  <RSelect
                    id="bge-equipmentName"
                    {...field}
                    ref={ref}
                    value={selectedValue}
                    options={options}
                    onChange={(o) => {
                      setSupplier(o.manufacturerId);
                      setAssetId(o.value);
                      setValue("equipmentName", o.value, { shouldValidate: true });
                    }}
                  />
                );
              }}
            />
            {errors?.equipmentName && (
              <span className="invalid" style={{ color: "red" }}>
                Equipment Name is required
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="form-group">
            <label className="form-label"> Contractual Quantity</label>
            <div className="form-control-wrap">
              <input
                id="bge-contractualQuantity"
                {...register("contractualQuantity")}
                type="number"
                name="contractualQuantity"
                className="form-control"
              />
            </div>
            {errors?.contractualQuantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors?.contractualQuantity.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="form-group">
            <label className="form-label"> Quantity</label>
            <div className="form-control-wrap">
              <input type="number" name="quantity" className="form-control" {...register("quantity")} />
            </div>
            {errors?.quantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors?.quantity.message ===
                "This field is sum of BGE warehouse, External warehouse and Supplier premises"
                  ? "This field is Required"
                  : errors?.quantity.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-label" htmlFor="fv-full-name">
              Supplier
            </Label>
            <div className="form-control-wrap">
              <input
                disabled={true}
                type="text"
                name="supplier"
                className="form-control"
                value={supplier ? manufacturer[supplier] : ""}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="form-group">
            <label className="form-label">Details</label>
            <div className="form-control-wrap">
              <div className="form-control-wrap">
                <Controller
                  control={control}
                  name="description"
                  render={({ field, ref }) => {
                    return (
                      <TextEditor
                        field={field}
                        setValue={setValue}
                        fieldName={"description"}
                      />
                    );
                  }}
                />
              </div>
            </div>
            {errors?.description && (
              <span className="invalid" style={{ color: "red" }}>
                {errors?.description.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="form-group">
            <label className="form-label">Key Component</label>
            <div className="form-control-wrap">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="isKeyComponent-2"
                  checked={isKeyComponent}
                  onChange={(e) => {
                    e.target.checked ? ikcState.on() : ikcState.off();
                    setValue("isKeyComponent", e.target.checked);
                  }}
                />
                <label className="custom-control-label" htmlFor="isKeyComponent-2">
                  {isKeyComponent ? "Yes" : "No"}
                </label>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
    );
  };
  const renderPlantOwned = () => {
    return (
      <Row className="g-4">
        {currentPvPLant ? (
          <Col lg="6">
            <FormGroup>
              <Label className="form-label" htmlFor="plantId">
                <span style={{ color: "indianred" }}>&#42;</span> Plant
              </Label>
              <div className="form-control-wrap">
                <Controller
                  control={control}
                  name="plantId"
                  rules={{ required: true }}
                  render={({ field, ref }) => {
                    const options = filterOptions.plant;
                    const selectedValue = options.find((e) => e.value === currentPvPLant);
                    return <RSelect id="plant-select" {...field} ref={ref} value={selectedValue} isDisabled />;
                  }}
                />
              </div>
              {errors.plantId && (
                <span className="invalid" style={{ color: "red" }}>
                  plant is required
                </span>
              )}
            </FormGroup>
          </Col>
        ) : (
          <Col lg="12">
            <FormGroup>
              <Label className="form-label" htmlFor="plantId">
                <span style={{ color: "indianred" }}>&#42;</span> Plant
              </Label>
              <div className="form-control-wrap">
                <Controller
                  control={control}
                  name="plantId"
                  rules={{ required: true }}
                  render={({ field, ref }) => {
                    const options = filterOptions.plant;
                    const selectedValue = options.find((e) => e.value === field.value);
                    return (
                      <RSelect
                        isDisabled={props.mode === VIEW.EDIT ? true : false}
                        id="plant-select"
                        {...field}
                        ref={ref}
                        value={selectedValue}
                        placeholder="Select the plant "
                        options={options}
                        onChange={(o) => setValue("plantId", o.value, { shouldValidate: true })}
                      />
                    );
                  }}
                />
              </div>
              {errors.plantId && (
                <span className="invalid" style={{ color: "red" }}>
                  plant is required
                </span>
              )}
            </FormGroup>
          </Col>
        )}
        <Col lg="6">
          <FormGroup>
            <Label className="form-label" htmlFor="category">
              <span style={{ color: "indianred" }}>&#42;</span> Category
            </Label>
            <div className="form-control-wrap">
              <Controller
                control={control}
                name="category"
                rules={{ required: true }}
                render={({ field, ref }) => {
                  const options = filterOptions.assetCategory;
                  const selectedValue = options.find((e) => e.value === field.value);
                  return (
                    <RSelect
                      id="plant-category-select"
                      {...field}
                      ref={ref}
                      value={selectedValue}
                      placeholder="Select the category "
                      options={options}
                      onChange={(o) => {
                        setValue("category", o.value, { shouldValidate: true });
                        updateIsCategory(getValues("category"));
                      }}
                    />
                  );
                }}
              />
            </div>
            {errors.category && (
              <span className="invalid" style={{ color: "red" }}>
                category is required
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-label" htmlFor="fv-full-name">
              <span style={{ color: "indianred" }}>&#42;</span> Equipment Name
            </Label>
            <Controller
              control={control}
              name="equipmentName"
              rules={{ required: true }}
              render={({ field, ref }) => {
                const options = asset[isCategory] || asset[currentEquipment?.assets?.assetCategoryId] || [];
                const selectedValue = options?.find((e) => e.value === field.value);
                return (
                  <RSelect
                    {...field}
                    ref={ref}
                    value={selectedValue}
                    options={options}
                    onChange={(o) => {
                      setSupplier(o.manufacturerId);
                      setAssetId(o.value);
                      setValue("equipmentName", o.value, { shouldValidate: true });
                    }}
                  />
                );
              }}
            />
            {errors?.equipmentName && (
              <span className="invalid" style={{ color: "red" }}>
                Equipment Name is required
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup className="form-group">
            <label className="form-label">Details</label>
            <div className="form-control-wrap">
              <Controller
                control={control}
                name="description"
                render={({ field, ref }) => {
                  return (
                    <TextEditor
                      field={field}
                      setValue={setValue}
                      fieldName={"description"}
                    />

                  );
                }}
              />
            </div>
            {errors?.description && (
              <span className="invalid" style={{ color: "red" }}>
                {errors?.description.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-label" htmlFor="supplier">
              Supplier
            </Label>
            <div className="form-control-wrap">
              <input
                disabled={true}
                type="text"
                name="supplier"
                className="form-control"
                value={supplier ? manufacturer[supplier] : ""}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="contractualQuantity">
              Contractual Quantity
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-contractualQuantity-input"
                {...register("contractualQuantity")}
                type="text"
                name="contractualQuantity"
                className="form-control"
              />
            </div>
            {errors.contractualQuantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.contractualQuantity?.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="quantity">
              Quantity
              <Icon name={"info"} id="info" />
              <Tooltip placement="right" isOpen={tooltipOpen} target="info" toggle={toggle}>
                Sum of BGE warehouse, External warehouse and Supplier premises
              </Tooltip>
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-quantity-input"
                {...register("quantity")}
                type="text"
                name="quantity"
                disabled={true}
                className="form-control"
              />
            </div>
            {errors.quantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.quantity?.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="installedQuantity">
              Installed at site (Quantity)
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-installedQuantity-input"
                {...register("installedQuantity")}
                type="text"
                name="installedQuantity"
                className="form-control"
              />
            </div>
            {errors.installedQuantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.installedQuantity?.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="spareQuantity">
              Spares at site (Quantity)
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-spareQuantity-input"
                {...register("spareQuantity")}
                type="text"
                name="spareQuantity"
                className="form-control"
              />
            </div>
            {errors.spareQuantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.spareQuantity?.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="bgeWarehouseQuantity">
              BGE warehouse (Quantity)
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-bgeWarehouseQuantity-input"
                {...register("bgeWarehouseQuantity")}
                type="text"
                name="bgeWarehouseQuantity"
                className="form-control"
              />
            </div>
            {errors.bgeWarehouseQuantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.bgeWarehouseQuantity?.message}
              </span>
            )}
          </FormGroup>
        </Col>
        {/* <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="installedQuantity">
              Installed (Quantity)
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-installedQuantity-input"
                {...register("installedQuantity")}
                type="text"
                name="installedQuantity"
                className="form-control"
              />
            </div>
            {errors.installedQuantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.installedQuantity?.message}
              </span>
            )}
          </FormGroup>
        </Col> */}
        <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="externalWarehouseQuantity">
              External warehouse (Quantity)
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-externalWarehouseQuantity-input"
                {...register("externalWarehouseQuantity")}
                type="text"
                name="externalWarehouseQuantity"
                className="form-control"
              />
            </div>
            {errors.externalWarehouseQuantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.externalWarehouseQuantity?.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="supplierPremisesQuantity">
              Supplier Premises (Quantity)
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-supplierPremisesQuantity-input"
                {...register("supplierPremisesQuantity")}
                type="text"
                name="supplierPremisesQuantity"
                className="form-control"
              />
            </div>
            {errors.supplierPremisesQuantity && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.supplierPremisesQuantity?.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <Label className="form-label" htmlFor="partNumber">
              Part Number
            </Label>
            <div className="form-control-wrap">
              <input
                id="plant-partNumber-input"
                {...register("partNumber")}
                type="text"
                name="partNumber"
                className="form-control"
              />
            </div>
            {errors.partNumber && (
              <span className="invalid" style={{ color: "red" }}>
                {errors.partNumber?.message}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="form-group">
            <label className="form-label">Key Component</label>
            <div className="form-control-wrap">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="isKeyComponent-1"
                  checked={isKeyComponent}
                  onChange={(e) => {
                    e.target.checked ? ikcState.on() : ikcState.off();
                    setValue("isKeyComponent", e.target.checked);
                  }}
                />
                <label className="custom-control-label" htmlFor="isKeyComponent-1">
                  {isKeyComponent ? "Yes" : "No"}
                </label>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
    );
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head
        title={
          props.mode === VIEW.EDIT
            ? "Brighter App | Equipment And Spares  | Edit"
            : "Brighter App | Equipment And Spares  | Create"
        }
      />
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            {props.mode === VIEW.CREATE ? (
              <FormGroup className="form-group">
                <label className="form-label">Owned by</label>
                <div className="form-control-wrap">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="ownership"
                      disabled={props.module}
                      checked={isPlantOwned}
                      onChange={(e) => {
                        e.target.checked ? ipoState.on() : ipoState.off();
                        setValue("ownership", e.target.checked ? "PLANT" : "BGE");
                        setValue("category", "");
                        setSupplier(null);
                        setValue("equipmentName", "");
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ownership">
                      {isPlantOwned ? "Plant Owned" : "BGE Owned"}
                    </label>
                  </div>
                </div>
              </FormGroup>
            ) : null}
            {isPlantOwned ? renderPlantOwned() : renderBGEOwned()}
            {currentEquipment?.id && props.mode === VIEW.EDIT ? (
              <Col xl="12">
                <FormGroup className="form-group mt-3">
                  <div className="form-control-wrap">
                    <Attachments
                      module={"STOCK"}
                      id={currentEquipment?.id}
                      attachments={currentEquipment.attachment}
                      subModule={""}
                    />
                  </div>
                </FormGroup>
              </Col>
            ) : null}
            <Col xl="12" className="d-flex justify-content-end mt-3">
              {renderSaveButton()}
            </Col>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};
export default EquipmentAndSpareFormComponent;
