export const HOME_PAGE = `${process.env.PUBLIC_URL}/`;
export const USER_PROFILE_PAGE = `${process.env.PUBLIC_URL}/user-profile`;
export const USER_PROFILE_SETTING = `${process.env.PUBLIC_URL}/user-profile-setting`;
export const USER_PROFILE_UPDATE = `${process.env.PUBLIC_URL}/user-profile-update`;

// MAINTENANCE
export const CORRECTIVE_MAINTENANCE_PAGE = `/maintenance/corrective`;
export const CORRECTIVE_MAINTENANCE_VIEW_PAGE = `/maintenance/corrective/:taskId`;
export const PREVENTATIVE_MASTERS_PAGE = `/maintenance/preventative-masters/:preventiveMasterListId`;
export const PREVENTATIVE_TASKS_PAGE = `/maintenance/preventative-tasks`;
export const PREVENTATIVE_GENERATOR_PAGE = `/maintenance/preventative-tasks/generate`;
export const PREVENTATIVE_TASK_VIEW_PAGE = `/maintenance/preventative/:taskId`;

export const REMEDIAL_MAINTENANCE_PAGE = `/maintenance/remedial`;
export const REMEDIAL_MAINTENANCE_VIEW_PAGE = `/maintenance/remedial/:taskId`;

export const STRING_MAINTENANCE = `/maintenance/string`;
export const DOCUMENTS_PAGE = `/documents`;
export const PREVENTATIVE_MASTER_LIST = `/entity-management/preventative-master-list`;
export const STRING_IMPORT = `/string/plant/:plantId/import/:importId`;

// INVENTORY
export const PO_INVENTORY_PAGE = `/inventory/plant-owned`;
export const SO_INVENTORY_PAGE = `/inventory/bge-owned`;

export const ADMIN_LOGIN_PAGE = `/auth-login`;

export const ADMIN_FORGOT_PASSWORD_PAGE = `/auth-forgot-password`;

export const ADMIN_RESET_PASSWORD_PAGE = `/auth-reset-password/:resetPasswordToken`;

export const USER_MANAGEMENT_PAGE = `/user-management`;
export const USER_MANAGEMENT_CREATE_PAGE = `/user-management/add`;
export const USER_MANAGEMENT_VIEW_PAGE = `/user-management/:userId`;
export const USER_MANAGEMENT_EDIT_PAGE = `/user-management/:userId/edit`;

export const CLIENT_MANAGEMENT_PAGE = `/clients`;
export const CLIENT_MANAGEMENT_CREATE_PAGE = `/client/add`;
export const CLIENT_MANAGEMENT_VIEW_PAGE = `/client/:clientId`;
export const CLIENT_MANAGEMENT_EDIT_PAGE = `/client/:clientId/edit`;

export const PVPLANT_MANAGEMENT_PAGE = `/pv-plants`;
export const PVPLANT_MANAGEMENT_CREATE_PAGE = `/pv-plant/add`;
export const PVPLANT_MANAGEMENT_VIEW_PAGE = `/pv-plant/:plantId`;
export const PVPLANT_MANAGEMENT_EDIT_PAGE = `/pv-plant/:plantId/edit`;
export const PREVENTATIVE_SCHEDULE = `/pv-plant/:plantId/preventative-schedule`;
export const PREVENTATIVE_SCHEDULE_EDIT = `/pv-plant/:plantId/preventative-schedule/:preventativeScheduleId`;
export const PREVENTATIVE_SCHEDULE_VIEW = `/pv-plant/:plantId/preventative-schedule/:preventativeScheduleId/view`;

export const PVPLANT_MANAGEMENT_PREVENTATIVE_SCHEDULE = `/pv-plant/:plantId?tab=master`;

export const MEDICAL_CENTER_PAGE = `${process.env.PUBLIC_URL}/entity_management/medical-centres`;

export const SLA_PAGE = `${process.env.PUBLIC_URL}/entity_management/slas`;

export const MANUFACTURER_PAGE = `${process.env.PUBLIC_URL}/entity_management/manufacturers`;

export const DNO_PAGE = `${process.env.PUBLIC_URL}/entity_management/dnos`;

export const ASSET_PAGE = `${process.env.PUBLIC_URL}/entity_management/assets`;

export const ASSET_CATEGORY_PAGE = `${process.env.PUBLIC_URL}/entity_management/asset-categories`;
export const FAILURE_REASON_PAGE = `${process.env.PUBLIC_URL}/entity_management/failure-reasons`;

export const ROLE_MANAGEMENT_PAGE = `${process.env.PUBLIC_URL}/role_management`;

export const NOTIFICATION_MANAGEMENT_PAGE = `${process.env.PUBLIC_URL}/admin/notifications`;

export const GLOBAL_SEARCH_MANAGEMENT_PAGE = `${process.env.PUBLIC_URL}/admin/globalSearch`;

export const FORM_CREATE_PAGE = `${process.env.PUBLIC_URL}/admin/form/:formId`;

export const FORM_PAGE = `${process.env.PUBLIC_URL}/admin/forms`;

export const FORM_LIST_PAGE = `${process.env.PUBLIC_URL}/admin/all-forms`;

export const VIEW_FORM = `${process.env.PUBLIC_URL}/admin/form/:formId/preview/:id`;

export const FORM_EDIT_PAGE = `${process.env.PUBLIC_URL}/admin/forms/:formId`;

export const FORM_VIEW_PAGE = `${process.env.PUBLIC_URL}/admin/form/view/:formId`;

export const FORM_SUBMISSION = `${process.env.PUBLIC_URL}/admin/formSubmission/:formId`;

export const FORM_SUBMISSION_PAGE = `${process.env.PUBLIC_URL}/forms/:formId/:formToken`;

export const PUBLIC_ADMIN_RESET_PASSWORD = ADMIN_RESET_PASSWORD_PAGE.replace(":resetPasswordToken", "");

export const PUBLIC_FORM_SUBMISSION_PAGE = FORM_SUBMISSION_PAGE.replace(":formId/:formToken", "");

export const EQUIPMENT_SPARE_LIST = `/equipments-and-spares`;
export const PUBLIC_PATH = [
  ADMIN_LOGIN_PAGE,
  ADMIN_FORGOT_PASSWORD_PAGE,
  PUBLIC_ADMIN_RESET_PASSWORD,
  PUBLIC_FORM_SUBMISSION_PAGE,
];
