import { PREVENTATIVE_SCHEDULE, MANAGE_PREVENTATIVE_SCHEDULE, PREVENTATIVE_SCHEDULE_OPTIONS, PREVENTATIVE_MASTER_TASK, PREVENTATIVE_SCHEDULE_TASK, SCHEDULE_PREVENTATIVE_TASK } from "../../constants/endpoints";
import apiService from "../../services/ApiService";

//api call

export const getPreventativeSchedules = async filterParams => {
    const response = await apiService.get(PREVENTATIVE_SCHEDULE, {
        queryParams: filterParams,
    })
    return response
}

export const createPreventativeSchedule = async params => {
    const response = await apiService.post(PREVENTATIVE_SCHEDULE, {
        body: params,
    })
    return response
}

export const getPreventativeSchedule = async id => {
    const response = await apiService.get(`${MANAGE_PREVENTATIVE_SCHEDULE}`, {
        pathParams: { id }
    })
    return response
}

export const updatePreventativeSchedule = async (id, params) => {
    const response = await apiService.put(`${MANAGE_PREVENTATIVE_SCHEDULE}`, {
        pathParams: { id },
        body: params
    })
    return response
}

export const deletePreventativeSchedule = async (id, params) => {
    const response = await apiService.delete(`${MANAGE_PREVENTATIVE_SCHEDULE}`, {
        pathParams: { id },
    })
    return response
}

export const getPreventativeScheduleOptions = async () => {
    const response = await apiService.get(PREVENTATIVE_SCHEDULE_OPTIONS)
    return response
}
export const getTaskByScheduleId = async (preventativeScheduleId) => {
    const response = await apiService.get(PREVENTATIVE_SCHEDULE_TASK, {
        pathParams: { preventativeScheduleId }
    })
    return response
}
export const getTaskByMasterId = async (preventativeMasterListId) => {
    const response = await apiService.get(PREVENTATIVE_MASTER_TASK, {
        pathParams: { preventativeMasterListId }
    })
    return response
}
export const getPreventativeTasks = async (queryParams) => {
    const response = await apiService.get(SCHEDULE_PREVENTATIVE_TASK, {
        queryParams
    })
    return response
}