/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import CorrectiveForm from "../components/CorrectiveForm";
import { CorrectiveContext, withCorrectiveProvider } from "../CorrectiveProvider";

const CorrectiveFormContainer = (props) => {
  const correctiveContext = useContext(CorrectiveContext);
  const { currentViewTask, updateTask, loadTask } = correctiveContext;

  useEffect(() => {
    if (props.taskId) loadTask(props.taskId);
  }, [props.taskId]);
  if (currentViewTask) {
    currentViewTask.startedAt = new Date(currentViewTask.startedAt);
    if (currentViewTask.resolvedAt) {
      currentViewTask.resolvedAt = new Date(currentViewTask.resolvedAt);
    }
  }

  return (
    <React.Fragment>
      <CorrectiveForm
        viewTask={props.viewTask}
        mode={props.mode}
        plantId={props.plantId}
        taskId={props.taskId}
        currentViewTask={currentViewTask}
        updateTask={updateTask}
        onSuccessfulModal={props.onSuccessfulModal}
      />
    </React.Fragment>
  );
};

export default withCorrectiveProvider(CorrectiveFormContainer);
