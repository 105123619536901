/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import FormSubmission from "../components/FormSubmission";
import { FormManagementContext, withFormManagementProvider } from "../FormManagementProvider";

const FormManagementViewContainer = (props) => {
  const params = useParams();
  const { formId } = params;

  const { loadForm } = useContext(FormManagementContext);

  useEffect(() => {
    if (formId) {
      loadForm(formId);
    }
  }, [formId]);

  return (
    <React.Fragment>
      <Head title={`Brighter App | ${formId} - Form Management`} />
      <Content>
        <FormSubmission />
      </Content>
    </React.Fragment>
  );
};

export default withFormManagementProvider(FormManagementViewContainer);
