/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button } from "reactstrap";
import {
  Block,
  BlockHead,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
} from "../../../../../components/Component";
import useBoolean from "../../../../../hooks/useBoolean";
import { EquipmentAndSpareContext } from "../../../../equipment_spare/EquipmentAndSpareProvider";
import { RECORD_TYPE } from "../../../../equipment_spare/constants";
import ModalViewer from "../../../ModalViewer";
import TransactionForm from "./transaction";
import Check from "../../../../../components/check_roles/check";

const SubTransactionList = (props) => {
  const { currentViewTask } = props;
  const {
    transactions,
    isTransactionLoading,
    transactionPagination,
    loadTransaction,
    loadEquipmentList,
    setTransactionPagination,
    setTransactions,
    equipmentList,
    currentEquipmentList,
  } = useContext(EquipmentAndSpareContext);

  const [isOpen, setIsOpen] = useState(false);
  const [correctiveSubequipmentsLoading, setCorrectiveSubEquipmentsLoading] = useBoolean();

  const getSubEquipment = async () => {
    setCorrectiveSubEquipmentsLoading.on();
    try {
      const subtask = await loadTransaction({ ...transactionPagination, taskid: currentViewTask.id });
      setTransactions(subtask.stockTransactions);
      setCorrectiveSubEquipmentsLoading.off();
    } catch (err) {
      // console.log({ err })
    }
  };
  const itHasEquipment = useMemo(() => {
    return equipmentList?.items?.find((data) => data?.plantId === currentViewTask?.plantId);
  }, [equipmentList, currentViewTask]);

  useEffect(() => {
    getSubEquipment();
  }, [currentViewTask]);

  useEffect(() => {
    loadEquipmentList();
  }, []);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSuccessfulModal = () => {
    toggleModal();
    getSubEquipment();
  };
  const getComponent = () => {
    return (
      <TransactionForm
        onSuccessfulModal={onSuccessfulModal}
        taskId={currentViewTask.id}
        plantId={currentViewTask.plantId}
      />
    );
  };

  useEffect(() => {
    loadTransaction({ taskid: currentViewTask.id });
  }, [transactionPagination]);

  return (
    <>
      <BlockHead size="sm">
        <div className="d-flex justify-content-end">
          <Check permission={["manageInventory"]}>
            <Button
              color="primary"
              className={`ml-2`}
              disabled={itHasEquipment ? false : true}
              onClick={() => {
                itHasEquipment ? setIsOpen(true) : setIsOpen(false);
              }}
            >
              <Icon name="plus" />
              <span>Add Transaction</span>
            </Button>
          </Check>
        </div>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <span className="sub-text">Record Date</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Record Type</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Cause</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="sub-text">Location</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Quantity</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Details</span>
              </DataTableRow>
              {/* <DataTableRow size="md">
                <span className="sub-text">Plant Name</span>
              </DataTableRow> */}
            </DataTableHead>
            {/*Head*/}
            {transactions?.items.length > 0 ? (
              transactions?.items.map((item) => {
                const recordType = RECORD_TYPE.find((d) => d.value === item.recordType).label;
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <span>
                        {item.recordDate ? format(new Date(item.recordDate || null), "dd/MM/yyyy HH:mm ") : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow>
                      <span>{recordType || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.cause || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>
                        <span>
                          {item.recordType === "TRANSFER"
                            ? item.fromWarehouse + ` -> ${item.toWarehouse}`
                            : item?.toWarehouse}
                        </span>
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.quantity || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span dangerouslySetInnerHTML={{__html:item?.description}} className="test-editor"/>
                    </DataTableRow>
                    {/* <DataTableRow size="md">
                      <span>{item?.plant?.name || "-"}</span>
                    </DataTableRow> */}
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>
          <div className="card-inner">
            {transactions?.pagination && transactions?.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={transactionPagination?.size}
                totalItems={transactions?.pagination?.totalCount}
                paginate={(page) => setTransactionPagination((p) => ({ ...p, page }))}
                paginatePage={(size) => setTransactionPagination((p) => ({ ...p, size }))}
                currentPage={transactions?.pagination?.currentPage}
              />
            ) : (
              !correctiveSubequipmentsLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {isTransactionLoading && <Skeleton count={10} className="w-100" />}
      </Block>
      <ModalViewer
        size={"lg"}
        title="Equipment And Spares"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
    </>
  );
};
export default SubTransactionList;
