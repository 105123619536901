/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewFailureReason from "../components/FailureReasonView";
import { FailureReasonContext, withFailureReasonProvider } from "../FailureReasonProvider";

const FailureReasonViewContainer = props => {

    const { failureReasonId, setIsOpen, editFailureReason } = props

    const failureReasonContext = useContext(FailureReasonContext)
    const { loadFailureReason } = failureReasonContext

    useEffect(() => {
        if (failureReasonId) loadFailureReason(failureReasonId)
    }, [failureReasonId])

    return (
        <React.Fragment>
            <ViewFailureReason setIsOpen={setIsOpen} editFailureReason={editFailureReason} />
        </React.Fragment>
    );
}

export default withFailureReasonProvider(FailureReasonViewContainer);