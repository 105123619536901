/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { useEffect } from "react";
import EquipmentAndSpareFormComponent from "../components/EquipmentAndSpareFormComponent";
import { EquipmentAndSpareContext } from "../EquipmentAndSpareProvider";

const EquipmentAndSpareForm = ({ mode, onSuccessfulModal, equipmentId, setMode, module }) => {
  const { loadEquipment } = useContext(EquipmentAndSpareContext);
  useEffect(() => {
    if (equipmentId) {
      loadEquipment(equipmentId);
    }
  }, [equipmentId]);

  return (
    <EquipmentAndSpareFormComponent
      module={module}
      mode={mode}
      setMode={setMode}
      onSuccessfulModal={onSuccessfulModal}
      equipmentId={equipmentId}
    />
  );
};
export default EquipmentAndSpareForm;
