import * as yup from "yup";

export const updatePasswordSchema = yup.object({
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "Passwords should fall within the range of 8 to 32 characters")
    .default(""),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    })
    .default(""),
});
