import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../hooks/useBoolean";
import { DnoContext } from "../DnoProvider";
import { PvPlantManagementContext } from "../../../pv_plant_management/PvPlantManagementProvider";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import Head from "../../../../layout/head/Head";

const createSchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(/^(\s*\S.*)/g, "name should be atleast one character"),
});

const DnoForm = (props) => {
  const { createDno, dnoLoading } = useContext(DnoContext);
  const { loadAllDnos } = useContext(PvPlantManagementContext);

  const [isEditMode, updateEditMode] = useBoolean(false);

  const dnoFormRef = useForm({
    resolver: yupResolver(props.dnoId ? createSchema : createSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = dnoFormRef;

  useEffect(() => {
    reset(props.currentViewDno);
    if (props.currentViewDno) updateEditMode.on();
  }, [props.currentViewDno]);

  const handleFormSubmit = (formData) => {
    if (props.dnoId) {
      props
        .updateDno(props.currentViewDno.id, formData)
        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while updating DNO" });
        });
    } else {
      createDno(formData)
        .then((res) => {
          props.onDnoSubmit && props.onDnoSubmit({ label: res.dno.name, value: res.dno.id });
          loadAllDnos();
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while creating DNO" });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,

    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="add-dno-btn"
        color="primary"
        size="lg"
        isLoading={dnoLoading}
        onClick={dnoLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return isDirty ? (
      <Button
        color="primary"
        isLoading={dnoLoading}
        size="lg"
        onClick={dnoLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Save Information
      </Button>
    ) : (
      <Button size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
        Cancel
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head title={props.dnoId ? "Brighter App | DNO | Edit" : "Brighter App | DNO | Create"} />
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>DNO
                  </Label>

                  <div className="form-control-wrap">
                    <input {...register("name")} type="text" name="name" className="form-control" />
                  </div>
                  {errors.name && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.name.message}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default DnoForm;
