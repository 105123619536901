/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import DnoForm from "../components/DnoForm";
import { DnoContext, withDnoProvider } from "../DnoProvider";

const DnoFormContainer = (props) => {
  const dnoContext = useContext(DnoContext);

  const { currentViewDno, updateDno, loadDno } = dnoContext;

  useEffect(() => {
    if (props.dnoId) loadDno(props.dnoId);
  }, [props.dnoId]);

  return (
    <React.Fragment>
      <DnoForm
        dnoId={props.dnoId}
        currentViewDno={currentViewDno}
        updateDno={updateDno}
        onSuccessfulModal={props.onSuccessfulModal}
        onDnoSubmit ={props.onDnoCreate}
      />
    </React.Fragment>
  );
};

export default withDnoProvider(DnoFormContainer);
