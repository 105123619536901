import {
  ASSET_CATEGORY_PAGE,
  ASSET_PAGE,
  CLIENT_MANAGEMENT_PAGE,
  CORRECTIVE_MAINTENANCE_PAGE,
  DNO_PAGE,
  EQUIPMENT_SPARE_LIST,
  FAILURE_REASON_PAGE,
  FORM_LIST_PAGE,
  FORM_PAGE,
  HOME_PAGE,
  MANUFACTURER_PAGE,
  MEDICAL_CENTER_PAGE,
  PREVENTATIVE_MASTER_LIST,
  PREVENTATIVE_TASKS_PAGE,
  PVPLANT_MANAGEMENT_PAGE,
  REMEDIAL_MAINTENANCE_PAGE,
  ROLE_MANAGEMENT_PAGE,
  SLA_PAGE,
  STRING_MAINTENANCE,
  USER_MANAGEMENT_PAGE,
} from "../../constants/routes";

const menu = [
  {
    icon: "home-fill", // nio icons was used
    text: "Home",
    link: HOME_PAGE,
  },
  {
    icon: "spark-fill",
    text: "PV Plants",
    link: PVPLANT_MANAGEMENT_PAGE,
    action: ["getPlants", "createPlant", "deletePlant", "updatePlant"],
  },
  {
    icon: "clipboad-check",
    text: "Maintenance",
    active: false,
    action: ["getTickets"],
    subMenu: [
      {
        text: "Corrective Maintenance",
        link: CORRECTIVE_MAINTENANCE_PAGE,
        action: ["getTickets", "manageTickets"],
      },
      {
        text: "Preventative Maintenance",
        action: ["getTickets", "manageTickets"],
        link: PREVENTATIVE_TASKS_PAGE,
        // subMenu: [
        //   {
        //     text: "Preventative Task",
        //     link: PREVENTATIVE_TASKS_PAGE,
        //     action: "getTickets",
        //   },
        // ],
      },
      {
        text: "Remedial Maintenance",
        link: REMEDIAL_MAINTENANCE_PAGE,
        action: ["getTickets", "manageTickets"],
      },
      {
        text: "String Maintenance",
        link: STRING_MAINTENANCE,
        action: ["getTickets", "manageTickets"],
      },
    ],
  },
  /* {
    icon: "file-docs",
    text: "Document",
    link: "/documents",
  }, */

  // {
  //   icon: "cpu",
  //   text: "Inventory",
  //   active: false,
  //   action: "getInventory",
  //   subMenu: [
  //     {
  //       text: "Plant Owned",
  //       link: PO_INVENTORY_PAGE,
  //       action: "getInventory",
  //     },
  //     {
  //       text: "BGE Owned",
  //       link: SO_INVENTORY_PAGE,
  //       action: "getInventory",
  //     },
  //   ],
  // },
  {
    icon: "cpu",
    text: "Equipment And Spares",
    link: EQUIPMENT_SPARE_LIST,
    action: ["getInventory", "manageInventory"],
  },
  {
    icon: "users-fill",
    text: "Clients",
    link: CLIENT_MANAGEMENT_PAGE,
    action: ["getClients", "deleteClient", "updateClient", "createClient"],
  },
  {
    icon: "card-view",
    text: "Form",
    active: false,
    link: FORM_LIST_PAGE,
    action: ["manageForms"],
  },
  {
    icon: "layers-fill",
    text: "Entity Management",
    active: false,
    action: ["getEntity"],
    subMenu: [
      {
        text: "Asset",
        link: ASSET_PAGE,
        action: ["getEntity", "manageEntity"],
      },
      {
        text: "Asset Category",
        link: ASSET_CATEGORY_PAGE,
        action: ["getEntity", "manageEntity"],
      },
      {
        text: "Failure Reason",
        link: FAILURE_REASON_PAGE,
        action: ["getEntity", "manageEntity"],
      },
      { text: "Manufacturer", link: MANUFACTURER_PAGE, action: ["getEntity", "manageEntity"] },
      {
        text: "Medical Centre",
        link: MEDICAL_CENTER_PAGE,
        action: ["getEntity", "manageEntity"],
      },
      {
        text: "DNO",
        link: DNO_PAGE,
        action: ["getEntity", "manageEntity"],
      },
      {
        text: "SLA",
        link: SLA_PAGE,
        action: ["getEntity", "manageEntity"],
      },
      {
        text: "Preventative Master List",
        link: PREVENTATIVE_MASTER_LIST,
        action: ["getEntity", "manageEntity"],
      },
    ],
  },
  {
    icon: "setting",
    text: "Settings",
    active: false,
    action: ["getUsers"],
    subMenu: [
      {
        text: "User Management",
        link: USER_MANAGEMENT_PAGE,
        action: ["getUsers", "deleteUser", "createUser", "updateUser"],
      },
      {
        text: "Roles",
        link: ROLE_MANAGEMENT_PAGE,
        action: ["getRoles", "manageRoles"],
      },
      {
        text: "Dynamic Form Builder",
        link: FORM_PAGE,
        action: ["manageForms"],
      },
    ],
  },
];

// const menuFromTheme = [{
//   heading: "Use-case preview",
// },
// {
//   icon: "bag",
//   text: "E-Commerce Panel",
//   link: "/ecommerce/index",
//   panel: true,
//   newTab: true,
//   subPanel: [
//     {
//       icon: "dashboard-fill",
//       text: "Dashboard",
//       link: "/ecommerce/index",
//     },
//     {
//       icon: "bag-fill",
//       text: "Orders",
//       link: "/ecommerce/orders",
//     },
//     {
//       icon: "package-fill",
//       text: "Products",
//       link: "/ecommerce/products",
//     },
//     {
//       icon: "users-fill",
//       text: "Customers",
//       link: "/ecommerce/customer",
//     },
//     {
//       icon: "chat-fill",
//       text: "Support",
//       link: "/ecommerce/support",
//     },
//     {
//       icon: "opt-alt-fill",
//       text: "Settings",
//       link: "/ecommerce/settings",
//     },
//     {
//       icon: "server-fill",
//       text: "Integration",
//       link: "/ecommerce/integration",
//     },
//   ],
// },
// {
//   heading: "Dashboards",
// },
// {
//   icon: "cart-fill",
//   text: "Default",
//   link: "/",
// },
// {
//   icon: "activity-round-fill",
//   text: "Sales",
//   link: "/sales",
// },
// {
//   icon: "growth-fill",
//   text: "Analytics",
//   link: "/analytics",
// },
// {
//   heading: "Pre-built Pages",
// },
// {
//   icon: "tile-thumb-fill",
//   text: "Projects",
//   active: false,
//   subMenu: [
//     {
//       text: "Project Cards",
//       link: "/project-card",
//     },
//     {
//       text: "Project List",
//       link: "/project-list",
//     },
//   ],
// },
// {
//   icon: "users-fill",
//   text: "User Manage",
//   active: false,
//   subMenu: [
//     {
//       text: "User List - Default",
//       link: "/user-list-default",
//     },
//     {
//       text: "User List - Regular",
//       link: "/user-list-regular",
//     },
//     {
//       text: "User List - Compact",
//       link: "/user-list-compact",
//     },
//     {
//       text: "User Details - Regular",
//       link: "/user-details-regular/1",
//     },
//     {
//       text: "User Profile - Regular",
//       link: "/user-profile-regular",
//     },
//     {
//       text: "User Contact - Card",
//       link: "/user-contact-card",
//     },
//   ],
// },

// {
//   icon: "cc-alt2-fill",
//   text: "Orders",
//   active: false,
//   subMenu: [
//     {
//       text: "Order List - Default",
//       link: "/order-list-default",
//     },
//     {
//       text: "Order List - Regular",
//       link: "/order-list-regular",
//     },
//     {
//       text: "Order List - Sales",
//       link: "/order-list-sales",
//     },
//   ],
// },
// {
//   icon: "file-docs",
//   text: "AML / KYCs",
//   active: false,
//   subMenu: [
//     {
//       text: "KYC List - Regular",
//       link: "/kyc-list-regular",
//     },
//     {
//       text: "KYC Details - Regular",
//       link: "/kyc-details-regular/UD01544",
//     },
//   ],
// },
// {
//   icon: "grid-alt-fill",
//   text: "Applications",
//   active: false,
//   subMenu: [
//     {
//       text: "Messages",
//       link: "/app-messages",
//     },
//     {
//       text: "Chats / Messenger",
//       link: "/app-chat",
//     },
//     {
//       text: "Calendar",
//       link: "/app-calender",
//     },
//   ],
// },
// {
//   icon: "card-view",
//   text: "Products",
//   active: false,
//   subMenu: [
//     {
//       text: "Product List",
//       link: "/product-list",
//     },
//     {
//       text: "Product Card",
//       link: "/product-card",
//     },
//     {
//       text: "Product Details",
//       link: "/product-details/0",
//     },
//   ],
// },
// {
//   icon: "file-docs",
//   text: "Invoice",
//   active: false,
//   subMenu: [
//     {
//       text: "Invoice List",
//       link: "/invoice-list",
//     },
//     {
//       text: "Invoice Details",
//       link: "/invoice-details/1",
//     },
//   ],
// },
// {
//   icon: "view-col",
//   text: "Pricing Table",
//   link: "/pricing-table",
// },
// {
//   icon: "img",
//   text: "Image Gallery",
//   link: "/image-gallery",
// },
// {
//   heading: "Misc Pages",
// },
// {
//   icon: "light-fill",
//   text: "Auth Pages",
//   active: false,
//   subMenu: [
//     {
//       text: "Login / Signin",
//       link: "/auth-login",
//       newTab: true,
//     },
//     {
//       text: "Register / Signup",
//       link: "/auth-register",
//       newTab: true,
//     },
//     {
//       text: "Forgot Password",
//       link: "/auth-reset",
//       newTab: true,
//     },
//     {
//       text: "Success / Confirm",
//       link: "/auth-success",
//       newTab: true,
//     },
//   ],
// },
// {
//   icon: "files-fill",
//   text: "Error Pages",
//   active: false,
//   subMenu: [
//     {
//       text: "404 Classic",
//       link: "/errors/404-classic",
//       newTab: true,
//     },
//     {
//       text: "504 Classic",
//       link: "/errors/504-classic",
//       newTab: true,
//     },
//     {
//       text: "404 Modern",
//       link: "/errors/404-modern",
//       newTab: true,
//     },
//     {
//       text: "504 Modern",
//       link: "/errors/504-modern",
//       newTab: true,
//     },
//   ],
// },
// {
//   icon: "files-fill",
//   text: "Other Pages",
//   active: false,
//   subMenu: [
//     {
//       text: "Blank / Startup",
//       link: "/_blank",
//     },
//     {
//       text: "Faqs / Help",
//       link: "/pages/faq",
//     },
//     {
//       text: "Terms / Policy",
//       link: "/pages/terms-policy",
//     },
//     {
//       text: "Regular Page - v1",
//       link: "/pages/regular-v1",
//     },
//     {
//       text: "Regular Page - v2",
//       link: "/pages/regular-v2",
//     },
//   ],
// },
// {
//   heading: "Components",
// },
// {
//   icon: "layers-fill",
//   text: "Ui Elements",
//   active: false,
//   subMenu: [
//     {
//       text: "Alerts",
//       link: "/components/alerts",
//     },
//     {
//       text: "Accordions",
//       link: "/components/accordions",
//     },
//     {
//       text: "Badges",
//       link: "/components/badges",
//     },
//     {
//       text: "Buttons",
//       link: "/components/buttons",
//     },
//     {
//       text: "Button Group",
//       link: "/components/button-group",
//     },
//     {
//       text: "Breadcrumbs",
//       link: "/components/breadcrumbs",
//     },
//     {
//       text: "Cards",
//       link: "/components/cards",
//     },
//     {
//       text: "Carousel",
//       link: "/components/carousel",
//     },
//     {
//       text: "Dropdowns",
//       link: "/components/dropdowns",
//     },
//     {
//       text: "Modals",
//       link: "/components/modals",
//     },
//     {
//       text: "Pagination",
//       link: "/components/pagination",
//     },
//     {
//       text: "Popovers",
//       link: "/components/popovers",
//     },
//     {
//       text: "Progress",
//       link: "/components/progress",
//     },
//     {
//       text: "Spinner",
//       link: "/components/spinner",
//     },
//     {
//       text: "Tabs",
//       link: "/components/tabs",
//     },
//     {
//       text: "Toast",
//       link: "/components/toast",
//     },
//     {
//       text: "Tooltips",
//       link: "/components/tooltips",
//     },
//     {
//       text: "Utilities",
//       active: false,
//       subMenu: [
//         {
//           text: "Borders",
//           link: "/components/util-border",
//         },
//         {
//           text: "Colors",
//           link: "/components/util-colors",
//         },
//         {
//           text: "Display",
//           link: "/components/util-display",
//         },
//         {
//           text: "Embeded",
//           link: "/components/util-embeded",
//         },
//         {
//           text: "Flex",
//           link: "/components/util-flex",
//         },
//         {
//           text: "Text",
//           link: "/components/util-text",
//         },
//         {
//           text: "Sizing",
//           link: "/components/util-sizing",
//         },
//         {
//           text: "Spacing",
//           link: "/components/util-spacing",
//         },
//         {
//           text: "Others",
//           link: "/components/util-others",
//         },
//       ],
//     },
//   ],
// },
// {
//   icon: "dot-box-fill",
//   text: "Crafted Icons",
//   active: false,
//   subMenu: [
//     {
//       text: "SVG Icon-Exclusive",
//       link: "/svg-icons",
//     },
//     {
//       text: "Nioicon - HandCrafted",
//       link: "/nioicon",
//     },
//   ],
// },
// {
//   icon: "table-view-fill",
//   text: "Tables",
//   active: false,
//   subMenu: [
//     {
//       text: "Basic Tables",
//       link: "/table-basic",
//     },
//     {
//       text: "Special Tables",
//       link: "/table-special",
//     },
//   ],
// },
// {
//   icon: "view-group-fill",
//   text: "Forms",
//   active: false,
//   subMenu: [
//     {
//       text: "Form Elements",
//       link: "/components/form-elements",
//     },
//     {
//       text: "Form Layouts",
//       link: "/components/form-layouts",
//     },
//     {
//       text: "Form Validation",
//       link: "/components/form-validation",
//     },
//     {
//       text: "Date Time Picker",
//       link: "/components/datetime-picker",
//     },
//   ],
// },
// {
//   icon: "pie-fill",
//   text: "Charts",
//   active: false,
//   subMenu: [
//     {
//       text: "Chart Js",
//       link: "/charts/chartjs",
//     },
//   ],
// },
// {
//   icon: "tag-alt-fill",
//   text: "Email Template",
//   link: "/email-template",
//   active: "false",
// }]

export default menu;
