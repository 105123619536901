/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import PlantOwnedCompact from "../components/PlantOwnedList";
import { PlantOwnedInventoryContext, withPlantOwnedInventoryProvider } from "../PlantOwnedProvider";

const PlantOwnedContainer = props => {
    const { loadPlantOwnedInventoryList, loadFilterOptions } = useContext(PlantOwnedInventoryContext)
    const { pagination, filterParams } = useContext(PlantOwnedInventoryContext)

    useEffect(() => {
        loadPlantOwnedInventoryList()
    }, [pagination, filterParams])
    useEffect(() => {
        loadFilterOptions()
    }, [])
    return (
        <React.Fragment>
            <Head title="Brighter App | Plant Owned Inventory" />
            <Content>
                <PlantOwnedCompact />
            </Content>
        </React.Fragment>
    );
}

export default withPlantOwnedInventoryProvider(PlantOwnedContainer);