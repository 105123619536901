import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { PREVENTATIVE_MASTER_LIST_FILTERS } from "../../../../constants/PreferenceKeys";
import ModalViewer from "../../../maintenance/ModalViewer";
import { MODE } from "../../../maintenance/preventative/constants";
import { DnoContext } from "../../dno/DnoProvider";
import { PreventativeContext } from "../PreventativeProvider";
import { deletePreventativeMasterList, exportTask } from "../PreventativeRepository";
import PreventiveMaintenanceForm from "./PreventativeMaintenanceForm";
import PreventativeMasterFilter from "./PreventativeMasterFilter";
import ExportForm from "./ExportForm";

const PreventiveMasterList = () => {
  const history = useHistory();

  const preventativeContext = useContext(PreventativeContext);
  const {
    pagination,
    filterParams,
    preventativeMasterLists,
    plants,
    preventativeMasterListLoading,
    handleApplyFilter,
    unArchivePreventativeMasterList,
    archivePreventativeMasterList,
    isFilterApplied,
    sfState,
    showFilter,
    loadPreventativeMasterLists,
  } = preventativeContext;
  const { filterOption, getDnoFilterOption } = useContext(DnoContext);
  const [sm, updateSm] = useState(false);
  const [mode, setMode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [task, setTask] = useState("");
  const [view, setView] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const toggle = () => setonSearch(!onSearch);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const focusSearchRef = useRef();

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };

  const createExport = () => {
    setView(VIEW.CREATE);
    setIsExportModalOpen(!isExportModalOpen);
    return <>Edit</>;
  };
  const getExportComponent = () => {
    if (view === VIEW.CREATE) {
      return (
        <ExportForm
          isFromMasterList={true}
          onSuccessfulModal={onSuccessfulModal}
          filterParams={filterParams}
          setIsExportModalOpen={setIsExportModalOpen}
        />
      );
    }
  };
  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const createTask = () => {
    setMode(MODE.CREATE);
    setIsOpen(true);
    setTask("");
  };
  const onSuccessfulModal = () => {
    toggleModal();
    loadPreventativeMasterLists(task);
  };

  const handlePreventativeMasterArchive = async (id) => {
    archivePreventativeMasterList(id)
      .then((res) => {
        successToast({ description: " Preventative Master Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Preventative Master" });
      });
  };
  const handlePreventativeMasterUnArchive = async (id) => {
    unArchivePreventativeMasterList(id)
      .then((res) => {
        successToast({ description: "Preventative Master UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Preventative Master" });
      });
  };

  const getComponent = () => {
    return (
      <PreventiveMaintenanceForm
        onSuccessfulModal={onSuccessfulModal}
        mode={mode}
        taskId={task}
        setMode={setMode}
        setIsOpen={setIsOpen}
      />
    );
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, taskText: onSearchText });
  };

  const currentItems = preventativeMasterLists?.items || [];

  useEffect(() => {
    const filters = localStorage.getItem(PREVENTATIVE_MASTER_LIST_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
      });
    }
  }, []);
  const viewTask = (id) => {
    setTask(id);
    setMode(MODE.VIEW);
    toggleModal();
  };
  const editTask = (id) => {
    setTask(id);
    setMode(MODE.EDIT);
    toggleModal();
  };
  const deleteFunc = async (taskId) => {
    try {
      await deletePreventativeMasterList(taskId);
      loadPreventativeMasterLists(task);
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Master maintenance" });
    }
  };
  const handleDeleteConfirmation = (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };
  React.useEffect(() => {
    getDnoFilterOption();
  }, []);
  const createdBySelectedFilter =
    filterParams &&
    filterParams?.users.map((item, key) => {
      return filterOption && filterOption?.users?.[filterOption.users?.findIndex((status) => status.value === item)];
    });

  const assignedEngineerData = filterParams.users.map((item) => {
    return filterOption.users?.[filterOption.users?.findIndex((status) => status.value === item)];
  });

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Preventative Master Lists
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>
                You have a total of {preventativeMasterLists?.pagination?.totalCount || 0} preventative master
                templates.
              </p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={assignedEngineerData} title={"Created By"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.createdAt} title={"Updated At"} isMap={false} isStr={true} />
              <FilteredByList data={filterParams.taskText} title={"Search By"} isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getEntity", "manageEntity"]}>
                    <li>
                      <Button
                        disabled={preventativeMasterLists?.pagination?.totalCount === 0}
                        className={`btn btn-white btn-outline-light`}
                        type="export"
                        isLoading={exportLoading}
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </Check>
                  <Check permission={["manageEntity"]}>
                    <li className="nk-block-tools-opt">
                      <Button color="primary" onClick={createTask}>
                        <Icon name="plus"></Icon>
                        <span>Add Preventative Master List</span>
                      </Button>
                    </li>
                  </Check>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, taskText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by ticket name.enter to search"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                      }}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                        ? handleNameSort("DESC", "name")
                        : handleNameSort("ASC", "name");
                    }}
                  >
                    Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "name");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "name");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "name"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "created_by_id"
                        ? handleNameSort("DESC", "created_by_id")
                        : handleNameSort("ASC", "created_by_id");
                    }}
                  >
                    Created By
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "created_by_id");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "created_by_id"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "created_by_id");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "created_by_id"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>

              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "created_at"
                        ? handleNameSort("DESC", "created_at")
                        : handleNameSort("ASC", "created_at");
                    }}
                  >
                    Created At
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "created_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "created_at"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "created_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "created_at"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">No of Tasks</span>
              </DataTableRow>
              <DataTableRow></DataTableRow>
            </DataTableHead>

            <Sidebar toggleState={showFilter}>
              <PreventativeMasterFilter plants={plants} />
            </Sidebar>
            {preventativeMasterListLoading ? (
              <></>
            ) : (
              currentItems.map((item, i) => {
                return (
                  <DataTableItem key={`${item.id}-preventative-masters`}>
                    <DataTableRow>
                      <div
                        className="user-card dropdown-toggle"
                        onClick={() => {
                          history.push(`/maintenance/preventative-masters/${item.id}`);
                        }}
                      >
                        <div className="user-name">
                          <span
                            data-toggle="tooltip"
                            title={item.name}
                            style={{
                              width: item.name?.length > 50 ? "350px" : "",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            className="tb-lead"
                          >
                            {item.name || "-"}
                          </span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="md">
                      {item.user.firstName || item.user.lastName ? (
                        <span className="noWrap">
                          {item.user.firstName} {item.user.lastName}
                        </span>
                      ) : (
                        <span>{"-"}</span>
                      )}
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">
                        {item?.created_at ? format(new Date(item?.created_at || null), "dd/MM/yyyy HH:mm") : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{item.count || "-"}</span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <React.Fragment>
                                  <Check permission={["manageEntity"]}>
                                    <li /* className="nk-tb-action-hidden" */ onClick={() => editTask(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit Task</span>
                                      </DropdownItem>
                                    </li>
                                    {item.isArchived ? (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => handlePreventativeMasterUnArchive(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#unarchive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="unarchive"></Icon>
                                            <span>UnArchive Task</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => handlePreventativeMasterArchive(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#archive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="archive"></Icon>
                                            <span>Archive Task</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                    <li className="divider"></li>
                                    <li
                                      /* className="nk-tb-action-hidden" */ onClick={() =>
                                        handleDeleteConfirmation(item.id)
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Delete Task</span>
                                      </DropdownItem>
                                    </li>
                                  </Check>
                                </React.Fragment>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {preventativeMasterListLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {preventativeMasterLists.pagination && preventativeMasterLists.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={preventativeMasterLists.pagination.totalCount}
                paginate={preventativeContext.updateShowListPage}
                currentPage={preventativeMasterLists.pagination.currentPage}
                paginatePage={preventativeContext.updateShowListSize}
              />
            ) : (
              !preventativeMasterListLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="Preventative Master List"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Preventative Master List - Select the Export Fields"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default PreventiveMasterList;
