import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import PreventiveMasterList from "../components/PreventiveMasterList";
import { withPreventativeProvider, PreventativeContext } from "../PreventativeProvider";

const PreventativeMasterListContainer = (props) => {
  const { loadPreventativeMasterLists } = useContext(PreventativeContext);
  const { pagination, filterParams } = useContext(PreventativeContext);

  useEffect(() => {
    loadPreventativeMasterLists();
  }, [pagination, filterParams]);

  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative Master List" />
      <Content>
        <PreventiveMasterList />
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeProvider(PreventativeMasterListContainer);
