export const ACCESS_TOKEN = "@/bge/auth_token"
export const REFRESH_TOKEN = "@/bge/refresh_token"
export const DEVICE_ID = "@/bge/device_id"
export const CURRENT_USER = "current_user"

export const CLIENT_FILTERS = "@/bge/client_filters"
export const CORRECTIVE_FILTERS = "@/bge/corrective_filters"
export const REMEDIAL_FILTERS = "@/bge/remedial_filters"
export const PREVENTATIVE_TASK_FILTERS = "@/bge/preventative_task_filters"
export const PREVENTATIVE_MASTER_FILTERS = "@/bge/preventative_master_filters"
export const PREVENTATIVE_MASTER_LIST_FILTERS = "@/bge/preventative_master_list_filters"
export const PREVENTATIVE_SCHEDULE_LIST_FILTERS = "@/bge/preventative_schedule_list_filters"
export const STRING_FILTER = "@/bge/string_filters"
export const BGE_OWNED_FILTERS = "@/bge/bge_owned_filters"
export const PLANT_OWNED_FILTERS = "@/bge/plant_owned_filters"
export const EQUIPMENT_SPARE_FILTERS = "@/bge/equipment_spare_filters"

export const CLIENT = "CLIENT"
export const TASK = "TASK"
export const STOCK = "STOCK"
export const PLANT = "PLANT"
export const USER = "USER"
export const ASSET = "ASSET"