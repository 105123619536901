/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { ClientManagementContext, withClientManagementProvider } from "../ClientManagementProvider";
import ClientView from "../components/ClientView";
import ActivityLog from "../../../components/activity_log/components/ActivityLog";
import { ActivityLogContext } from "../../../providers/ActivtiyLogProvider";

const UserManagementViewContainer = (props) => {
  const params = useParams();
  const { clientId } = params;

  const { loadClient } = useContext(ClientManagementContext);
  const { loadActivityLogList } = useContext(ActivityLogContext);
  const filter = { entityId: clientId, entityType: "CLIENT" };
  useEffect(() => {
    loadActivityLogList(filter);
  }, [clientId]);

  useEffect(() => {
    if (clientId) loadClient(clientId);
  }, [clientId]);

  return (
    <React.Fragment>
      <Head title={`${clientId} - Client`} />
      <Content>
        <ClientView />
      </Content>
      <Content>
        <ActivityLog id={clientId} />
      </Content>
    </React.Fragment>
  );
};

export default withClientManagementProvider(UserManagementViewContainer);
