/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Content from "../../../layout/content/Content";
import { withClientManagementProvider } from "../ClientManagementProvider";
import ClientPlantForm from "../components/ClientPlantform";

const ClientManagementPlantFormContainer = props => {

    const { setIsOpen, setPlants, plants, toggleModal, plantId } = props
    return (
            <Content>
                <ClientPlantForm
                    setIsOpen={setIsOpen}
                    setPlants={setPlants}
                    plants={plants}
                    toggleModal={toggleModal}
                    plantId={plantId}
                />
            </Content>
    );
}

export default withClientManagementProvider(ClientManagementPlantFormContainer);