import apiService from "../../../services/ApiService";
import {

  CREATE_FAILURE_REASON,
  EXPORT_FAILURE_REASON,
  FAILURE_REASON_FILTER_LIST_API,
  GET_FAILURE_REASON, MANAGE_FAILURE_REASON
} from "../../../constants/endpoints";

export const getFailureReasonList = async (filterParams) => {
  const response = await apiService.get(GET_FAILURE_REASON, {
    queryParams: filterParams,
  });
  return response;
};
export const updateFailureReasonAPI = async (failureReasonId, params) => {
  const response = await apiService.put(MANAGE_FAILURE_REASON, {
    pathParams: { failureReasonId },
    body: params
  })
  return response
}

export const createFailureReasonAPI = async (params) => {
  const response = await apiService.post(CREATE_FAILURE_REASON, {
    body: params,
  });
  return response
}
export const getFailureReasonAPI = async (failureReasonId) => {
  const response = await apiService.get(MANAGE_FAILURE_REASON, {
    pathParams: { failureReasonId }
  })
  return response
}

export const deleteFailureReasonAPI = async (failureReasonId) => {
  const response = await apiService.delete(MANAGE_FAILURE_REASON, {
    pathParams: { failureReasonId }
  })
  return response
}

export const exportFailureReason = async (filterParams) => {
  const response = await apiService.get(EXPORT_FAILURE_REASON, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
export const getFailureReasonFilterListAPI = async () => {
  const response = await apiService.get(FAILURE_REASON_FILTER_LIST_API);
  return response;
};
