import { format } from "date-fns";
import React, { useContext } from "react";
import { Spinner } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, Button, Icon } from "../../../../components/Component";
import Check from "../../../../components/check_roles/check";
import Head from "../../../../layout/head/Head";
import { FailureReasonContext } from "../FailureReasonProvider";

const ViewFailureReason = ({ sm, updateSm, editFailureReason }) => {
  const failureReasoncontext = useContext(FailureReasonContext);
  const { currentViewFailureReason } = failureReasoncontext;
  if (!currentViewFailureReason) return <Spinner />;
  return (
    <div className="overflow-auto h-max-450px">
      <Head title="Brighter App | Failure Reason | View" />
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md" className="d-flex justify-content-end p-2">
          <BlockBetween>
            <BlockHeadContent className="align-self-end">
              <Check permission={["manageEntity"]}>
                <Button
                  id="edit-faliure"
                  size="sm"
                  color="primary"
                  onClick={() => {
                    editFailureReason(currentViewFailureReason.id);
                  }}
                >
                  <Icon name="edit" />
                  <span>Edit</span>
                </Button>
              </Check>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Category</span>
                <span className="data-value">{currentViewFailureReason.name} </span>
              </div>
            </div>
            <div className="m-2 ml-3">
              <small class="text-muted">
                CreatedAt: {format(new Date(currentViewFailureReason?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
              </small>
            </div>
            <div className="m-2 ml-3">
              <small class="text-muted">
                UpdatedAt: {format(new Date(currentViewFailureReason?.updated_at || null), "dd/MM/yyyy HH:mm")}{" "}
              </small>
            </div>
          </div>
        </Block>
      </Block>
    </div>
  );
};
export default ViewFailureReason;
