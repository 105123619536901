/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import AddForm from "../components/AddForm";
import { FormManagementContext, withFormManagementProvider } from "../FormManagementProvider";
import { UserManagementContext } from "../../user_management/UserManagementProvider";

const FormManagementContainer = props => {

    const formManagementContext = useContext(FormManagementContext)
    const { currentViewForm, updateForm, loadForm } = formManagementContext

    const { loadAllRoles } = useContext(UserManagementContext);

    useEffect(() => {
        if (props.formId) loadForm(props.formId)
    }, [props.formId])

    useEffect(()=>{
        loadAllRoles()
    },[])
    
    return (
        <React.Fragment>
            <AddForm
                formId={props.formId}
                currentViewForm={currentViewForm}
                updateForm={updateForm}
                onSuccessfulModal={props.onSuccessfulModal}
            />
        </React.Fragment>
    );
}

export default withFormManagementProvider(FormManagementContainer);