/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
} from "../../../../components/Component";
import { PREVENTATIVE_TASK_FILTERS } from "../../../../constants/PreferenceKeys";
import Skeleton from "react-loading-skeleton";
import { ScheduleContext } from "../../ScheduleProvider";
import { MONTH } from "../../../../constants/constants";
import ExportForm from "./ExportForm";
import ModalViewer from "../../../maintenance/ModalViewer";

const PreventativeTaskList = ({ preventativeScheduleId }) => {
  const preventativeContext = useContext(ScheduleContext);
  const {
    preventativeTaskFilter,
    preventativeTaskList,
    preventativeTaskLoading,
    setPreventativeTaskFilter,
    loadPreventativeScheduleTasks,
    pagination,
  } = preventativeContext;
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const toggle = () => setonSearch(!onSearch);

  const focusSearchRef = useRef();

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onSearchAction = () => {
    setPreventativeTaskFilter({ ...preventativeTaskFilter, search: onSearchText });
  };

  useEffect(() => {
    const filters = localStorage.getItem(PREVENTATIVE_TASK_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      setPreventativeTaskFilter({
        ...parseFilters,
      });
    }
  }, []);

  useEffect(() => {
    loadPreventativeScheduleTasks(preventativeScheduleId);
  }, [preventativeTaskFilter, pagination]);

  const currentItems = preventativeTaskList?.items || [];

  const handleNameSort = (sortOrder, field) => {
    setPreventativeTaskFilter({ ...preventativeTaskFilter, sortingOrder: sortOrder, sortingField: field });
  };

  const createExport = () => {
    setIsExportModalOpen(!isExportModalOpen);
    return <>Edit</>;
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const getExportComponent = () => {
    return (
      <ExportForm
        onSuccessfulModal={toggleExportModal}
        filterParams={{ ...preventativeTaskFilter, taskText: preventativeTaskFilter.search, preventativeScheduleId }}
      />
    );
  };

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Preventative Maintenance Tickets
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {preventativeTaskList?.pagination?.totalCount || 0} preventative tickets.</p>
            </BlockDes>
          </BlockHeadContent>
          {/* {
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3 d-inline-block">
                    <li>
                      <Button
                        className="btn btn-white btn-outline-light"
                        type="export"
                        isLoading={exportLoading}
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          } */}
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        setPreventativeTaskFilter({ ...preventativeTaskFilter, search: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by Ticket Name,status and Plant Name.enter to search"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          setPreventativeTaskFilter({ ...preventativeTaskFilter, search: "" });
                          toggle();
                        }
                      }}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      preventativeTaskFilter.sortingOrder === "ASC" && preventativeTaskFilter.sortingField === "title"
                        ? handleNameSort("DESC", "code")
                        : handleNameSort("ASC", "code");
                    }}
                  >
                    Code
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "code");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "ASC" && preventativeTaskFilter.sortingField === "code"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "code");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "DESC" && preventativeTaskFilter.sortingField === "code"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      preventativeTaskFilter.sortingOrder === "ASC" && preventativeTaskFilter.sortingField === "title"
                        ? handleNameSort("DESC", "title")
                        : handleNameSort("ASC", "title");
                    }}
                  >
                    Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "title");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "ASC" && preventativeTaskFilter.sortingField === "title"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "title");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "DESC" &&
                        preventativeTaskFilter.sortingField === "title"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      preventativeTaskFilter.sortingOrder === "ASC" && preventativeTaskFilter.sortingField === "months"
                        ? handleNameSort("DESC", "months")
                        : handleNameSort("ASC", "months");
                    }}
                  >
                    Months
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "months");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "ASC" &&
                        preventativeTaskFilter.sortingField === "months"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "months");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "DESC" &&
                        preventativeTaskFilter.sortingField === "months"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      preventativeTaskFilter.sortingOrder === "ASC" &&
                      preventativeTaskFilter.sortingField === "frequency"
                        ? handleNameSort("DESC", "frequency")
                        : handleNameSort("ASC", "frequency");
                    }}
                  >
                    Frequency
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "frequency");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "ASC" &&
                        preventativeTaskFilter.sortingField === "frequency"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "frequency");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "DESC" &&
                        preventativeTaskFilter.sortingField === "frequency"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      preventativeTaskFilter.sortingOrder === "ASC" &&
                      preventativeTaskFilter.sortingField === "instructions"
                        ? handleNameSort("DESC", "instructions")
                        : handleNameSort("ASC", "instructions");
                    }}
                  >
                    Site Specific Instructions
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "instructions");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "ASC" &&
                        preventativeTaskFilter.sortingField === "instructions"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "instructions");
                      }}
                      name={`${
                        preventativeTaskFilter.sortingOrder === "DESC" &&
                        preventativeTaskFilter.sortingField === "instructions"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
            </DataTableHead>
            {/* <Sidebar toggleState={showFilter}>
                            <PreventativeListFilter plants={plants} engineers={engineers} />
                        </Sidebar> */}

            {preventativeTaskLoading ? (
              <></>
            ) : (
              currentItems.map((item, i) => {
                return (
                  <DataTableItem key={`${item.id}-preventative-masters`}>
                    <DataTableRow>
                      <div className="user-card">
                        <div className="user-name">
                          <span
                            data-toggle="tooltip"
                            title={item.code}
                            style={{
                              width: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            className="tb-lead"
                          >
                            {item.code || "-"}
                          </span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item.title}
                        style={{
                          display: "inline-block",
                          width: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.title || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      {/* <Badge className="text-capitalize" color={statusObj[item.status]} pill>
                                                January
                                            </Badge> */}
                      <span
                        data-toggle="tooltip"
                        title={item.months.map((m) => MONTH[m]).join(", ")}
                        style={{
                          display: "inline-block",
                          width: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.months.map((m) => MONTH[m]).join(", ") || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">{item?.frequency || "-"}</DataTableRow>
                    <DataTableRow size="md">{item?.instructions || "-"}</DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {preventativeTaskLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {preventativeTaskList.pagination && preventativeTaskList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={preventativeTaskList.pagination.totalCount}
                paginate={preventativeContext.updateShowListPage}
                currentPage={preventativeTaskList.pagination.currentPage}
                paginatePage={preventativeContext.updateShowListSize}
              />
            ) : (
              !preventativeTaskLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="Remedial Maintenance"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default PreventativeTaskList;
