/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import SelfOwnedView from "../components/SelfOwnedView";
import { SelfOwnedInventoryContext, withSelfOwnedInventoryProvider } from "../SelfOwnedProvider";

const SelfOwnedViewContainer = props => {
    const { inventoryId, setIsOpen, editInventory, moveSpareInventory } = props

    const correctiveContext = useContext(SelfOwnedInventoryContext)
    const { loadInventory, loadFilterOptions } = correctiveContext

    useEffect(() => {
        loadFilterOptions()
        if (inventoryId) loadInventory(inventoryId)
    }, [inventoryId])

    return (
        <React.Fragment>
            <SelfOwnedView setIsOpen={setIsOpen} editInventory={editInventory} moveSpareInventory={moveSpareInventory} />
        </React.Fragment>
    );
}

export default withSelfOwnedInventoryProvider(SelfOwnedViewContainer);