/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../../components/Component";
import download from "js-file-download";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { exportFailureReason } from "../FailureReasonRepository";

const FAILURE_FIELDS = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "CreatedAt",
    value: "created_at",
  },
  {
    label: "UpdatedAt",
    value: "updated_at",
  },
];

const ExportForm = (props) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (FAILURE_FIELDS) {
      const data = FAILURE_FIELDS.map((failureReason) => {
        return failureReason.value;
      });
      setValue(data);
    }
  }, [FAILURE_FIELDS]);

  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async (formData) => {
    try {
      setExportLoading(true);
      const response = await exportFailureReason({ ...props.filterParams, headerData: value });
      download(response, `failureReason.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting failureReason" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="export-faliure"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Failure Reason Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0 ">
                {FAILURE_FIELDS &&
                  FAILURE_FIELDS.map((failureReason, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${failureReason} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0 d-flex">
                              <input
                                key={`${failureReason}-${key}`}
                                type="checkbox"
                                checked={value.includes(failureReason.value)}
                                id={`${failureReason}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([failureReason.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === failureReason.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${failureReason}-${key}`} check>
                                {failureReason.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;
