/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import StringList from "../components/StringList";
import { withStringProvider, StringContext } from "../StringProvider";
import { useLocation, useHistory } from "react-router-dom";

const StringListContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const plantId = new URLSearchParams(location).get("plantId");
  const { loadStringList, loadOptions } = useContext(StringContext);
  const { pagination, filterParams, dashboardFilterStatus } = useContext(StringContext);

  const searchtext = filterParams.searchText;

  useEffect(() => {
    if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
      const queryParams = new URLSearchParams(location);
      if (queryParams.has("status")) {
        queryParams.delete("status");
        history.replace({
          search: queryParams.toString(),
        });
      }
      if (queryParams.has("plantId")) {
        queryParams.delete("plantId");
        history.replace({
          search: queryParams.toString(),
        });
      }
      loadStringList();
    }
  }, [pagination, filterParams]);

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    if (status && !dashboardFilterStatus) {
      loadStringList(parseInt(plantId), status);
    }
  }, [pagination, searchtext]);

  return (
    <React.Fragment>
      <Head title="BGE | String Maintenance" />
      <Content>
        <StringList />
      </Content>
    </React.Fragment>
  );
};

export default withStringProvider(StringListContainer);
