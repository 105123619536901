import React from "react";
import TSK_LOGO from "../../images/footer-TESARK@3x.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            <a
              href={"https://brightergreenengineering.com"}
              className="nav-link px-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              &copy; Brighter Green Engineering {currentYear}
            </a>
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <a href={"https://www.tesark.com"} className="nav-link px-0" target="_blank" rel="noopener noreferrer">
                      <img className="logo-dark logo-img" src={TSK_LOGO} alt="logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
