import React, { useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../components/Component";
import { EQUIPMENT_SPARE_FILTERS } from "../../../constants/PreferenceKeys";
import { STATUSES } from "../constants";
import { EquipmentAndSpareContext } from "../EquipmentAndSpareProvider";
import { sortOption } from "../../../utils/Utils";

const EquipmentAndSpareFilter = (props) => {
  const equipmentAndSpareFilter = React.useContext(EquipmentAndSpareContext);
  const { filterOptions, showFilter, sfState } = equipmentAndSpareFilter;
  const { filterParams, handleApplyFilter, resetEquipmentListFilter, loadEquipmentList, setEquipmentColumnSettings, equipmentColumnSettings, checkIsFilterApplied, updateTableSettings } = equipmentAndSpareFilter;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm , updateTableSm] = useState(false);
  const KEY_COMPONENT_OPTION = [
    { label: "Yes", value: true },
    { label: "No", value: false },
    { label: "All", value: "" },
  ];
  const OWNED_BY_OPTIONS = [
    { label: "BGE Owned", value: "BGE" },
    { label: "Plant Owned", value: "PLANT" },
    { label: "All", value: "" },
  ];
  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    const equipmentSettings = equipmentColumnSettings;
    const settings = {
      ...equipmentSettings,
      filters: {
        ...(equipmentSettings.filters.size && { size: equipmentSettings.filters.size }),
        ...(equipmentSettings.filters.page && {page: equipmentSettings.filters.page}),
        ...(equipmentSettings.filters.sortingField && { sortingField: equipmentSettings.filters.sortingField }),
        ...(equipmentSettings.filters.sortingOrder && { sortingOrder: equipmentSettings.filters.sortingOrder }),
      },
    };
    handleApplyFilter(selectedFilter);
    setEquipmentColumnSettings(settings)
    updateTableSettings(settings)
    resetEquipmentListFilter()
    loadEquipmentList({
      ...settings,
      filters: {
        ...settings.filters,
        category: "",
        plant: props?.plantId ? [props?.plantId] : [],
        ownership: [],
        supplier: [],
        status:"",
        isKeyComponent:"",
      }
    }) 
   };

  const handleApply = () => {
    const settings = {
      ...equipmentColumnSettings,
      filters: {
        ...equipmentColumnSettings.filters,
        category: selectedFilter.category ?? "",
        plant: props?.plantId ? [props?.plantId] : selectedFilter.plant ?? "",
        ownership: selectedFilter.ownership ?? "",
        supplier: selectedFilter.supplier ?? "",
        isKeyComponent: selectedFilter.isKeyComponent ?? "",
        status: selectedFilter.status ?? ""
      },
    };
    handleApplyFilter(selectedFilter)
    updateTableSettings(settings);  
    setEquipmentColumnSettings(settings)
    checkIsFilterApplied();
    loadEquipmentList(settings);
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handlePlantFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ plant: statusFilter });
  };

  const handleCategoryFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ category: statusFilter });
  };

  const handleSupplierFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ supplier: statusFilter });
  };

  const handleStatusSelection = (selectedOption) => {
    const statusFilter = selectedOption.value;
    handleFilterSelection({ status: statusFilter });
  };

  const saveFilter = () => {
    localStorage.setItem(EQUIPMENT_SPARE_FILTERS, JSON.stringify(filterParams));
  };

  const plantSelectedFilter = selectedFilter.plant?.map((item, key) => {
    return filterOptions.plant?.[filterOptions.plant.findIndex((status) => status.value === item)];
  });
  const categorySelectedFilter = selectedFilter.category.map((item, key) => {
    return filterOptions.assetCategory?.[filterOptions.assetCategory.findIndex((status) => status.value === item)];
  });

  const supplierSelectedFilter = selectedFilter.supplier.map((item, key) => {
    return filterOptions.manufacturer?.[filterOptions.manufacturer.findIndex((status) => status.value === item)];
  });
  // let statusFilter = "";
  // if (filterOptions.status || selectedFilter.status) {
  //   statusFilter = STATUSES.find((d) => d.value === filterOptions.status);
  // }

  const selectedStatusFilter = STATUSES.find((data) => data.value === selectedFilter?.status);

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup className="form-group">
                <label className="overline-title overline-title-alt">Owned By</label>
                <RSelect
                  options={OWNED_BY_OPTIONS}
                  placeholder="Owned By"
                  onChange={(e) => {
                    handleFilterSelection({ ownership: e.value });
                  }}
                  value={OWNED_BY_OPTIONS.find((d) => d.value === selectedFilter.ownership)}
                />
              </FormGroup>
            </Col>
            {props.subList ? null : (
              <Col size="12">
                {!props?.plantId &&
                  <FormGroup>
                    <label className="overline-title overline-title-alt">Plant</label>
                    <RSelect
                      options={sortOption(filterOptions.plant)}
                      placeholder="Any Plants"
                      onChange={handlePlantFilterSelection}
                      isMulti
                      value={plantSelectedFilter}
                    />
                  </FormGroup>
                }
              </Col>
            )}
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Category</label>
                <RSelect
                  options={sortOption(filterOptions.assetCategory)}
                  placeholder="Any Asset Category"
                  onChange={handleCategoryFilterSelection}
                  isMulti
                  value={categorySelectedFilter}
                />
              </FormGroup>
            </Col>

            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Supplier</label>
                <RSelect
                  options={sortOption(filterOptions.manufacturer)}
                  placeholder="Any Supplier"
                  onChange={handleSupplierFilterSelection}
                  isMulti
                  value={supplierSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={STATUSES}
                  placeholder="Any Status"
                  onChange={handleStatusSelection}
                  value={selectedStatusFilter}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              {/* <FormGroup className="form-group">
                <div className="custom-control custom-control-sm custom-checkbox" style={{ zIndex: 0 }}>
                  <input
                    type="checkbox"
                    className="custom-control-input form-control"
                    id="isKeyComponent"
                    onChange={(e) => {
                      handleFilterSelection({ isKeyComponent: e.target.checked });
                    }}
                    checked={selectedFilter.isKeyComponent ? selectedFilter.isKeyComponent : false}
                  />
                  <label className="custom-control-label" htmlFor="isKeyComponent">
                    {" "}
                    Key Component
                  </label>
                </div>
              </FormGroup> */}
              <FormGroup className="form-group">
                <label className="overline-title overline-title-alt">Key Component</label>
                <RSelect
                  options={KEY_COMPONENT_OPTION}
                  placeholder="Key Component"
                  onChange={(e) => {
                    handleFilterSelection({ isKeyComponent: e.value });
                  }}
                  value={KEY_COMPONENT_OPTION.find((d) => d.value === selectedFilter.isKeyComponent)}
                />
              </FormGroup>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>

              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
            {/* <Col size="12">
              <a href="#save" onClick={saveFilter}>
                Save Filter
              </a></Col> */}
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default EquipmentAndSpareFilter;
