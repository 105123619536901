/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import React, { useContext, useEffect, useState } from "react";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import {
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  Icon,
} from "../../../../components/Component";
import { Col, Row } from "reactstrap";
// import ModalViewer from "../../../../ModalViewer";
import { STATUSES } from "../../../maintenance/string/constants";

import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { compareAsc } from "date-fns";
import "../../../maintenance/string/components/stringList.css";
import { ImportContext } from "../../ImportProvider";
import ModalViewer from "../../../maintenance/ModalViewer";
import { useHistory, useParams } from "react-router-dom";
import { PVPLANT_MANAGEMENT_VIEW_PAGE } from "../../../../constants/routes";

const StringList = () => {
  const history = useHistory();
  const { importId, plantId } = useParams();
  const importContext = useContext(ImportContext);
  const {
    stringData,
    stringLoading,
    setStringData,
    plants,
    failureReason: failureReasons,
    importStrings,
    isStringImport,
    loadOptions
  } = useContext(ImportContext);

  const [confirmModal, setConfirmModal] = useState(false);
  const [currentChange, setCurrentChange] = useState({});


  useEffect(() => {
    loadOptions()
  }, [])

  useEffect(() => {
    stringData.forEach((item) => {
      let matchingFailureReason
      if (item?.failureReason) {
        matchingFailureReason = failureReasons.find((failure) => failure.value === item.failureReason);
      }
      if (item?.failureReasonId) {
        matchingFailureReason = failureReasons.find((failure) => failure.value === item.failureReasonId);
      } if (matchingFailureReason) {
        item.failureReasonId = matchingFailureReason.id;
      }
    });
  }, [stringData]);
  

  const [columns] = useState([
    { columnId: "sno", width: 30 },
    { columnId: "identifier", width: 100 },
    { columnId: "addedAt", width: 120 },
    { columnId: "investigatedAt", width: 120 },
    { columnId: "repairedAt", width: 120 },
    { columnId: "status", width: 150 },
    { columnId: "failureReasonId", width: 150 },
    { columnId: "monitoringComments", width: 160 },
    { columnId: "workComments", width: 160 },
    { columnId: "noOfStringsPerDuct", width: 150 },
  ]);

  const headerRow = {
    rowId: "header",
    cells: [
      { type: "header", text: "Sno." },
      { type: "header", text: "Identifier" },
      { type: "header", text: "Added At" },
      { type: "header", text: "Investigated At" },
      { type: "header", text: "Repaired At" },
      { type: "header", text: "Status" },
      { type: "header", text: "Failure Reason" },
      { type: "header", text: "Monitoring Comments" },
      { type: "header", text: "Works Comments" },
      { type: "header", text: "No. of Strings fixed per duct" },
    ],
  };
  const style = {
    textAlign: "center",
  };

  const getRows = () => {
    const d = stringData.map((string, idx) => ({
      rowId: idx,
      cells: [
        { type: "text", text: (idx + 1).toString() },
        { type: "text", text: string.identifier },
        { type: "date", date: string.addedAt && new Date(string.addedAt), format: Intl.DateTimeFormat("in-IN") },
        {
          type: "date",
          date: string.investigatedAt && new Date(string.investigatedAt),
          format: Intl.DateTimeFormat("in-IN"),
        },
        {
          type: "date",
          date: string.repairedAt && new Date(string.repairedAt),
          format: Intl.DateTimeFormat("in-IN"),
        },
        {
          type: "dropdown",
          selectedValue: string.status,
          values: STATUSES,
          isOpen: string.statusOpen,
        },
        {
          type: "dropdown",
          selectedValue: string.failureReason,
          values: failureReasons,
          isOpen: string.failureReasonIdOpen,
        },
        { type: "text", text: string.monitoringComments || "" },
        { type: "text", text: string.workComments || "" },
        { type: "number", value: string.noOfStringsPerDuct, style: { style } },
      ],
    }));
    return [headerRow, ...d];
  };

  const deleteFunc = async (index) => {
    const ids = [];
    stringData.forEach((s, idx) => {
      if (index.includes(idx)) {
        ids.push(s.importItemId);
      }
    });
    const res = await importContext.updateImportItemData({ ids, status: "CANCELLED" });
    if (res) {
      setStringData((prev) => {
        return [...prev.filter((p, idx) => !index.includes(idx))];
      });
      successToast({ description: "String deleted successfully" });
    }
  };

  const handleDeleteConfirmation = (index) => {
    confirmationSweetAlert({
      id: index,
      handleConfirmation: deleteFunc,
    });
  };

  const getComponent = () => {
    return (
      <>
        <Row className="d-flex justify-content-center">
          <p>{`Identifier already exist for this plants`}</p>
        </Row>
        <Row className="d-flex justify-content-center">
          <p>Are you sure want to confirm?</p>
        </Row>
        <Row className=" mt-5 d-flex justify-content-center">
          <Col lg="3">
            <Button
              id="confirm-plantstring"
              color="primary"
              onClick={() => {
                stringData[currentChange.rowId][currentChange.columnId] = currentChange.newCell.text;
                setStringData([...stringData]);
                setConfirmModal(false);
              }}
            >
              CONFIRM
            </Button>
          </Col>
          <Col lg="3">
            <Button id="cancel-plantstring" color="primary" onClick={() => setConfirmModal(false)}>
              CANCEL
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const update = async (index, stringData) => {
    const { plantId, statusOpen, failureReasonIdOpen, importItemId, ...rest } = stringData[index];
    await importContext.updateImportItemData({ ids: [importItemId], data: rest });
  };
  const handleChanges = async (changes) => {
    // const change = changes[0]
    await Promise.all(
      changes.map(async (change, i) => {
        let rowIndex = change.rowId;
        switch (change.type) {
          case "date":
            if (
              change.columnId === "repairedAt" &&
              (stringData[rowIndex]["investigatedAt"] || stringData[rowIndex]["addedAt"])
            ) {
              if (stringData[rowIndex]["investigatedAt"] && stringData[rowIndex]["addedAt"]) {
                if (
                  compareAsc(change.newCell.date, new Date(stringData[rowIndex]["investigatedAt"])) >= 0 &&
                  compareAsc(change.newCell.date, new Date(stringData[rowIndex]["addedAt"])) >= 0
                ) {
                  stringData[rowIndex][change.columnId] = change.newCell.value;
                  await update(rowIndex, stringData);
                  break;
                } else {
                  errorToast({ description: "Repaired At should be greater or equal to Investigated At & Added At" });
                  break;
                }
              } else if (
                stringData[rowIndex]["addedAt"] &&
                compareAsc(change.newCell.date, new Date(stringData[rowIndex]["addedAt"])) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                await update(rowIndex, stringData);

                break;
              } else if (
                stringData[rowIndex]["investigatedAt"] &&
                compareAsc(change.newCell.date, new Date(stringData[rowIndex]["investigatedAt"])) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                await update(rowIndex, stringData);

                break;
              } else {
                errorToast({ description: "Repaired At should be greater or equal to Investigated At & Added At" });
                break;
              }
            }
            if (
              change.columnId === "investigatedAt" &&
              (stringData[rowIndex]["repairedAt"] || stringData[rowIndex]["addedAt"])
            ) {
              if (stringData[rowIndex]["addedAt"] && stringData[rowIndex]["repairedAt"]) {
                if (
                  compareAsc(new Date(stringData[rowIndex]["repairedAt"]), change.newCell.date) >= 0 &&
                  compareAsc(change.newCell.date, new Date(stringData[rowIndex]["addedAt"])) >= 0
                ) {
                  stringData[rowIndex][change.columnId] = change.newCell.value;
                  await update(rowIndex, stringData);
                  break;
                } else {
                  errorToast({
                    description: "Investigated At should be lesser or equal to Repaired At & greater than Added At",
                  });
                  break;
                }
              } else if (
                stringData[rowIndex]["addedAt"] &&
                compareAsc(change.newCell.date, new Date(stringData[rowIndex]["addedAt"])) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                await update(rowIndex, stringData);
                break;
              } else if (
                stringData[rowIndex]["repairedAt"] &&
                compareAsc(new Date(stringData[rowIndex]["repairedAt"]), change.newCell.date) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                await update(rowIndex, stringData);
                break;
              } else {
                errorToast({
                  description: "Investigated At should be lesser or equal to Repaired At & greater than Added At",
                });
                break;
              }
            }
            if (
              change.columnId === "addedAt" &&
              (stringData[rowIndex]["repairedAt"] || stringData[rowIndex]["investigatedAt"])
            ) {
              if (stringData[rowIndex]["investigatedAt"] && stringData[rowIndex]["repairedAt"]) {
                if (
                  compareAsc(new Date(stringData[rowIndex]["investigatedAt"]), change.newCell.date) >= 0 &&
                  compareAsc(new Date(stringData[rowIndex]["repairedAt"]), change.newCell.date) >= 0
                ) {
                  stringData[rowIndex][change.columnId] = change.newCell.value;
                  await update(rowIndex, stringData);
                  break;
                } else {
                  errorToast({ description: "Added At should be lesser or equal to Repaired At & Investigated At" });
                  break;
                }
              } else if (
                stringData[rowIndex]["investigatedAt"] &&
                compareAsc(new Date(stringData[rowIndex]["investigatedAt"]), change.newCell.date) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                await update(rowIndex, stringData);
                break;
              } else if (
                stringData[rowIndex]["repairedAt"] &&
                compareAsc(new Date(stringData[rowIndex]["repairedAt"]), change.newCell.date) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                await update(rowIndex, stringData);
                break;
              } else {
                errorToast({ description: "Added At should be lesser or equal to Repaired At & Investigated At" });
                break;
              }
            }
            stringData[rowIndex][change.columnId] = change.newCell.value;
            await update(rowIndex, stringData);
            break;
          case "text":
            if (change.columnId === "identifier") {
              const condition = stringData.findIndex((d) => d.identifier === change.newCell.text);
              if (condition > -1) {
                setConfirmModal(true);
                setCurrentChange(change);
              } else {
                stringData[rowIndex][change.columnId] = change.newCell.text;
                await update(rowIndex, stringData);
              }
              break;
            }
            stringData[rowIndex][change.columnId] = change.newCell.text;
            await update(rowIndex, stringData);
            break;
          case "number":
            stringData[rowIndex][change.columnId] = change.newCell.value;
            await update(rowIndex, stringData);
            break;
          case "dropdown":
            stringData[rowIndex][`${change.columnId}Open`] = change.newCell.isOpen;
            if (change.newCell.selectedValue !== change.previousCell.selectedValue) {
              if (change.newCell.selectedValue) {
                stringData[rowIndex][change.columnId] = change.newCell.selectedValue;
                await update(rowIndex, stringData);
              }
            }
            break;
          default:
            break;
        }
      })
    );
    setStringData([...stringData]);
  };

  const simpleHandleContextMenu = (selectedRowIds, selectedColIds, selectionMode, menuOptions) => {
    if (selectionMode === "row") {
      menuOptions = [
        ...menuOptions,
        {
          id: "removeString",
          label: "Remove String",
          handler: () => {
            handleDeleteConfirmation(selectedRowIds);
          },
        },
        {
          id: "addString",
          label: "Add String",
          handler: () => {
            setStringData((prev) => {
              return [
                ...prev,
                {
                  identifier: "",
                  status: "OPEN",
                  new: true,
                  statusOpen: false,
                  plantIdOpen: false,
                  failureReasonIdOpen: false,
                },
              ];
            });
          },
        },
      ];
    }
    return menuOptions;
  };

  const onImportClick = async () => {
    await importStrings(importId);
    successToast({ description: "Strings imported successfully" });
    history.push(`${PVPLANT_MANAGEMENT_VIEW_PAGE.replace(":plantId", plantId)}?tab=string`);
  };

  const cancelImport = async () => {
    await importContext.updateImportData(importId, { status: "CANCELLED" });
    successToast({ description: "Import cancelled successfully" });
    history.push(`${PVPLANT_MANAGEMENT_VIEW_PAGE.replace(":plantId", plantId)}?tab=string`);
  };
  return failureReasons.length && plants.length ? (
    <div
      style={{
        height: "505px",
        overflow: "scroll",
        maxWidth: "100%",
      }}
    >
      <BlockHead size="sm">
        <div className="justify-content-md-between d-md-flex align-items-center">
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              String Import
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {stringData.length} Strings.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="d-flex justify-content-md-end mt-3 mt-md-0">
            <Button
              id="import-plantstring-btn"
              className={`mr-2`}
              size="md"
              color={"danger"}
              isLoading={importContext.isUpdateImportLoading}
              onClick={() => cancelImport()}
            >
              <span>Cancel Import</span>
            </Button>
            <Button
              id="import-plantstring-btn"
              className={`btn btn-white mr-2 btn-outline-light`}
              size="md"
              isLoading={isStringImport}
              onClick={() => onImportClick()}
            >
              <Icon name="upload-cloud"> </Icon>
              <span>Import</span>
            </Button>

            <Button
              id="add-plantstring"
              className="d-flex justify-content-end btn btn-icon mr-2 ml-3"
              color="primary"
              onClick={() => {
                setStringData((prev) => {
                  return [
                    ...prev,
                    {
                      identifier: "",
                      status: "OPEN",
                      new: true,
                      statusOpen: false,
                      plantIdOpen: false,
                      failureReasonIdOpen: false,
                    },
                  ];
                });
              }}
            >
              <Icon name="plus"></Icon>
            </Button>
          </BlockHeadContent>
        </div>
      </BlockHead>
      <BlockContent>
        <DataTable>
        <div style={{overflow:"auto"}}>
            {stringLoading ? (
              <></>
            ) : stringData.length ? (
              <ReactGrid
                rows={getRows()}
                columns={columns}
                onCellsChanged={handleChanges}
                enableRowSelection
                onContextMenu={simpleHandleContextMenu}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            )}
            {stringLoading ? <Skeleton count={10} className="w-100" /> : null}
          </div>

          <div className="card-inner">
            <Row className="d-flex justify-content-end">
              <Col className="d-flex justify-content-end mt-2">
                <Button
                  id="add-plantstring"
                  className=" btn btn-icon"
                  color="primary"
                  onClick={() => {
                    setStringData((prev) => {
                      return [
                        ...prev,
                        {
                          identifier: "",
                          status: "OPEN",
                          new: true,
                          statusOpen: false,
                          plantIdOpen: false,
                          failureReasonIdOpen: false,
                        },
                      ];
                    });
                  }}
                >
                  <Icon name="plus"></Icon>
                </Button>
              </Col>
            </Row>
          </div>
        </DataTable>
      </BlockContent>
      <ModalViewer
        size="md"
        title="Confirm Box"
        isOpen={confirmModal}
        toggleModal={() => setConfirmModal(!confirmModal)}
        component={getComponent()}
      />
    </div>
  ) : null;
};
export default StringList;
