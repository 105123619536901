import React, { useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  createPlantOwnedInventoryAPI,
  deleteInventoryAPI,
  getInventoryAPI,
  getPlantOwnedInventoryFilterOptions,
  getPlantOwnedInventoryList,
  moveToBgePlantInventoryAPI,
  updatePlantOwnedInventoryAPI,
} from "./PlantOwnedRepository";

export const PlantOwnedInventoryContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};
const initialFilterOptions = {
  assetCategories: [],
  assets: [],
  manufacturers: [],
  plants: [],
};
const initialFilterParams = {
  plant: [],
  category: [],
  searchText: "",
};

export const PlantOwnedInventoryProvider = (props) => {
  const [plantOwnedInventoryList, setPlantOwnedInventoryList] = useState([]);
  const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams));
  const [currentViewInventory, setCurrentViewInventory] = useState();
  const [filterOptions, setFilterOptions] = useState(Object.assign({}, initialFilterOptions));

  //Loaders
  const [plantOwnedInventoryLoading, plantOwnedInventoryState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);

  const getPlantOwnedInventoryListFilterParams = () => {
    const queryParams = { ...pagination };
    const { plant, category, searchText } = filterParams;
    if (plant.length) queryParams["plant"] = plant;
    if (category.length) queryParams["category"] = category;
    if (searchText) queryParams["equipmentName"] = searchText;
    return queryParams;
  };

  const loadPlantOwnedInventoryList = async () => {
    plantOwnedInventoryState.on();

    try {
      const filterParams = getPlantOwnedInventoryListFilterParams();
      const response = await getPlantOwnedInventoryList(filterParams);
      setPlantOwnedInventoryList(response.plantOwnedInventoryList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      plantOwnedInventoryState.off();
    }
  };

  const loadFilterOptions = async () => {
    flState.on();

    try {
      const response = await getPlantOwnedInventoryFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  // VIEW INVENTORY
  const loadInventory = async (inventoryId) => {
    try {
      const response = await getInventoryAPI(inventoryId);
      setCurrentViewInventory(response.inventory);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  // UPDATE INVENTORY
  const updateInventory = async (inventoryId, updateParams) => {
    try {
      await updatePlantOwnedInventoryAPI(inventoryId, updateParams);
      loadPlantOwnedInventoryList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  //MOVE TO PLANT INVENTORY
  const moveToPlantInventory = async (inventoryId, updateParams) => {
    try {
      await moveToBgePlantInventoryAPI(inventoryId, updateParams);
      loadPlantOwnedInventoryList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  //CREATE INVENTORY
  const createInventory = async (updateParams) => {
    try {
      await createPlantOwnedInventoryAPI(updateParams);
      loadPlantOwnedInventoryList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  // DELETE INVENTORY
  const deleteInventory = async (inventoryId) => {
    try {
      await deleteInventoryAPI(inventoryId)
      loadPlantOwnedInventoryList();
    } catch (e) {
      throw e
    } finally {
      //
    }
  }

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    plantOwnedInventoryList,
    pagination,
    loadFilterOptions,
    loadPlantOwnedInventoryList,
    updateInventory,
    plantOwnedInventoryLoading,
    filterParams,
    createInventory,
    handleApplyFilter,
    resetClientListFilter,
    currentViewInventory,
    filterOptions,
    filtersLoading,
    deleteInventory,
    loadInventory,
    moveToPlantInventory,
    updateShowListSize: size => setPagination({ ...pagination, size, page:1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <PlantOwnedInventoryContext.Provider value={mContext}>{props.children}</PlantOwnedInventoryContext.Provider>;
};

export const withPlantOwnedInventoryProvider = (Container, containerProps) => (props) =>
(
  <PlantOwnedInventoryProvider>
    <Container {...containerProps} {...props} />
  </PlantOwnedInventoryProvider>
);
