/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { Controller, get, useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard, RSelect } from "../../../components/Component";
import { CAUSE, RECORD_TYPE, TRANSFER } from "../constants";
import { EquipmentAndSpareContext, currentEquipment } from "../EquipmentAndSpareProvider";
import * as yup from "yup";

const TransactionForm = (props) => {
  const {
    currentEquipment,
    isAddTransactionLoading,
    maintenanceTicketOptions,
    createStockTransaction,
    getAllTaskOption,
  } = useContext(EquipmentAndSpareContext);
  const WARE_HOUSE_OPTIONS = [
    {
      label: `Installed at site (${currentEquipment?.installedQuantity})`,
      value: `INSTALLED_AT_SITE`,
      title: "Installed at site",
    },
    {
      label: `Spares at site (${currentEquipment?.spareQuantity})`,
      value: `SPARES_AT_SITE`,
      title: "Spares at site",
    },
    {
      label: `External warehouse (${currentEquipment?.externalWarehouseQuantity})`,
      value: `EXTERNAL_WAREHOUSE`,
      title: "External warehouse",
    },
    {
      label: `BGE Warehouse (${currentEquipment?.bgeWarehouseQuantity})`,
      value: `BGE_WAREHOUSE`,
      title: "BGE Warehouse",
    },
    {
      label: `Supplier premises (${currentEquipment?.supplierPremisesQuantity})`,
      value: `SUPPLIER_PREMISES`,
      title: "Supplier premises",
    },
  ];

  let getSelectedQuantity;
  let getSelectedWarehouseQuantity;
  const [initialQuantity, setinitialnum] = useState(getSelectedQuantity);
  const transactionCreateSchema = yup.object({
    recordType: yup.string().required(),
    recordDate: yup.date().required().default(new Date()),
    quantity: yup
      .number()
      .required("This field is required")
      .typeError("Specify a Number")
      .min(1, "Min value 1.")
      .max(initialQuantity, `Entered quantity must be less than or equal to ${initialQuantity}.`)
      .integer("Invalid Number"),
    cause: yup.string(),
    description: yup.string(),
    fromWarehouse: yup.string().when("recordType", {
      is: "TRANSFER",
      then: yup.string().required(),
    }),
    warehouse: yup.string().when("recordType", {
      is: (recordType) => recordType === "ADD" || recordType === "SUBTRACT",
      then: yup.string().required(),
    }),
    toWarehouse: yup.string().when("recordType", {
      is: "TRANSFER",
      then: yup.string().required(),
    }),
    taskId: yup.string(),
  });

  const equipmentFormRef = useForm({
    resolver: yupResolver(transactionCreateSchema),
    mode: "onChange",
  });
  const {
    setValue,
    control,
    getValues,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = equipmentFormRef;

  const AllQuantity = (data) => {
    switch (data) {
      case "INSTALLED_AT_SITE":
        return currentEquipment?.installedQuantity;
      case "SPARES_AT_SITE":
        return currentEquipment?.spareQuantity;
      case "EXTERNAL_WAREHOUSE":
        return currentEquipment?.externalWarehouseQuantity;
      case "BGE_WAREHOUSE":
        return currentEquipment?.bgeWarehouseQuantity;
      case "SUPPLIER_PREMISES":
        return currentEquipment?.supplierPremisesQuantity;
      default:
        return NaN;
    }
  };
  getSelectedQuantity = AllQuantity(watch(["fromWarehouse"])[0]);
  getSelectedWarehouseQuantity = AllQuantity(watch(["warehouse"])[0]);

  React.useEffect(() => {
    getAllTaskOption();
  }, []);

  const [selectedCause, setSelectedCause] = useState({});
  const [selectedMaintenanceTicket, setSelectedMaintenanceTicket] = useState({});
  const [maintenanceTicketOption, setMaintenanceTicketOption] = useState([]);

  const [selectedRecordType, setSelectedRecordType] = useState({});

  const [selectedDate, setSelectedDate] = useState(new Date());
  const fromWarehouseValue = getValues("fromWarehouse");
  const recordTypeValue = getValues("recordType");
  const warehouseData = getValues("warehouse");
  useEffect(() => {
    if (recordTypeValue === TRANSFER) {
      setinitialnum(getSelectedQuantity);
    } else if (recordTypeValue === "SUBTRACT") {
      setinitialnum(getSelectedWarehouseQuantity);
    } else if (recordTypeValue === "ADD") {
      setinitialnum(1000000);
    }
  }, [fromWarehouseValue, recordTypeValue, warehouseData]);

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  useEffect(() => {
    setValue("quantity", 0);
  }, []);

  const handleFormSubmit = async (formData) => {
    if (formData.moveToPlantId) {
      formData.moveToPlantId = Number(formData.moveToPlantId);
    } else {
      delete formData.moveToPlantId;
    }
    const createBody = { ...formData, stockId: currentEquipment.id, assetId: currentEquipment.assetId };
    await createStockTransaction(createBody);
    props.onSuccessfulModal();
  };
  const renderSaveButton = () => {
    return (
      <Button color="primary" size="lg" disabled={isAddTransactionLoading} onClick={handleSubmit(handleFormSubmit)}>
        Add
      </Button>
    );
  };
  let ticketOptions = currentEquipment.plantId ? [] : maintenanceTicketOptions;

  useEffect(() => {
    if (currentEquipment && currentEquipment.plantId) {
      if (maintenanceTicketOptions.length) {
        for (let i = 0; i < maintenanceTicketOptions.length; i++) {
          if (maintenanceTicketOptions[i].plantId === currentEquipment.plantId) {
            ticketOptions.push(maintenanceTicketOptions[i]);
          }
        }
      }
      setMaintenanceTicketOption(ticketOptions);
    }
  }, [maintenanceTicketOptions]);

  return (
    <>
      <Block size="lg">
        <PreviewCard>
          <BlockHead size="lg">
            <div className="justify-content-mb-between d-md-flex">
              <BlockHeadContent>
                <BlockTitle tag="h4">Add Transaction</BlockTitle>
              </BlockHeadContent>
            </div>
          </BlockHead>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg={4}>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Category
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled={true}
                      type="text"
                      name="addressLine1"
                      className="form-control"
                      value={currentEquipment?.assets?.assetCategories?.name}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Asset
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled={true}
                      type="text"
                      name="addressLine1"
                      className="form-control"
                      value={currentEquipment?.assets?.model}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Manufacturer
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled={true}
                      type="text"
                      name="addressLine1"
                      className="form-control"
                      value={currentEquipment?.assets?.manufacturers?.name}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span> Record Date
                  </Label>
                  <ReactDatePicker
                    maxDate={moment().toDate()}
                    className="form-control"
                    selected={selectedDate}
                    onChange={(date) => {
                      setValue("recordDate", date, { shouldValidate: true });
                      setSelectedDate(date);
                    }}
                    showTimeSelect
                    timeIntervals={30}
                    timeFormat="HH:mm"
                    timeCaption="time"
                    dateFormat="dd/MM/yyyy h:mm aa"
                  />
                  {errors?.recordDate && (
                    <span className="invalid" style={{ color: "red" }}>
                      Record Date is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span> Record Type
                  </Label>
                  <Controller
                    control={control}
                    name="recordType"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      return (
                        <RSelect
                          defaultValue={selectedRecordType[1]}
                          {...field}
                          ref={ref}
                          value={!selectedRecordType ? RECORD_TYPE[0] : selectedRecordType}
                          options={RECORD_TYPE}
                          onChange={(o) => {
                            setValue("recordType", o.value, { shouldValidate: true });
                            setSelectedRecordType(o);
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.recordType && (
                    <span className="invalid" style={{ color: "red" }}>
                      Record Type is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Cause
                  </Label>
                  <Controller
                    control={control}
                    name="cause"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedCause}
                          options={CAUSE}
                          onChange={(o) => {
                            setValue("cause", o.value, { shouldValidate: true });
                            setSelectedCause(o);
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.cause && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.cause.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg={12}>
                {selectedRecordType?.value == "ADD" || selectedRecordType?.value == "SUBTRACT" ? (
                  <FormGroup className="form-group">
                    <label className="form-label">
                      {" "}
                      <span style={{ color: "indianred" }}>&#42;</span> Warehouse
                    </label>
                    <Controller
                      control={control}
                      name="warehouse"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = WARE_HOUSE_OPTIONS;
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                        return (
                          <RSelect
                            id="from-warehouse-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => setValue("warehouse", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors?.warehouse && (
                      <span className="invalid" style={{ color: "red" }}>
                        {errors?.warehouse.message}
                      </span>
                    )}
                  </FormGroup>
                ) : null}
              </Col>

              {selectedRecordType?.value === "TRANSFER" ? (
                <>
                  <Col lg={12}>
                    <FormGroup className="form-group">
                      <label className="form-label">
                        {" "}
                        <span style={{ color: "indianred" }}>&#42;</span>From Warehouse
                      </label>
                      <Controller
                        control={control}
                        name="fromWarehouse"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = WARE_HOUSE_OPTIONS;
                          const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                          return (
                            <RSelect
                              id="from-warehouse-select"
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("fromWarehouse", o.value, { shouldValidate: true })}
                            />
                          );
                        }}
                      />
                      {errors?.fromWarehouse && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors?.fromWarehouse.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup className="form-group">
                      <label className="form-label">
                        {" "}
                        <span style={{ color: "indianred" }}>&#42;</span> To Warehouse
                      </label>
                      <Controller
                        control={control}
                        name="toWarehouse"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = WARE_HOUSE_OPTIONS;
                          const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                          return (
                            <RSelect
                              id="to-warehouse-select"
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("toWarehouse", o.value, { shouldValidate: true })}
                            />
                          );
                        }}
                      />
                      {errors?.toWarehouse && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors?.toWarehouse.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </>
              ) : null}
              <Col lg={12}>
                <FormGroup className="form-group">
                  <label className="form-label">
                    {" "}
                    <span style={{ color: "indianred" }}>&#42;</span> Quantity
                  </label>
                  <div className="form-control-wrap">
                    <input type="text" className="form-control" {...register("quantity")} />
                  </div>
                  {errors?.quantity && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.quantity.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Maintenance Ticket
                  </Label>
                  <Controller
                    control={control}
                    name="taskId"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedMaintenanceTicket}
                          options={maintenanceTicketOption}
                          onChange={(o) => {
                            setValue("taskId", o.value, { shouldValidate: true });
                            setSelectedMaintenanceTicket(o);
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.maintenanceTicket && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.maintenanceTicket.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea className="form-control" {...register("description")} />
                  </div>
                  {errors?.description && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.description.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">{renderSaveButton()}</div>
          </Form>
        </PreviewCard>
      </Block>
    </>
  );
};
export default TransactionForm;
