import apiService from "../../../services/ApiService";
import {
  MOVE_TO_BGE_PLANT_API,
  PLANT_OWNED_INVENTORY_CREATE,
  PLANT_OWNED_INVENTORY_LIST_API,
  PLANT_OWNED_INVENTORY_LIST_FILTER_OPTIONS_API,
  PLANT_OWNED_INVENTORY_UPDATE,
  PLANT_OWNED_INVENTORY_VIEW_API,
  PLANT_OWNED_INVENTORY_DELETE,
  EXPORT_PLANT_OWNED_INVENTORY_API
} from "../../../constants/endpoints";

export const getPlantOwnedInventoryList = async (filterParams) => {
  const response = await apiService.get(PLANT_OWNED_INVENTORY_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};

export const getPlantOwnedInventoryFilterOptions = async (filterParams) => {
  const response = await apiService.get(PLANT_OWNED_INVENTORY_LIST_FILTER_OPTIONS_API);
  return response;
};

export const getInventoryAPI = async (inventoryId) => {
  const response = await apiService.get(PLANT_OWNED_INVENTORY_VIEW_API, {
    pathParams: { inventoryId },
  });
  return response;
};

export const createPlantOwnedInventoryAPI = async (params) => {
  const response = await apiService.post(PLANT_OWNED_INVENTORY_CREATE, {
    body: params,
  });
  return response;
};

export const updatePlantOwnedInventoryAPI = async (inventoryId, params) => {
  const response = await apiService.put(PLANT_OWNED_INVENTORY_UPDATE, {
    body: params,
    pathParams: { inventoryId },
  });
  return response;
};

export const moveToBgePlantInventoryAPI = async (inventoryId, params) => {
  const response = await apiService.put(MOVE_TO_BGE_PLANT_API, {
    body: params,
    pathParams: { inventoryId },
  });
  return response;
};
export const deleteInventoryAPI = async inventoryId => {
  const response = await apiService.delete(PLANT_OWNED_INVENTORY_DELETE, {
    pathParams: { inventoryId }
  })
  return response
}
export const exportPlantOwnedInventory = async (filterParams) => {
  const response = await apiService.get(EXPORT_PLANT_OWNED_INVENTORY_API, {
      queryParams: filterParams,
      responseType: 'blob',
      headers: {
          'Content-Type': 'application/pdf',
      }
  })
  return response
}