import React, { useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../../components/Component";
import { AssetCategoryContext } from "../AssetCategoryProvider";
import DatePicker from "react-datepicker";
import { sortOption } from "../../../../utils/Utils";

const AssetCategoryListFilter = () => {
  const assetCategoryContext = React.useContext(AssetCategoryContext);
  const { filterOptions } = assetCategoryContext;
  const { filterParams, handleApplyFilter, checkIsFilterApplied, sfState, showFilter, } =
  assetCategoryContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    assetCategoryContext.resetAssetCategoryListFilter();
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    updateTableSm(false);
    checkIsFilterApplied();
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleAssetCategoryFilterSelection = (selectedOption) => {
    handleFilterSelection({ selecetedAssetCategories: selectedOption.value });
  };

  const selectedAssetCategoryOption =
    filterOptions.assetCategories?.find((o) => o.value === selectedFilter.selecetedAssetCategories) || null;

  const handleCreatedAtSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ createdAt: "" });
    } else {
      handleFilterSelection({ createdAt: selectedOption });
    }
  };

  const handleUpdatedAtSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ updatedAt: "" });
    } else {
      handleFilterSelection({ updatedAt: selectedOption });
    }
  };
  return (
    <div className="vh-100  ">
      <div className="dropdown-head">
        <span className="sub-title dropdown-title fs-16px">Filter Options</span>
        <div onClick={sfState.off}>
          <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
        </div>
      </div>
      <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Category</label>
                <RSelect
                  options={sortOption(filterOptions.assetCategories)}
                  placeholder="Any Asset Category"
                  onChange={handleAssetCategoryFilterSelection}
                  value={selectedAssetCategoryOption}
                />
              </FormGroup>
            </Col>
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">CreatedAt</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.createdAt || null}
                  onChange={(date) => handleCreatedAtSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">UpdatedAt</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.updatedAt || null}
                  onChange={(date) => handleUpdatedAtSelection(date)}
                />
              </FormGroup>
            </Col>

            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                {" "}
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </UncontrolledDropdown>
    </div>
  );
};

export default AssetCategoryListFilter;
