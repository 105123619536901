/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import PvPlantForm from "../components/PvPlantForm";
import { withPvPlantManagementProvider} from "../PvPlantManagementProvider";

const PvPlantManagementFormContainer = props => {

  const params = useParams()
  const { plantId } = params

  const title = plantId ? `${plantId} - PV-Plant Management | EDIT` : "Create | PV-Plant Management"

  return (
    <React.Fragment>
      <Head title={title} />
      <Content>
        <PvPlantForm pvPlantId={plantId} />
      </Content>
    </React.Fragment>
  );
}

export default withPvPlantManagementProvider(PvPlantManagementFormContainer);