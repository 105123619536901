import React from "react";
import { Pagination, PaginationLink, PaginationItem, Row, Col } from "reactstrap";
import { RSelect } from "../Component";
import Icon from "../icon/Icon";
import "./style.css";

const PaginationComponent = ({ itemPerPage, totalItems, paginate, paginatePage, currentPage, pageOptions }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  let currentItemPerPage;
  if (itemPerPage > totalItems) {
    currentItemPerPage = totalItems;
  } else {
    currentItemPerPage = itemPerPage;
  }

  let options = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 200, label: 200 },
  ];
  if (pageOptions) {
    options = pageOptions.map((p) => {
      return { value: p, label: p };
    });
  }

  const displayCurrentItems = (currentPage) => {
    if (currentPage === 1) {
      return `1 - ${currentItemPerPage} of ${totalItems}`;
    }
    if (currentPage > 1 && currentItemPerPage * currentPage < totalItems) {
      return `${currentItemPerPage * (currentPage - 1) + 1} - ${currentItemPerPage * currentPage} of ${totalItems}`;
    }
    if (currentPage > 1 && currentItemPerPage * currentPage > totalItems) {
      return `${currentItemPerPage * (currentPage - 1) + 1} - ${totalItems} of ${totalItems}`;
    }
  };
  const renderPaginationItems = () => {
    const visiblePages = 5;
    const items = [];

    if (pageNumbers.length <= visiblePages) {
      return pageNumbers.map((item) => (
        <PaginationItem key={item} className={currentPage === item ? "active" : ""}>
          <PaginationLink
            tag="a"
            href="#pageitem"
            onClick={(ev) => {
              ev.preventDefault();
              paginate(item);
            }}
          >
            {item}
          </PaginationLink>
        </PaginationItem>
      ));
    }
    if (currentPage !== 1) {
      items.push(
        <PaginationItem key="first-page">
          <PaginationLink
            onClick={(ev) => {
              ev.preventDefault();
              paginate(1);
            }}
          >
            1
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (currentPage > visiblePages - 2) {
      items.push(
        <PaginationItem key="left-ellipsis">
          <PaginationLink tag="a" href="#">
            ...
          </PaginationLink>
        </PaginationItem>
      );
    }
    let start, end;

    if (window.innerWidth <= 768) {
      start = Math.max(0, currentPage - 1);
      end = Math.min(pageNumbers.length - 1, currentPage + -1);
    } else {
      start = Math.max(0, currentPage - 1);
      end = Math.min(pageNumbers.length - 1, currentPage + 3);
    }
    for (let i = start; i <= end; i++) {
      items.push(
        <PaginationItem key={i + 1} className={currentPage === i + 1 ? "active" : ""}>
          <PaginationLink
            tag="a"
            href="#pageitem"
            onClick={(ev) => {
              ev.preventDefault();
              paginate(i + 1);
            }}
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (currentPage < pageNumbers.length - visiblePages + 3) {
      items.push(
        <PaginationItem key="right-ellipsis">
          <PaginationLink tag="a" href="#">
            ...
          </PaginationLink>
        </PaginationItem>
      );
    }
    if (!items.some((item) => item.key === String(pageNumbers.length))) {
      items.push(
        <PaginationItem key={pageNumbers.length}>
          <PaginationLink
            tag="a"
            href="#pageitem"
            onClick={(ev) => {
              ev.preventDefault();
              paginate(pageNumbers.length);
            }}
          >
            {pageNumbers.length}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return items;
  };

  return (
    <Row className="align-items-center">
      <Col className="p-0" sm="12" md="9">
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
            <PaginationLink
              id="client-prev-btn"
              className="page-link-prev"
              onClick={(ev) => {
                ev.preventDefault();
                prevPage();
              }}
              href="#prev"
            >
              <Icon name="chevrons-left" />
              <span>Prev</span>
            </PaginationLink>
          </PaginationItem>
          {renderPaginationItems()}
          <PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === currentPage}>
            <PaginationLink
              id="client-next-btn"
              className="page-link-next"
              onClick={(ev) => {
                ev.preventDefault();
                nextPage();
              }}
              href="#next"
            >
              <span>Next</span>
              <Icon name="chevrons-right" />
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="ml-2 mt-1">
            <span>Show</span>
          </PaginationItem>
          <PaginationItem className="ml-1 page-show">
            <RSelect
              id="paginate"
              options={options}
              onChange={(o) => {
                paginatePage(o.value);
              }}
              defaultValue={options.find((o) => o.value === itemPerPage)}
            />
          </PaginationItem>
        </Pagination>
      </Col>
      <Col sm="12" md="3" className="col-5 text-left text-md-right mt-3 mt-md-0">
        <div className="dataTables_info" id="DataTables_Table_2_info" role="status" aria-live="polite">
          {displayCurrentItems(currentPage)}
        </div>
      </Col>
    </Row>
  );
};

export default PaginationComponent;
