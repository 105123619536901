/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import { useParams } from "react-router-dom";
import { MaintenanceContext } from "../../../../../providers/Maintenance.provider";
import { withPreventativeTaskProvider ,PreventativeTaskContext} from "../PreventativeTaskProvider";
import ViewPreventativeTask from "../components/PreventativeTaskView";

const PreventativeTaskViewContainer = (props) => {
  const { taskId } = useParams();
  const preventativeContext = useContext(PreventativeTaskContext);
  const { loadCurrentTask } = preventativeContext;
  const { loadFilterOptions } = useContext(MaintenanceContext);

  useEffect(() => {
    if (taskId) loadCurrentTask(taskId);
  }, [taskId]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title={`${taskId} - Task`} />
      <Content>
        <ViewPreventativeTask currentTaskId={taskId}/>
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeTaskProvider(PreventativeTaskViewContainer);
