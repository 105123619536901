import React, { useContext, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";
import PrivateRoute from "./route/PrivateRoute";
import "./custom.css";

import Layout from "./layout/Index";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";

import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import FormComponent from "./pages/auth/Form";
import { AuthContext, withAuthProvider } from "./providers/AuthContext.provider";
import {
  ADMIN_LOGIN_PAGE,
  ADMIN_FORGOT_PASSWORD_PAGE,
  ADMIN_RESET_PASSWORD_PAGE,
  FORM_SUBMISSION_PAGE,
} from "./constants/routes";
import { withMultiProviders } from "./providers/withMultiProviders";
import { withMaintenanceProvider } from "./providers/Maintenance.provider";
import { withActivityLogProvider } from "./providers/ActivtiyLogProvider";
import { withNotificationProvider } from "./providers/NotificationProvider";
import { withGlobalSearchProvider } from "./providers/GlobalSearchProvider";
import { withUserManagementProvider } from "./pages/user_management/UserManagementProvider";
import { withDnoProvider } from "./pages/entity_management/dno/DnoProvider";
import { withPvPlantManagementProvider } from "./pages/pv_plant_management/PvPlantManagementProvider";
import { withHealthAndSafetyProvider } from "./pages/entity_management/home_and_safety/HealthAndSafetyProvider";
import { withStringProvider } from "./pages/maintenance/string/StringProvider";
import { withRoleManagementProvider } from "./pages/role_management/RoleManagementProvider";
import { withCorrectiveProvider } from "./pages/maintenance/corrective/CorrectiveProvider";
import { withRemedialProvider } from "./pages/maintenance/remedial/RemedialProvider";
import { withPreventativeTaskProvider } from "./pages/maintenance/preventative/preventativeTasks/PreventativeTaskProvider";
import { withHomeProvider } from "./pages/home/HomeProvider";

import Skeleton from "react-loading-skeleton";
import { withPreventativeScheduleProvider } from "./pages/preventative_schedule/ScheduleProvider";
import { withEquipmentAndSpareProvider } from "./pages/equipment_spare/EquipmentAndSpareProvider";

const App = (props) => {
  const authContext = useContext(AuthContext);
  const isStatusDetermined = authContext.isDetermined;

  useEffect(() => {
    authContext.lookupUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isStatusDetermined) return <Skeleton count={10} />;

  return (
    <Switch>
      {/* Auth Pages */}
      <Route exact path={ADMIN_LOGIN_PAGE} component={Login}></Route>
      <Route exact path={ADMIN_FORGOT_PASSWORD_PAGE} component={ForgotPassword}></Route>
      <Route exact path={ADMIN_RESET_PASSWORD_PAGE} component={ResetPassword}></Route>
      <Route exact path={FORM_SUBMISSION_PAGE} component={FormComponent}></Route>

      {/*Error Pages*/}
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-classic`} component={Error404Classic}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/504-modern`} component={Error504Modern}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-modern`} component={Error404Modern}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/504-classic`} component={Error504Classic}></Route>

      {/*Main Routes*/}
      <PrivateRoute exact path="" component={Layout}></PrivateRoute>
      <Route component={RedirectAs404}></Route>
    </Switch>
  );
};
export default withRouter(
  withMultiProviders(
    [
      withAuthProvider,
      withMaintenanceProvider,
      withActivityLogProvider,
      withNotificationProvider,
      withUserManagementProvider,
      withDnoProvider,
      withPvPlantManagementProvider,
      withHealthAndSafetyProvider,
      withStringProvider,
      withRoleManagementProvider,
      withCorrectiveProvider,
      withPreventativeTaskProvider,
      withRemedialProvider,
      withGlobalSearchProvider,
      withPreventativeScheduleProvider,
      withHomeProvider,
      withEquipmentAndSpareProvider,
    ],
    App
  )
);
