/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../../components/Component";
import download from "js-file-download";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { exportMasterList, exportMasterTemplate } from "../PreventativeRepository";
import { PreventativeContext } from "../PreventativeProvider";

const MASTER_FIELDS = [
  {
    label: "Name",
    value: "name",
  },

  {
    label: "Description",
    value: "description",
  },
  {
    label: "Created By",
    value: "createdBy",
  },
  {
    label: "Archived",
    value: "isArchived",
  },
  {
    label: "No of Tasks",
    value: "taskCount",
  },
  {
    label: "Created At",
    value: "created_at",
  },
  { label: "Updated At", value: "updated_at" },
];

const MASTER_TEMPLATE_FIELDS = [
  {
    label: "Title",
    value: "title",
  },

  {
    label: "Code",
    value: "code",
  },

  {
    label: "Frequency",
    value: "frequency",
  },

  {
    label: "Asset Category",
    value: "assetCategory",
  },

  {
    label: "Description",
    value: "description",
  },

  {
    label: "Created At",
    value: "created_at",
  },
  { label: "Updated At", value: "updated_at" },
];

const ExportForm = (props) => {
  const { isFromMasterList, filterParams } = props;
  const FIELDS = isFromMasterList ? MASTER_FIELDS : MASTER_TEMPLATE_FIELDS;
  const { templateId } = props;
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (FIELDS) {
      const data = FIELDS.map((form) => {
        return form.value;
      });
      setValue(data);
    }
  }, [FIELDS]);

  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async (formData) => {
    try {
      setExportLoading(true);
      const response = isFromMasterList
        ? await exportMasterList({
            ...filterParams,
            headerData: value,
          })
        : await exportMasterTemplate(
            {
              ...filterParams,
              headerData: value,
            },
            templateId
          );
      download(response, `Preventative Master.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="export-btn"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Preventative Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0 ">
                {FIELDS &&
                  FIELDS.map((item, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${item} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0 d-flex">
                              <input
                                key={`${item}-${key}`}
                                type="checkbox"
                                checked={value.includes(item.value)}
                                id={`${item}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([item.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === item.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${item}-${key}`} check>
                                {item.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;
