import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';


export const modules = {
    toolbar: [
      ['bold', 'italic', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      ['clean']
    ],
  };
  
    //REACTQUILL TEXT EDITOR FORMAT
    
  export const TextEditor = (props) => {
  
  const {field, setValue, fieldName, isDisabled,ref}= props
  
  const handleValue = (value) => {
    setValue(fieldName, value, { shouldDirty: true })
  }
  
    return (
      <div>
        <ReactQuill
          style={{ height: "100px ", marginBottom: "50px" }}
          readOnly={isDisabled}
          name={fieldName}
          modules={modules}
          theme="snow"
          value={field?.value}
          onChange={(o) => handleValue(o)}
        />
      </div>
    )
  }