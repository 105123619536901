/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import RoleForm from "../components/RoleForm";
import { RoleManagementContext, withRoleManagementProvider } from "../RoleManagementProvider";

const RoleFormContainer = props => {
    const roleManagementContext = useContext(RoleManagementContext)
    const { currentViewRole, updateRole, loadRole } = roleManagementContext
    const {loadRoles,loadRoleActions} = roleManagementContext

    useEffect(() => {
        if (props.roleId) loadRole(props.roleId)
    }, [props.roleId])

    useEffect(()=>{
        loadRoles()
        loadRoleActions()
    },[])
    
    return (
        <React.Fragment>
            <RoleForm
                roleId={props.roleId}
                currentViewRole={currentViewRole}
                updateRole={updateRole}
                onSuccessfulModal={props.onSuccessfulModal}
            />
        </React.Fragment>
    );
}

export default withRoleManagementProvider(RoleFormContainer);