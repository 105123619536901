/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import AssetCategoryForm from "../components/AssetCategoryForm";
import { AssetCategoryContext, withAssetCategoryProvider } from "../AssetCategoryProvider";

const AssetCategoryFormContainer = props => {

    const assetCategoryContext = useContext(AssetCategoryContext)
    const { currentViewAssetCategory, updateAssetCategory, loadAssetCategory } = assetCategoryContext

    useEffect(() => {
        if (props.assetCategoryId) loadAssetCategory(props.assetCategoryId)
    }, [props.assetCategoryId])
    
    return (
        <React.Fragment>
            <AssetCategoryForm
                assetCategoryId={props.assetCategoryId}
                currentViewAssetCategory={currentViewAssetCategory}
                updateAssetCategory={updateAssetCategory}
                onSuccessfulModal={props.onSuccessfulModal}
            />
        </React.Fragment>
    );
}

export default withAssetCategoryProvider(AssetCategoryFormContainer);