import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  getManufacturerList,
  updateManufacturerAPI,
  createManufacturerAPI,
  getManufacturerAPI,
  deleteManufacturerAPI,
  getManufacturerFilterListAPI,
} from "./ManufacturerRepository";

export const ManufacturerContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 50,
};

const initialFilterParams = {
  searchText: "",
  sortingField: "name",
  sortingOrder: "ASC",
  selectedManufacturers: [],
  selectedEmail: [],
};

const initialFilterOptions = {
  manufacturers: [],
  address: "",
  telePhone: "",
  emails: [],
};
export const ManufacturerProvider = (props) => {
  const [manufacturerList, setManufacturerList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [currentViewManufacturer, setCurrentViewManufacturer] = React.useState();
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  //Loaders

  const [manufacturerLoading, manufacturerState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);

  const getListFilterParams = () => {
    const queryParams = { ...pagination };
    const { searchText, sortingField, sortingOrder, selectedManufacturers, selectedEmail} = filterParams;
    if (searchText) queryParams["manufacturerSearchText"] = searchText;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (selectedManufacturers) queryParams["manufacturer"] = selectedManufacturers;
    if (selectedEmail) queryParams["email"] = selectedEmail;
    return queryParams;
  };

  const loadManufacturerList = async () => {
    manufacturerState.on();
    try {
      const filterParams = getListFilterParams();
      const response = await getManufacturerList(filterParams);
      setManufacturerList(response.manufacturersList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      manufacturerState.off();
    }
  };

  //CREATE ASSET_CATEGORY

  const createManufacturer = async (createParams) => {
    try {
      manufacturerState.on();

      await createManufacturerAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      manufacturerState.off();
    }
  };

  // VIEW TASK

  const loadManufacturer = async (manufacturerId) => {
    try {
      const response = await getManufacturerAPI(manufacturerId);
      setCurrentViewManufacturer(response.manufacturer);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  // UPDATE TASK

  const updateManufacturer = async (manufacturerId, updateParams) => {
    manufacturerState.on();
    try {
      const response = await updateManufacturerAPI(manufacturerId, updateParams);
      setCurrentViewManufacturer(response.manufacturer);
    } catch (e) {
      throw e;
    } finally {
      manufacturerState.off();
    }
  };

  // DELETE TASK

  const deleteManufacturer = async (manufacturerId) => {
    try {
      await deleteManufacturerAPI(manufacturerId);
      loadManufacturerList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const loadFilterOptions = async () => {
    flState.on();
    try {
      const response = await getManufacturerFilterListAPI();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const checkIsFilterApplied = () => {
    if (
      filterParams.selectedManufacturers != 0 ||
      filterParams.selectedEmail != 0 
    ) {
      return true;
    } else return false;
  };

  const isFilterApplied = checkIsFilterApplied();

  const resetManufacturerListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    pagination,
    manufacturerList,
    loadManufacturerList,
    currentViewManufacturer,
    manufacturerLoading,
    deleteManufacturer,
    createManufacturer,
    updateManufacturer,
    loadManufacturer,
    handleApplyFilter,
    filterParams,
    loadFilterOptions,
    filtersLoading,
    filterOptions,
    showFilter,
    sfState,
    isFilterApplied,
    resetManufacturerListFilter,
    checkIsFilterApplied,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <ManufacturerContext.Provider value={mContext}>{props.children}</ManufacturerContext.Provider>;
};

export const withManufacturerProvider = (Container, containerProps) => (props) =>
  (
    <ManufacturerProvider>
      <Container {...containerProps} {...props} />
    </ManufacturerProvider>
  );
