/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  createRoleAPI,
  updateRoleAPI,
  getRoleAPI,
  getRoleList,
  getAllRoles,
  getRoleActionsAPI
} from "./RoleManagementRepository";

export const RoleManagementContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterParams = {
    searchText: '',
    sortingOrder: "ASC",
    sortingField: "name",
  } 

export const RoleManagementProvider = (props) => {
  const [rolesList, setRolesList] = React.useState([]);
  const [roleActions, setRoleActions] = React.useState([]);
  const [allRoles,setAllRoles] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));

  const [currentViewRole, setCurrentViewRole] = React.useState(null);
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams))

  // LOADERS
  const [roleLoading, rlState] = useBoolean(false);
  const [loadingRoleView, lswState] = useBoolean(false);

  const getRoleListFilterParams = () => {
    const queryParams = { ...pagination };
    const {searchText ,sortingField,sortingOrder} = filterParams
    if (searchText) queryParams['roleSearchText'] = searchText
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    return queryParams
  }

  const loadRoleList = async () => {
    rlState.on();
    try {
      const filterParams = getRoleListFilterParams();
      const response = await getRoleList(filterParams);
      setRolesList(response.rolesList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      rlState.off();
    }
  };

  // VIEW ROLE
  const loadRole = async (roleId) => {
    try {
      const response = await getRoleAPI(roleId);
      setCurrentViewRole(response.role);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  //UPDATE ROLE
  const updateRole = async (roleId, updateParams) => {
    try {
      lswState.on();
      await updateRoleAPI(roleId, updateParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  //CRETAE ROLE
  const createRole = async (createParams) => {
    try {
      lswState.on();
      await createRoleAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  const loadRoles = async () => {
    rlState.on();
    try {
      const response = await getAllRoles();
      setAllRoles(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      rlState.off();
    }
  };

  const loadRoleActions = async () => {
    rlState.on();
    try {
      const response = await getRoleActionsAPI();
      setRoleActions(response);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      rlState.off();
    }
  };
  const handleApplyFilter = selectedFilters => {
    setFilterParams(selectedFilters)
  }
  
  const mContext = {
    rolesList,
    pagination,
    loadRoleList,
    roleLoading,
    loadRole,
    currentViewRole,
    updateRole,
    createRole,
    loadingRoleView,
    loadRoles,
    allRoles,
    handleApplyFilter,
    filterParams,
    loadRoleActions,
    roleActions,
    updateShowListSize: size => setPagination({ ...pagination, size, page:1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <RoleManagementContext.Provider value={mContext}>{props.children}</RoleManagementContext.Provider>;
};

export const withRoleManagementProvider = (Container, containerProps) => (props) =>
  (
    <RoleManagementProvider>
      <Container {...containerProps} {...props}/>
    </RoleManagementProvider>
  );