import apiService from "../../services/ApiService";
import {
  FORM_MANAGEMENT_LIST_API,
  FORM_MANAGEMENT_CREATE_API,
  FORM_MANAGEMENT_VIEW_API,
  FORM_MANAGEMENT_UPDATE_API,
  FORM_MANAGEMENT_DELETE_API,
  FORM_SUBMISSION_LIST_API,
  FORM_SUBMISSION_CREATE_API,
  FORM_SUBMISSION_VIEW_API,
  EXPORT_FORM_API,
  FORM_LIST_FILTER_OPTIONS_API,
  EXPORT_SUBMISSION,
  FORM_FILTER_OPTION,
  GET_FORMS_API,
} from "../../constants/endpoints";

export const getFormList = async (filterParams) => {
  const response = await apiService.get(FORM_MANAGEMENT_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};

export const getFormsAPI = async (filterParams) => {
  const response = await apiService.get(GET_FORMS_API, {
    queryParams: filterParams,
  });
  return response;
};

export const updateFormAPI = async (formId, params) => {
  const response = await apiService.put(FORM_MANAGEMENT_UPDATE_API, {
    pathParams: { formId },
    body: params,
  });
  return response;
};

export const createFormAPI = async (params) => {
  const response = await apiService.post(FORM_MANAGEMENT_CREATE_API, {
    body: params,
  });
  return response;
};

export const getFormAPI = async (formId, version) => {
  const response = await apiService.get(FORM_MANAGEMENT_VIEW_API, {
    pathParams: { formId },
    queryParams: { version },
  });
  return response;
};

export const deleteFormAPI = async (formId) => {
  const response = await apiService.delete(FORM_MANAGEMENT_DELETE_API, {
    pathParams: { formId },
  });
  return response;
};

export const getSubmittedFormList = async (filterParams) => {
  const response = await apiService.get(FORM_SUBMISSION_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};

export const createFormSubmissionAPI = async (params) => {
  const response = await apiService.post(FORM_SUBMISSION_CREATE_API, {
    body: params,
  });
  return response;
};
export const getSubmittedFormApi = async (formId, filterParams) => {
  const response = await apiService.get(FORM_SUBMISSION_VIEW_API, {
    pathParams: { formId },
    queryParams: filterParams,
  });
  return response;
};
export const exportForm = async (filterParams) => {
  const response = await apiService.get(EXPORT_FORM_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};

export const formFilterOption = async () => {
  const response = await apiService.get(FORM_FILTER_OPTION);
  return response;
};

export const exportSubmission = async (filterParams, formId) => {
  const response = await apiService.get(EXPORT_SUBMISSION, {
    queryParams: filterParams,
    pathParams: formId,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
export const getFormListFilterOptions = async (filterParams) => {
  const response = await apiService.get(FORM_LIST_FILTER_OPTIONS_API);
  return response;
};
