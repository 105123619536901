/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import EquipmentAndSpareList from "../components/EquipmentAndSpareList";
import { EquipmentAndSpareContext, withEquipmentAndSpareProvider } from "../EquipmentAndSpareProvider";

const EquipmentAndSpareListContainer = (props) => {
  const { loadEquipmentList, loadFilterOptions, filterParams, pagination, getTableSettings } = useContext(EquipmentAndSpareContext);
  
  const fetchEquipmentsData = async () => {
    await loadFilterOptions();
    const settings = await getTableSettings({
      entityType: "EQUIPMENT_AND_SPARES",
    });
    if(settings){
      await loadEquipmentList(settings);
    }
  };
  useEffect(() => {
    fetchEquipmentsData();
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | Equipment And Spares" />
      <Content>
        <EquipmentAndSpareList />
      </Content>
    </React.Fragment>
  );
};

export default withEquipmentAndSpareProvider(EquipmentAndSpareListContainer);
