import apiService from "../../../services/ApiService";
import {
  MANUFACTURER_LIST_API,
  MANUFACTURER_CREATE_API,
  MANUFACTURER_VIEW_API,
  MANUFACTURER_UPDATE_API,
  MANUFACTURER_DELETE_API,
  EXPORT_MANUFACTURER_API,
  MANUFACTURER_FILTER_LIST_API
} from "../../../constants/endpoints";

export const getManufacturerList = async (filterParams) => {
  const response = await apiService.get(MANUFACTURER_LIST_API, {
    queryParams: filterParams,
  });

  return response;
};

export const updateManufacturerAPI = async (manufacturerId, params) => {
  const response = await apiService.put(MANUFACTURER_UPDATE_API, {
    pathParams: { manufacturerId },

    body: params,
  });

  return response;
};

export const createManufacturerAPI = async (params) => {
  const response = await apiService.post(MANUFACTURER_CREATE_API, {
    body: params,
  });

  return response;
};

export const getManufacturerAPI = async (manufacturerId) => {
  const response = await apiService.get(MANUFACTURER_VIEW_API, {
    pathParams: { manufacturerId },
  });
  return response;
};

export const deleteManufacturerAPI = async (manufacturerId) => {
  const response = await apiService.delete(MANUFACTURER_DELETE_API, {
    pathParams: { manufacturerId },
  });

  return response;
};
export const exportManufacturerAPI = async (filterParams) => {
  const response = await apiService.get(EXPORT_MANUFACTURER_API, {
      queryParams: filterParams,
      responseType: 'blob',
      headers: {
          'Content-Type': 'application/pdf',
      }
  })
  return response
}
export const getManufacturerFilterListAPI = async () => {
  const response = await apiService.get(MANUFACTURER_FILTER_LIST_API);
  return response;
};