import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import AssetCategoryListCompact from "../components/FailureReasonList";
import { FailureReasonContext, withFailureReasonProvider } from "../FailureReasonProvider";

const FailureReasonContainer = (props) => {
  const { loadFailureReasonList, pagination, filterParams, loadFilterOptions } = useContext(FailureReasonContext);

  useEffect(() => {
    loadFailureReasonList();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | FailureReason" />
      <Content>
        <AssetCategoryListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withFailureReasonProvider(FailureReasonContainer);
