import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import AssetCategoryListCompact from "../components/AssetCategoryList";
import { AssetCategoryContext, withAssetCategoryProvider } from "../AssetCategoryProvider";

const AssetCategoryContainer = (props) => {
  const { loadAssetCategoryList, pagination, filterParams,loadFilterOptions } = useContext(AssetCategoryContext);

  useEffect(() => {
    loadAssetCategoryList();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | AssetCategory" />
      <Content>
        <AssetCategoryListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withAssetCategoryProvider(AssetCategoryContainer);
