import { format } from "date-fns";
import React, { useContext } from "react";
import { Badge, Spinner } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, Button, Icon } from "../../../components/Component";
import Check from "../../../components/check_roles/check";
import Head from "../../../layout/head/Head";
import { RoleManagementContext } from "../RoleManagementProvider";

const ViewRole = ({ sm, updateSm, editRole }) => {
  const roleManagementContext = useContext(RoleManagementContext);
  const { currentViewRole } = roleManagementContext;
  if (!currentViewRole) return <Spinner />;
  return (
    <div className="overflow-auto h-max-450px">
      <Head title="Brighter App | Role | View" />

      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md" className="d-flex justify-content-end">
          <BlockBetween>
            <Check permission={["manageRoles"]}>
              <BlockHeadContent className="align-self-end pb-0">
                <Button
                  size="sm"
                  color="primary"
                  className="m-2"
                  onClick={() => {
                    editRole(currentViewRole.id);
                  }}
                >
                  <Icon name="edit" />
                  <span>Edit</span>
                </Button>
              </BlockHeadContent>
            </Check>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Name</span>
                <span className="data-value">{currentViewRole.name || "-"} </span>
              </div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Level</span>
                <span className="data-value">{currentViewRole?.level || "-"} </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Actions</span>
                <span className="data-value ">
                  {" "}
                  {currentViewRole?.actions.map((action) => {
                    return (
                      <Badge pill color="primary" className="ml-1">
                        {action.replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                      </Badge>
                    );
                  }) || "-"}
                </span>
              </div>
            </div>
            <div className="m-2 ml-3">
              <small class="text-muted">
                CreatedAt: {format(new Date(currentViewRole?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
              </small>
            </div>
            <div className="m-2 ml-3">
              <small class="text-muted">
                UpdatedAt: {format(new Date(currentViewRole?.updated_at || null), "dd/MM/yyyy HH:mm")}{" "}
              </small>
            </div>
          </div>
        </Block>
      </Block>
    </div>
  );
};
export default ViewRole;
