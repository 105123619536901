import React, { useState } from "react";
import useBoolean from "../../hooks/useBoolean";
import { getDashboardApi } from "./HomeRepository";

export const HomeContext = React.createContext()

export const HomeProvider = props => {

  const [isLoadingDashboard,dashboardState] = useBoolean(false)
  const [dashboardData,setDashboardData] = useState({})
  const [error,setError] = useState()
  

  const loadDashboard = async () => {
    dashboardState.on();
    try {
      const response = await getDashboardApi();
      setDashboardData(response);
      return response
    } catch (e) {
      // CAPTURE EXCEPTION
      setError(e.message)
      throw e.message;
    } finally {
      dashboardState.off();
    }
  };

  const mContext = {
    loadDashboard,
    isLoadingDashboard,
    dashboardData,
    error
  }

  return <HomeContext.Provider value={mContext}>
    {props.children}
  </HomeContext.Provider>
}

export const withHomeProvider = (Container, containerProps) => props => <HomeProvider>
  <Container {...containerProps} />
</HomeProvider>