/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewAssetCategory from "../components/AssetCategoryView";
import { AssetCategoryContext, withAssetCategoryProvider } from "../AssetCategoryProvider";

const AssetCategoryViewContainer = props => {

    const { assetCategoryId, setIsOpen, editAssetCategory } = props

    const assetCategoryContext = useContext(AssetCategoryContext)
    const { loadAssetCategory } =assetCategoryContext

    useEffect(() => {
        if (assetCategoryId) loadAssetCategory(assetCategoryId)
    }, [assetCategoryId])

    return (
        <React.Fragment>
            <ViewAssetCategory setIsOpen={setIsOpen} editAssetCategory={editAssetCategory} />
        </React.Fragment>
    );
}

export default withAssetCategoryProvider(AssetCategoryViewContainer);