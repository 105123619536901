/* eslint-disable jsx-a11y/anchor-is-valid */
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../components/Component";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import {
  PREVENTATIVE_SCHEDULE,
  PREVENTATIVE_SCHEDULE_EDIT,
  PREVENTATIVE_SCHEDULE_VIEW,
} from "../../../constants/routes";
import ModalViewer from "../../maintenance/ModalViewer";
import { ScheduleContext } from "../ScheduleProvider";
import { deletePreventativeSchedule } from "../ScheduleRepository";
import ExportContainer from "../containers/ExportContainer";
import PreventativeScheduleFilter from "./PreventativeScheduleFilter";
import Check from "../../../components/check_roles/check";

const PreventativeScheduleList = ({ plantId, plantStatus, isArchived }) => {
  const scheduleContext = useContext(ScheduleContext);
  const {
    preventativeSchedules,
    preventativeScheduleLoading,
    loadPreventativeSchedules,
    pagination,
    handleApplyFilter,
    filterParams,
    showFilter,
    sfState,
    isFilterApplied,
  } = scheduleContext;
  const history = useHistory();

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const focusSearchRef = useRef();
  const currentItems = preventativeSchedules?.items || [];

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  const toggle = () => setonSearch(!onSearch);
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const onSearchAction = () => {
    setFilterApplied(true);
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const deleteFunc = async (taskId) => {
    try {
      await deletePreventativeSchedule(taskId);
      loadPreventativeSchedules({ plantId });
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Task" });
    }
  };
  const handleDeleteConfirmation = (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  const onSuccessfulModal = () => {
    toggleExportModal();
  };

  const getExportComponent = () => {
    return <ExportContainer plantId={plantId} onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };

  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
    return <>Edit</>;
  };

  return (
    <>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Preventative Schedule Lists
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {preventativeSchedules?.pagination?.totalCount || 0} Preventative Schedule.</p>
            </BlockDes>
          </BlockHeadContent>
          {!preventativeSchedules?.items?.length && !filterApplied ? (
            <></>
          ) : (
            <BlockHeadContent className="d-flex justify-content-end">
              <Check permission={["manageTickets"]}>
                {plantStatus === "ACTIVE" && isArchived === false && (
                  <a
                    id="add-schedule"
                    href={PREVENTATIVE_SCHEDULE.replace(":plantId", plantId)}
                    className="btn btn-primary mr-3"
                  >
                    <Icon name="plus"></Icon>
                    <span>Create Preventative Schedule</span>
                  </a>
                )}
              </Check>
              <Check permission={["manageTickets", "getTickets"]}>
                <a
                  id="export-schedule"
                  className={`btn btn-white btn-outline-light ${
                    preventativeSchedules?.pagination?.totalCount === 0 ? "disabled" : ""
                  }`}
                  type="export"
                  onClick={createExport}
                >
                  <Icon name="download-cloud"> </Icon>
                  <span>Export</span>
                </a>
              </Check>
            </BlockHeadContent>
          )}
        </BlockBetween>
      </BlockHead>
      <BlockContent>
        {!preventativeSchedules?.items?.length && !filterApplied && !preventativeScheduleLoading ? (
          plantStatus === "ACTIVE" &&
          isArchived === false && (
            <a
              id="add-schedule-btn"
              href={PREVENTATIVE_SCHEDULE.replace(":plantId", plantId)}
              className="btn btn-primary "
            >
              <Icon name="plus"></Icon>
              <span>Create Preventative Schedule</span>
            </a>
          )
        ) : (
          <Block>
            <DataTable className="card-stretch">
              {
                <div className="card-inner position-relative card-tools-toggle ">
                  <div className="card-title-group ">
                    <div className="card-tools"></div>
                    <div className="card-tools mr-n1 ">
                      <ul className="btn-toolbar gx-1">
                        <li>
                          <a
                            id="search-schedule-btn"
                            href="#search"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggle();
                            }}
                            className="btn btn-icon search-toggle toggle-search"
                          >
                            <Icon name="search"></Icon>
                          </a>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <div id="filter-schedule" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                          {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                          <Icon name="filter-alt"></Icon>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                    <div className="card-body">
                      <div className="search-content">
                        <Button
                          id="cancel-search-schedule"
                          className="search-back btn-icon toggle-search active"
                          onClick={() => {
                            setFilterApplied(false);
                            setSearchText("");
                            handleApplyFilter({ ...filterParams, searchText: "" });
                            toggle();
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          id="search-input-schedule"
                          type="text"
                          className="border-transparent form-focus-none form-control"
                          placeholder="Search by Name.enter to search"
                          ref={(el) => {
                            focusSearchRef.current = !onSearch ? el : null;
                          }}
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              onSearchAction();
                            }
                            if (e.keyCode === 27) {
                              setFilterApplied(false);
                              setSearchText("");
                              handleApplyFilter({ ...filterParams, searchText: "" });
                              toggle();
                            }
                          }}
                        />
                        <Button id="tab-to-search-schedule" className="search-submit btn-icon">
                          <Icon name="search" onClick={onSearchAction}></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <DataTableBody compact>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">Name</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">Start Date</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">End Date</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">Actions</span>
                  </DataTableRow>
                </DataTableHead>
                <Sidebar toggleState={showFilter}>
                  <PreventativeScheduleFilter setFilterApplied={setFilterApplied} />
                </Sidebar>
                {preventativeScheduleLoading ? (
                  <></>
                ) : (
                  currentItems.map((item, i) => {
                    return (
                      <DataTableItem key={`${item.id}-preventative-masters`}>
                        <DataTableRow>
                          <div
                            className="user-card dropdown-toggle"
                            onClick={() => {
                              history.push(
                                PREVENTATIVE_SCHEDULE_VIEW.replace(":plantId", plantId).replace(
                                  ":preventativeScheduleId",
                                  item.id
                                )
                              );
                            }}
                          >
                            <div className="user-name">
                              <span
                                data-toggle="tooltip"
                                title={item.title}
                                style={{
                                  display: "inline-block",
                                  width: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                                className="tb-lead"
                              >
                                {item.name}
                              </span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{format(new Date(item?.startDate || null), "dd/MM/yyyy")} </span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{format(new Date(item?.endDate || null), "dd/MM/yyyy")}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <ul>
                            <li>
                              <Check permission={["manageTickets"]}>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <React.Fragment>
                                        <li
                                          /* className="nk-tb-action-hidden" */ onClick={() => {
                                            history.push(
                                              PREVENTATIVE_SCHEDULE_EDIT.replace(":plantId", plantId).replace(
                                                ":preventativeScheduleId",
                                                item.id
                                              )
                                            );
                                          }}
                                        >
                                          <DropdownItem
                                            tag="a"
                                            href="#edit"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="edit-alt-fill"></Icon>
                                            <span>Edit</span>
                                          </DropdownItem>
                                        </li>
                                        <li
                                          /* className="nk-tb-action-hidden" */ onClick={() =>
                                            handleDeleteConfirmation(item.id)
                                          }
                                        >
                                          <DropdownItem
                                            tag="a"
                                            href="#delete"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Delete</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </Check>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                )}
              </DataTableBody>
              {preventativeScheduleLoading && <Skeleton count={10} className="w-100" />}

              <div className="card-inner">
                {preventativeSchedules.pagination && preventativeSchedules.pagination.totalCount > 0 ? (
                  <PaginationComponent
                    itemPerPage={pagination.size}
                    totalItems={preventativeSchedules.pagination.totalCount}
                    paginate={scheduleContext.updateShowListPage}
                    currentPage={preventativeSchedules.pagination.currentPage}
                    paginatePage={scheduleContext.updateShowListSize}
                  />
                ) : (
                  !preventativeScheduleLoading && (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  )
                )}
              </div>
            </DataTable>
          </Block>
        )}
      </BlockContent>
      <ModalViewer
        title="Preventative Schedule - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </>
  );
};
export default PreventativeScheduleList;
