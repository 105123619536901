import React from "react";
import useBoolean from "../hooks/useBoolean";
import { getGlobalSearch } from "../repositories/GlobalSearchRepository";


const initialFilterParams = {
  searchText: "",
};

export const GlobalSearchContext = React.createContext();

export const GlobalSearchProvider = (props) => {
   
  const [globalData, setGlobalData] = React.useState([]);
  const [totalCount,setTotalCount] = React.useState(0)
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  //Loaders
  const [globalSearchLoading, globalSearchState] = useBoolean(false);

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const loadGlobalSearchList = async (searchParams) => {
    globalSearchState.on();
    try {
      const filterParams = { searchText: searchParams };
      const response = await getGlobalSearch(filterParams);
      setGlobalData(response.result);
      setTotalCount(response.totalCount)
      
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      globalSearchState.off();
    }
  };

  const resetFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    globalSearchLoading,
    loadGlobalSearchList,
    globalData,
    handleApplyFilter,
    resetFilter,
    totalCount
  };
  return <GlobalSearchContext.Provider value={mContext}>{props.children}</GlobalSearchContext.Provider>;
};
export const withGlobalSearchProvider = (Container, containerProps) => (props) =>
  (
    <GlobalSearchProvider>
      <Container {...containerProps} />
    </GlobalSearchProvider>
  );
