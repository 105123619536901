/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import UserForm from "../components/UserForm";
import { withUserManagementProvider, UserManagementContext } from "../UserManagementProvider";

const UserManagementFormContainer = (props) => {
  const params = useParams();
  const { userId } = params;

  const { loadAllRoles } = useContext(UserManagementContext);

  useEffect(() => {
    loadAllRoles();
  }, []);

  const title = userId ? `${userId} - User Management | Edit` : "Create | User Management";

  return (
    <React.Fragment>
      <Head title={title} />
      <Content>
        <UserForm userId={userId} />
      </Content>
    </React.Fragment>
  );
};

export default withUserManagementProvider(UserManagementFormContainer);
