import React, { useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../components/Component";
import { CLIENT_FILTERS } from "../../../constants/PreferenceKeys";
import { ClientManagementContext } from "../ClientManagementProvider";
import { sortOption } from "../../../utils/Utils";

const ClientListFilter = () => {
  const clientManagementContext = React.useContext(ClientManagementContext);
  const { filterOptions } = clientManagementContext;
  const { filterParams, handleApplyFilter, sfState, checkIsFilterApplied, showFilter } = clientManagementContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    clientManagementContext.resetClientListFilter();
    localStorage.removeItem(CLIENT_FILTERS);
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    checkIsFilterApplied();
    sfState.off();
    updateTableSm(false);
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleStateFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedClientStatus: statusFilter });
  };

  const handleArchiveFilter = (e) => {
    const isChecked = e.target.checked;
    handleFilterSelection({ isArchiveSelected: isChecked });
  };

  const handlePhoneNumberFilterSelection = (selectedOption) => {
    handleFilterSelection({ phoneNumber: selectedOption.value });
  };
  const selectedPhoneNumberOption =
    filterOptions.phoneNumber?.find((o) => o.value === selectedFilter.phoneNumber) || null;

  const handleMobileNumberFilterSelection = (selectedOption) => {
    handleFilterSelection({ mobileNumber: selectedOption.value });
  };
  const selectedmobileNumberOption =
    filterOptions.mobileNumber?.find((o) => o.value === selectedFilter.mobileNumber) || null;

  const handleCityFilterSelection = (selectedOption) => {
    handleFilterSelection({ city: selectedOption.value });
  };
  const selectedCityOption = filterOptions.city?.find((o) => o.value === selectedFilter.city) || null;

  const handleEmailFilterSelection = (selectedOption) => {
    handleFilterSelection({ email: selectedOption.value });
  };
  const selectedEmailOption = filterOptions.email?.find((o) => o.value === selectedFilter.email) || null;

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Phone Number</label>
                <RSelect
                  options={sortOption(filterOptions.phoneNumber)}
                  placeholder="Any PhoneNumber"
                  onChange={handlePhoneNumberFilterSelection}
                  value={selectedPhoneNumberOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Mobile Number</label>
                <RSelect
                  options={sortOption(filterOptions.mobileNumber)}
                  placeholder="Any MobileNumber"
                  onChange={handleMobileNumberFilterSelection}
                  value={selectedmobileNumberOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">City</label>
                <RSelect
                  options={sortOption(filterOptions.city)}
                  placeholder="Any City"
                  onChange={handleCityFilterSelection}
                  value={selectedCityOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Email</label>
                <RSelect
                  options={sortOption(filterOptions.email)}
                  placeholder="Any Email"
                  onChange={handleEmailFilterSelection}
                  value={selectedEmailOption}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <div className="custom-control custom-control-sm custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchived"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isArchived">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default ClientListFilter;
