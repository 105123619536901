import React, { useContext } from "react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  PreviewCard,
  Icon,
} from "../../components/Component";
import Logo from "../../images/bge_logo.png";
import LogoDark from "../../images/bge_logo.png";
import { FormGroup, Form, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContext.provider";
import { ForgotPasswordContext, withForgotPasswordProvider } from "./providers/ForgotPasswordContext.provider";
import { useForm } from "react-hook-form";
import { handleSuccessSweetAlert } from "../../components/sweetAlert/SweetAlert";

const ForgotPassword = () => {
  const authContext = useContext(AuthContext);
  const forgotPasswordContext = useContext(ForgotPasswordContext);

  const { loading, setLoading } = forgotPasswordContext;
  const { errorVal, setError } = forgotPasswordContext;

  const onFormSubmit = async (formData) => {
    setLoading(true);
    try {
      await authContext.onForgotPassword(formData);
      handleSuccessSweetAlert();
    } catch (e) {
      setError("Invalid Email Adrress");
    } finally {
      setLoading(false);
    }
  };

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();
  return (
    <React.Fragment>
      <Head title="Brighter App  | Forget Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Reset password</BlockTitle>
                <BlockDes>
                  <p>
                    Enter the email associated with your account and we'll send an email with instructions to reset your
                    password.
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter">
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="email"
                    {...register("email", { required: "Email is required" })}
                    placeholder="Enter your email address"
                    className="form-control-lg form-control"
                  />
                </div>
                {errors.email?.message && <span className="invalid">{errors.email?.message}</span>}
              </FormGroup>
              <FormGroup>
                <Button color="primary" size="lg" onClick={handleSubmit(onFormSubmit)} className="btn-block">
                  {loading ? <Spinner size="sm" color="light" /> : " Send Reset Link"}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default withForgotPasswordProvider(ForgotPassword);
