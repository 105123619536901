import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import DnoListCompact from "../components/DnoList";
import { DnoContext, withDnoProvider } from "../DnoProvider";

const DnoContainer = (props) => {
  const { loadDnoList } = useContext(DnoContext);

  const { pagination, filterParams, getDnoFilterOption } = useContext(DnoContext);

  useEffect(() => {
    loadDnoList();
  }, [pagination, filterParams]);

  useEffect(() => {
    getDnoFilterOption();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | DNO" />

      <Content>
        <DnoListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withDnoProvider(DnoContainer);
