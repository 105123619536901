/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { ClientManagementContext, withClientManagementProvider } from "../ClientManagementProvider";
import ClientList from "../components/ClientList";

const ClientManagementContainer = (props) => {
  const { loadClientList, loadFilterOptions } = useContext(ClientManagementContext);
  const { pagination, filterParams } = useContext(ClientManagementContext);

  useEffect(() => {
    loadClientList();
  }, [pagination, filterParams]);
  useEffect(() => {
    loadFilterOptions();
  }, []);
  return (
    <React.Fragment>
      <Head title="Brighter App | Client" />
      <Content>
        <ClientList />
      </Content>
    </React.Fragment>
  );
};

export default withClientManagementProvider(ClientManagementContainer);
