/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import RemedialForm from "../components/RemedialForm";
import { RemedialContext, withRemedialProvider } from "../RemedialProvider";

const RemedialFormContainer = (props) => {
  const remedialContext = useContext(RemedialContext);
  const { currentViewTask, updateTask, loadTask } = remedialContext;

  useEffect(() => {
    if (props.taskId) loadTask(props.taskId);
  }, [props.taskId]);

  if (currentViewTask) {
    currentViewTask.startedAt = new Date(currentViewTask.startedAt);
    if (currentViewTask.resolvedAt) {
      currentViewTask.resolvedAt = new Date(currentViewTask.resolvedAt);
    }
  }
  return (
    <React.Fragment>
      <RemedialForm
        plantId={props.plantId}
        taskId={props.taskId}
        currentViewTask={currentViewTask}
        updateTask={updateTask}
        onSuccessfulModal={props.onSuccessfulModal}
      />
    </React.Fragment>
  );
};

export default withRemedialProvider(RemedialFormContainer);
