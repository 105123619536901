/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect, Icon, BlockHeadContent } from "../../../components/Component";
import DatePicker from "react-datepicker";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";
import { DnoContext } from "../../entity_management/dno/DnoProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useParams } from "react-router-dom";
import useBoolean from "../../../hooks/useBoolean";
import { useHistory, useLocation } from "react-router-dom";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { PVPLANT_MANAGEMENT_PAGE, PVPLANT_MANAGEMENT_VIEW_PAGE } from "../../../constants/routes";
import Attachments from "../../../components/attachments/Attachment";
import CreateFormAttachments from "../../../components/attachments/CreateFormAttachment";
import { PLANT } from "../../../constants/PreferenceKeys";
import ModalViewer from "../../entity_management/ModalViewer";
import HealthAndSafetyFormContainer from "../../entity_management/home_and_safety/containers/HealthAndSafetyFormContainer";
import DnoFormContainer from "../../entity_management/dno/containers/DnoFromContainer";
import { sortOption } from "../../../utils/Utils";
import Head from "../../../layout/head/Head";
import { format } from "date-fns";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const createSchema = yup
  .object({
    name: yup.string().required(),
    clientId: yup.number().required(),
    size: yup.string().notRequired().default(""),
    onboardedAt: yup.date().nullable().default(null),
    status: yup.string().required(),
    address: yup.string().notRequired().default(""),
    plantManagerId: yup.number().notRequired().default(),
    teamLeaderId: yup.number().notRequired().default(),
    fieldEngineerIds: yup.array().of(yup.number()).notRequired().default([]),
    identifier: yup.string().required(),
    postalCode: yup.string(),
    dnoId: yup.number().notRequired().default(),
    medicalCenterId: yup.number().notRequired().default(),
    googleMapLink: yup.string().notRequired().default(""),
    what3WordLink: yup.string().notRequired().default(""),
  })
  .required();

const updateSchema = yup
  .object({
    name: yup.string().required(),
    clientId: yup.number().required(),
    size: yup.string().notRequired().default(""),
    onboardedAt: yup.date().nullable(),
    status: yup.string().required(),
    address: yup.string().nullable().notRequired().default(""),
    plantManagerId: yup.number().notRequired().default(),
    teamLeaderId: yup.number().notRequired().default(),
    fieldEngineerIds: yup
      .array()
      .transform((value, originalValue) => {
        if (originalValue === null || typeof originalValue === "undefined") {
          return [];
        }
        return originalValue;
      })
      .default([]),
    identifier: yup.string().required(),
    postalCode: yup.string().nullable(),
    dnoId: yup.number().notRequired().default(),
    medicalCenterId: yup.number().notRequired().default(),
    googleMapLink: yup.string().nullable().notRequired().default(""),
    what3WordLink: yup.string().nullable().notRequired().default(""),
  })
  .required();

const PvPlantForm = (props) => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { plantId } = params;

  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const { currentViewPvPlant, filterOptions, userList, clientList, dnoList, healthAndSafetyList, loadingPvPlantView,
    plantManagers
   } =
    pvPlantManagementContext;
  const dnoContext = useContext(DnoContext);

  const { dnoData } = dnoContext;

  const [isEditMode, updateEditMode] = useBoolean(false);
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState("");
  const [isDnoOpen, setIsDnoOpen] = useState(false);

  const [attachments, setAttachments] = useState([]);

  let allDnoList = dnoList;

  const dnoDataLength = Object.keys(dnoData).length;

  useEffect(() => {
    if (dnoDataLength > 0) {
      allDnoList = [dnoData, ...allDnoList];
    }
  }, []);

  const pvPlantFormRef = useForm({
    resolver: yupResolver(props.pvPlantId ? updateSchema : createSchema),
  });

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = pvPlantFormRef;

  useEffect(() => {
    pvPlantManagementContext.loadFilterOptions();
  }, []);

  useEffect(() => {
    if (props.pvPlantId) {
      pvPlantManagementContext.loadPvPlant(props.pvPlantId).catch((e) => {
        errorToast({ description: "Error happened while loading PV-Plants list" });
      });
    }
    pvPlantManagementContext.loadAllUsers();
    pvPlantManagementContext.loadAllClients();
    pvPlantManagementContext.loadAllDnos();
    pvPlantManagementContext.loadAllHealthAndSafeties();
  }, [props.pvPlantId]);

  useEffect(() => {
    if (currentViewPvPlant) {
      updateEditMode.on();
      const { attachment, deletedAt, ...pvPlantData } = currentViewPvPlant;
      reset(pvPlantData);
    }
  }, [currentViewPvPlant]);

  useEffect(() => {
    pvPlantManagementContext.loadAllDnos();
  }, []);

  if (currentViewPvPlant?.onboardedAt) {
    currentViewPvPlant.onboardedAt = new Date(currentViewPvPlant.onboardedAt);
  }

  const handleFormSubmit = (formData) => {

    // Only date is required so removed timestamp which double converts to UTC;
    if (formData.onboardedAt) {
      formData.onboardedAt = format(formData.onboardedAt, 'yyyy-MM-dd')
    }
    // ***

    if (isEditMode) {
      pvPlantManagementContext
        .updatePvPlant(currentViewPvPlant.id, formData)
        .then((res) => {
          if (location.state) {
            history.push(PVPLANT_MANAGEMENT_VIEW_PAGE.replace(":plantId", props.pvPlantId));
          } else {
            history.replace(PVPLANT_MANAGEMENT_PAGE);
          }
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e.message}` });
        });
    } else {
      pvPlantManagementContext
        .createPvPlant({ ...formData, attachments })
        .then((res) => {
          history.replace(PVPLANT_MANAGEMENT_PAGE);
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          if (e.message === "Validation error") {
            errorToast({ description: "Acronomy already exists" });
          } else {
            errorToast({ description: e.message });
          }
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id={"create-plant-form"}
        color="primary"
        isLoading={loadingPvPlantView}
        size="lg"
        onClick={loadingPvPlantView ? null : handleSubmit(handleFormSubmit)}
      >
        Create Plant
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <Button
        id={"save-plant-form"}
        color="primary"
        size="lg"
        isLoading={loadingPvPlantView}
        onClick={loadingPvPlantView ? null : handleSubmit(handleFormSubmit)}
      >
        Save Information
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleDnoModal = () => {
    setIsDnoOpen(!isDnoOpen);
  };
  const VIEW = {
    CREATE: "CREATE",
  };

  const createHealthAndSafety = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };

  const createDno = () => {
    setView(VIEW.CREATE);
    setIsDnoOpen(!isDnoOpen);
    return <>Edit</>;
  };

  const onSuccessfulModal = () => {
    toggleModal();
    setIsOpen(!isOpen);
  };

  const onSuccessfulDnoModal = () => {
    toggleDnoModal();
    setIsDnoOpen(!isDnoOpen);
  };

  const onDnoCreate = (value) => {
    setValue("dnoId", value.value);
  };
  const onHealthandSafetyCreate = (value) => {
    setValue("medicalCenterId", value.value);
  };
  const getDnoComponent = () => {
    if (view === VIEW.CREATE) {
      return <DnoFormContainer onSuccessfulModal={onSuccessfulDnoModal} onDnoCreate={(value) => onDnoCreate(value)} />;
    }
  };
  const getComponent = () => {
    if (view === VIEW.CREATE) {
      return (
        <HealthAndSafetyFormContainer
          onSuccessfulModal={onSuccessfulModal}
          onHealthandSafetyCreate={(value) => onHealthandSafetyCreate(value)}
        />
      );
    }
  };

  const attachmentFiles = (files) => {
    if (files) {
      setAttachments(files);
    }
  };

  return (
    <>
      <Block size="lg">
        <Head title={plantId ? "Brighter App | Pv-Plant | Edit" : "Brighter App | Pv-Plant | Create"} />
        <BlockHeadContent className="mb-1"></BlockHeadContent>
        <PreviewCard>
          {!plantId || currentViewPvPlant ? (
            <>
              <div className="card-head">
                <h5 className="card-title">PV-Plant Information</h5>
              </div>
              <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
                <Row className="g-4">
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="name">
                        <span style={{ color: "indianred" }}>&#42;</span>Plant Name
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          id="plant-name-input"
                          {...register("name")}
                          type="text"
                          name="name"
                          className="form-control"
                        />
                      </div>
                      {errors.name && (
                        <span className="invalid" style={{ color: "red" }}>
                          Name is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="size">
                        Size
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          id={"plant-size-input"}
                          {...register("size")}
                          type="text"
                          name="size"
                          className="form-control"
                        />
                      </div>
                      {errors.size && (
                        <span className="invalid" style={{ color: "red" }}>
                          Size is Required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup className="form-group">
                      <label className="form-label" htmlFor="identifier">
                        <span style={{ color: "indianred" }}>&#42;</span>Acronym
                      </label>
                      <div className="form-control-wrap">
                        <input
                          id={"plant-identifier-input"}
                          {...register("identifier")}
                          type="text"
                          name="identifier"
                          className="form-control"
                        />
                      </div>
                      {errors.identifier && (
                        <span className="invalid" style={{ color: "red" }}>
                          Identifier is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup className="form-group">
                      <label className="form-label" htmlFor="full-name">
                        On-Boarding Date
                      </label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="onboardedAt"
                          render={(e, f) => {
                            const { field } = e;

                            return (
                              <DatePicker
                                id="plant-onBoardDate-input"
                                ref={field.ref}
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  field.value !== "Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)"
                                    ? field.value
                                    : null
                                }
                                onChangeRaw={(e) => {
                                  const dddd = moment(new Date(e.target)).format("DD/MM/YYYY");
                                  const newDate = new Date(dddd);
                                  field.value = newDate;
                                  const ddd = newDate.toLocaleDateString("en-GB");
                                }}
                                onChange={(date) => {
                                  field.onChange(date);
                                }}
                                className="form-control date-picker"
                                locale="en-GB"
                              />
                            );
                          }}
                        />
                      </div>
                      {errors?.onboardedAt && (
                        <span className="invalid" style={{ color: "red" }}>
                          onboardedAt is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="clientId">
                        <span style={{ color: "indianred" }}>&#42;</span>Client Name
                      </Label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="clientId"
                          rules={{ required: true }}
                          render={({ field, ref }) => {
                            const options = clientList;
                            const selectedValue = options.find((e) => e.value === field.value);
                            return (
                              <RSelect
                                id="plant-clientName-select"
                                {...field}
                                ref={ref}
                                value={selectedValue}
                                placeholder="Select the Client "
                                options={sortOption(options)}
                                onChange={(o) => setValue("clientId", o.value, { shouldValidate: true })}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors.clientId && (
                        <span className="invalid" style={{ color: "red" }}>
                          Client is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="plantManagerId">
                        Plant Manager
                      </Label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="plantManagerId"
                          rules={{ required: true }}
                          render={({ field, ref }) => {
                            const options = plantManagers;
                            const selectedValue = options.find((e) => e.value === field.value);
                            return (
                              <RSelect
                                id={"plant-manager-select"}
                                {...field}
                                ref={ref}
                                value={selectedValue}
                                placeholder="Select the Plant manager "
                                options={sortOption(options)}
                                onChange={(o) => setValue("plantManagerId", o.value)}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors.plantManagerId && (
                        <span className="invalid" style={{ color: "red" }}>
                          Plant Manager is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="6" md="6">
                    <FormGroup className="form-group" htmlFor="teamLeaderId">
                      <label className="form-label">Team Leader</label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="teamLeaderId"
                          rules={{ required: true }}
                          render={({ field, ref }) => {
                            const options = filterOptions.users;
                            const selectedValue = options.find((e) => e.value === field.value);
                            return (
                              <RSelect
                                id="plant-team-select"
                                {...field}
                                ref={ref}
                                value={selectedValue}
                                placeholder="Select the Team Leader "
                                options={sortOption(options)}
                                onChange={(o) => setValue("teamLeaderId", o.value)}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors.teamLeaderId && (
                        <span className="invalid" style={{ color: "red" }}>
                          Team Leader is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="6" md="6">
                    <FormGroup className="form-group">
                      <label className="form-label" htmlFor="fieldEngineerIds">
                        Field Engineer
                      </label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="fieldEngineerIds"
                          rules={{ required: true }}
                          render={({ field, ref }) => {
                            const options = filterOptions.users;
                            const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                            return (
                              <RSelect
                                id="plant-engineer-select"
                                {...field}
                                ref={ref}
                                value={selectedValue}
                                isMulti
                                options={sortOption(options)}
                                onChange={(o) => {
                                  setValue(
                                    "fieldEngineerIds",
                                    o.map((item) => item.value)
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors.fieldEngineerIds && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors.fieldEngineerIds?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup className="form-group">
                      <label className="form-label" htmlFor="status">
                        <span style={{ color: "indianred" }}>&#42;</span>Status
                      </label>
                      <Controller
                        control={control}
                        name="status"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = filterOptions.plantStatuses;
                          const selectedValue = options.find((e) => e.value === field.value);
                          return (
                            <RSelect
                              id="plant-status-select"
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              placeholder="Select the Status"
                              options={options}
                              onChange={(o) => setValue("status", o.value, { shouldValidate: true })}
                            />
                          );
                        }}
                      />
                      {errors.status && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors.status?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="postalCode">
                        Postal Code
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          id="plant-postal-input"
                          {...register("postalCode")}
                          type="text"
                          name="postalCode"
                          className="form-control"
                        />
                      </div>
                      {errors.postalCode && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors.postalCode?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="address">
                        Address
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          id="plant-address-input"
                          {...register("address")}
                          type="text"
                          name="address"
                          className="form-control"
                        />
                      </div>
                      {errors.address && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors.address?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6"></Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="googleMapLink">
                        GoogleMap Link
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          id="plant-map-input"
                          {...register("googleMapLink")}
                          type="text"
                          name="googleMapLink"
                          className="form-control"
                        />
                      </div>
                      {errors.googleMapLink && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors.googleMapLink?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="what3WordLink">
                        What3Word Link
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          id="plant-what3word-input"
                          {...register("what3WordLink")}
                          type="text"
                          name="what3WordLink"
                          className="form-control"
                        />
                      </div>
                      {errors.what3WordLink && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors.what3WordLink?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="5" md="5">
                    <FormGroup>
                      <Label className="form-label" htmlFor="dnoId">
                        DNO
                      </Label>
                      <div className="form-control-wrap ">
                        <Controller
                          control={control}
                          name="dnoId"
                          rules={{ required: true }}
                          render={({ field, ref }) => {
                            const options = allDnoList;
                            const selectedValue = options.find((e) => e.value === field.value);
                            return (
                              <RSelect
                                id="plant-dno-select"
                                {...field}
                                ref={ref}
                                value={selectedValue}
                                placeholder="Select the DNO "
                                options={sortOption(options)}
                                onChange={(o) => setValue("dnoId", o.value)}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors.dnoId && (
                        <span className="invalid" style={{ color: "red" }}>
                          Dno is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="1" md="1" className="mt-1 ">
                    <Button
                      id="plant-dno-btn"
                      type={"add"}
                      color="primary"
                      className="btn-icon mt-4"
                      onClick={createDno}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                  </Col>
                  <Col lg="5" md="5">
                    <FormGroup>
                      <Label className="form-label" htmlFor="medicalCenterId">
                        Hospital
                      </Label>
                      <div className="form-control-wrap">
                        <Controller
                          control={control}
                          name="medicalCenterId"
                          rules={{ required: true }}
                          render={({ field, ref }) => {
                            const options = healthAndSafetyList;
                            const selectedValue = options.find((e) => e.value === field.value);
                            return (
                              <RSelect
                                id="plant-hospital-select"
                                {...field}
                                ref={ref}
                                value={selectedValue}
                                placeholder="Select the hospital "
                                options={sortOption(options)}
                                onChange={(o) => setValue("medicalCenterId", o.value)}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors.medicalCenterId && (
                        <span className="invalid" style={{ color: "red" }}>
                          medicalCentre is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg="1" md="1" className="mt-1">
                    <Button
                      id="add-hospital-plant"
                      color="primary"
                      className="btn-icon mt-4"
                      onClick={createHealthAndSafety}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                  </Col>
                  {isEditMode && currentViewPvPlant && (
                    <Col lg="12">
                      <FormGroup className="form-group">
                        <div className="form-control-wrap">
                          <Attachments
                            module={PLANT}
                            id={currentViewPvPlant.id}
                            attachments={currentViewPvPlant.attachment}
                            subModule={""}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  )}
                  {!isEditMode && (
                    <Col lg="12">
                      <FormGroup className="form-group">
                        <div className="form-control-wrap">
                          <CreateFormAttachments attachmentFiles={attachmentFiles} />
                        </div>
                      </FormGroup>
                    </Col>
                  )}

                  <Col xl="12" className="d-flex justify-content-end">
                    <Button id="cancel-plant" className="mr-1" size="lg" color="outline-primary">
                      <Link
                        to={
                          location.state
                            ? PVPLANT_MANAGEMENT_VIEW_PAGE.replace(":plantId", props.pvPlantId)
                            : PVPLANT_MANAGEMENT_PAGE
                        }
                      >
                        Cancel
                      </Link>
                    </Button>
                    {renderSaveButton()}
                  </Col>
                </Row>
              </Form>
            </>
          ) : (
            <Skeleton count={15} width={"100"} />
          )}
        </PreviewCard>
        <ModalViewer
          title="Medical Centre"
          size="md"
          isOpen={isOpen}
          toggleModal={toggleModal}
          component={getComponent()}
        />
        <ModalViewer
          title="DNO"
          size="sm"
          isOpen={isDnoOpen}
          toggleModal={toggleDnoModal}
          component={getDnoComponent()}
        />
      </Block>
    </>
  );
};

export default PvPlantForm;
