/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../hooks/useBoolean";
import { AssetCategoryContext } from "../AssetCategoryProvider";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import Head from "../../../../layout/head/Head";

const createSchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(/^(\s*\S.*)/g, "category should be atleast one character"),
});

const AssetCategoryForm = (props) => {
  const { createAssetCategory, assetCategoryLoading } = useContext(AssetCategoryContext);

  const [isEditMode, updateEditMode] = useBoolean(false);

  const assetCategoryFormRef = useForm({
    resolver: yupResolver(props.assetCategoryId ? createSchema : createSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = assetCategoryFormRef;

  useEffect(() => {
    reset(props.currentViewAssetCategory);
    if (props.currentViewAssetCategory) updateEditMode.on();
  }, [props.currentViewAssetCategory]);

  const handleFormSubmit = (formData) => {
    if (props.assetCategoryId) {
      props
        .updateAssetCategory(props.currentViewAssetCategory.id, formData)
        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while updating Asset Category" });
        });
    } else {
      createAssetCategory(formData)
        .then((res) => {
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while creating Asset Category" });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="add-category"
        color="primary"
        size="lg"
        isLoading={assetCategoryLoading}
        onClick={assetCategoryLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return isDirty ? (
      <Button
        id="save-category"
        color="primary"
        size="lg"
        isLoading={assetCategoryLoading}
        onClick={assetCategoryLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Save Information
      </Button>
    ) : (
      <Button id="cancel-category" size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
        Cancel
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head
        title={
          props.assetCategoryId ? "Brighter App | Asset Category | Edit" : "Brighter App | Asset Category | Create"
        }
      />
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>category
                  </Label>
                  <div className="form-control-wrap">
                    <input id="name-input" {...register("name")} type="text" name="name" className="form-control" />
                  </div>
                  {errors.name && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.name?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default AssetCategoryForm;
