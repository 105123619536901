import apiService from "../../../services/ApiService";
import {
    ASSET_CATEGORY_LIST_API ,
    ASSET_CATEGORY_CREATE_API,
    ASSET_CATEGORY_VIEW_API ,
    ASSET_CATEGORY_UPDATE_API,
    ASSET_CATEGORY_DELETE_API,
    EXPORT_ASSET_CATEGORY_API,
    ASSET_CATEGORY_FILTER_LIST_API
} from "../../../constants/endpoints";

export const getAssetCategoryList = async (filterParams) => {
  const response = await apiService.get(ASSET_CATEGORY_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};
export const updateAssetCategoryAPI = async (assetCategoryId, params) => {
  const response = await apiService.put(ASSET_CATEGORY_UPDATE_API, {
      pathParams: { assetCategoryId },
      body: params
  })
  return response
}

export const createAssetCategoryAPI = async (params) => {
  const response = await apiService.post(ASSET_CATEGORY_CREATE_API, {
    body: params,
  });
  return response
}
export const getAssetCategoryAPI = async (assetCategoryId) => {
    const response = await apiService.get(ASSET_CATEGORY_VIEW_API, {
        pathParams: { assetCategoryId }
    })
    return response
}

export const deleteAssetCategoryAPI = async (assetCategoryId) => {
    const response = await apiService.delete(ASSET_CATEGORY_DELETE_API, {
        pathParams: { assetCategoryId }
    })
    return response
}
export const exportAssetCategory = async (filterParams) => {
  const response = await apiService.get(EXPORT_ASSET_CATEGORY_API, {
      queryParams: filterParams,
      responseType: 'blob',
      headers: {
          'Content-Type': 'application/pdf',
      }
  })
  return response
}
export const getAssetCategoryFilterListAPI = async () => {
  const response = await apiService.get(ASSET_CATEGORY_FILTER_LIST_API);
  return response;
};
