/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { withClientManagementProvider } from "../ClientManagementProvider";
import ClientForm from "../components/ClientForm";

const ClientManagementFormContainer = (props) => {
  const params = useParams();
  const { clientId } = params;

  const title = clientId ? `${clientId} - Client | Edit` : "Create | Client";

  return (
    <React.Fragment>
      <Head title={title} />
      <Content>
        <ClientForm clientId={clientId} />
      </Content>
    </React.Fragment>
  );
};

export default withClientManagementProvider(ClientManagementFormContainer);
