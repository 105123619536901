/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  createPreventativeSchedule,
  getPreventativeSchedule,
  getPreventativeScheduleOptions,
  getPreventativeSchedules,
  getPreventativeTasks,
  getTaskByMasterId,
  getTaskByScheduleId,
  updatePreventativeSchedule,
} from "./ScheduleRepository";
import useBoolean from "../../hooks/useBoolean";
import { errorToast } from "../../components/toastify/Toastify";
import { getPvPlantAPI } from "../pv_plant_management/PvPlantManagementRepository";

export const ScheduleContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};
const initialFilterParams = {
  searchText: "",
  isArchiveSelected: false,
};

export const PreventativeScheduleProvider = (props) => {
  const [preventativeSchedules, setPreventativeSchedules] = useState([]);
  const [preventativeScheduleOptions, setPreventativeScheduleOptions] = useState([]);
  const [preventativeMasterListOptions, setPreventativeMasterListOptions] = useState([]);
  const [isLoading, setIsLoding] = useState(false);

  const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams));
  const [preventativeTasks, setPreventativeTasks] = useState([]);
  const [preventativeScheduleTasks, setPreventativeScheduleTasks] = useState([]);

  //Loaders
  const [preventativeScheduleLoading, pmlState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);
  const [preventativeTaskList, setPreventativeTaskList] = useState({ pagination: {}, items: [] });
  const [preventativeTaskFilter, setPreventativeTaskFilter] = useState({ page: 1, size: 10, search: "" });

  const getQueryParams = () => {
    const queryParams = {
      ...pagination,
      ...filterParams,
    };
    return queryParams;
  };
  const loadPreventativeSchedules = async ({ plantId }) => {
    pmlState.on();
    try {
      const filterParams = getQueryParams();
      if (plantId) {
        filterParams["plantId"] = plantId;
      }
      const response = await getPreventativeSchedules(filterParams);
      setPreventativeSchedules(response.preventativeSchedule);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      pmlState.off();
    }
  };
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const resetListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));
  const getOptions = async () => {
    try {
      const response = await getPreventativeScheduleOptions();
      setPreventativeMasterListOptions(response.preventativeMasterList);
      setPreventativeScheduleOptions(response.preventativeSchedules);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    }
  };
  const createSchedule = async (data) => {
    try {
      setIsLoding(true);
      const response = await createPreventativeSchedule(data);
      return response;
    } catch (err) {
      errorToast({ description: "Something went wrong" });
    } finally {
      setIsLoding(false);
    }
  };

  const getPreventativeMasterTask = async (id) => {
    try {
      const response = await getTaskByMasterId(id);
      const temp = [];
      response.map((res) => {
        if (!preventativeTasks.some((task) => task.code === res.code && task.title === res.title)) {
          if (res.frequency === "Monthly") {
            temp.push({ ...res, months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] });
          } else {
            temp.push({ ...res, removeId: true });
          }
        }
      });
      if (preventativeScheduleTasks.length) {
        setPreventativeTasks([...preventativeScheduleTasks, ...temp]);
      } else {
        setPreventativeTasks(response);
      }
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    }
  };

  const getPreventativeScheduleTask = async (id) => {
    try {
      const response = await getTaskByScheduleId(id);
      if (preventativeScheduleTasks.length) {
        const temp = [];
        response.map((res) => {
          if (!preventativeTasks.some((task) => task.code === res.code && task.title === res.title)) {
            temp.push({ ...res, removeId: true });
          }
        });
        setPreventativeTasks([...preventativeScheduleTasks, ...temp]);
      } else {
        setPreventativeTasks(response);
      }
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    }
  };

  const getPreventativeScheduleById = async (id) => {
    try {
      const response = await getPreventativeSchedule(id);
      setPreventativeScheduleTasks(response.tasks);
      return response;
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    }
  };

  const updatePreventativeScheduleById = async (id, data) => {
    try {
      setIsLoding(true);
      const response = await updatePreventativeSchedule(id, data);
      return response;
    } catch (err) {
      errorToast({ description: "something went wrong" });
    } finally {
      setIsLoding(false);
    }
  };

  const getPlant = async (plantId) => {
    try {
      const response = await getPvPlantAPI(plantId);
      return response.plant;
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const checkIsFilterApplied = () => {
    if (filterParams.isArchiveSelected) {
      return true;
    } else return false;
  };

  const isFilterApplied = checkIsFilterApplied();

  const loadPreventativeScheduleTasks = async (preventativeScheduleId) => {
    pmlState.on();
    try {
      const response = await getPreventativeTasks({ ...preventativeTaskFilter, preventativeScheduleId, ...pagination });
      setPreventativeTaskList(response.preventativeScheduleTask);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      pmlState.off();
    }
  };
  const mContext = {
    pagination,
    preventativeSchedules,
    setPreventativeSchedules,
    filterParams,
    preventativeScheduleLoading,
    preventativeMasterListOptions,
    preventativeScheduleOptions,
    preventativeTasks,
    isLoading,
    setPreventativeTasks,
    getOptions,
    loadPreventativeSchedules,
    handleApplyFilter,
    getQueryParams,
    resetListFilter,
    getPreventativeScheduleTask,
    getPreventativeMasterTask,
    getPreventativeScheduleById,
    updatePreventativeScheduleById,
    createSchedule,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
    getPlant,
    showFilter,
    sfState,
    isFilterApplied,
    checkIsFilterApplied,
    preventativeTaskList,
    preventativeTaskFilter,
    setPreventativeTaskFilter,
    loadPreventativeScheduleTasks,
  };
  return <ScheduleContext.Provider value={mContext}>{props.children}</ScheduleContext.Provider>;
};

export const withPreventativeScheduleProvider = (Container, containerProps) => (props) =>
  (
    <PreventativeScheduleProvider>
      <Container {...containerProps} {...props} />
    </PreventativeScheduleProvider>
  );
