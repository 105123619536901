import React, { useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
    createBgeOwnedInventoryAPI,
    deleteInventoryAPI,
    getBgeOwnedInventoryFilterOptions,
    getBgeOwnedInventoryList,
    getInventoryAPI,
    moveToPlantInventoryAPI,
    updateBgeOwnedInventoryAPI
} from "./SelfOwnedRepository";

export const SelfOwnedInventoryContext = React.createContext()

const initialPaginationState = {
    page: 1,
    size: 10,
}
const initialFilterOptions = {
    assetCategories: [],
    assets: [],
    manufacturers: [],
    plants: []
}
const initialFilterParams = {
    supplier: [],
    category: [],
    searchText: ''
}

export const SelfOwnedInventoryProvider = props => {
    const [bgeOwnedInventoryList, setBgeOwnedInventoryList] = useState([])
    const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState))
    const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams))
    const [currentViewInventory, setCurrentViewInventory] = useState()
    const [filterOptions, setFilterOptions] = useState(Object.assign({}, initialFilterOptions))

    //Loaders
    const [bgeOwnedInventoryLoading, bgeOwnedInventoryState] = useBoolean(false)
    const [filtersLoading, flState] = useBoolean(false)

    const getBgeOwnedInventoryListFilterParams = () => {
        const queryParams = { ...pagination }
        const {
            supplier,
            category,
            searchText } = filterParams
        if (supplier.length) queryParams['supplier'] = supplier
        if (category.length) queryParams['category'] = category
        if (searchText) queryParams['equipmentName'] = searchText
        return queryParams
    }

    const loadBgeOwnedInventoryList = async () => {
        bgeOwnedInventoryState.on()

        try {
            const filterParams = getBgeOwnedInventoryListFilterParams()
            const response = await getBgeOwnedInventoryList(filterParams)
            setBgeOwnedInventoryList(response.bgeOwnedInventoryList)
        } catch (e) {
            // CAPTURE EXCEPTION
            throw e
        } finally {
            bgeOwnedInventoryState.off()
        }
    }

    const loadFilterOptions = async () => {
        flState.on()

        try {
            const response = await getBgeOwnedInventoryFilterOptions()
            setFilterOptions(response)
        } catch (e) {
            // HANDLE ERROR
        } finally {
            flState.off()
        }
    }

    // VIEW INVENTORY
    const loadInventory = async inventoryId => {
        try {
            const response = await getInventoryAPI(inventoryId)
            setCurrentViewInventory(response.inventory)
        } catch (e) {
            throw e
        } finally {
            //
        }
    }
    // UPDATE INVENTORY
    const updateInventory = async (inventoryId, updateParams) => {
        try {
            await updateBgeOwnedInventoryAPI(inventoryId, updateParams)
            loadBgeOwnedInventoryList()
        } catch (e) {
            throw e
        } finally {
            //
        }
    }

    //MOVE TO PLANT INVENTORY
    const moveToPlantInventory = async (inventoryId, updateParams) => {
        try {
            await moveToPlantInventoryAPI(inventoryId, updateParams)
            loadBgeOwnedInventoryList()
        } catch (e) {
            throw e
        } finally {
            //
        }
    }

    //CREATE INVENTORY
    const createInventory = async (updateParams) => {
        try {
            await createBgeOwnedInventoryAPI(updateParams)
            loadBgeOwnedInventoryList()
        } catch (e) {
            throw e
        } finally {
            //
        }
    }

    const deleteInventory = async (inventoryId) => {
        try {
            await deleteInventoryAPI(inventoryId)
            loadBgeOwnedInventoryList();
        } catch (e) {
            throw e
        } finally {
            //
        }
    }

    const handleApplyFilter = selectedFilters => {
        setFilterParams(selectedFilters)
    }

    const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams))

    const mContext = {
        bgeOwnedInventoryList,
        pagination,
        loadFilterOptions,
        loadBgeOwnedInventoryList,
        updateInventory,
        bgeOwnedInventoryLoading,
        filterParams,
        createInventory,
        handleApplyFilter,
        resetClientListFilter,
        currentViewInventory,
        filterOptions,
        filtersLoading,
        deleteInventory,
        loadInventory,
        moveToPlantInventory,
        updateShowListSize: size => setPagination({ ...pagination, size }),
        updateShowListPage: page => setPagination({ ...pagination, page })
    }

    return <SelfOwnedInventoryContext.Provider value={mContext}>
        {props.children}
    </SelfOwnedInventoryContext.Provider>
}

export const withSelfOwnedInventoryProvider = (Container, containerProps) => props => <SelfOwnedInventoryProvider>
    <Container {...containerProps} {...props} />
</SelfOwnedInventoryProvider>