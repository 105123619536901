import apiService from "../../services/ApiService";
import {
    CLIENT_MANAGEMENT_ARCHIVE_API,
    CLIENT_MANAGEMENT_CREATE_API,
    CLIENT_MANAGEMENT_DELETE_API,
    CLIENT_MANAGEMENT_LIST_API,
    CLIENT_MANAGEMENT_LIST_FILTER_OPTIONS_API,
    CLIENT_MANAGEMENT_UNARCHIVE_API,
    CLIENT_MANAGEMENT_UPDATE_API,
    CLIENT_MANAGEMENT_VIEW_API,
    EXPORT_CLIENT_API,
    GET_ALL_PVPLANTS
} from '../../constants/endpoints';

export const getAdminClientList = async filterParams => {
    const response = await apiService.get(CLIENT_MANAGEMENT_LIST_API, {
        queryParams: filterParams,
    })
    return response
}

export const getAdminClientListFilterOptions = async filterParams => {
    const response = await apiService.get(CLIENT_MANAGEMENT_LIST_FILTER_OPTIONS_API)
    return response
}

export const createClientAPI = async (params) => {
    const response = await apiService.post(CLIENT_MANAGEMENT_CREATE_API, {
        body: params,
    })
    return response
}
export const getClientAPI = async (clientId) => {
    const response = await apiService.get(CLIENT_MANAGEMENT_VIEW_API, {
        pathParams: { clientId }
    })
    return response
}
export const updateClientAPI = async (clientId, params) => {
    const response = await apiService.put(CLIENT_MANAGEMENT_UPDATE_API, {
        body: params,
        pathParams: { clientId }
    })
    return response
}
export const archiveClientAPI = async (clientId) => {
    const response = await apiService.put(CLIENT_MANAGEMENT_ARCHIVE_API, {
        pathParams: { clientId }
    })
    return response
}
export const unarchiveClientAPI = async (clientId) => {
    const response = await apiService.put(CLIENT_MANAGEMENT_UNARCHIVE_API, {
        pathParams: { clientId }
    })
    return response
}
export const deleteClientAPI = async (clientId) => {
    const response = await apiService.delete(CLIENT_MANAGEMENT_DELETE_API, {
        pathParams: { clientId }
    })
    return response
}
export const exportClientAPI = async (filterParams) => {
    const response = await apiService.get(EXPORT_CLIENT_API, {
        queryParams: filterParams,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf',
        }
    })
    return response
  }
  export const getAdminpvPlantsList = async () => {
    const response = await apiService.get(GET_ALL_PVPLANTS)
    return response
}