/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewManufacturer from "../components/ManufacturerView";
import { ManufacturerContext, withManufacturerProvider } from "../ManufacturerProvider";

const ManufacturerViewContainer = props => {
    const { manufacturerId, setIsOpen, editManufacturer } = props

    const manufacturerContext = useContext(ManufacturerContext)
    const { loadManufacturer } =manufacturerContext

    useEffect(() => {
        if (manufacturerId) loadManufacturer(manufacturerId)
    }, [manufacturerId])

    return (
        <React.Fragment>
            <ViewManufacturer setIsOpen={setIsOpen} editManufacturer={editManufacturer} />
        </React.Fragment>
    );
}

export default withManufacturerProvider(ManufacturerViewContainer);