/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect } from "../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import Head from "../../../layout/head/Head";
import { successToast } from "../../../components/toastify/Toastify";

const ClientPlantForm = (props) => {
  const plantSchema = yup.object({
    name: yup.string().required(),
    size: yup.string().notRequired().default(""),
    onboardedAt: yup.date().nullable().default(null),
    status: yup.string().required(),
    identifier: yup.string().required(),
  });

  const userFormRef = useForm({
    resolver: yupResolver(plantSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = userFormRef;

  const STATUS = [
    { label: "Active", value: "ACTIVE" },
    { label: "In Active", value: "INACTIVE" },
    { label: "Suspended", value: "SUSPENDED" },
  ];

  useEffect(() => {
    if (props.plantId !== "") {
      reset({ ...props.plants[props.plantId] });
    }
  }, [props.plantId]);

  const handleFormSubmit = (formData) => {
    if (props.plantId !== "") {
      props.plants[props.plantId] = formData;
      props.setPlants([...props.plants]);
      successToast({ description: "Successfully Updated" });

      props.toggleModal();
    } else {
      props.plants.push(formData);
      props.setPlants([...props.plants]);
      successToast({ description: "Successfully Created" });

      props.toggleModal();
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderSaveButton = () => {
    return (
      <Button id="client-add-plant" color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        {props.plantId !== "" ? "Update Plant" : "Add Plant"}
      </Button>
    );
  };

  return (
    <div className="overflow-auto h-max-550px">
      <Head title="Brighter App | Plant | Create" />
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>Plant Name
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="client-plant-name-input"
                      type="text"
                      name="name"
                      className="form-control"
                      {...register("name")}
                    />
                  </div>
                  {errors.name && <span className="invalid text-danger">{errors.name?.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="size">
                    Size
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="client-size-input"
                      {...register("size")}
                      type="text"
                      name="size"
                      className="form-control"
                    />
                  </div>
                  {errors.size && <span className="invalid">{errors.size?.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="form-group">
                  <label className="form-label" htmlFor="identifier">
                    <span style={{ color: "indianred" }}>&#42;</span>Acronym
                  </label>
                  <div className="form-control-wrap">
                    <input
                      id="client-acronym-input"
                      {...register("identifier")}
                      type="text"
                      name="identifier"
                      className="form-control"
                    />
                  </div>
                  {errors.identifier && <span className="invalid text-danger">{errors.identifier?.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    On-Boarding Date
                  </label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="onboardedAt"
                      render={({ field }) => {
                        return (
                          <ReactDatePicker
                            id="client-onboardat"
                            ref={field.ref}
                            dateFormat="dd/MM/yyyy"
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control date-picker"
                          />
                        );
                      }}
                    />
                  </div>
                  {errors?.onboardedAt && <span className="invalid">Onboarded Date is required</span>}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="form-group">
                  <label className="form-label" htmlFor="status">
                    <span style={{ color: "indianred" }}>&#42;</span>Status
                  </label>
                  <Controller
                    control={control}
                    name="status"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = STATUS;
                      const selectedValue = options.find((e) => e.value === field.value);
                      return (
                        <RSelect
                          id="client-status-select"
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          placeholder="Select the Status"
                          options={options}
                          onChange={(o) => setValue("status", o.value, { shouldValidate: true })}
                        />
                      );
                    }}
                  />
                  {errors.status && <span className="invalid text-danger">Status is Required</span>}
                </FormGroup>
              </Col>
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ClientPlantForm;
