import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import PreventativeScheduleList from "../components/PreventativeScheduleList";
import { ScheduleContext, withPreventativeScheduleProvider } from "../ScheduleProvider";

const PreventativeScheduleListContainer = (props) => {
  const { loadPreventativeSchedules } = useContext(ScheduleContext);
  const { pagination, filterParams } = useContext(ScheduleContext);

  useEffect(() => {
    loadPreventativeSchedules({ plantId: props.plantId });
  }, [pagination, filterParams]);

  return (
    <React.Fragment>
      {!props.plantId && <Head title="BGE | Preventative Schedule List" />}
      <Content>
        <PreventativeScheduleList plantId={props.plantId} plantStatus={props.plantStatus} isArchived={props.isArchived}/>
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeScheduleProvider(PreventativeScheduleListContainer);
