import React, { useEffect, useContext } from "react";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../components/Component";
import { Row, Col, Spinner } from "reactstrap";
import Head from "../../layout/head/Head";
import { ReactFormGenerator } from "react-form-builder2";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContext.provider";
import { errorToast, successToast } from "../../components/toastify/Toastify";
import { ACCESS_TOKEN } from "../../constants/PreferenceKeys";
import { FORM_SUBMISSION_PAGE } from "../../constants/routes";
import { FormManagementContext, withFormManagementProvider } from "../form/FormManagementProvider";
import useBoolean from "../../hooks/useBoolean";
import JSZip from "jszip";
import { singleUpload } from "../../repositories/FileRepository";

const Form = () => {
  const authContext = useContext(AuthContext);
  const { formData } = authContext;
  const formManagementContext = useContext(FormManagementContext);
  const { multiData, setCompressedFile, singleFileUpload } = formManagementContext;
  const [isFormSubmitted, ifsState] = useBoolean(false);
  const convertToZip = async () => {
    if (multiData.length > 0) {
      const zip = new JSZip();

      multiData.forEach((file, index) => {
        zip.file(file.name, file);
      });

      try {
        const content = await zip.generateAsync({ type: "blob" });
        const zipFile = new File([content], "files.zip", { type: "application/zip" });
        setCompressedFile(zipFile);
        return zipFile;
      } catch (error) {
        errorToast({ description: "Error while downloading file" });
      }
    }
  };
  const loading = authContext.isFetchingForm;
  const history = useHistory();

  const { formId, formToken } = useParams();

  async function onFormSubmit() {
    const formContent = { formId, formToken };
    const res = await authContext.formView(formContent);
    return res;
  }

  useEffect(() => {
    onFormSubmit();
  }, []);

  const handleFormSubmit = async (datas) => {
    ifsState.on();
    try {
      const res = await Promise.all(
        datas.map(async (item) => {
          const zidformData = await convertToZip();
          if (item)
            if (item?.name?.includes("my_input") && zidformData?.name) {
              const data = await singleUpload(zidformData);
              return {
                ...item,
                value: process.env.REACT_APP_BASE_URL + "/api/files/redirect/" + data.files.key,
              };
            }
          if (item?.name?.includes("my_fileupload") && singleFileUpload?.name) {
            const data = await singleUpload(singleFileUpload);
            return {
              ...item,
              value: process.env.REACT_APP_BASE_URL + "/api/files/redirect/" + data.files.key,
            };
          }
          if (item.value instanceof File && item.value?.name) {
            const data = await singleUpload(item.value);
            return {
              ...item,
              value: process.env.REACT_APP_BASE_URL + "/api/files/redirect/" + data.files.key,
            };
          } else {
            return item;
          }
        })
      );

      const params = { data: res, formId, formToken };
      authContext
        .createFormSubmission(params)
        .then((res) => {
          history.replace(
            FORM_SUBMISSION_PAGE.replace(":formId", formId).replace(":formToken", formToken) + "?state=Closed"
          );
          successToast({ description: "Form is Successfully Submitted" });
        })

        .catch((e) => {
          errorToast({ description: `${e.message}` });
        });
    } catch (err) {
      errorToast({ description: `${err.message}` });
    } finally {
      ifsState.off();
    }
  };

  useEffect(() => {
    localStorage.setItem(ACCESS_TOKEN, formToken);
  }, [formToken]);

  const renderFormData = () => {
    return (
      <Block className="nk-block-middle nk-auth-body  wide-md">
        <BlockHead>
          <BlockHeadContent>
            <Row md="12" lg="12">
              <Col md="12" lg="12">
                <BlockTitle tag="h3" page>
                  Form
                </BlockTitle>
              </Col>
              <Col md="9" lg="9" className="mt-1">
                <h4>{formData?.title || "-"}</h4>
              </Col>
            </Row>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <PreviewCard>
            <ReactFormGenerator
              data={formData?.data || []}
              onSubmit={(data) => handleFormSubmit(data)}
              actionName="Set this to change the default submit button text"
              submitButton={
                <button type="submit" disabled={isFormSubmitted} className="btn btn-primary">
                  {isFormSubmitted ? <Spinner style={{ width: "20px", height: "20px" }} /> : "Submit"}
                </button>
              }
              backButton={
                <a href="/" className="btn btn-default btn-cancel btn-big">
                  Back
                </a>
              }
            />
          </PreviewCard>
        </Block>
      </Block>
    );
  };

  const mainContent = () => {
    if (!loading) {
      return renderFormData();
    }
  };

  return (
    <React.Fragment>
      <Head title="Form" />
      {mainContent()}
    </React.Fragment>
  );
};
export default withFormManagementProvider(Form);
