/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ManufacturerForm from "../components/ManufacturerForm";
import { ManufacturerContext, withManufacturerProvider } from "../ManufacturerProvider";

const ManufacturerFormContainer = props => {

    const manufacturerContext = useContext(ManufacturerContext)
    const { currentViewManufacturer, updateManufacturer, loadManufacturer } = manufacturerContext

    useEffect(() => {
        if (props.manufacturerId) loadManufacturer(props.manufacturerId)
    }, [props.manufacturerId])
    
    return (
        <React.Fragment>
            <ManufacturerForm
                manufacturerId={props.manufacturerId}
                currentViewManufacturer={currentViewManufacturer}
                updateManufacturer={updateManufacturer}
                onSuccessfulModal={props.onSuccessfulModal}
            />
        </React.Fragment>
    );
}

export default withManufacturerProvider(ManufacturerFormContainer);