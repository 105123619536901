/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import * as yup from "yup";
import { Block, Button, PreviewCard, RSelect } from "../../../../components/Component";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { phoneCode } from "../../../../constants/country";
import useBoolean from "../../../../hooks/useBoolean";
import Head from "../../../../layout/head/Head";
import { ManufacturerContext } from "../ManufacturerProvider";

const createSchema = yup.object({
  name: yup.string().required(),
  address: yup.string(),
  phoneNumber: yup.string().test("min-max-validation", "Phone number must be between 8 and 15 characters", (value) => {
    if (!value) {
      return true;
    }
    const phoneNumberLength = value.replace(/[^\d]/g, "").length;
    return phoneNumberLength >= 6 && phoneNumberLength <= 16;
  }),
  phoneCode: yup
    .string()
    .default("+44")
    .when("phoneNumber", {
      is: (data) => data,
      then: yup.string().required().default("+44"),
    }),
  email: yup.string().email(),
  weblink: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      { message: "Website is not valid eg(google.com)", excludeEmptyString: true }
    )
    .optional(),
});

const ManufacturerForm = (props) => {
  const { createManufacturer, manufacturerLoading } = useContext(ManufacturerContext);

  const [isEditMode, updateEditMode] = useBoolean(false);

  const manufacturerFormRef = useForm({
    resolver: yupResolver(props.manufacturerId ? createSchema : createSchema),
    mode: "onChange",
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = manufacturerFormRef;

  useEffect(() => {
    reset(props.currentViewManufacturer);
    if (props.currentViewManufacturer) {
      reset({
        ...props.currentViewManufacturer,
        phoneCode: props.currentViewManufacturer.phoneNumber.split(" ")[0],
        phoneNumber: props.currentViewManufacturer.phoneNumber.split(" ")[1],
      });
      updateEditMode.on();
    }
  }, [props.currentViewManufacturer]);
  const handleFormSubmit = (formData) => {
    if (props.manufacturerId) {
      props
        .updateManufacturer(props.currentViewManufacturer.id, {
          ...formData,
          phoneNumber: `${formData.phoneCode} ${formData.phoneNumber}`,
        })
        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while updating Manufacturer" });
        });
    } else {
      createManufacturer({
        ...formData,
        phoneNumber: formData.phoneNumber ? `${formData.phoneCode} ${formData.phoneNumber}` : "",
      })
        .then((res) => {
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while creating Manufacturer" });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="ads-manufacture"
        color="primary"
        size="lg"
        isLoading={manufacturerLoading}
        onClick={manufacturerLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return isDirty ? (
      <Button
        id="save-manufacture"
        color="primary"
        isLoading={manufacturerLoading}
        size="lg"
        onClick={manufacturerLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Save Information
      </Button>
    ) : (
      <Button id="cancel-manufacture" size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
        Cancel
      </Button>
    );
  };
  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head
        title={props.manufacturerId ? "Brighter App | Manufacturer | Edit" : "Brighter App | Manufacturer | Create"}
      />
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    <span style={{ color: "indianred" }}>&#42;</span>Corporate Brand Name
                  </Label>
                  <div className="form-control-wrap">
                    <input id="name-input" {...register("name")} type="text" name="name" className="form-control" />
                  </div>
                  {errors.name && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.name?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="address">
                    Address
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="register-input"
                      {...register("address")}
                      type="text"
                      name="address"
                      className="form-control"
                    />
                  </div>
                  {errors.address && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.address?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup className="form-group">
                  <label className="form-label">Phone Number</label>
                  <div className="form-control-wrap d-flex">
                    <Col xs="4" md="2" lg="3" className="p-0">
                      <Controller
                        control={control}
                        name="phoneCode"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = phoneCode;
                          const selectedValue = options.find((e) => e.value === field.value);

                          return (
                            <RSelect
                              id="phone-select"
                              defaultValue={options[0]}
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("phoneCode", o.value)}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col xs="8" md="10" lg="9" className="p-0">
                      <input
                        id="phoneNumber-input"
                        {...register("phoneNumber")}
                        type="number"
                        name="phoneNumber"
                        className="form-control"
                      />
                    </Col>
                  </div>
                  {errors.phoneNumber && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.phoneNumber?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="email">
                    Email
                  </Label>
                  <div className="form-control-wrap">
                    <input id="email-input" {...register("email")} type="text" name="email" className="form-control" />
                  </div>
                  {errors.email && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.email?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup className="form-group">
                  <label className="form-label">Weblink</label>
                  <div className="form-control-wrap">
                    <input id="text-input" type="text" className="form-control" {...register("weblink")} />
                  </div>
                  {errors.weblink && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.weblink?.message}
                    </span>
                  )}{" "}
                </FormGroup>
              </Col>
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ManufacturerForm;
