import React, { useEffect, useState } from "react";
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  RSelect,
} from "../../../components/Component";
import { FREQUENCY_DROPDOWN } from "../../entity_management/preventative_masters/constants";
import { MONTH_OPTIONS } from "../../maintenance/preventative/constants";

const TaskTable = ({ preventativeTasks, setPreventativeTask, setSelectedTask, selectedTask }) => {
  const onBlurFunc = (key, value, obj) => {
    const index = preventativeTasks.findIndex((p, i) => {
      return p.id === obj.id;
    });
    switch (key) {
      case "title":
      case "frequency":
      case "months":
      case "instructions":
        preventativeTasks[index][key] = value;
        setPreventativeTask(preventativeTasks);
        break;
      default:
        break;
    }
  };
  return (
    <DataTable className="card-stretch mt-2 border overflow-auto">
      <DataTableBody compact>
        <DataTableHead className="text-left">
          <DataTableRow className="d-md-table-cell d-flex">
            <input
              type="checkbox"
              style={{
                backgroundColor: "red",
                marginTop: 5,
              }}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedTask([...preventativeTasks]);
                } else {
                  setSelectedTask([]);
                }
              }}
            />
          </DataTableRow>
          <DataTableRow size="sm">
            <span className="sub-text">Code</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span className="sub-text">Task</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span className="sub-text">Months</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span className="sub-text">Frequency</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span className="sub-text">Site Specific Instructions</span>
          </DataTableRow>
        </DataTableHead>
        {preventativeTasks.length > 0
          ? preventativeTasks.map((item, i) => {
              return (
                <DataTableItem>
                  <DataTableRow className="d-md-table-cell d-flex">
                    <input
                      type="checkbox"
                      key={`${i}-schedule`}
                      id={`${i}-selectedtask`}
                      style={{
                        backgroundColor: "red",
                      }}
                      // className="custom-control-input form-control"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTask((prev) => [...prev, item]);
                        } else {
                          setSelectedTask(selectedTask.filter((task) => task.id !== item.id));
                        }
                      }}
                      checked={selectedTask.findIndex((task) => task.id === item.id) !== -1}
                    />
                  </DataTableRow>
                  <DataTableRow size="sm">
                    <input
                      style={{ minWidth: 130 }}
                      type="text"
                      name="code"
                      id={`${i}-plant-code`}
                      className="form-control "
                      defaultValue={item.code}
                      onBlur={(e) => onBlurFunc("code", e.target.value, item)}
                    />
                  </DataTableRow>
                  <DataTableRow className="d-md-table-cell d-flex">
                    <input
                      style={{ minWidth: 130 }}
                      type="text"
                      name="title"
                      id={`${i}-plant`}
                      className="form-control "
                      defaultValue={item.title}
                      onBlur={(e) => onBlurFunc("title", e.target.value, item)}
                    />
                  </DataTableRow>
                  <DataTableRow className="d-md-table-cell d-flex" style={{ maxWidth: "300px" }}>
                    <RSelect
                      defaultValue={MONTH_OPTIONS.filter((d) => item.months.includes(d.value))}
                      isMulti
                      name="months"
                      id={`${i}-months`}
                      options={MONTH_OPTIONS}
                      onChange={(o) =>
                        onBlurFunc(
                          "months",
                          o.map((c) => c.value),
                          item
                        )
                      }
                      placeholder="Choose months"
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          minWidth: 130,
                        }),
                        control: (provided) => ({
                          ...provided,
                          minWidth: 130,
                        }),
                      }}
                    />
                  </DataTableRow>
                  <DataTableRow className="d-md-table-cell d-flex">
                    <RSelect
                      defaultValue={FREQUENCY_DROPDOWN.filter((d) => item.frequency === d.value)}
                      options={FREQUENCY_DROPDOWN}
                      onChange={(o) => {
                        onBlurFunc("frequency", o.value, item);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minWidth: 110,
                        }),
                      }}
                    />
                  </DataTableRow>
                  <DataTableRow className="d-md-table-cell d-flex">
                    <textarea
                      className="form-control textBox"
                      name={`${i}-instruction`}
                      id={`${i}-instruction`}
                      onBlur={(e) => onBlurFunc("instructions", e.target.value, item)}
                    >
                      {item.instructions}
                    </textarea>
                  </DataTableRow>
                </DataTableItem>
              );
            })
          : null}
      </DataTableBody>
    </DataTable>
  );
};
export { TaskTable };
