import apiService from "../../../services/ApiService";
import {
  ASSET_LIST_API,
  ASSET_CREATE_API,
  ASSET_VIEW_API,
  ASSET_UPDATE_API,
  ASSET_DELETE_API,
  ASSET_ALL_MANUFACTURER_API,
  ASSET_ALL_ASSET_CATEGORY_API,
  EXPORT_ASSET_API,
  GET_PRODUCT_ID_API,
  ASSET_FILTER_LIST_API,
} from "../../../constants/endpoints";

export const getAssetList = async (filterParams) => {
  const response = await apiService.get(ASSET_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};
export const updateAssetAPI = async (assetId, params) => {
  const response = await apiService.put(ASSET_UPDATE_API, {
    pathParams: { assetId },
    body: params,
  });
  return response;
};

export const createAssetAPI = async (params) => {
  const response = await apiService.post(ASSET_CREATE_API, {
    body: params,
  });
  return response;
};
export const getAssetAPI = async (assetId) => {
  const response = await apiService.get(ASSET_VIEW_API, {
    pathParams: { assetId },
  });
  return response;
};

export const deleteAssetAPI = async (assetId) => {
  const response = await apiService.delete(ASSET_DELETE_API, {
    pathParams: { assetId },
  });
  return response;
};

export const getAllManufacturers = async () => {
  const response = await apiService.get(ASSET_ALL_MANUFACTURER_API);
  return response;
};
export const getAllAssetCategories = async () => {
  const response = await apiService.get(ASSET_ALL_ASSET_CATEGORY_API);
  return response;
};

export const exportAsset = async (filterParams) => {
  const response = await apiService.get(EXPORT_ASSET_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};

export const getProductId = async () => {
  const response = await apiService.get(GET_PRODUCT_ID_API);
  return response;
};

export const getAssetFilterListAPI = async () => {
  const response = await apiService.get(ASSET_FILTER_LIST_API);
  return response;
};
