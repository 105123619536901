import { format } from "date-fns";
import React, { useContext } from "react";
import { Col, FormGroup, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  Icon,
  Button,
} from "../../../../components/Component";
import { SelfOwnedInventoryContext } from "../SelfOwnedProvider";
import Attachments from "../../../../components/attachments/Attachment";
import { STOCK } from "../../../../constants/PreferenceKeys";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";

const SelfOwnedView = ({ sm, updateSm, setIsOpen, editInventory, moveSpareInventory }) => {
  const selfOwnedInventoryContext = useContext(SelfOwnedInventoryContext);
  const { filterOptions } = selfOwnedInventoryContext;
  const { currentViewInventory, deleteInventory } = selfOwnedInventoryContext;

  let asset = {};
  let assetCategory = {};
  let manufacturer = {};

  for (let i = 0; i < filterOptions?.asset?.length; i++) {
    asset[filterOptions?.asset[i].value] = filterOptions?.asset[i].label;
  }
  for (let i = 0; i < filterOptions?.assetCategory?.length; i++) {
    assetCategory[filterOptions?.assetCategory[i].value] = filterOptions?.assetCategory[i].label;
  }
  for (let i = 0; i < filterOptions?.manufacturer?.length; i++) {
    manufacturer[filterOptions?.manufacturer[i].value] = filterOptions?.manufacturer[i].label;
  }

  if (!currentViewInventory) return <Spinner />;

  const handleDeleteInventory = async (inventoryId) => {
    deleteInventory(inventoryId)
      .then((res) => {
        successToast({ description: "Inventory is deleted successfully" });
        setIsOpen(false);
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Inventory" });
      });
  };
  const handleDeleteConfirmation = async (inventoryId) => {
    confirmationSweetAlert({
      id: inventoryId,
      handleConfirmation: handleDeleteInventory,
    });
  };
  return (
    <div className="overflow-auto h-max-450px">
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md">
          <BlockBetween>
            <BlockHeadContent>
              <BlockDes>{/* <p>Basic info.</p> */}</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-end mr-2">
              <Button
                color="danger"
                onClick={() => {
                  handleDeleteConfirmation(currentViewInventory.id);
                }}
              >
                <Icon name="na" />
                <span>Delete</span>
              </Button>
              <Button
                color="primary"
                className={"ml-2"}
                onClick={() => {
                  editInventory(currentViewInventory.id);
                }}
              >
                <Icon name="edit" />
                <span>Edit</span>
              </Button>
              <Button
                color="primary"
                className={"ml-md-2 ml-0 mt-2 mt-md-0"}
                onClick={() => {
                  moveSpareInventory(currentViewInventory.id);
                }}
              >
                <Icon name="edit" />
                <span>Move To PV Plants</span>
              </Button>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            {/* <div className="data-head">
                            <h6 className="overline-title">Basics</h6>
                        </div> */}
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Category</span>
                <span className="data-value">{assetCategory[currentViewInventory?.assets?.assetCategoryId]} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Equipment Name</span>
                <span className="data-value">{currentViewInventory?.assets?.model}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Supplier</span>
                <span className="data-value">{manufacturer[currentViewInventory?.assets?.manufacturerId]}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Contractual Quantity</span>
                <span className="data-value">{currentViewInventory?.contractualQuantity}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Quantity</span>
                <span className="data-value">{currentViewInventory?.quantity}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Key Component</span>
                <span className="data-value">{currentViewInventory.isKeyComponent ? "True" : "False"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Details</span>
                <span className="data-value">{currentViewInventory?.description}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            {
              <Col lg="12 pt-2">
                <FormGroup className="form-group">
                  <div className="form-control-wrap">
                    <Attachments
                      module={STOCK}
                      id={currentViewInventory.id}
                      attachments={currentViewInventory.attachment}
                    />
                  </div>
                </FormGroup>
              </Col>
            }
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              CreatedAt: {format(new Date(currentViewInventory?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              UpdatedAt: {format(new Date(currentViewInventory?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
        </Block>
      </Block>
    </div>
  );
};
export default SelfOwnedView;
