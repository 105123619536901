import apiService from "../../../services/ApiService";

import {
  SLA_LIST_API,
  SLA_CREATE_API,
  SLA_VIEW_API,
  SLA_UPDATE_API,
  SLA_DELETE_API,
  EXPORT_SLA_API,
  SLA_FILTER_LIST_API
} from "../../../constants/endpoints";

export const getSlaList = async (filterParams) => {
  const response = await apiService.get(SLA_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};

export const updateSlaAPI = async (slaId, params) => {
  const response = await apiService.put(SLA_UPDATE_API, {
    pathParams: { slaId },

    body: params,
  });

  return response;
};

export const createSlaAPI = async (params) => {
  const response = await apiService.post(SLA_CREATE_API, {
    body: params,
  });

  return response;
};

export const getSlaAPI = async (slaId) => {
  const response = await apiService.get(SLA_VIEW_API, {
    pathParams: { slaId },
  });

  return response;
};

export const deleteSlaAPI = async (slaId) => {
  const response = await apiService.delete(SLA_DELETE_API, {
    pathParams: { slaId },
  });

  return response;
};
export const exportSlaAPI = async (filterParams) => {
  const response = await apiService.get(EXPORT_SLA_API, {
      queryParams: filterParams,
      responseType: 'blob',
      headers: {
          'Content-Type': 'application/pdf',
      }
  })
  return response
}

export const getSlaFilterListAPI = async () => {
  const response = await apiService.get(SLA_FILTER_LIST_API);
  return response;
};