import { format } from "date-fns";
import React, { useContext } from "react";
import { ReactFormGenerator } from "react-form-builder2";
import { Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Button,
  Icon,
  PreviewCard,
} from "../../../components/Component";
import Check from "../../../components/check_roles/check";
import Head from "../../../layout/head/Head";
import { UserManagementContext } from "../../user_management/UserManagementProvider";
import { FormManagementContext } from "../FormManagementProvider";

const ViewForm = ({ editForm }) => {
  const formManagementContext = useContext(FormManagementContext);
  const { currentViewForm } = formManagementContext;
  const { roleList } = useContext(UserManagementContext);

  if (!currentViewForm) return <Spinner />;

  let roleIds = {};

  for (let i = 0; i < roleList?.length; i++) {
    roleIds[roleList[i].value] = roleList[i].label;
  }

  return (
    <div className="overflow-auto h-max-450px">
      <Head title="Brighter App | Form | View" />

      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md" className="d-flex justify-content-end">
          <BlockBetween>
            <Check permission={["manageForm"]}>
              <BlockHeadContent className="align-self-end">
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    editForm(currentViewForm.id);
                  }}
                >
                  <Icon name="edit" />
                  <span>Edit</span>
                </Button>
              </BlockHeadContent>
            </Check>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Title</span>
                <span className="data-value">{currentViewForm?.title || "-"} </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Description</span>
                <span className="data-value text-break">{currentViewForm?.description || "-"} </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Roles</span>
                <span className="data-value">
                  {currentViewForm?.roles
                    ?.map((id, key) => {
                      return roleIds[id];
                    })
                    .join(", ") || "-"}
                </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Status</span>
                <span className="data-value">{currentViewForm?.status || "-"} </span>
              </div>
            </div>
            <div>
              {currentViewForm.data && (
                <PreviewCard>
                  <ReactFormGenerator
                    download_path=""
                    back_action="/"
                    back_name="Back"
                    answer_data={{}}
                    action_name="Save"
                    form_action="/"
                    form_method="POST"
                    read_only={true}
                    hide_actions={true}
                    data={currentViewForm.data}
                  />
                </PreviewCard>
              )}
            </div>

            <div className="m-2 ml-3">
              <small class="text-muted">
                CreatedAt: {format(new Date(currentViewForm.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
              </small>
            </div>
            <div className="m-2 ml-3">
              <small class="text-muted">
                UpdatedAt: {format(new Date(currentViewForm?.updated_at || null), "dd/MM/yyyy HH:mm")}{" "}
              </small>
            </div>
          </div>
        </Block>
      </Block>
    </div>
  );
};
export default ViewForm;
