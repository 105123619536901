import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  getAssetList,
  updateAssetAPI,
  createAssetAPI,
  getAssetAPI,
  deleteAssetAPI,
  getAllManufacturers,
  getAllAssetCategories,
  getProductId,
  getAssetFilterListAPI,
} from "./AssetRepository";

export const AssetContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 50,
};

const initialFilterOptions = {
  manufacutrers: [],
  assetCategories: [],
  models: [],
};

const initialFilterParams = {
  selectedManufacturer: [],
  selectedAssetCategory: [],
  searchText: "",
  selectedModel: [],
  sortingField: "identifier",
  sortingOrder: "ASC",
};

export const AssetProvider = (props) => {
  const [assetList, setAssetList] = React.useState([]);
  const [manufacutrerList, setManufacturerList] = React.useState([]);
  const [assetCategoryList, setAssetCategoryList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [currentViewAsset, setCurrentViewAsset] = React.useState();
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [identifier, setIdentifier] = React.useState();
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));

  //Loaders

  const [assetLoading, assetState] = useBoolean(false);
  const [manufacturerLoading, mlState] = useBoolean(false);
  const [assetCategoryLoading, aclState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);

  const getListFilterParams = () => {
    const queryParams = { ...pagination };
    const { searchText, selectedManufacturer, selectedAssetCategory, selectedModel, sortingField, sortingOrder } =
      filterParams;
    if (searchText) queryParams["assetSearchText"] = searchText;
    if (selectedManufacturer) queryParams["manufacturer"] = selectedManufacturer;
    if (selectedAssetCategory != null) queryParams["assetCategory"] = selectedAssetCategory;
    if (selectedModel) queryParams["model"] = selectedModel;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    return queryParams;
  };

  const loadAssetList = async () => {
    assetState.on();
    try {
      const filterParams = getListFilterParams();
      const response = await getAssetList(filterParams);
      setAssetList(response.assetList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      assetState.off();
    }
  };

  //CREATE ASSET_CATEGORY

  const createAsset = async (createParams) => {
    try {
      assetState.on();

      await createAssetAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      assetState.off();
    }
  };

  //LOAD ALL MANUFACTURERS FOR DROPDOWN
  const loadAllManufacturers = async () => {
    mlState.on();

    try {
      const response = await getAllManufacturers();
      setManufacturerList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      mlState.off();
    }
  };

  //LOAD ALL ASSET CATEGORIES FOR DROPDOWN
  const loadAllAssetCategories = async () => {
    aclState.on();
    try {
      const response = await getAllAssetCategories();
      setAssetCategoryList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      aclState.off();
    }
  };

  //LOAD ASSET
  const loadAsset = async (assetId) => {
    try {
      const response = await getAssetAPI(assetId);
      setCurrentViewAsset(response.asset);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  // UPDATE TASK

  const updateAsset = async (assetId, updateParams) => {
    assetState.on();
    try {
      const response = await updateAssetAPI(assetId, updateParams);
      setCurrentViewAsset(response.asset);
    } catch (e) {
      throw e;
    } finally {
      assetState.off();
    }
  };

  // DELETE TASK

  const deleteAsset = async (assetId) => {
    try {
      await deleteAssetAPI(assetId);
      loadAssetList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  //get productId
  const getIdentifier = async () => {
    assetState.on();
    try {
      const response = await getProductId();
      setIdentifier(response.identifier);
      return response;
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      assetState.off();
    }
  };

  const loadFilterOptions = async () => {
    flState.on();
    try {
      const response = await getAssetFilterListAPI();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const resetAssetListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const checkIsFilterApplied = () => {
    if (
      filterParams.selectedManufacturer != 0 ||
      filterParams.selectedAssetCategory != 0 ||
      filterParams.selectedModel != 0
    ) {
      return true;
    } else return false;
  };

  const isFilterApplied = checkIsFilterApplied();

  const mContext = {
    pagination,
    assetList,
    loadAssetList,
    loadAllManufacturers,
    manufacutrerList,
    manufacturerLoading,
    loadAllAssetCategories,
    assetCategoryList,
    assetCategoryLoading,
    currentViewAsset,
    assetLoading,
    deleteAsset,
    createAsset,
    updateAsset,
    loadAsset,
    handleApplyFilter,
    filterParams,
    identifier,
    loadFilterOptions,
    filtersLoading,
    filterOptions,
    getIdentifier,
    showFilter,
    sfState,
    isFilterApplied,
    resetAssetListFilter,
    checkIsFilterApplied,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <AssetContext.Provider value={mContext}>{props.children}</AssetContext.Provider>;
};

export const withAssetProvider = (Container, containerProps) => (props) =>
  (
    <AssetProvider>
      <Container {...containerProps} {...props} />
    </AssetProvider>
  );
