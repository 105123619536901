/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { FormManagementContext, withFormManagementProvider } from "../FormManagementProvider";
import { UserManagementContext } from "../../user_management/UserManagementProvider";
import FormListCompact from "../components/ListForms";

const FormManagementContainer = (props) => {
  const { loadFormSubmissionList } = useContext(FormManagementContext);
  const { pagination, filterParams, handleApplyFilter } = useContext(FormManagementContext);

  const { loadAllRoles } = useContext(UserManagementContext);

  useEffect(() => {
    loadFormSubmissionList();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadAllRoles();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | Form" />
      <Content>
        <FormListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withFormManagementProvider(FormManagementContainer);
