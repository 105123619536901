import React, { useContext, useEffect, useState } from "react";
import {  FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../../components/Component";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { CORRECTIVE_FILTERS } from "../../../../constants/PreferenceKeys";
import { DnoContext } from "../DnoProvider";
import { sortOption } from "../../../../utils/Utils";

const DnoFilter = (props) => {
  const dnoContext = useContext(DnoContext);
  const {
    filterParams,
    handleApplyFilter,
    showFilter,
    filterOption,
    sfState,
    checkIsFilterApplied,
  } = dnoContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    dnoContext.handleDnoResetFilter();
    localStorage.removeItem(CORRECTIVE_FILTERS);
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    checkIsFilterApplied();
    sfState.off();
    updateTableSm(false);
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleCreatedbyFilterSelection = (selectedOption) => {
    const createdByFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ users: createdByFilter });
  };

  const handleDnoFilterSelection = (selectedOption) => {
    const DnoFilters = selectedOption.map((item, key) => {
      return item.label;
    });
    handleFilterSelection({ dnos: DnoFilters });
  };

  const handleCreatedAtSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ createdAt: "" });
    } else {
      handleFilterSelection({ createdAt: selectedOption });
    }
  };

  const handleUpdatedAtSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ updatedAt: "" });
    } else {
      handleFilterSelection({ updatedAt: selectedOption });
    }
  };

  const createdBySelectedFilter = selectedFilter.users.map((item, key) => {
    return filterOption.users?.[filterOption.users?.findIndex((status) => status.value === item)];
  });

  const DnoSelectedFilter = selectedFilter.dnos.map((item, key) => {
    return filterOption.dnos?.[filterOption.dnos?.findIndex((status) => status.label === item)];
  });

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">DNO</label>
                <RSelect
                  options={sortOption(filterOption.dnos)}
                  placeholder="Any Dno"
                  onChange={handleDnoFilterSelection}
                  isMulti
                  value={DnoSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">created by</label>
                <RSelect
                  options={sortOption(filterOption.users)}
                  placeholder="Created By"
                  onChange={handleCreatedbyFilterSelection}
                  isMulti
                  value={createdBySelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size={6}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Created At</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.createdAt || null}
                  onChange={(date) => handleCreatedAtSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size={6}>
              <FormGroup>
                <label className="overline-title overline-title-alt">updated at</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.updatedAt || null}
                  onChange={(date) => {
                    handleUpdatedAtSelection(date);
                  }}
                />
              </FormGroup>
            </Col>

            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default DnoFilter;
