import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import ManufacturerListCompact from "../components/ManufacturerList";
import { ManufacturerContext, withManufacturerProvider } from "../ManufacturerProvider";

const ManufacturerContainer = (props) => {
  const { loadManufacturerList } = useContext(ManufacturerContext);
  const { pagination, filterParams, loadFilterOptions } = useContext(ManufacturerContext);

  useEffect(() => {
    loadManufacturerList();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | Manufacturer" />
      <Content>
        <ManufacturerListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withManufacturerProvider(ManufacturerContainer);
