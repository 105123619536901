/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewHealthAndSafety from "../components/HealthAndSafetyView";
import { HealthAndSafetyContext, withHealthAndSafetyProvider } from "../HealthAndSafetyProvider";

const HealthAndSafetyViewContainer = (props) => {
  const { healthAndSafetyId, setIsOpen, editHealthAndSafety } = props;
  const healthAndSafetyContext = useContext(HealthAndSafetyContext);
  const { loadHealthAndSafety } = healthAndSafetyContext;
  
  useEffect(() => {
    if (healthAndSafetyId) loadHealthAndSafety(healthAndSafetyId);
  }, [healthAndSafetyId]);

  return (
    <React.Fragment>
      <ViewHealthAndSafety setIsOpen={setIsOpen} editHealthAndSafety={editHealthAndSafety} />
    </React.Fragment>
  );
};

export default withHealthAndSafetyProvider(HealthAndSafetyViewContainer);
