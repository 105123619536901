/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewSla from "../components/SlaView";
import { SlaContext, withSlaProvider } from "../SlaProvider";

const SlaViewContainer = (props) => {
  const { slaId, setIsOpen, editSla } = props;

  const slaContext = useContext(SlaContext);
  const { loadSla } = slaContext;

  useEffect(() => {
    if (slaId) loadSla(slaId);
  }, [slaId]);

  return (
    <React.Fragment>
      <ViewSla setIsOpen={setIsOpen} editSla={editSla} />
    </React.Fragment>
  );
};

export default withSlaProvider(SlaViewContainer);
