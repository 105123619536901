import React, { useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../../components/Component";
import { HealthAndSafetyContext } from "../HealthAndSafetyProvider";
import { sortOption } from "../../../../utils/Utils";

const HealthAndSafetyListFilter = () => {
  const healthAndSafetyContext = React.useContext(HealthAndSafetyContext);
  const { filterOptions } = healthAndSafetyContext;
  const { filterParams, handleApplyFilter, checkIsFilterApplied, sfState, showFilter } = healthAndSafetyContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    healthAndSafetyContext.resetHealthAndSafetyListFilter();
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    updateTableSm(false);
    checkIsFilterApplied();
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleHealthAndSafetyFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedMedicalCenter: selectedOption.value });
  };

  const selectedHealthAndSafetyOption =
    filterOptions.medicalCenters?.find((o) => o.value === selectedFilter.selectedMedicalCenter) || null;

  const handleAddressFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedAddress: selectedOption.value });
  };

  const selectedAddressOption = filterOptions.address?.find((o) => o.value === selectedFilter.selectedAddress) || null;

  const handlePhoneNumberFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedPhoneNumber: selectedOption.value });
  };

  const selectedPhoneNumberOption =
    filterOptions.phoneNumbers?.find((o) => o.value === selectedFilter.selectedPhoneNumber) || null;
  return (
    <div className="vh-100  ">
      <div className="dropdown-head">
        <span className="sub-title dropdown-title fs-16px">Filter Options</span>
        <div onClick={sfState.off}>
          <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
        </div>
      </div>
      <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Medical Center</label>
                <RSelect
                  options={sortOption(filterOptions.medicalCenters)}
                  placeholder="Any Medical Center"
                  onChange={handleHealthAndSafetyFilterSelection}
                  value={selectedHealthAndSafetyOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Address</label>
                <RSelect
                  options={sortOption(filterOptions.address)}
                  placeholder="Any Address"
                  onChange={handleAddressFilterSelection}
                  value={selectedAddressOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">MPhone Number</label>
                <RSelect
                  options={sortOption(filterOptions.phoneNumbers)}
                  placeholder="Any PhoneNumber"
                  onChange={handlePhoneNumberFilterSelection}
                  value={selectedPhoneNumberOption}
                />
              </FormGroup>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                {" "}
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </UncontrolledDropdown>
    </div>
  );
};

export default HealthAndSafetyListFilter;
