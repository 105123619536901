import { format } from "date-fns";
import download from "js-file-download";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../../components/Component";
import useBoolean from "../../../../hooks/useBoolean";
import { Link, useHistory } from "react-router-dom";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { CORRECTIVE_FILTERS } from "../../../../constants/PreferenceKeys";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import ModalViewer from "../../ModalViewer";
import { CorrectiveContext } from "../CorrectiveProvider";
import { exportParticularTask } from "../CorrectiveRepository";
import CorrectiveMaintenanceFormContainer from "../containers/CorrectiveMaintenanceFormContainer";
import CorrectiveMaintenanceViewContainer from "../containers/CorrectiveMaintenanceViewContianer";
import CorrectiveListFilter from "./CorrectiveListFilter";
import ExportForm, { TASK_FIELDS } from "./ExportForm";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { statusColorTheme } from "../../../../constants/constants";
import { createColumnHelper } from "@tanstack/react-table";
import { findUpper, openImageInNewTab } from "../../../../utils/Utils";
import { updateViewSettings } from "../../../../repositories/ViewRepository";
import { createPortal } from "react-dom";
import TanstackTable from "../../../../components/tableV1/Table";
import SortableColumns from "../../../../components/tableV1/SortableColumns";
import { taskCategory } from "../../../../constants/constants";

const columnHelper = createColumnHelper();

const CorrectiveListCompact = (props) => {
  // REFS

  const focusSearchRef = React.useRef();

  const location = useLocation().search;

  const TaskId = JSON.parse(new URLSearchParams(location).get("id"));

  const correctiveContext = useContext(CorrectiveContext);
  const { filterOptions, userList } = useContext(MaintenanceContext);
  const { correctiveList, pagination, unarchiveTask, archiveTask, tasksColumnSettings,
    setTasksColumnSettings, getTableSettings, correctivetasks } = correctiveContext;
  const {
    filterParams,
    handleApplyFilter,
    loadCorrectiveList,
    deleteTask,
    correctiveLoading,
    isFilterApplied,
    sfState,
    showFilter,
    setLoadingTasks,
    loadTasks
  } = correctiveContext;

  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [task, setTask] = useState("");
  const [view, setView] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);
  const [isColumnArrangeModalOpen, icamState] = useBoolean(false);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [isAttachmentModalOpen, iamoState] = useBoolean(false);

  const renderTableValue = (tableValue) => {
    if (!tableValue) {
      return <div>-</div>;
    }
    return tableValue;
  };
  function tableColumn(columnSetting) {
    switch (columnSetting.field_key) {
      case "title":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => "Ticket Name",
          cell: (info) => {
            return (
              <div style={{ display: "flex", gap: "10px", cursor: "pointer", }}
                onClick={() => {
                  viewTask(info.row.original.id);

                }}>
                <div className="user-card">
                  <UserAvatar theme={"primary"} className="xs" text={findUpper(info.row.original?.title)} />
                  <div className="user-name"></div>
                  <span
                    data-toggle="tooltip"
                    title={info.row.original?.title}
                    style={{
                      width: info.row.original?.title?.length > 50 ? "350px" : "",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      color: "#364a63"
                    }}
                    className="tb-lead"
                  >
                    {info.row.original?.title || "-"}
                  </span>
                </div>

              </div>
            );
          },
        });
      case "status":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.status) {
              return (
                <>
                  <Badge className="text-capitalize" style={statusColorTheme[info.row.original?.status]} pill>
                    <span>{STATUSES[info.row.original?.status] || "-"}</span>
                  </Badge>
                </>
              )

            }
            else return "-"
          }

        });
      case "identifier":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "startedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.startedAt) {
              return `${format(new Date(info.row.original?.startedAt || null), "dd/MM/yyyy")}`
            }
            else return "-"
          }
        });

      case "resolvedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.resolvedAt) {
              return `${format(new Date(info.row.original?.resolvedAt || null), "dd/MM/yyyy")}`
            }
            else return "-"
          }
        });
      case "plantId":
        return columnHelper.accessor("plants", {
          id: "plantId",
          enableResizing: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.row.original.plants?.name),
        });
      case "slaId":
        return columnHelper.accessor("sla", {
          id: "slaId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.row.original.sla?.name),
        });
      case "isArchived":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? "Archived" : "UnArchived"}</p>,
        });
      case "priority":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "labourHours":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "description":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p dangerouslySetInnerHTML={{ __html: info.getValue() ? info.getValue() : "-" }} />,
        });
      case "comment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p dangerouslySetInnerHTML={{ __html: info.getValue() ? info.getValue() : "-" }} />,
        });

      case "category":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.category) {
              return `${taskCategory(info.row.original.category)}`
            }
          }

        });
      case "assetCategoryId":
        return columnHelper.accessor("assetCategory", {
          enableResizing: false,
          id: "assetCategoryId",
          header: () => "Asset Category",
          cell: (info) => renderTableValue(info.row.original?.assetCategory?.name),
        });
      case "teamLeaderId":
        return columnHelper.accessor("users", {
          id: "teamLeaderId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.users && Object.keys(info.row.original?.users).length) {
              return `${info.row.original?.users?.firstName} ${info.row.original?.users?.lastName}`;
            }
            return "-";
          },
        });
      case "createdById":
        return columnHelper.accessor("createdBy", {
          id: "createdById",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.createdBy) {
              return (
                <p>
                  {info.row.original?.createdBy?.firstName}
                  {info.row.original?.createdBy?.lastName}
                </p>
              );
            }
            return "-";
          },
        });
      case "parentId":
        return columnHelper.accessor("parentId", {
          id: "parentId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.parentId) {
              const parentId = info.row.original.parentId
              if (correctivetasks.length) {
                const parentTask = correctivetasks.find((task) => task.value === parentId)
                return parentTask.label
              }
              else return "-"
            }
            return "-";
          },
        });
      case "fieldEngineerIds":
        return columnHelper.accessor("fieldEngineerIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.fieldEngineerIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.fieldEngineerIds.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {user.map((u, i) => {
                  return (
                    <div key={`field-engineer-${i}`}>
                      {u.label}
                      {i !== user.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "assignedToIds":
        return columnHelper.accessor("assignedToIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          enableResizing: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.assignedToIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.assignedToIds.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {user.map((u, i) => {
                  return (
                    <div key={`field-engineer-${i}`}>
                      {u.label}
                      {i !== user.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "attachment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.attachment?.length) {
              return <p>No Attachments</p>;
            }
            return (
              <div style={{ display: "flex" }}>
                {info.row.original?.attachment?.length > 2 ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {info.row.original?.attachment?.slice(0, 2).map((attachment, i) => {
                      return (
                        <div
                          onClick={() => openImageInNewTab(attachment.files.key)}
                          key={`attachment-${i}`}
                          style={{ color: "#0000EE", cursor: "pointer" }}
                        >
                          {attachment.files.name}
                          {i !== info.row.original?.attachment?.length - 1 && <span>,&nbsp;</span>}
                        </div>
                      );
                    })}
                    <span
                      onClick={() => {
                        setSelectedAttachment({
                          taskName: info.row.original?.title,
                          attachments: info.row.original?.attachment.slice(2),
                        });
                        iamoState.on();
                      }}
                      style={{ color: "#fff", cursor: "pointer" }}
                      class="badge rounded-pill badge-xs bg-primary"
                    >
                      +{info.row.original?.attachment?.length - 2}
                    </span>
                  </div>
                ) : (
                  info.row.original?.attachment?.map((attachment, i) => {
                    return (
                      <div
                        onClick={() => openImageInNewTab(attachment.files.key)}
                        key={`attachment-${i}`}
                        style={{ color: "#0000EE", cursor: "pointer" }}
                      >
                        {attachment.files.name}
                        {i !== info.row.original?.attachment?.length - 1 && <span>,&nbsp;</span>}
                      </div>
                    );
                  })
                )}
              </div>
            );
          },
        });
      default:
        return columnHelper.accessor(columnSetting.field_key, {
          id: "id",
          enableSorting: false,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => {
            return <p>unknown column</p>;
          },
        });
    }
  }

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
    loadCorrectiveList({
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, taskText: onSearchText },
    });
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
    setLoadingTasks.on()
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  let engineers = {};
  const STATUSES = {
    OPEN: "Open",
    IN_PROGRESS: "In Progress",
    READY_FOR_APPROVAL: "Ready for Approval",
    COMPLETED: "Completed",
  };
  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };
  const fetchCorrectiveTasksData = async () => {
    const settings = await getTableSettings({
      entityType: "CORRECTIVE_MAINTENANCE",
    });
    if (settings) {
      await loadCorrectiveList(settings);
    }
  }
  useEffect(() => {
    if (loadTasks) {
      fetchCorrectiveTasksData()
    }
    setLoadingTasks.off()
  }, [loadTasks])

  useEffect(() => {
    const filters = localStorage.getItem(CORRECTIVE_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
        startDate: parseFilters.startDate ? new Date(parseFilters.startDate) : "",
        resolvedDate: parseFilters.resolvedDate ? new Date(parseFilters.resolvedDate) : "",
      });
    }
  }, []);

  async function handleUpdateViewSettings(payload) {
    return await updateViewSettings(payload);
  }

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
    const updatedSettings = {
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, sortingField: field, sortingOrder: sortOrder },
    };
    handleUpdateViewSettings(updatedSettings);
    loadCorrectiveList(updatedSettings);
    setTasksColumnSettings(updatedSettings);
  };
  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => { }, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleArchive = async (taskId) => {
    archiveTask(taskId)
      .then((res) => {
        successToast({ description: " Corrective Task Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Corrective Task" });
      });
  };
  const handleUnArchive = async (taskId) => {
    unarchiveTask(taskId)
      .then((res) => {
        successToast({ description: "Corrective Task UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Corrective Task" });
      });
  };

  const handleDeleteCorrective = async (taskId) => {
    deleteTask(taskId)
      .then((res) => {
        successToast({ description: "Corrective task is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Corrective task" });
      });
  };
  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: handleDeleteCorrective,
    });
  };
  const exportParticularTaskFuntion = async (taskId) => {
    try {
      setExportLoading(true);
      const response = await exportParticularTask({ taskId, category: "corrective" });
      download(response, `task.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }

  const viewTask = (taskId) => {
    setTask(taskId);
    setView(VIEW.VIEW);
    setIsOpen(true);
  };

  useMemo(() => {
    if (TaskId) {
      viewTask(TaskId);
    }
  }, []);

  const editTask = (taskId) => {
    setTask(taskId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createTask = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const onSuccessfulModal = () => {
    toggleModal();
    fetchCorrectiveTasksData()
  };
  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <CorrectiveMaintenanceViewContainer mode={VIEW.VIEW} taskId={task} setIsOpen={setIsOpen} editTask={editTask} />
      );
    } else if (view === VIEW.CREATE) {
      return (
        <CorrectiveMaintenanceFormContainer
          mode={VIEW.CREATE}
          onSuccessfulModal={onSuccessfulModal}
          viewTask={viewTask}
        />
      );
    } else if (view === VIEW.EDIT) {
      return (
        <CorrectiveMaintenanceFormContainer
          mode={VIEW.EDIT}
          taskId={task}
          onSuccessfulModal={onSuccessfulModal}
          viewTask={viewTask}
        />
      );
    }
  };
  const getExportComponent = () => {
    if (view === VIEW.CREATE) {
      return (
        <ExportForm
          onSuccessfulModal={onSuccessfulModal}
          filterParams={filterParams}
          setIsExportModalOpen={setIsExportModalOpen}
        />
      );
    }
  };
  const createExport = () => {
    setView(VIEW.CREATE);
    setIsExportModalOpen(!isExpotModalOpen);
    return <>Edit</>;
  };
  const assignedEngineerData = React.useMemo(() => {
    if (filterParams && filterParams.selectedAssignedEngineer.length) {
      const engineersData = filterParams.selectedAssignedEngineer.map((item) => {
        return filterOptions.assignedEngineer?.[
          filterOptions.assignedEngineer?.findIndex((status) => status.value === item)
        ];
      });
      return engineersData
    }
  }, [filterParams, correctiveList])

  const plantSelectedData = React.useMemo(() => {
    if (filterParams) {
      const plantsData = filterParams.selectedPlant && filterParams.selectedPlant.map((item) => {
        return filterOptions.plants?.[filterOptions.plants?.findIndex((status) => status.value === item)];
      })
      return plantsData
    }
  }, [filterParams, correctiveList]
  )
  const selectedSLA = React.useMemo(() => {
    if (filterParams) {
      return filterOptions.slas?.find((o) => o.value === filterParams.selectedSLA);
    }
  }, [filterParams, correctiveList]);

  const columns = React.useMemo(() => {
    if (tasksColumnSettings?.fields?.length) {
      return tasksColumnSettings.fields
        .filter((cs) => cs.isChecked)
        .map((columnSetting) => {
          return tableColumn(columnSetting);
        });
    }

    return [];
  }, [correctiveList.items]);

  const renderAction = React.useMemo(() => {
    return [
      columnHelper.accessor("id", {
        header: "",
        size: 40,
        enableSorting: false,
        enableResizing: false,
        cell: (info) => {
          const item = info?.row.original;
          return (
            <ul className="nk-tb-actions gx-1">
              <UncontrolledDropdown>
                <DropdownToggle id="menu-btn-corrective" tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                {createPortal(
                  <DropdownMenu right>
                    <ul className="link-list-opt no-bdr">
                      <React.Fragment>
                        <Check permission={["manageTickets"]}>
                          {(item?.plants && item.plants.status === "ACTIVE") &&
                            <>
                              <li /* className="nk-tb-action-hidden" */ onClick={() => editTask(item.id)}>
                                <DropdownItem
                                  id="edit-corrective-btn"
                                  tag="a"
                                  href="#edit"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="edit-alt-fill"></Icon>
                                  <span>Edit Task</span>
                                </DropdownItem>
                              </li>
                              <li className="divider"></li>
                            </>
                          }
                          {item.isArchived ? (
                            <React.Fragment>
                              <li onClick={() => handleUnArchive(item.id)}>
                                <DropdownItem
                                  id="unarchive-corrective"
                                  tag="a"
                                  href="#unarchive"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="unarchive"></Icon>
                                  <span>UnArchive Task</span>
                                </DropdownItem>
                              </li>
                              <li className="divider"></li>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <li onClick={() => handleArchive(item.id)}>
                                <DropdownItem
                                  id="archive-corrective"
                                  tag="a"
                                  href="#archive"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="archive"></Icon>
                                  <span>Archive Task</span>
                                </DropdownItem>
                              </li>
                            </React.Fragment>
                          )}
                        </Check>
                        <Check permission={["manageTickets"]}>
                          {item.status !== "SUSPENDED" && (
                            <>
                              <li className="divider"></li>
                              <li onClick={() => handleDeleteConfirmation(item.id)}>
                                <DropdownItem
                                  id="delete-corrective"
                                  tag="a"
                                  href="#delete"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="trash"></Icon>
                                  <span>Delete Task</span>
                                </DropdownItem>
                              </li>
                            </>
                          )}
                        </Check>
                        <Check permission={["getTickets", "manageTickets"]}>
                          <li className="divider"></li>
                          <li isLoading={exportLoading} onClick={() => exportParticularTaskFuntion(item.id)}>
                            <DropdownItem
                              id="export-corrective"
                              tag="a"
                              href="#export"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="download"></Icon>
                              <span>Export</span>
                            </DropdownItem>
                          </li>
                        </Check>
                      </React.Fragment>
                    </ul>
                  </DropdownMenu>,
                  document.body
                )}
              </UncontrolledDropdown>

            </ul>
          );
        },
      }),
    ];
  }, [correctiveList.items]);

  const renderTable = React.useMemo(() => {
    return (
      <TanstackTable
        dataSource={correctiveList.items ?? []}
        _column={[...columns, ...renderAction]}
        columnSettings={tasksColumnSettings}
        onColumWidthChanged={(resizedColumn) => {
          const _tasksColumnSettings = [...tasksColumnSettings.fields].map((ts) => {
            if (ts.field_key === resizedColumn.field_key) {
              return {
                ...ts,
                width: resizedColumn.width,
              };
            } else {
              return ts;
            }
          });
          handleUpdateViewSettings({
            entityType: "CORRECTIVE_MAINTENANCE",
            fields: _tasksColumnSettings,
          });
          setTasksColumnSettings((prev) => ({ ...prev, fields: _tasksColumnSettings }));
        }}
        handleSorting={(sortingParams) => {
          handleNameSort(sortingParams.sortingOrder, sortingParams.sortingField, sortingParams.table);
        }}
      />
    );
  }, [columns, correctiveList.items, tasksColumnSettings]);

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Corrective Maintenance Tickets
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {correctiveList?.pagination?.totalCount || 0} corrective maintenance tickets.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {filterParams.selectedTaskStatus && (
                <FilteredByList
                  data={filterParams.selectedTaskStatus}
                  title={"Status"}
                  isNotMap={true}
                  isStr={true}
                />
              )}
              {plantSelectedData ? (
                <FilteredByList data={plantSelectedData} title={"Plant"} isMap={true} isStr={false} />
              ) : null}
              {assignedEngineerData && (
                <FilteredByList
                  data={assignedEngineerData}
                  title={"Assigned Engineer"}
                  isMap={true}
                  isStr={false}
                />
              )}
              {selectedSLA && (
                <FilteredByList
                  data={selectedSLA?.label}
                  title={"SLA"}
                  isNotMap={true}
                  isStr={true}
                />
              )}
              {filterParams.searchText && (
                <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
              )}
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getTickets", "manageTickets"]}>
                    <li>
                      <Button
                        disabled={correctiveList?.pagination?.totalCount === 0}
                        className={`btn btn-white btn-outline-light`}
                        id="export-corrective"
                        type="export"
                        isLoading={exportLoading}
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </Check>
                  <Check permission={["manageTickets"]}>
                    <li className="nk-block-tools-opt">
                      <Button id="add-task-btn" color="primary" className="btn-icon" onClick={createTask}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </Check>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="search-task-btn"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <Sidebar toggleState={showFilter}>
                      <div>
                        <CorrectiveListFilter filterOptions={filterOptions} isPlantName={false} />
                      </div>
                    </Sidebar>
                    <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                    {correctiveList?.pagination?.totalCount > 0 &&
                      <div id="task-filter" tag="a" className="btn btn-icon " onClick={() => icamState.on()}>
                        <Icon name="view-list-fill"></Icon>
                      </div>}
                    <ModalViewer
                      title={`${selectedAttachment.taskName}'s Attachments`}
                      isOpen={isAttachmentModalOpen}
                      size="md"
                      toggleModal={iamoState.toggle}
                      component={
                        Object.keys(selectedAttachment).length ? (
                          <div>
                            {selectedAttachment?.attachments.map((attachment) => {
                              return (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <em class="icon ni ni-dot"></em>
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => openImageInNewTab(attachment.files.key)}
                                  >
                                   <div style={{ color: "#0000EE", cursor: "pointer"}} href="">{attachment.files.name}</div> 
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    />
                    <ModalViewer
                      title="Corrective Task Columns"
                      isOpen={isColumnArrangeModalOpen}
                      size="md"
                      toggleModal={icamState.toggle}
                      component={
                        <SortableColumns
                          entityType={"CORRECTIVE_MAINTENANCE"}
                          fields={TASK_FIELDS}
                          updateViewSettings={async (modifiedSettings) => {
                            const updatedSettings = await handleUpdateViewSettings({
                              ...tasksColumnSettings,
                              fields: modifiedSettings,
                            });
                            setTasksColumnSettings((prev) => ({ ...prev, fields: updatedSettings.fields }));
                            await loadCorrectiveList(updatedSettings);
                          }}
                          closeModal={icamState.off}
                          visibleColumns={tasksColumnSettings.fields}
                        />
                      }
                    />
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    id="search-input-task"
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by Ticket Name, status and Plant Name.enter to search"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {correctiveLoading || correctiveContext.isLoadingFilters ? <Skeleton count={10} className="w-100" /> :
            (correctiveList?.pagination?.totalCount > 0) && renderTable}

          <div className="card-inner">
            {correctiveList.pagination && correctiveList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={correctiveList.pagination.totalCount}
                paginate={(page) => {
                  correctiveContext.loadCorrectiveList({
                    ...tasksColumnSettings,
                    filters: { ...tasksColumnSettings.filters, page },
                  });
                  correctiveContext.updateShowListPage(page);
                }}
                currentPage={correctiveList.pagination.currentPage}
                paginatePage={(size) => {
                  correctiveContext.updateShowListSize(size);
                  const updatedSetting = {
                    ...tasksColumnSettings,
                    filters: { ...tasksColumnSettings.filters, size },
                  };
                  handleUpdateViewSettings(updatedSetting);
                  setTasksColumnSettings(updatedSetting);
                  loadCorrectiveList(updatedSetting);
                }}
              />
            ) : (
              !correctiveLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="Corrective Maintainance Ticket"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Corrective Maintenance - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default CorrectiveListCompact;