import apiService from "../../../services/ApiService";
import {
  DNO_LIST_API,
  DNO_CREATE_API,
  DNO_VIEW_API,
  DNO_UPDATE_API,
  DNO_DELETE_API,
  EXPORT_DNO_API,
  DNO_FILTER_OPIONS,
} from "../../../constants/endpoints";

export const getDnoList = async (filterParams) => {
  const response = await apiService.get(DNO_LIST_API, {
    queryParams: filterParams,
  });

  return response;
};

export const getDnoOptions = async () => {
  const response = await apiService.get(DNO_FILTER_OPIONS);
  return response;
};

export const updateDnoAPI = async (dnoId, params) => {
  const response = await apiService.put(DNO_UPDATE_API, {
    pathParams: { dnoId },

    body: params,
  });

  return response;
};

export const createDnoAPI = async (params) => {
  const response = await apiService.post(DNO_CREATE_API, {
    body: params,
  });

  return response;
};

export const getDnoAPI = async (dnoId) => {
  const response = await apiService.get(DNO_VIEW_API, {
    pathParams: { dnoId },
  });
  return response;
};

export const deleteDnoAPI = async (dnoId) => {
  const response = await apiService.delete(DNO_DELETE_API, {
    pathParams: { dnoId },
  });

  return response;
};
export const exportDno = async (filterParams) => {
  const response = await apiService.get(EXPORT_DNO_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
