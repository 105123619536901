/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../hooks/useBoolean";
import { HealthAndSafetyContext } from "../HealthAndSafetyProvider";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { phoneCode } from "../../../../constants/country";
import { PvPlantManagementContext } from "../../../pv_plant_management/PvPlantManagementProvider";
import Head from "../../../../layout/head/Head";

const createSchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(/^(\s*\S.*)/g, "name should be atleast one character"),
  phoneNumber: yup
    .number()
    .min(999999, "Mobile Number must be greater than 6")
    .max(9999999999999999, "Mobile Number must be less than 16")
    .required()
    .typeError("Please enter a valid number"),
  phoneCode: yup.string().notRequired().default(`+44`),
  address: yup
    .string()
    .required()
    .matches(/^(\s*\S.*)/g, "address should be atleast one character"),
});

const HealthAndSafetyForm = (props) => {
  const { createHealthAndSafety } = useContext(HealthAndSafetyContext);
  const [isEditMode, updateEditMode] = useBoolean(false);

  const { loadAllHealthAndSafeties } = useContext(PvPlantManagementContext);
  const healthAndSafetyFormRef = useForm({
    resolver: yupResolver(props.healthAndSafetyId ? createSchema : createSchema),
  });

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = healthAndSafetyFormRef;

  useEffect(() => {
    reset(props.currentViewHealthAndSafety);

    if (props.currentViewHealthAndSafety) {
      reset({
        ...props.currentViewHealthAndSafety,
        phoneCode: props.currentViewHealthAndSafety.phoneNumber.split(" ")[0],
        phoneNumber: props.currentViewHealthAndSafety.phoneNumber.split(" ")[1],
      });
      updateEditMode.on();
    }
  }, [props.currentViewHealthAndSafety]);

  const handleFormSubmit = (formData) => {
    if (props.healthAndSafetyId) {
      props
        .updateHealthAndSafety(props.currentViewHealthAndSafety.id, {
          ...formData,
          phoneNumber: `${formData.phoneCode} ${formData.phoneNumber}`,
        })
        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while updating Home and Safety" });
        });
    } else {
      createHealthAndSafety({
        ...formData,
        phoneNumber: `${formData.phoneCode} ${formData.phoneNumber}`,
      })
        .then((res) => {
          props.onHealthAndSafetySubmit &&
            props.onHealthAndSafetySubmit({ label: res.medicalCenter.name, value: res.medicalCenter.id });
          loadAllHealthAndSafeties();
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while creating Home and Safety" });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,

    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button id="add-medical" color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return isDirty ? (
      <Button id="save-medical" color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Save Information
      </Button>
    ) : (
      <Button id="cancel-medical" size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
        Cancel
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head
        title={
          props.healthAndSafetyId ? "Brighter App | Medical Centre | Edit" : "Brighter App | Medical Centre | Create"
        }
      />
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>Hospital
                  </Label>

                  <div className="form-control-wrap">
                    <input id="name-input" {...register("name")} type="text" name="name" className="form-control" />
                  </div>
                  {errors.name && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.name?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup className="form-group">
                  <label className="form-label">
                    <span style={{ color: "indianred" }}>&#42;</span>Phone Number
                  </label>
                  <div className="form-control-wrap d-flex">
                    <Col xs="4" md="2" lg="3" className="p-0">
                      <Controller
                        control={control}
                        name="phoneCode"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = phoneCode;
                          const selectedValue = options.find((e) => e.value === field.value);

                          return (
                            <RSelect
                              id="phonecode-select"
                              defaultValue={options[0]}
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("phoneCode", o.value)}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col xs="8" md="10" lg="9" className="p-0">
                      <input
                        id="phoneNumber-input"
                        {...register("phoneNumber")}
                        type="text"
                        name="phoneNumber"
                        className="form-control"
                      />
                    </Col>
                  </div>
                  {errors.phoneNumber && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.phoneNumber?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="address">
                    <span style={{ color: "indianred" }}>&#42;</span>Address
                  </Label>

                  <div className="form-control-wrap">
                    <input
                      id="address-input"
                      {...register("address")}
                      type="text"
                      name="address"
                      className="form-control"
                    />
                  </div>
                  {errors.address && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.address?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default HealthAndSafetyForm;
