import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import ModalViewer from "../../ModalViewer";
import { AssetContext } from "../AssetProvider";
import AssetFormContainer from "../containers/AssetFormContainer";
import AssetViewContainer from "../containers/AssetViewContainer";
import AssetListFilter from "./AssetListFilter";
import ExportForm from "./ExportForm";

const AssetListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const assetContext = useContext(AssetContext);
  const { assetList, pagination } = assetContext;
  const {
    loadAssetList,
    deleteAsset,
    assetLoading,
    filterParams,
    handleApplyFilter,
    isFilterApplied,
    filterOptions,
    showFilter,
    sfState,
  } = assetContext;
  const [sm, updateSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [asset, setAsset] = useState("");
  const [view, setView] = useState("");
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleDeleteAsset = async (assetId) => {
    deleteAsset(assetId)
      .then((res) => {
        successToast({ description: "Asset is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Asset" });
      });
  };

  const handleDeleteConfirmation = async (assetId) => {
    confirmationSweetAlert({
      id: assetId,
      handleConfirmation: handleDeleteAsset,
    });
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const currentItems = assetList?.items || [];
  const viewAsset = (assetId) => {
    setAsset(assetId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editAsset = (assetId) => {
    setAsset(assetId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createAsset = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const onSuccessfulModal = () => {
    toggleModal();
    loadAssetList();
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };

  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return <AssetViewContainer assetId={asset} setIsOpen={setIsOpen} editAsset={editAsset} />;
    } else if (view === VIEW.CREATE) {
      return <AssetFormContainer onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.EDIT) {
      return <AssetFormContainer assetId={asset} onSuccessfulModal={onSuccessfulModal} />;
    }
  };

  const selectedModel = filterOptions.models?.find((model) => model.value === filterParams.selectedModel);
  const selectedManufacturer = filterOptions.manufacturers?.find(
    (manufacturers) => manufacturers.value === filterParams.selectedManufacturer
  );
  const selectedAssetCategory = filterOptions.assetCategories?.find(
    (assetCategories) => assetCategories.value === filterParams.selectedAssetCategory
  );

  return (
    <React.Fragment>
      {
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Asset Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total of {assetList?.pagination?.totalCount || 0} Assets.</p>
              </BlockDes>
              <div className="d-flex flex-wrap">
                {filterParams.selectedModel.length != 0 ? (
                  <FilteredByList data={selectedModel.label} title={"Model"} isNotMap={true} isStr={true} />
                ) : null}
                {filterParams.selectedManufacturer != 0 ? (
                  <FilteredByList
                    data={selectedManufacturer.label}
                    title={"Manufacturer"}
                    isNotMap={true}
                    isStr={true}
                  />
                ) : null}
                {filterParams.selectedAssetCategory != 0 ? (
                  <FilteredByList
                    data={selectedAssetCategory.label}
                    title={"Asset Category"}
                    isNotMap={true}
                    isStr={true}
                  />
                ) : null}
                <FilteredByList data={filterParams.searchText} title={"Search By"} isNotMap={true} isStr={true} />
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  id="menu-asset"
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <Check permission={["getEntity", "manageEntity"]}>
                      <li>
                        <Button
                          disabled={assetList?.pagination?.totalCount === 0}
                          id="export-assets"
                          className={`btn btn-white btn-outline-light`}
                          type="export"
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>
                    <Check permission={["manageEntity"]}>
                      <li className="nk-block-tools-opt">
                        <Button id="add-asset" color="primary" className="btn-icon" onClick={createAsset}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      }
      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        id="search-asset"
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <div id="asset-filter-btn" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      id="cancel-search"
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      id="asset-search-input"
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by model"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                      }}
                    />
                    <Button id="search-asset-btn" className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                        ? handleNameSort("DESC", "identifier")
                        : handleNameSort("ASC", "identifier");
                    }}
                  >
                    Product Id
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "identifier"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "model"
                        ? handleNameSort("DESC", "model")
                        : handleNameSort("ASC", "model");
                    }}
                  >
                    Model
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "model");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "model"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "model");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "model"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>

              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "description"
                        ? handleNameSort("DESC", "description")
                        : handleNameSort("ASC", "description");
                    }}
                  >
                    Description
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "description");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "description"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "description");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "description"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "asset_category_id"
                        ? handleNameSort("DESC", "asset_category_id")
                        : handleNameSort("ASC", "asset_category_id");
                    }}
                  >
                    Category
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "asset_category_id");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "asset_category_id"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "asset_category_id");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "asset_category_id"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "manufacturer_id"
                        ? handleNameSort("DESC", "manufacturer_id")
                        : handleNameSort("ASC", "manufacturer_id");
                    }}
                  >
                    Manufacturer
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "manufacturer_id");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "manufacturer_id"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "manufacturer_id");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "manufacturer_id"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <Check permission={["manageEntity"]}>
                <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
              </Check>
            </DataTableHead>

            <Sidebar toggleState={showFilter}>
              <AssetListFilter />
            </Sidebar>

            {/*Head*/}
            {!assetLoading ? (
              currentItems.map((item, key) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow size="md">
                      <div
                        id="asset-view"
                        className="user-card dropdown-toggle"
                        onClick={() => {
                          viewAsset(item.id);
                        }}
                      >
                        <div className="user-name">
                          <span

                            data-toggle="tooltip"
                            title={item?.identifier}
                            className="tb-lead "
                          >
                            {item?.identifier || "-"}
                          </span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <span
                        data-toggle="tooltip"
                        title={item?.model}
                        style={{
                          display: "inline-block",
                          width: item.model?.length > 50 ? "350px" : "",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item?.model || "-"}
                      </span>
                    </DataTableRow>

                    <DataTableRow size="md">
                      <span
                      className="test-editor"
                      dangerouslySetInnerHTML={{__html:item?.description}}
                        data-toggle="tooltip"
                        title={item?.description}
                        style={{
                          display:"inline-block",
                          width: "140px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      />
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{item.assetCategories?.name || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{item.manufacturers?.name || "-"}</span>
                    </DataTableRow>
                    <Check permission={["manageEntity"]}>
                      <DataTableRow className="d-flex justify-content-center">
                        <Button
                          onClick={() => {
                            editAsset(item.id);
                          }}
                        >
                          {" "}
                          <Icon name="edit"></Icon>
                          <span>Edit</span>
                        </Button>
                        <Button onClick={() => handleDeleteConfirmation(item.id)}>
                          {" "}
                          <Icon name="trash"></Icon>
                          <span>Delete </span>
                        </Button>
                      </DataTableRow>
                    </Check>
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>
          {assetLoading && <Skeleton count={10} className="w-100" />}

          <div className="card-inner">
            {assetList.pagination && assetList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={assetList.pagination.totalCount}
                paginate={assetContext.updateShowListPage}
                currentPage={assetList.pagination.currentPage}
                paginatePage={assetContext.updateShowListSize}
              />
            ) : (
              !assetLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer title="Asset" isOpen={isOpen} size="md" toggleModal={toggleModal} component={getComponent()} />
      <ModalViewer
        title="Asset - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default AssetListCompact;
