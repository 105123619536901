import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../hooks/useBoolean";
import { SlaContext } from "../SlaProvider";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import Head from "../../../../layout/head/Head";

const createSchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(/^(\s*\S.*)/g, "name should be atleast one character"),
  description: yup
    .string()
    .required()
    .matches(/^(\s*\S.*)/g, "description should be atleast one character"),
});

const SlaForm = (props) => {
  const { createSla, slaLoading } = useContext(SlaContext);

  const [isEditMode, updateEditMode] = useBoolean(false);

  const slaFormRef = useForm({
    resolver: yupResolver(props.slaId ? createSchema : createSchema),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = slaFormRef;

  useEffect(() => {
    reset(props.currentViewSla);
    if (props.currentViewSla) updateEditMode.on();
  }, [props.currentViewSla]);

  const handleFormSubmit = (formData) => {
    if (props.slaId) {
      props
        .updateSla(props.currentViewSla.id, formData)

        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while updating SLA" });
        });
    } else {
      createSla(formData)
        .then((res) => {
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while creating SLA" });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,

    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        color="primary"
        size="lg"
        isLoading={slaLoading}
        onClick={slaLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return isDirty ? (
      <Button
        color="primary"
        size="lg"
        isLoading={slaLoading}
        onClick={slaLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Save Information
      </Button>
    ) : (
      <Button size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
        Cancel
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head title={props.slaId ? "Brighter App | SLA | Edit" : "Brighter App | SLA | Create"} />

      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>Level
                  </Label>

                  <div className="form-control-wrap">
                    <input {...register("name")} type="text" name="name" className="form-control" />
                  </div>
                  {errors.name && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.name?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="description">
                    <span style={{ color: "indianred" }}>&#42;</span>Description
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field, ref }) => {
                        return (
                          <textarea
                            name="description"
                            value={field.value}
                            {...register("description")}
                            className="form-control"
                            maxLength={200}
                          />
                        );
                      }}
                    />
                  </div>
                  {errors.description && (
                    <p className="invalid" style={{ color: "red" }}>
                      {errors.description?.message}
                    </p>
                  )}
                </FormGroup>
              </Col>
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default SlaForm;
