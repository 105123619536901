import apiService from "../services/ApiService";
import {
    NOTIFICATION_LIST_API
} from '../constants/endpoints';


export const getNotificationList = async filterParams => {
    const response = await apiService.get(NOTIFICATION_LIST_API, {
        queryParams: filterParams,
    })
    return response
}
