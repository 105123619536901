/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import SelfOwnedCompact from "../components/SelfOwnedList";
import { SelfOwnedInventoryContext, withSelfOwnedInventoryProvider } from "../SelfOwnedProvider";

const SelfOwnedContainer = (props) => {
  const { loadBgeOwnedInventoryList, loadFilterOptions } = useContext(SelfOwnedInventoryContext);
  const { pagination, filterParams } = useContext(SelfOwnedInventoryContext);

  useEffect(() => {
    loadBgeOwnedInventoryList();
  }, [pagination, filterParams]);
  useEffect(() => {
    loadFilterOptions();
  }, []);
  return (
    <React.Fragment>
      <Head title="Brighter App Owned Inventory" />
      <Content>
        <SelfOwnedCompact />
      </Content>
    </React.Fragment>
  );
};

export default withSelfOwnedInventoryProvider(SelfOwnedContainer);
