/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import download from "js-file-download";
import React, { useContext, useState } from "react";
import { CardBody, CardText, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Block, Button, PreviewCard } from "../../../../components/Component";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { exportTask } from "../../../maintenance/corrective/CorrectiveRepository";
import { ScheduleContext } from "../../ScheduleProvider";

const TASK_FIELDS = [
  {
    label: "Code",
    value: "code",
  },
  {
    label: "Name",
    value: "title",
  },
  {
    label: "Months",
    value: "months",
  },
  {
    label: "Frequency",
    value: "frequency",
  },
  {
    label: "Instructions",
    value: "instruction",
  },
  {
    label: "Priority",
    value: "priority",
  },
  {
    label: "Identifier",
    value: "identifier",
  },

  {
    label: "Started At",
    value: "startedAt",
  },
  {
    label: "Resolved At",
    value: "resolvedAt",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Category",
    value: "category",
  },
  {
    label: "Asset Category ID",
    value: "assetCategoryId",
  },
  {
    label: "Description",
    value: "description",
  },
  {
    label: "Labour Hours",
    value: "labourHours",
  },
  {
    label: "Created by ID",
    value: "createdById",
  },
  {
    label: "Parent ID",
    value: "parentId",
  },
  {
    label: "Is Archived",
    value: "isArchived",
  },
  {
    label: "Schedule",
    value: "schedule",
  },
  {
    label: "Comment ID",
    value: "commentId",
  },
  {
    value: "plant.name",
    label: "Plant Name",
  },
  {
    value: "sla.name",
    label: "SLA",
  },
  {
    value: "assignedTo",
    label: "Assigned Engineers",
  },
  {
    value: "fieldEngineers",
    label: "Field Engineers",
  },
];

const ExportForm = (props) => {
  const { plantId } = props;
  const preventativeSCheduleContext = useContext(ScheduleContext);
  const { filterParams } = preventativeSCheduleContext;
  const [value, setValue] = useState(
    TASK_FIELDS.map((plant) => {
      return plant.value;
    })
  );
  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async (formData) => {
    try {
      if (plantId) {
        filterParams["plantId"] = plantId;
      }
      setExportLoading(true);
      const response = await exportTask({ ...filterParams, category: "preventative_schedule", headerData: value });
      download(response, `schedule_task.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button color="primary" size="md" className="mt-1" isLoading={exportLoading} onClick={handleFormSubmit}>
        Export Task Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0">
                {TASK_FIELDS &&
                  TASK_FIELDS.map((task, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${task} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0">
                              <input
                                key={`${task}-${key}`}
                                type="checkbox"
                                checked={value.includes(task.value)}
                                id={`${task}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([task.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === task.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${task}-${key}`} check>
                                {task.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;
