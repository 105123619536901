import {
  PREVENTATIVE_MASTER_LIST,
  MANAGE_PREVENTATIVE_MASTER_LIST,
  PREVENTATIVE_MASTER_TASK_ARCHIVE_API,
  PREVENTATIVE_MASTER_TASK_UNARCHIVE_API,
  PREVENTATIVE_MASTER_LIST_EXPORT,
  PREVENTATIVE_MASTER_TEMPLATE_EXPORT,
} from "../../../constants/endpoints";
import apiService from "../../../services/ApiService";

//api call

export const getPreventativeMasterLists = async (filterParams) => {
  const response = await apiService.get(PREVENTATIVE_MASTER_LIST, {
    queryParams: filterParams,
  });
  return response;
};

export const createPreventativeMasterList = async (params) => {
  const response = await apiService.post(PREVENTATIVE_MASTER_LIST, {
    body: params,
  });
  return response;
};

export const getPreventativeMasterList = async (id) => {
  const response = await apiService.get(`${MANAGE_PREVENTATIVE_MASTER_LIST}`, {
    pathParams: { id },
  });
  return response;
};

export const updatePreventativeMasterList = async (id, params) => {
  const response = await apiService.put(`${MANAGE_PREVENTATIVE_MASTER_LIST}`, {
    pathParams: { id },
    body: params,
  });
  return response;
};
export const deletePreventativeMasterList = async (id, params) => {
  const response = await apiService.delete(`${MANAGE_PREVENTATIVE_MASTER_LIST}`, {
    pathParams: { id },
  });
  return response;
};
export const archivePreventativeMasterListAPI = async (id) => {
  const response = await apiService.put(PREVENTATIVE_MASTER_TASK_ARCHIVE_API, {
    pathParams: { id },
  });
  return response;
};
export const unArchivePreventativeMasterListAPI = async (id) => {
  const response = await apiService.put(PREVENTATIVE_MASTER_TASK_UNARCHIVE_API, {
    pathParams: { id },
  });
  return response;
};

export const exportMasterList = async (filterParams) => {
  const response = await apiService.get(PREVENTATIVE_MASTER_LIST_EXPORT, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
export const exportMasterTemplate = async (filterParams, id) => {
  const response = await apiService.get(PREVENTATIVE_MASTER_TEMPLATE_EXPORT, {
    queryParams: filterParams,
    pathParams: { id },
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
