import React, { useContext, useEffect, useState } from "react";
import { UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row } from "../../../components/Component";

import "react-datepicker/dist/react-datepicker.css";
import { PREVENTATIVE_SCHEDULE_LIST_FILTERS } from "../../../constants/PreferenceKeys";
import { ScheduleContext } from "../ScheduleProvider";

const PreventativeMasterFilter = (props) => {
  const preventativeContext = useContext(ScheduleContext);
  const { filterParams, handleApplyFilter, sfState, showFilter, checkIsFilterApplied } = preventativeContext;
  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  // const [tablesm, updateTableSm] = useBoolean(false)
  // const [showFilter, sfState] = useBoolean(false)
  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    props.setFilterApplied(false);
    preventativeContext.resetListFilter();
    setIsArchived(false);
    localStorage.removeItem(PREVENTATIVE_SCHEDULE_LIST_FILTERS);
  };

  const handleApply = () => {
    props.setFilterApplied(true);
    checkIsFilterApplied();
    handleApplyFilter(selectedFilter);
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleArchiveFilter = (e) => {
    setIsArchived(e.target.checked);
    handleFilterSelection({ isArchiveSelected: e.target.checked });
  };

  const saveFilter = () => {
    localStorage.setItem(PREVENTATIVE_SCHEDULE_LIST_FILTERS, JSON.stringify(filterParams));
  };

  // return <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
  //     <ul className="btn-toolbar gx-1">
  //         <li className="toggle-close">
  //             <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm.off()}>
  //                 <Icon name="arrow-left"></Icon>
  //             </Button>
  //         </li>
  //         <li>
  //             <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
  //                 <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
  //                     <div className="dot dot-primary"></div>
  //                     <Icon name="filter-alt"></Icon>
  //                 </DropdownToggle>
  //                 <DropdownMenu
  //                     right
  //                     className="filter-wg dropdown-menu-xl"
  //                     style={{ overflow: "visible" }}
  //                 >
  //                     <div className="dropdown-head">
  //                         <span className="sub-title dropdown-title">Filter Preventative Schedule</span>
  //                         <div className="dropdown">
  //                             <DropdownItem
  //                                 href="#more"
  //                                 onClick={(ev) => {
  //                                     ev.preventDefault();
  //                                 }}
  //                                 className="btn btn-sm btn-icon"
  //                             >
  //                                 <Icon name="more-h"></Icon>
  //                             </DropdownItem>
  //                         </div>
  //                     </div>
  //                     <div className="dropdown-body dropdown-body-rg">
  //                         <Row className="gx-6 gy-3">
  //                             <Col size="6">
  //                                 <div className="custom-control custom-control-sm custom-checkbox">
  //                                     <input
  //                                         type="checkbox"
  //                                         className="custom-control-input form-control"
  //                                         id="isArchived-preventative-schedule"
  //                                         onChange={handleArchiveFilter}
  //                                         checked={isArchived}
  //                                     />
  //                                     <label className="custom-control-label" htmlFor="isArchived-preventative-schedule">
  //                                         {" "}
  //                                         Archived
  //                                     </label>
  //                                 </div>
  //                             </Col>
  //                             <Col size="12">
  //                                 <Button onClick={handleApply} color="secondary">Apply</Button>
  //                             </Col>
  //                         </Row>
  //                     </div>
  //                     <div className="dropdown-foot between">
  //                         <a
  //                             href="#reset"
  //                             onClick={(ev) => {
  //                                 ev.preventDefault();
  //                                 handleReset()
  //                             }}
  //                             className="clickable"
  //                         >
  //                             Reset Filter
  //                         </a>
  //                         <a
  //                             href="#save"
  //                             onClick={saveFilter}
  //                         >
  //                             Save Filter
  //                         </a>
  //                     </div>
  //                 </DropdownMenu>
  //             </UncontrolledDropdown>
  //         </li>
  //     </ul>
  // </div >

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size={6}>
              <div className="custom-control custom-control-sm custom-checkbox" style={{ zIndex: 0 }}>
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isChecked"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isChecked">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default PreventativeMasterFilter;
