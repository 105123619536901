/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import NotificationListCompact from "./components/NotificationList";
import { NotificationContext, withNotificationProvider } from "../../providers/NotificationProvider";

const NotificationContainer = props => {
  const { loadNotificationList } = useContext(NotificationContext)
  const { pagination, filterParams } = useContext(NotificationContext)

  useEffect(() => {
    loadNotificationList()
  }, [pagination, filterParams])

  return (
    <React.Fragment>
      <Head title="Notification Management" />
      <Content>
        <NotificationListCompact />
      </Content>
    </React.Fragment>
  );
}

export default withNotificationProvider(NotificationContainer);