/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import FailureReasonForm from "../components/FailureReasonForm";
import { FailureReasonContext, withFailureReasonProvider } from "../FailureReasonProvider";

const FailureReasonFormContainer = props => {

    const failureReasonContext = useContext(FailureReasonContext)
    const { currentViewFailureReason, updateFailureReason, loadFailureReason } = failureReasonContext

    useEffect(() => {
        if (props.failureReasonId) loadFailureReason(props.failureReasonId)
    }, [props.failureReasonId])

    return (
        <React.Fragment>
            <FailureReasonForm
                failureReasonId={props.failureReasonId}
                currentViewFailureReason={currentViewFailureReason}
                updateFailureReason={updateFailureReason}
                onSuccessfulModal={props.onSuccessfulModal}
            />
        </React.Fragment>
    );
}

export default withFailureReasonProvider(FailureReasonFormContainer);