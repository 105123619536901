import React, { useContext, useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../../components/Component";
import "react-datepicker/dist/react-datepicker.css";
import { PREVENTATIVE_MASTER_FILTERS } from "../../../../constants/PreferenceKeys";
import { PreventativeContext } from "../PreventativeProvider";
import { FREQUENCY_DROPDOWN } from "../constants";
import { sortOption } from "../../../../utils/Utils";

const PreventativeMasterFilter = (props) => {
  const preventativeContext = useContext(PreventativeContext);
  const { filterParams, handleApplyFilter, assetCategories, checkIsFilterApplied, sfState, showFilter } =
    preventativeContext;
  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    preventativeContext.resetClientListFilter();
    localStorage.removeItem(PREVENTATIVE_MASTER_FILTERS);
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    checkIsFilterApplied();
    sfState.off();
    updateTableSm(false);
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleFrequencyFilterSelection = (selectedOption) => {
    const month = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedFrequency: month });
  };
  const handleAssetCategorySelection = (selectedOption) => {
    const assignedFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedAssetCategory: assignedFilter });
  };
  const handleArchiveFilter = (e) => {
    setIsArchived(e.target.checked);
    handleFilterSelection({ isArchiveSelected: e.target.checked });
  };

  const saveFilter = () => {
    localStorage.setItem(PREVENTATIVE_MASTER_FILTERS, JSON.stringify(filterParams));
  };

  const assetCategorySelectedFilter = selectedFilter?.selectedAssetCategory?.map((item, key) => {
    return assetCategories?.[assetCategories?.findIndex((plant) => plant.value === item)];
  });

  const frequencySelectedFilter = selectedFilter?.selectedFrequency?.map((item, key) => {
    return FREQUENCY_DROPDOWN?.[FREQUENCY_DROPDOWN?.findIndex((month) => month.value === item)];
  });

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Frequency</label>
                <RSelect
                  options={FREQUENCY_DROPDOWN}
                  placeholder="Any Frequency"
                  onChange={handleFrequencyFilterSelection}
                  isMulti
                  value={frequencySelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Asset Category</label>
                <RSelect
                  options={sortOption(assetCategories) || []}
                  placeholder="Any Asset Category"
                  onChange={handleAssetCategorySelection}
                  isMulti
                  value={assetCategorySelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <div className="custom-control custom-control-sm custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchived"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isArchived">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className={"d-flex justify-content-between"} size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default PreventativeMasterFilter;
