import axios from 'axios'
import { ACCESS_TOKEN } from '../constants/PreferenceKeys'
import { ADMIN_LOGIN_PAGE, PUBLIC_PATH } from '../constants/routes';
// queryParams: {},
//   pathParams: {},
//   body: {},
//   headers: {}

/**
 * @typedef {Object} APIOptions
 * @property {Object} queryParams
 * @property {Object} pathParams
 * @property {Object} body
 * @property {Object} headers
 */

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if ([401, 403].includes(status)) {
      logout();
    }
    return Promise.reject(error);
  }
);

const DEFAULT_OPTIONS = {
  queryParams: {},
  pathParams: {},
  body: {},
  headers: {},
}

const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later'

export const logout = () => {
  for (let path of PUBLIC_PATH) {
    if (window.location.pathname.startsWith(path)) {
      return
    }
  }
  if (PUBLIC_PATH.includes(window.location.pathname)) return;
  window.location.replace(ADMIN_LOGIN_PAGE)
}

export const formattedErrorMessage = serverError => {
  if (typeof serverError === "string") return serverError

  if (typeof serverError === "object") {
    const error = serverError?.error || {};

    let description;
    if (Array.isArray(error.message)) {
      description = error.message[0].message;
    } else {
      description = error?.message;
    }

    return description;
  }

  return SOMETHING_WENT_WRONG;
}

export const getErrorMessage = axiosError => {
  const response = axiosError.response;

  if (!response) return SOMETHING_WENT_WRONG

  const serverError = response.data
  const serverErrorMessage = formattedErrorMessage(serverError)

  if (typeof serverErrorMessage === 'object') return SOMETHING_WENT_WRONG
  return serverErrorMessage || SOMETHING_WENT_WRONG
}

/**
 * 
 * @param {string} url 
 * @param {APIOptions} options 
 * @returns 
 */
const getAPI = async (url, options = DEFAULT_OPTIONS) => {
  url = _prepareURL(url, options)
  const _headers = _prepareHeaders(options)
  let params = {}
  params.headers = _headers
  if (options.responseType) {
    params.responseType = options.responseType
  }
  try {
    const response = await axios.get(url, params)
    const data = await _formattedResponse(response)
    return data
  } catch (e) {
    const errorMessage = getErrorMessage(e)
    throw new Error(errorMessage)
  }
}

/**
 * 
 * @param {string} url 
 * @param {APIOptions} options 
 * @returns 
 */
const postAPI = async (url, options = DEFAULT_OPTIONS) => {
  url = _prepareURL(url, options)
  const _headers = _prepareHeaders(options)
  if (options.formData && Object.keys(options.formData).length) {
    const data = new FormData();
    Object.keys(options.formData).map((item, index) => {
      data.append(item, options.formData[item])
    })
    options.body = data
  }
  try {
    const response = await axios.post(url, options.body, {
      headers: _headers
    })
    const data = await _formattedResponse(response)

    return data
  } catch (e) {
    const errorMessage = getErrorMessage(e)
    throw new Error(errorMessage)
  }
}

/**
 * 
 * @param {string} url 
 * @param {APIOptions} options 
 * @returns 
 */
const putAPI = async (url, options = DEFAULT_OPTIONS) => {
  url = _prepareURL(url, options)
  const _headers = _prepareHeaders(options)

  try {
    const response = await axios.put(url, options.body, { headers: _headers })
    const data = await _formattedResponse(response)

    return data
  } catch (e) {
    const errorMessage = getErrorMessage(e)
    throw new Error(errorMessage)
  }
}

/**
 * 
 * @param {string} url 
 * @param {APIOptions} options 
 * @returns 
 */
const deleteAPI = async (url, options = DEFAULT_OPTIONS) => {
  url = _prepareURL(url, options)
  const _headers = _prepareHeaders(options)

  try {
    const response = await axios.delete(url, { headers: _headers })
    const data = await _formattedResponse(response)

    return data
  } catch (e) {
    const errorMessage = getErrorMessage(e)
    throw new Error(errorMessage)
  }
}


/**
 * 
 * @param {string} url 
 * @param {APIOptions} options 
 */
const _prepareURL = (url, options = DEFAULT_OPTIONS) => {
  const { queryParams = {}, pathParams = {} } = options

  Object.entries(pathParams).forEach(([k, v]) => {
    url = url.replaceAll(`:${k}`, v)
  })
  const params = new URLSearchParams(queryParams)
  const qp = params.toString()

  url = url + `?${qp}`
  return url
}

const _prepareHeaders = (options = DEFAULT_OPTIONS) => {
  return {
    Authorization: `${localStorage.getItem(ACCESS_TOKEN)}`,
    ...(options?.headers || {})
  }
}

/**
 * 
 * @param {AxiosResponse} response 
 */
const _formattedResponse = async response => {
  if (response.data.data) {
    return response.data.data
  }

  return response.data
}

const API = {
  get: getAPI,
  post: postAPI,
  put: putAPI,
  delete: deleteAPI,
}

export default API
