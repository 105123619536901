import React, { useContext, useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../../components/Component";
import useBoolean from "../../../../hooks/useBoolean";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { PREVENTATIVE_MASTER_LIST_FILTERS } from "../../../../constants/PreferenceKeys";
import { PreventativeContext } from "../PreventativeProvider";
import { DnoContext } from "../../dno/DnoProvider";
import { sortOption } from "../../../../utils/Utils";

const PreventativeMasterFilter = (props) => {
  const preventativeContext = useContext(PreventativeContext);
  const { filterOption, getDnoFilterOption } = useContext(DnoContext);
  const { filterParams, handleApplyFilter, sfState, showFilter } = preventativeContext;
  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);
  React.useEffect(() => {
    getDnoFilterOption();
  }, []);

  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    preventativeContext.resetListFilter();
    setIsArchived(false);
    localStorage.removeItem(PREVENTATIVE_MASTER_LIST_FILTERS);
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    sfState.off();
    updateTableSm(false);
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleArchiveFilter = (e) => {
    setIsArchived(e.target.checked);
    handleFilterSelection({ isArchiveSelected: e.target.checked });
  };

  const saveFilter = () => {
    localStorage.setItem(PREVENTATIVE_MASTER_LIST_FILTERS, JSON.stringify(filterParams));
  };

  const handleCreatedbyFilterSelection = (selectedOption) => {
    const createdByFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ users: createdByFilter });
  };
  const handleCreatedAtSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ createdAt: "" });
    } else {
      handleFilterSelection({ createdAt: selectedOption });
    }
  };

  // const handleUpdatedAtSelection = (selectedOption) => {
  //   if (selectedOption === null) {
  //     handleFilterSelection({ updatedAt: "" });
  //   } else {
  //     handleFilterSelection({ updatedAt: selectedOption });
  //   }
  // };

  const createdBySelectedFilter =
    selectedFilter &&
    selectedFilter?.users.map((item, key) => {
      return filterOption && filterOption?.users?.[filterOption.users?.findIndex((status) => status.value === item)];
    });

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Option</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">created by</label>
                <RSelect
                  options={sortOption(filterOption.users)}
                  placeholder="Created By"
                  onChange={handleCreatedbyFilterSelection}
                  isMulti
                  value={createdBySelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Created At</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.createdAt || null}
                  onChange={(date) => handleCreatedAtSelection(date)}
                />
              </FormGroup>
            </Col>
            {/* <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">updated at</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.updatedAt || null}
                  onChange={(date) => {
                    handleUpdatedAtSelection(date);
                  }}
                />
              </FormGroup>
            </Col> */}
            <Col size={6}>
              <div className="custom-control custom-control-sm custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchived"
                  onChange={handleArchiveFilter}
                  checked={isArchived}
                />
                <label className="custom-control-label" htmlFor="isArchived">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default PreventativeMasterFilter;
