/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import { useEffect } from "react";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Block,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
} from "../../../../components/Component";
import { RECORD_TYPE } from "../../constants";
import { EquipmentAndSpareContext } from "../../EquipmentAndSpareProvider";
import { CORRECTIVE_MAINTENANCE_PAGE, REMEDIAL_MAINTENANCE_PAGE } from "../../../../constants/routes";
import { useHistory } from "react-router-dom";

const TransactionComponent = (props) => {
  const {
    transactions,
    isTransactionLoading,
    transactionPagination,
    loadTransaction,
    currentEquipment,
    setTransactionPagination,
  } = useContext(EquipmentAndSpareContext);

  const history = useHistory();
  useEffect(() => {
    if (currentEquipment.id) {
      loadTransaction({ stockId: currentEquipment.id });
    }
  }, [currentEquipment, transactionPagination]);

  return (
    <>
      <Block>
        <DataTable className="card-stretch">
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <span className="sub-text">Record Date</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Record Type</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Cause</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="sub-text">Location</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Quantity</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Details</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text" style={{ textAlign: "center" }}>
                  Maintenance Ticket
                </span>
              </DataTableRow>
            </DataTableHead>
            {/*Head*/}
            {transactions?.items.length > 0 ? (
              transactions?.items.map((item) => {
                const recordType = RECORD_TYPE.find((d) => d.value === item.recordType).label;
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <span>
                        {" "}
                        {item.recordDate ? format(new Date(item.recordDate || null), "dd/MM/yyyy HH:mm ") : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow>
                      <span>{recordType || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.cause || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>
                        {item.recordType === "TRANSFER"
                          ? item.fromWarehouse + ` -> ${item.toWarehouse}`
                          : item?.toWarehouse}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.quantity || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.description || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md" style={{ textAlign: "center" }}>
                      {item?.task?.identifier ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.replace(
                              item?.task?.category === "remedial"
                                ? REMEDIAL_MAINTENANCE_PAGE + `?id=${item?.task?.id}`
                                : CORRECTIVE_MAINTENANCE_PAGE + `?id=${item?.task?.id}`
                            );
                          }}
                        >
                          {item?.task?.identifier || "-"}
                        </span>
                      ) : (
                        <span>{"-"}</span>
                      )}
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>
          <div className="card-inner">
            {transactions?.pagination && transactions?.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={transactionPagination?.size}
                totalItems={transactions?.pagination?.totalCount}
                paginate={(page) => setTransactionPagination((p) => ({ ...p, page }))}
                paginatePage={(size) => setTransactionPagination((p) => ({ ...p, size, page: 1 }))}
                currentPage={transactions?.pagination?.currentPage}
              />
            ) : (
              !isTransactionLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {isTransactionLoading && <Skeleton count={10} className="w-100" />}
      </Block>
    </>
  );
};
export default TransactionComponent;
