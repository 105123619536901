import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Label } from "reactstrap";
import { PreviewCard, Button } from "../../../components/Component";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../hooks/useBoolean";
import { useHistory } from "react-router-dom";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { PVPLANT_MANAGEMENT_VIEW_PAGE } from "../../../constants/routes";
import { Icon } from "../../../components/Component";

const updateSchema = yup
  .object({
    "System Capacity": yup.string().notRequired().default(""),
    "Grid Connection": yup.string().notRequired().default(""),
    "PV modules": yup.string().notRequired().default(""),
    Inverter: yup.string().notRequired().default(""),
    Pyranometer: yup.string().notRequired().default(""),
    "Weather Stations": yup.string().notRequired().default(""),
    "Seperate CSS": yup.string().notRequired().default(""),
    "Open Point Present": yup.string().notRequired().default(""),
    "Type of Switchgear(DNO)": yup.string().notRequired().default(""),
    "Site Feeder Circuits(Nr.)": yup.string().notRequired().default(""),
    "Ring Main": yup.string().notRequired().default(""),
    G59: yup.string().notRequired().default(""),
    "O/C+E/F Relay": yup.string().notRequired().default(""),
    "Meter Type": yup.string().notRequired().default(""),
    "Main Meter S/N": yup.string().notRequired().default(""),
    "Check Meter S/N": yup.string().notRequired().default(""),
    "VT Ratio": yup.string().notRequired().default(""),
    "CT Ratio": yup.string().notRequired().default(""),
    Subs: yup.string().notRequired().default(""),
    "Type of Switchgear(Transformer)": yup.string().notRequired().default(""),
    "TX Standalone": yup.string().notRequired().default(""),
    "TX Type": yup.string().notRequired().default(""),
    "TX Rating": yup.string().notRequired().default(""),
    "Inverter Type": yup.string().notRequired().default(""),
    Acres: yup.string().notRequired().default(""),
    Modules: yup.string().notRequired().default(""),
    Fields: yup.string().notRequired().default(""),
    Zones: yup.string().notRequired().default(""),
    Rows: yup.string().notRequired().default(""),
    "Table Config": yup.string().notRequired().default(""),
    "Access Between Rows": yup.string().notRequired().default(""),
    Welfare: yup.string().notRequired().default(""),
    "Mains Water On-site": yup.string().notRequired().default(""),
    "Spare Parts Store": yup.string().notRequired().default(""),
  })
  .required();

const SystemForm = (props) => {
  const history = useHistory();

  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const { currentViewPvPlant, attributeMasterList, loadAttributeMasterList, loadPlantAttributes, attributes } =
    pvPlantManagementContext;

  const [isEditMode, updateEditMode] = useBoolean(false);

  let currentItems = [];

  if (attributeMasterList) {
    currentItems = attributeMasterList.filter((attributeMaster) => attributeMaster.group === "system");
  }

  const SystemFormRef = useForm({
    resolver: yupResolver(updateSchema),
  });

  const { setValue, register, handleSubmit } = SystemFormRef;

  useEffect(() => {
    loadAttributeMasterList();
    loadPlantAttributes({ parentId: currentViewPvPlant.id, group: "system" });
  }, [currentViewPvPlant]);

  useEffect(() => {
    if (attributes.length) {
      attributes.map((attribute) => {
        return setValue(attribute.name, attribute.value.value);
      });
    }
  }, [attributes]);

  const handleFormSubmit = (formData) => {
    const plantId = { parentId: currentViewPvPlant.id, parentType: "PLANT", group: "system" };
    const object = { ...plantId, ...formData };
    if (isEditMode) {
      pvPlantManagementContext
        .updateAttribute(object)
        .then((res) => {
          history.push(PVPLANT_MANAGEMENT_VIEW_PAGE.replace(":plantId", currentViewPvPlant.id));
          successToast({ description: "Successfully updated system" });
        })
        .catch((e) => {
          errorToast({ description: `${e.message}` });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderEditButton = () => {
    return (
      <Button color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Save Information
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderEditButton();
  };

  return (
    <>
      <PreviewCard>
        <div style={{ justifyContent: "end", display: "flex" }}>
          <Button color="primary" onClick={() => updateEditMode.on()}>
            <Icon name="edit" />
            <span>Edit</span>
          </Button>
        </div>
        <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
          <Row className="g-4">
            {currentItems &&
              currentItems.map((item) => {
                return (
                  <Col lg="6" md="6">
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <Label
                            className="input-group-label mt-1 d-flex"
                            style={{ width: "120px", fontWeight: "bolder" }}
                          >
                            {item.name}
                          </Label>
                        </div>
                        <input
                          {...register(item.name)}
                          disabled={!isEditMode}
                          type="text"
                          name={item.name}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}

            {isEditMode && (
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            )}
          </Row>
        </Form>
      </PreviewCard>
    </>
  );
};
export default SystemForm;
