/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { withFormManagementProvider } from "../FormManagementProvider";
import AddForm from "../components/Form";

const FormContainer = props => {

    const title = "Create"

    return (
        <React.Fragment>
            <Head title={title} />
            <Content>
                <AddForm />
            </Content>
        </React.Fragment>
    );
}

export default withFormManagementProvider(FormContainer);