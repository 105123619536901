/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import { SelfOwnedInventoryContext } from "../SelfOwnedProvider";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";

const SelfOwnedMoveSparesForm = (props) => {
  const { filterOptions, moveToPlantInventory } = useContext(SelfOwnedInventoryContext);
  const moveToPlantSchema = yup.object({
    quantity: yup
      .number()
      .required("This field is required")
      .typeError("Specify a Number")
      .min(1, `Min value 1.`)
      .integer("Invalid Number")
      .max(props.currentViewInventory?.quantity, `Max value ${props.currentViewInventory?.quantity}.`),
    reason: yup.string().trim(),
    equipmentName: yup.string().required(),
    plantId: yup.number().notRequired()
  });

  const userFormRef = useForm({
    resolver: yupResolver(moveToPlantSchema),
  });

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = userFormRef;

  useEffect(() => {
    if (props.currentViewInventory) {
      reset({
        quantity: props.currentViewInventory.quantity,
        equipmentName: props.currentViewInventory.assets.model,
      });
    }
  }, [props.currentViewInventory]);

  const handleFormSubmit = (formData) => {
    const { equipmentName, plantId, ...rest } = formData;
    const data = {
      plantId: parseInt(plantId),
      ...rest,
      assetId: props.currentViewInventory.assets.id,
    };
    moveToPlantInventory(props.currentViewInventory.id, data)
      .then((res) => {
        successToast({ description: "Successfully Moved to Inventory" });
        if (props.onSuccessfulModal) props.onSuccessfulModal();
      })
      .catch((e) => {
        errorToast({ description: `Error happened while updating Corrective maintenance ${e}` });
      });
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderSaveButton = () => {
    return (
      <Button color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Move to Plant
      </Button>
    );
  };
  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Equipment Name
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled="true"
                      type="text"
                      name="addressLine1"
                      className="form-control"
                      value={getValues("equipmentName")}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    From
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled="true"
                      type="text"
                      name="addressLine1"
                      className="form-control"
                      value="Bge owned Inventory"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Move to
                  </Label>
                  <Controller
                    control={control}
                    name="plantId"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = filterOptions?.plant;
                      const selectedValue = options?.find((e) => e.value === field.value);

                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={options}
                          onChange={(o) => {
                            setValue("plantId", o.value);
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.plantId && <span className="invalid">Plant is required</span>}
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup className="form-group">
                  <label className="form-label">Quantity</label>
                  <div className="form-control-wrap">
                    <input type="text" className="form-control" {...register("quantity")} />
                  </div>
                  {errors?.quantity && <span className="invalid">{errors?.quantity.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup className="form-group">
                  <label className="form-label">Reason</label>
                  <div className="form-control-wrap">
                    <input type="text" className="form-control" {...register("reason")} />
                  </div>
                  {errors?.reason && <span className="invalid">{errors?.reason.message}</span>}
                </FormGroup>
              </Col>
              <Col xl="12" className="d-flex justify-content-end">{renderSaveButton()}</Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default SelfOwnedMoveSparesForm;
