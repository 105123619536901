export const FREQUENCY_DROPDOWN = [
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Six Monthly", value: "Six Monthly" },
    { label: "Annually", value: "Annually" },
    { label: "2 Yearly", value: "2 Yearly" },
    { label: "3 Yearly", value: "3 Yearly" },
    { label: "4 Yearly", value: "4 Yearly" },
    { label: "5 Yearly", value: "5 Yearly" },
    { label: "6 Yearly", value: "6 Yearly" },

]