import apiService from "../../services/ApiService";
import {
  PVPLANT_MANAGEMENT_LIST_API,
  PVPLANT_MANAGEMENT_LIST_FILTER_OPTIONS_API,
  PVPLANT_MANAGEMENT_CREATE_API,
  PVPLANT_MANAGEMENT_ARCHIVE_API,
  PVPLANT_MANAGEMENT_UNARCHIVE_API,
  PVPLANT_MANAGEMENT_DELETE_API,
  USER_MANAGEMENT_ALL_USERS_API,
  CLIENT_MANAGEMENT_ALL_CLIENTS_API,
  PVPLANT_MANAGEMENT_VIEW_API,
  PVPLANT_MANAGEMENT_UPDATE_API,
  ALL_HEALTH_AND_SAFETY_API,
  ALL_DNO_API,
  CORRECTIVE_LIST_API,
  REMEDIAL_LIST_API,
  PREVENTATIVE_TASK,
  EXPORT_PVPLANT_API,
  GET_PVPLANT_TASKS_API,
  MANAGE_ATTRIBUTE_MASTER,
  CREATE_ATTRIBUTE,
  GET_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  MANAGE_ATTRIBUTE,
} from "../../constants/endpoints";

export const getAdminPvPlantList = async (filterParams, payload) => {
  const response = await apiService.post(PVPLANT_MANAGEMENT_LIST_API, {
    queryParams: filterParams,
    body: payload,
  });
  return response;
};

export const getAdminPvPlantListFilterOptions = async (filterParams) => {
  const response = await apiService.get(PVPLANT_MANAGEMENT_LIST_FILTER_OPTIONS_API, {
    queryParams: filterParams,
})
return response
};

export const createPvPlantAPI = async (params) => {
  const response = await apiService.post(PVPLANT_MANAGEMENT_CREATE_API, {
    body: params,
  });
  return response
}

export const getPvPlantAPI = async (plantId) => {
    const response = await apiService.get(PVPLANT_MANAGEMENT_VIEW_API, {
        pathParams: { plantId }
    })
    return response
}
  export const archivePvPlantAPI = async (plantId) => {
    const response = await apiService.put(PVPLANT_MANAGEMENT_ARCHIVE_API, {
        pathParams: { plantId }
    })
    return response
}
export const unarchivePvPlantAPI = async (plantId) => {
    const response = await apiService.put(PVPLANT_MANAGEMENT_UNARCHIVE_API, {
        pathParams: { plantId }
    })
    return response
}
export const deletePvPlantAPI = async (plantId) => {
    const response = await apiService.delete(PVPLANT_MANAGEMENT_DELETE_API, {
        pathParams: { plantId }
    })
    return response
}
export const updatePvPlantAPI = async (plantId, params) => {
  const response = await apiService.put(PVPLANT_MANAGEMENT_UPDATE_API, {
      body: params,
      pathParams: { plantId }
  })
  return response
}
export const getAllUsers = async () => {
  const response = await apiService.get(USER_MANAGEMENT_ALL_USERS_API);
  return response;
};
export const getAllClients = async () => {
  const response = await apiService.get(CLIENT_MANAGEMENT_ALL_CLIENTS_API);
  return response;
};
export const getAllDnos = async () => {
  const response = await apiService.get(ALL_DNO_API);
  return response;
};
export const getAllHealthAndSafeties = async () => {
  const response = await apiService.get(ALL_HEALTH_AND_SAFETY_API);
  return response;
};

export const getPlantPreventiveTaskList = async (filterParams) => {
  const response = await apiService.get(PREVENTATIVE_TASK, {
    queryParams: filterParams,
  });
  return response;
};
export const exportPlantAPI = async (filterParams) => {
  const response = await apiService.get(EXPORT_PVPLANT_API, {
      queryParams: filterParams,
      responseType: 'blob',
      headers: {
          'Content-Type': 'application/pdf',
      }
  })
  return response
}
export const getPvPlantTasksAPI = async (plantId) => {
  const response = await apiService.get(GET_PVPLANT_TASKS_API, {
      pathParams: { plantId }
  })
  return response
}
export const getAttributeMasterList = async (filterParams) => {
  const response = await apiService.get(MANAGE_ATTRIBUTE_MASTER, {
    queryParams: filterParams,
  });
  return response;
};

export const getAttributes = async (filterParams) => {
  const response = await apiService.get(MANAGE_ATTRIBUTE, {
    queryParams: filterParams,
  });
  return response;
};

export const updateAttributeAPI = async (params) => {
  const response = await apiService.put(UPDATE_ATTRIBUTE, {
      body: params,
  })
  return response
}