/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import PlantOwnedForm from "../components/PlantOwnedForm";
import { PlantOwnedInventoryContext, withPlantOwnedInventoryProvider } from "../PlantOwnedProvider";

const PlantOwnedFormContainer = props => {

    const plantOwnedInventoryContext = useContext(PlantOwnedInventoryContext)
    const { loadInventory, loadFilterOptions, currentViewInventory } = plantOwnedInventoryContext

    useEffect(() => {
        loadFilterOptions()
        if (props.inventoryId) loadInventory(props.inventoryId)
    }, [props.inventoryId])

    return (
        <React.Fragment>
            <PlantOwnedForm
                inventoryId={props.inventoryId}
                currentViewInventory={currentViewInventory}
                onSuccessfulModal={props.onSuccessfulModal}
            />
        </React.Fragment>
    );
}

export default withPlantOwnedInventoryProvider(PlantOwnedFormContainer);