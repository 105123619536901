/* eslint-disable eqeqeq */
import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import { getOptions, getStringAPI, getStringList, importFile, fetchImportId } from "./StringRepository";

export const StringContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 100,
};

const initialFilterParams = {
  selectedStatus: [],
  selectedPlant: [],
  searchText: "",
  addedAt: "",
  investigatedAt: "",
  selectedFailureReason: [],
  sortBy: "",
};

export const StringProvider = (props) => {
  const [stringList, setStringList] = React.useState({});
  const [stringData, setStringData] = React.useState([]);

  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [currentViewString, setCurrentViewString] = React.useState();
  const [plants, setPlants] = React.useState([]);
  const [failureReason, setFailureReason] = React.useState([]);
  const [stringStatus, setStringStatus] = React.useState("");

  //Loaders
  const [stringLoading, stringState] = useBoolean(false);
  const [dashboardFilterStatus, setDashboardFilterStatus] = useBoolean(false);

  const [showFilter, sfState] = useBoolean(false);

  const [isImportLoading, imDtate] = useBoolean(false);
  const loadOptions = async () => {
    try {
      const response = await getOptions();
      setPlants(response.plants);
      setFailureReason(response.failureReason);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    }
  };

  //LOAD REMEDIAL LIST
  const loadStringList = async (id, status, searchText) => {
    setStringData([]);
    stringState.on();
    let filter = {};
    if (status) {
      filterParams.selectedStatus = [status];
      filter = { ...pagination, ...filterParams };
    }

    if (status && id) {
      filterParams.selectedStatus = [status];
      filterParams.selectedPlant = [id.toString()];
      filter = { ...pagination, ...filterParams };
    }
    if (id) {
      filter = { ...pagination, ...filterParams, selectedPlant: [id] };
    } else if (searchText) {
      filter = { ...pagination, ...filterParams, selectedStatus: [searchText] };
    } else {
      filter = { ...pagination, ...filterParams };
    }
    try {
      const response = await getStringList(filter);
      setStringList(response.strings);
      if (response.strings.items.length) {
        let data = response.strings.items.map((d) => {
          return {
            ...d,
            plantId: d.plantId?.toString(),
            failureReasonId: d.failureReasonId?.toString(),
            statusOpen: false,
            plantIdOpen: false,
            failureReasonIdOpen: false,
          };
        });
        setStringData([...data]);
      }
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      stringState.off();
    }
  };

  // VIEW TASK
  const loadString = async (taskId) => {
    try {
      const response = await getStringAPI(taskId);
      setCurrentViewString(response.string);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));
  const checkIsFilterApplied = () => {
    if (
      filterParams.sortBy ||
      filterParams.selectedStatus.length != 0 ||
      filterParams.selectedPlant.length != 0 ||
      filterParams.selectedFailureReason.length != 0 ||
      filterParams.addedAt ||
      filterParams.investigatedAt
    ) {
      return true;
    } else return false;
  };

  const importStringFile = async (file, plantId) => {
    try {
      imDtate.on();
      const response = await importFile(file, plantId);
      // with import id go to string import url
    } catch (e) {
      throw e;
    } finally {
      imDtate.off();
    }
  };

  const fetchImport = async (plantId) => {
    try {
      imDtate.on();

      const response = await fetchImportId(plantId);
      return response;
      // with import id go to string import url
    } catch (e) {
      throw e;
    } finally {
      imDtate.off();
    }
  };
  const isFilterApplied = checkIsFilterApplied();
  const mContext = {
    checkIsFilterApplied,
    isFilterApplied,
    sfState,
    showFilter,
    stringData,
    setStringData,
    stringList,
    setStringList,
    pagination,
    loadStringList,
    stringLoading,
    filterParams,
    currentViewString,
    plants,
    failureReason,
    stringStatus,
    setStringStatus,
    loadOptions,
    loadString,
    handleApplyFilter,
    resetClientListFilter,
    dashboardFilterStatus,
    setDashboardFilterStatus,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
    importStringFile,
    isImportLoading,
    fetchImport,
  };

  return <StringContext.Provider value={mContext}>{props.children}</StringContext.Provider>;
};

export const withStringProvider = (Container, containerProps) => (props) =>
  (
    <StringProvider>
      <Container {...containerProps} {...props} />
    </StringProvider>
  );
