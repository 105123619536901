import apiService from "../../services/ApiService";
import {
  ROLE_MANAGEMENT_LIST_API,
  ROLE_MANAGEMENT_CREATE_API,
  ROLE_MANAGEMENT_UPDATE_API,
  ROLE_MANAGEMENT_VIEW_API,
  ROLE_MANAGEMENT_ALL_ROLES_API,
  EXPORT_ROLE_API,
  ROLE_MANAGEMENT_ACTION_API
} from "../../constants/endpoints";

export const getRoleList = async (filterParams) => {
  const response = await apiService.get(ROLE_MANAGEMENT_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};

export const createRoleAPI = async (params) => {
  const response = await apiService.post(ROLE_MANAGEMENT_CREATE_API, {
    body: params,
  });
  return response;
};

export const updateRoleAPI = async (roleId, params) => {
  const response = await apiService.put(ROLE_MANAGEMENT_UPDATE_API, {
    body: params,
    pathParams: { roleId },
  });
  return response;
};

export const getRoleAPI = async (roleId) => {
  const response = await apiService.get(ROLE_MANAGEMENT_VIEW_API, {
    pathParams: { roleId },
  });
  return response;
};

export const getAllRoles = async () => {
    const response = await apiService.get(ROLE_MANAGEMENT_ALL_ROLES_API);
    return response;
  };

  export const exportRoleAPI = async (filterParams) => {
    const response = await apiService.get(EXPORT_ROLE_API, {
        queryParams: filterParams,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf',
        }
    })
    return response
  }
  export const getRoleActionsAPI = async () => {
    const response = await apiService.get(ROLE_MANAGEMENT_ACTION_API);
    return response;
  };