/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import PreventativeScheduleView from "../components/PreventativeScheduleView";
import { ScheduleContext, withPreventativeScheduleProvider } from "../ScheduleProvider";

const PreventativeScheduleViewContainer = (props) => {
  const { getOptions } = useContext(ScheduleContext);
  const { plantId, preventativeScheduleId } = useParams();

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative Schedule View" />
      <Content>
        <PreventativeScheduleView plantId={plantId} preventativeScheduleId={preventativeScheduleId} />
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeScheduleProvider(PreventativeScheduleViewContainer);
