import React, { useCallback, useState, useRef } from "react";
import { Button, Icon } from "../Component";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useBoolean from "../../hooks/useBoolean";

const DraggableCard = ({ index, plantField, isChecked, moveListItem, handleCheckBox, isDisabled: isSaving }) => {
  const ref = useRef(null);

  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isSaving,
  });

  const [, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      if (dragIndex === hoverIndex) return;

      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;

      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const dragDropRef = dragRef(dropRef(ref));

  return (
    <div
      ref={dragDropRef}
      className={`p-2 mb-2 bg-white border border-light rounded-lg ${isDragging ? "dragging" : ""}`}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center ">
          <Icon name={"menu"} />
          <div style={{ paddingLeft: "10px" }}>{plantField.field_name}</div>
        </div>
        <input
          disabled={plantField.showAlways || isSaving}
          key={`${plantField.value}-${index}`}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleCheckBox(e)}
        />
      </div>
    </div>
  );
};

const SortableColumns = ({ visibleColumns, entityType, closeModal, updateViewSettings }) => {
  const [isUpdatingSettings, iusState] = useBoolean(false);
  const [columnData, setColumnData] = useState(visibleColumns);

  const moveListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = columnData[dragIndex];
      const hoverItem = columnData[hoverIndex];
      setColumnData((columns) => {
        const updatedColumn = [...columns];
        updatedColumn[dragIndex] = { ...hoverItem, position: dragIndex + 1 };
        updatedColumn[hoverIndex] = { ...dragItem, position: hoverIndex + 1 };
        return updatedColumn;
      });
    },
    [columnData]
  );

  const handleCheckBox = useCallback((plantField) => {
    setColumnData((prevData) =>
      prevData.map((column) => {
        return column.field_key === plantField.field_key ? { ...column, isChecked: !column.isChecked } : column;
      })
    );
  }, [columnData]);

  function renderDraggableCard() {
    return columnData.map((plantField, index) => {
      return (
        <DraggableCard
          key={plantField.value}
          index={index}
          plantField={plantField}
          isChecked={plantField.isChecked}
          moveListItem={moveListItem}
          isDisabled={isUpdatingSettings}
          handleCheckBox={() => handleCheckBox(plantField)}
        />
      );
    });
  }

  async function updateSettings(payload) {
    try {
      iusState.on();
      const res = await updateViewSettings(payload);
      closeModal();
      return res;
    } catch (error) {
      throw error;
    } finally {
      iusState.off();
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        style={{ overflow: "auto", maxHeight: "30rem", cursor: "pointer" }}
        id="basicLeft"
        className="dragula-container card card-bordered p-4 h-100"
      >
        {renderDraggableCard()}
      </div>
      <div className="d-flex justify-content-end">
        <Button
          id="apply-settings"
          color={"primary"}
          className="mt-2 btn btn-white btn-outline-light"
          isLoading={isUpdatingSettings}
          onClick={() => {
            const constructPayload = columnData.map((cd) => {
              return {
                field_key: cd.field_key,
                field_name: cd.field_name,
                position: cd.position,
                width: cd.width ?? 300,
                isChecked: cd.isChecked,
                showAlways: cd.showAlways
              };
            });
            updateSettings(constructPayload);
          }}
        >
          <span style={{ color: "#fff" }}>Save Changes</span>
        </Button>
      </div>
    </DndProvider>
  );
};

export default SortableColumns;
