/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import PlantOwnedView from "../components/PlantOwnedView";
import { PlantOwnedInventoryContext, withPlantOwnedInventoryProvider } from "../PlantOwnedProvider";

const PlantOwnedViewContainer = (props) => {
  const { inventoryId, setIsOpen, editInventory, moveSpareInventory } = props;

  const plantOwnedContext = useContext(PlantOwnedInventoryContext);
  const { loadInventory, loadFilterOptions } = plantOwnedContext;

  useEffect(() => {
    loadFilterOptions();
    if (inventoryId) loadInventory(inventoryId);
  }, [inventoryId]);

  return (
    <React.Fragment>
      <PlantOwnedView setIsOpen={setIsOpen} editInventory={editInventory} moveSpareInventory={moveSpareInventory} />
    </React.Fragment>
  );
};

export default withPlantOwnedInventoryProvider(PlantOwnedViewContainer);
