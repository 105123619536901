import React, { useState } from "react";
import useBoolean from "../hooks/useBoolean";
import {
  createCorrectiveMaintenance,
  createRemedialMaintenance,
  getCorrectiveListFilterOptions,
  getAllUsers
} from "../repositories/MaintenanceRepository";
import { updateViewSettings } from "../repositories/ViewRepository";
export const MaintenanceContext = React.createContext();

const initialFilterOptions = {
  correctiveStatuses: [],
};
export const AuthProvider = (props) => {
  const [filterOptions, setFilterOptions] = useState(Object.assign({}, initialFilterOptions));
  const [userList, setUserList] = React.useState([]);

  const [filtersLoading, flState] = useBoolean(false);
  const [createLoading, clState] = useBoolean(false);
  const [usersLoading, ulState] = useBoolean(false);

  const loadFilterOptions = async () => {
    flState.on();
    try {
      const response = await getCorrectiveListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  //CREATE CORRECTIVE
  const createCorrective = async (createParams) => {
    clState.on();
    try {
      await createCorrectiveMaintenance(createParams);
    } catch (e) {
      throw e;
    } finally {
      clState.off();
    }
  };

  //CREATE REMEDIAL
  const createRemedial = async (createParams) => {
    clState.on();
    try {
      await createRemedialMaintenance(createParams);
    } catch (e) {
      throw e;
    } finally {
      clState.off();
    }
  };

  const loadAllUsers = async () => {
    ulState.on();

    try {
      const response = await getAllUsers();
      if(response.length){
        setUserList(response);
      }
     
    } catch (e) {
      // HANDLE ERROR
    } finally {
      ulState.off();
    }
  };

  const updateTableSettings = async (payload) => {
    try {
      const res = await updateViewSettings(payload);
    } catch (error) {
      throw error;
    }
  };

  const mContext = {
    loadFilterOptions,
    createLoading,
    filterOptions,
    createCorrective,
    createRemedial,
    loadAllUsers,userList,
    usersLoading,
    updateTableSettings
  };
  return <MaintenanceContext.Provider value={mContext}>{props.children}</MaintenanceContext.Provider>;
};

export const withMaintenanceProvider = (Container, containerProps = {}) => {
  return () => (
    <AuthProvider>
      <Container {...containerProps} />
    </AuthProvider>
  );
};
