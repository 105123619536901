/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewRole from "../components/RoleView";
import { RoleManagementContext, withRoleManagementProvider } from "../RoleManagementProvider";

const RoleViewContainer = props => {
    const { roleId, setIsOpen, editRole } = props

    const roleManagementContext = useContext(RoleManagementContext)
    const { loadRole } =roleManagementContext

    useEffect(() => {
        if (roleId) loadRole(roleId)
    }, [roleId])

    return (
        <React.Fragment>
            <ViewRole setIsOpen={setIsOpen} editRole={editRole} />
        </React.Fragment>
    );
}

export default withRoleManagementProvider(RoleViewContainer);