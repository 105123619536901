/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { AssetContext, withAssetProvider } from "../AssetProvider";
import AssetListCompact from "../components/AssetList";

const AssetContainer = (props) => {
  const { loadAssetList } = useContext(AssetContext);
  const { pagination, filterParams,loadFilterOptions } = useContext(AssetContext);

  useEffect(() => {
    loadAssetList();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);
  return (
    <React.Fragment>
      <Head title="Brighter App | Asset" />
      <Content>
        <AssetListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withAssetProvider(AssetContainer);
