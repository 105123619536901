/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { useParams } from "react-router-dom";
import ViewRemedial from "../components/ViewRemedial";
import { RemedialContext, withRemedialProvider } from "../RemedialProvider";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import { EquipmentAndSpareContext } from "../../../equipment_spare/EquipmentAndSpareProvider";

const RemedialViewContainer = (props) => {
  const { taskId } = useParams();
  const remedialContext = useContext(RemedialContext);
  const { loadTask } = remedialContext;
  const { loadFilterOptions } = useContext(MaintenanceContext);

  useEffect(() => {
    if (taskId) loadTask(taskId);
  }, [taskId]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title={`${taskId} - Task`} />
      <Content>
        <ViewRemedial />
      </Content>
    </React.Fragment>
  );
};

export default withRemedialProvider(RemedialViewContainer);
