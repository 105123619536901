import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { withDocumentProvider } from "./DocumentProvider";

const DocumentMaintenanceStartup = props => {
  return (
    <React.Fragment>
      <Head title="Document Maintenance" />
      <Content>
        <p>Document Page</p>
      </Content>
    </React.Fragment>
  );
}

export default withDocumentProvider(DocumentMaintenanceStartup);