import React from "react";

export const DocumentContext = React.createContext()

export const DocumentProvider = props => {
  const mContext = {}

  return <DocumentContext.Provider value={mContext}>
    {props.children}
  </DocumentContext.Provider>
}

export const withDocumentProvider = (Container, containerProps) => props => <DocumentProvider>
  <Container {...containerProps} />
</DocumentProvider>