/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewDno from "../components/DnoView";
import { DnoContext, withDnoProvider } from "../DnoProvider";

const DnoViewContainer = (props) => {
  const { dnoId, setIsOpen, editDno } = props;

  const dnoContext = useContext(DnoContext);
  const { loadDno } = dnoContext;

  useEffect(() => {
    if (dnoId) loadDno(dnoId);
  }, [dnoId]);

  return (
    <React.Fragment>
      <ViewDno setIsOpen={setIsOpen} editDno={editDno} />
    </React.Fragment>
  );
};

export default withDnoProvider(DnoViewContainer);
