import React, { useContext, useState } from "react";
import Icon from "../../components/icon/Icon";
import { GlobalSearchContext } from "../../providers/GlobalSearchProvider";
import { useHistory } from "react-router-dom";
import { GLOBAL_SEARCH_MANAGEMENT_PAGE, HOME_PAGE } from "../../constants/routes";

const HeaderSearch = () => {
  const history = useHistory();

  const globalSearchContext = useContext(GlobalSearchContext);
  const { handleApplyFilter } = globalSearchContext;

  const [onSearchText, setSearchText] = useState("");
  const [onClose, setClose] = useState(false);

  // onChange function for searching name
  const onFilterChange = (e) => {
    if (e) {
      setSearchText(e.target.value);
    } else {
      setSearchText("");
    }
  };

  // Changing state value when searching name
  const onSearchAction = () => {
    handleApplyFilter({ searchText: onSearchText });
    if (onSearchText === "") {
      history.push(HOME_PAGE);
    } else {
      history.push(`${GLOBAL_SEARCH_MANAGEMENT_PAGE}?searchText=${onSearchText}`);
    }
  };

  return (
    <React.Fragment>
      <Icon name="search" onClick={onSearchAction}></Icon>
      <input
        className="form-control border-transparent form-focus-none"
        value={onSearchText}
        onChange={(e) => {
          onFilterChange(e);
          setClose(true);
        }}
        type="text"
        placeholder="Search anything. Enter to search"
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            onSearchAction();
          }
          if (e.keyCode === 27) {
            setSearchText("");
            handleApplyFilter({ searchText: "" });
          }
          if (e.keyCode === 88) {
            setSearchText("");
          }
        }}
        style={{ width: "50%" }}
      />
      {onClose && (
        <Icon
          name="cross"
          className="mr-4"
          onClick={() => {
            setSearchText("");
            handleApplyFilter({ searchText: "" });
            setClose(false);
          }}
        ></Icon>
      )}
    </React.Fragment>
  );
};

export default HeaderSearch;
