import { GET_IMPORT_ITEMS, UPDATE_IMPORT, IMPORT_STRING, UPDATE_IMPORT_ITEM } from "../../constants/endpoints";
import apiService from "../../services/ApiService";

export const getImportItems = async importId => {
    const response = await apiService.get(GET_IMPORT_ITEMS, {
        pathParams: { importId },
    })
    return response
}
export const importStringData = async (stringData, importId) => {
    const response = await apiService.post(IMPORT_STRING, {
        body: { stringData, importId }
    })
    return response
}
export const updateImport = async (importId, data) => {
    const response = await apiService.put(UPDATE_IMPORT, {
        body: data,
        pathParams: { importId }
    })
    return response
}

export const updateImportItems = async (data) => {
    const response = await apiService.put(UPDATE_IMPORT_ITEM, {
        body: data,
    })
    return response
}
