import { useState, useCallback, useEffect } from "react";

// Custom debounce hook
export function useDebounce(callback, delay) {
  const [timeoutId, setTimeoutId] = useState(null);

  const debouncedCallback = useCallback(
    (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const id = setTimeout(() => {
        callback(...args);
      }, delay);
      setTimeoutId(id);
    },
    [callback, delay, timeoutId]
  );

  const cancelDebounce = useCallback(() => {
    clearTimeout(timeoutId);
    setTimeoutId(null);
  }, [timeoutId]);

  // Cleanup the timeout on unmount
  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  return [debouncedCallback, cancelDebounce];
}
