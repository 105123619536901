/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Label } from "reactstrap";
import { PreviewCard, Button } from "../../../components/Component";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../hooks/useBoolean";
import { useHistory } from "react-router-dom";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { PVPLANT_MANAGEMENT_VIEW_PAGE } from "../../../constants/routes";
import { Icon } from "../../../components/Component";

const updateSchema = yup
  .object({
    Client: yup.string().notRequired().default(""),
    Company: yup.string().notRequired().default(""),
    Notes: yup.string().notRequired().default(""),
    "UserName-Password": yup.string().notRequired().default(""),
    "IP Address": yup.string().notRequired().default(""),
    "Security Password": yup.string().notRequired().default(""),
    "Main Gate Code": yup.string().notRequired().default(""),
  })
  .required();

const CctvForm = (props) => {
  const history = useHistory();

  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const { currentViewPvPlant, attributeMasterList, loadAttributeMasterList,loadPlantAttributes,attributes } = pvPlantManagementContext;

  const [isEditMode, updateEditMode] = useBoolean(false);

  let currentItems = [];

  if (attributeMasterList) {
    currentItems = attributeMasterList.filter((attributeMaster) => attributeMaster.group === "CCTV & IT Access");
  }

  const CctvFormRef = useForm({
    resolver: yupResolver(updateSchema),
  });

  const { setValue, control, register, handleSubmit, reset } = CctvFormRef;

  useEffect(() => {
    loadAttributeMasterList();
    loadPlantAttributes({parentId:currentViewPvPlant.id,group:"CCTV & IT Access"})
  }, [currentViewPvPlant]);
  
  useEffect(()=>{
    if(attributes.length){
      attributes.map((attribute)=>{
        return(
          setValue(attribute.name,attribute.value.value)
        )
      })
    }
  },[attributes])

  const handleFormSubmit = (formData) => {
    const plantId = { parentId: currentViewPvPlant.id, parentType: "PLANT", group: "CCTV & IT Access" };
    const object = { ...plantId, ...formData };
    if (isEditMode) {
      pvPlantManagementContext
        .updateAttribute(object)
        .then((res) => {
          history.push(PVPLANT_MANAGEMENT_VIEW_PAGE.replace(":plantId", currentViewPvPlant.id));
          successToast({ description: "Successfully Updated CCTV" });
        })
        .catch((e) => {
          errorToast({ description: `${e.message}` });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderEditButton = () => {
    return (
      <Button color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Save Information
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderEditButton();
  };

  return (
    <>
      <PreviewCard>
        <div style={{ justifyContent: "end", display: "flex" }}>
          <Button color="primary" onClick={() => updateEditMode.on()}>
            <Icon name="edit" />
            <span>Edit</span>
          </Button>
        </div>
        <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
          <Row className="g-4">
            {currentItems &&
              currentItems.map((item) => {
                return (
                  <Col lg="10" md="10">
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <Label
                            className="input-group-label mt-1 d-flex"
                            style={{ width: "120px", fontWeight: "bolder" }}
                          >
                            {item.name}
                          </Label>
                        </div>
                        <input
                          {...register(item.name)}
                          disabled={!isEditMode}
                          type="text"
                          name={item.name}
                          className="form-control"
                         
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}

            {isEditMode && (
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            )}
          </Row>
        </Form>
      </PreviewCard>
    </>
  );
};

export default CctvForm;
