import React, { useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../../components/Component";
import { BGE_OWNED_FILTERS } from "../../../../constants/PreferenceKeys";
import useBoolean from "../../../../hooks/useBoolean";
import { SelfOwnedInventoryContext } from "../SelfOwnedProvider";

const SelfOwnedListFilter = () => {
  const selfOwnedInventoryContext = React.useContext(SelfOwnedInventoryContext);
  const { filterOptions, pagination } = selfOwnedInventoryContext;
  const { filterParams, handleApplyFilter } = selfOwnedInventoryContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const itemPerPage = pagination.size;

  const [tablesm, updateTableSm] = useState(false);
  const [showFilter, sfState] = useBoolean(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    selfOwnedInventoryContext.resetClientListFilter();
    localStorage.removeItem(BGE_OWNED_FILTERS);
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    sfState.off();
    updateTableSm(false);
  };

  const checkIsFilterApplied = () => {
    if (filterParams.supplier.length != 0 || filterParams.category.length != 0) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleSupplierFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ supplier: statusFilter });
  };

  const handleCategoryFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ category: statusFilter });
  };
  const saveFilter = () => {
    localStorage.setItem(BGE_OWNED_FILTERS, JSON.stringify(filterParams));
  };
  const renderShowOptions = (showValue = 10) => {
    return (
      <li key={`filter-pagination-${showValue}`} className={itemPerPage === showValue ? "active" : ""}>
        <DropdownItem
          tag="a"
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            selfOwnedInventoryContext.updateShowListSize(showValue);
          }}
        >
          {showValue}
        </DropdownItem>
      </li>
    );
  };
  const supplierSelectedFilter = selectedFilter.supplier.map((item, key) => {
    return filterOptions.manufacturer?.[filterOptions.manufacturer.findIndex((status) => status.value === item)];
  });
  const categorySelectedFilter = selectedFilter.category.map((item, key) => {
    return filterOptions.assetCategory?.[filterOptions.assetCategory.findIndex((status) => status.value === item)];
  });
  return (
    <div className="toggle-wrap">
      <Button className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`} onClick={() => updateTableSm(true)}>
        <Icon name="menu-right"></Icon>
      </Button>
      <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
        <ul className="btn-toolbar gx-1">
          <li className="toggle-close">
            <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
              <Icon name="arrow-left"></Icon>
            </Button>
          </li>
          <li>
            <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
              <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                <Icon name="filter-alt"></Icon>
              </DropdownToggle>
              <DropdownMenu right className="filter-wg dropdown-menu-xl" style={{ overflow: "visible" }}>
                <div className="dropdown-head">
                  <span className="sub-title dropdown-title">Filter Bge Owned Inventory</span>
                  <div className="dropdown">
                    <DropdownItem
                      href="#more"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn btn-sm btn-icon"
                    >
                      <Icon name="more-h"></Icon>
                    </DropdownItem>
                  </div>
                </div>
                <div className="dropdown-body dropdown-body-rg">
                  <Row className="gx-6 gy-3">
                    <Col size="12">
                      <FormGroup>
                        <label className="overline-title overline-title-alt">Supplier</label>
                        <RSelect
                          options={filterOptions.manufacturer}
                          placeholder="Any Status"
                          onChange={handleSupplierFilterSelection}
                          isMulti
                          value={supplierSelectedFilter}
                        />
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <FormGroup>
                        <label className="overline-title overline-title-alt">Category</label>
                        <RSelect
                          options={filterOptions.assetCategory}
                          placeholder="Any Status"
                          onChange={handleCategoryFilterSelection}
                          isMulti
                          value={categorySelectedFilter}
                        />
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <Button onClick={handleApply} color="secondary">
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="dropdown-foot between">
                  <a
                    href="#reset"
                    onClick={(ev) => {
                      ev.preventDefault();
                      handleReset();
                    }}
                    className="clickable"
                  >
                    Reset Filter
                  </a>
                  <a href="#save" onClick={saveFilter}>
                    Save Filter
                  </a>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                <Icon name="setting"></Icon>
              </DropdownToggle>
              <DropdownMenu right className="dropdown-menu-xs">
                <ul className="link-check">
                  <li>
                    <span>Show</span>
                  </li>

                  {[10, 25, 50, 100, 200].map(renderShowOptions)}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SelfOwnedListFilter;
