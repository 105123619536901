import { PREVENTATIVE_MASTER, PREVENTATIVE_MASTER_CREATE } from "../../../constants/endpoints";
import apiService from "../../../services/ApiService";

//api call

export const getPreventativeMasters = async filterParams => {
    const response = await apiService.get(PREVENTATIVE_MASTER, {
        queryParams: filterParams,
    })
    return response
}

export const createPreventativeMaster = async params => {
    const response = await apiService.post(PREVENTATIVE_MASTER_CREATE, {
        body: params,
    })
    return response
}

export const getPreventativeMaster = async taskId => {
    const response = await apiService.get(`${PREVENTATIVE_MASTER}/:taskId`, {
        pathParams: { taskId }
    })
    return response
}

export const updatePreventativeMaster = async (taskId, params) => {
    const response = await apiService.put(`${PREVENTATIVE_MASTER}/:taskId`, {
        pathParams: { taskId },
        body: params
    })
    return response
}
export const deletePreventativeMaster = async (taskId, params) => {
    const response = await apiService.delete(`${PREVENTATIVE_MASTER}/:taskId`, {
        pathParams: { taskId },
    })
    return response
}