import { formatDistance } from "date-fns";
import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { NotificationContext } from "../../../providers/NotificationProvider";

const NotificationListCompact = () => {
  const notificationContext = useContext(NotificationContext);

  const { notificationsList, loadNotificationList } = notificationContext;

  useEffect(() => {
    loadNotificationList();
  }, []);

  const currentItems = notificationsList?.items || <Skeleton count={10} />;

  let data = [];
  if (currentItems && currentItems.length > 0) {
    data = currentItems.map((currentItem, key) => {
      return {
        id: key + 1,
        icon: "curve-down-right",
        iconStyle: "bg-warning-dim",
        text: currentItem.title,
        time: formatDistance(new Date(currentItem.created_at), new Date(), {
          addSuffix: true,
        }),
      };
    });
  }

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Notification Lists
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {notificationsList?.pagination?.totalCount} Notifications.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            {data &&
              data.map((item) => {
                return (
                  <div className="card-inner">
                    <div className="between-center ">
                      <div className="nk-block-text">
                        <Icon
                          name={item.icon}
                          className={[`icon-circle ${item.iconStyle ? " " + item.iconStyle : ""}`]}
                        />{" "}
                        {item.text}.
                      </div>
                      <div className="nk-block-actions flex-shrink-sm-0">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                          <li>
                            <em className="text-soft text-date fs-12px">
                              <span className="profile-ud-value">{item.time}</span>
                            </em>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Card>
      </Block>
    </React.Fragment>
  );
};
export default NotificationListCompact;
