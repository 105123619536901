import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import HealthAndSafetyListCompact from "../components/HealthAndSafetyList";
import { HealthAndSafetyContext, withHealthAndSafetyProvider } from "../HealthAndSafetyProvider";

const HealthAndSafetyContainer = (props) => {
  const { loadHealthAndSafetyList } = useContext(HealthAndSafetyContext);
  const { pagination, filterParams, loadFilterOptions } = useContext(HealthAndSafetyContext);

  useEffect(() => {
    loadHealthAndSafetyList();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | Medical Centre" />
      <Content>
        <HealthAndSafetyListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withHealthAndSafetyProvider(HealthAndSafetyContainer);
