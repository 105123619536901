/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Head from "../../../layout/head/Head";
import { ImportContext, withImportProvider } from "../ImportProvider";
import Content from "../../../layout/content/Content";
import StringList from "./components/StringList";

const StringImportContainer = props => {
  const { importId, plantId } = useParams()
  const { getImportItemData, loadOptions } = useContext(ImportContext)

  useEffect(() => {
    if (importId) {
      getImportItemData(importId, plantId)
    }
    loadOptions()
  }, [importId])
  
  return (
    <React.Fragment>
      <Head title="BGE | String Import" />
      <Content>
        <StringList />
      </Content>
    </React.Fragment>
  );
}

export default withImportProvider(StringImportContainer);