import { PREVENTATIVE_MASTER, PREVENTATIVE_TASK, PREVENTATIVE_TASK_CREATE, EXPORT_TASK_API, GET_ALL_PVPLANTS, ADMIN_TASK_ARCHIVE_API, ADMIN_TASK_UNARCHIVE_API } from "../../../../constants/endpoints";
import apiService from "../../../../services/ApiService";

//api call

export const getPreventativeTasks = async (filterParams,payload) => {
    const response = await apiService.post(PREVENTATIVE_TASK, {
        queryParams: filterParams,
        body:payload
    })
    return response
}

export const createPreventativeTask = async params => {
    const response = await apiService.post(PREVENTATIVE_TASK_CREATE, {
        body: params,
    })
    return response
}
export const getPreventativeTaskOptions = async () => {
    const response = await apiService.get(`${PREVENTATIVE_TASK}/options`)
    return response
}

export const getPreventativeTask = async taskId => {
    const response = await apiService.get(`${PREVENTATIVE_TASK}/:taskId`, {
        pathParams: { taskId }
    })
    return response
}

export const updatePreventativeTask = async (taskId, params) => {
    const response = await apiService.put(`${PREVENTATIVE_TASK}/:taskId`, {
        pathParams: { taskId },
        body: params
    })
    return response
}
export const deletePreventativeTask = async (taskId, params) => {
    const response = await apiService.delete(`${PREVENTATIVE_TASK}/:taskId`, {
        pathParams: { taskId },
    })
    return response
}

export const getPreventativeMasterByMonthPlantId = async (filterParams) => {
    const response = await apiService.get(`${PREVENTATIVE_MASTER}/generate`, {
        queryParams: filterParams,
    })
    return response
}

export const exportTask = async (filterParams) => {
    const response = await apiService.get(EXPORT_TASK_API, {
        queryParams: filterParams,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf',
        }
    })
    return response
}
export const getAdminpvPlantsList = async () => {
    const response = await apiService.get(GET_ALL_PVPLANTS)
    return response
}

export const archiveTaskAPI = async (taskId) => {
    const response = await apiService.put(ADMIN_TASK_ARCHIVE_API, {
      pathParams: { taskId },
    });
    return response;
  };
  export const unarchiveTaskAPI = async (taskId) => {
    const response = await apiService.put(ADMIN_TASK_UNARCHIVE_API, {
      pathParams: { taskId },
    });
    return response;
  };