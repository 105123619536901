import apiService from "../../../services/ApiService";
import {
    HEALTH_AND_SAFETY_LIST_API ,
    HEALTH_AND_SAFETY_CREATE_API,
    HEALTH_AND_SAFETY_VIEW_API ,
    HEALTH_AND_SAFETY_UPDATE_API,
    HEALTH_AND_SAFETY_DELETE_API,
    EXPORT_HEALTH_AND_SAFETY_API,
    HEALTH_AND_SAFETY_FILTER_LIST_API
} from "../../../constants/endpoints";

export const getHealthAndSafetyList = async (filterParams) => {
  const response = await apiService.get(HEALTH_AND_SAFETY_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};
export const updateHealthAndSafetyAPI = async (medicalCenterId, params) => {
  const response = await apiService.put(HEALTH_AND_SAFETY_UPDATE_API, {
      pathParams: { medicalCenterId },
      body: params
  })
  return response
}

export const createHealthAndSafetyAPI = async (params) => {
  const response = await apiService.post(HEALTH_AND_SAFETY_CREATE_API, {
    body: params,
  });
  return response
}
export const getHealthAndSafetyAPI = async (medicalCenterId) => {
    const response = await apiService.get(HEALTH_AND_SAFETY_VIEW_API, {
        pathParams: { medicalCenterId }
    })
    return response
}

export const deleteHealthAndSafetyAPI = async (medicalCenterId) => {
    const response = await apiService.delete(HEALTH_AND_SAFETY_DELETE_API, {
        pathParams: { medicalCenterId }
    })
    return response
}
export const exportHealthAndSafetyAPI = async (filterParams) => {
  const response = await apiService.get(EXPORT_HEALTH_AND_SAFETY_API, {
      queryParams: filterParams,
      responseType: 'blob',
      headers: {
          'Content-Type': 'application/pdf',
      }
  })
  return response
}
export const getHealthAndSafetyFilterListAPI = async () => {
  const response = await apiService.get(HEALTH_AND_SAFETY_FILTER_LIST_API);
  return response;
};