import { useContext } from "react";
import { AuthContext } from "../../providers/AuthContext.provider";

const Check = (props) => {
  const { permission, children } = props;
  const permissions = permission;
  const authContext = useContext(AuthContext);
  const { userRoles } = authContext;
  const userPermission = userRoles;
  const allowAccess = () => {
    return userPermission && permissions?.some((p) => userPermission.includes(p));
  };

  if (allowAccess()) {
    return children;
  } else return null;
};
export default Check;
