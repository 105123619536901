import React from "react";
import useBoolean from "../hooks/useBoolean";
import {
    getNotificationList
} from "../repositories/NotificationRepository";

const initialPaginationState = {
    page: 1,
    size: 10,
}

export const NotificationContext = React.createContext()

export const NotificationProvider = props => {

    const [notificationsList, setNotificationsList] = React.useState([])
    const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState))

    //Loaders
    const [notificationLoading, notificationState] = useBoolean(false)


    const loadNotificationList = async (params) => {
        notificationState.on()
        try {
            const response = await getNotificationList(params)
            setNotificationsList(response.notificationList)
        } catch (e) {
            // CAPTURE EXCEPTION
            throw e
        } finally {
            notificationState.off()
        }
    }
    const mContext = {
        notificationsList,
        pagination,
        loadNotificationList,
        notificationLoading,
        updateShowListSize: size => setPagination({ ...pagination, size }),
        updateShowListPage: page => setPagination({ ...pagination, page })
    }
    return <NotificationContext.Provider value={mContext}>
        {props.children}
    </NotificationContext.Provider>
}
export const withNotificationProvider = (Container, containerProps) => props => <NotificationProvider>
    <Container {...containerProps} />
</NotificationProvider>