import { withHomeProvider } from "./HomeProvider";
import React, { useContext, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import HomePage from "./Home";
import { HomeContext } from "./HomeProvider";

const HomeContainerPage = (props) => {
  const { loadDashboard } = useContext(HomeContext);

  useEffect(() => {
    loadDashboard();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | Dashboard" />
      <Content>
        <HomePage />
      </Content>
    </React.Fragment>
  );
};

export default withHomeProvider(HomeContainerPage);
