/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewForm from "../components/FormView";
import { FormManagementContext, withFormManagementProvider } from "../FormManagementProvider";

const FormViewContainer = props => {
    const { formId, setIsOpen, editForm } = props

    const formManagementContext = useContext(FormManagementContext)
    const { loadForm } =formManagementContext

    useEffect(() => {
        if (formId) loadForm(formId)
    }, [formId])

    return (
        <React.Fragment>
            <ViewForm setIsOpen={setIsOpen} editForm={editForm} />
        </React.Fragment>
    );
}

export default withFormManagementProvider(FormViewContainer);