import download from "js-file-download";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
} from "../../../components/Component";
import Check from "../../../components/check_roles/check";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import ModalViewer from "../../entity_management/ModalViewer";
import { RoleManagementContext } from "../RoleManagementProvider";
import { exportRoleAPI } from "../RoleManagementRepository";
import RoleFormContainer from "../containers/RoleFormContainer";
import RoleViewContainer from "../containers/RoleViewContainer";

const RoleListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const roleManagementContext = useContext(RoleManagementContext);
  const { rolesList, pagination } = roleManagementContext;
  const { loadRoleList, roleLoading, filterParams, handleApplyFilter } = roleManagementContext;
  const [sm, updateSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [role, setRole] = useState("");
  const [view, setView] = useState("");
  const [exportLoading, setExportLoading] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => { }, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const exportRoleFuntion = async () => {
    try {
      setExportLoading(true);
      const response = await exportRoleAPI({ ...filterParams });
      download(response, `role.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  const currentItems = rolesList?.items || [];
  const viewRole = (roleId) => {
    setRole(roleId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editRole = (roleId) => {
    setRole(roleId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createRole = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const onSuccessfulModal = () => {
    toggleModal();
    loadRoleList();
  };
  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return <RoleViewContainer roleId={role} setIsOpen={setIsOpen} editRole={editRole} />;
    } else if (view === VIEW.CREATE) {
      return <RoleFormContainer onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.EDIT) {
      return <RoleFormContainer roleId={role} onSuccessfulModal={onSuccessfulModal} />;
    }
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  return (
    <React.Fragment>
      {
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Role List
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total of {rolesList?.pagination?.totalCount || 0} Roles.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <Check permission={["manageTickets"]}>
                      <li>
                        <Button
                          disabled={rolesList?.pagination?.totalCount === 0}
                          className="btn btn-white btn-outline-light"
                          type="export"
                          isLoading={exportLoading}
                          onClick={() => exportRoleFuntion()}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>
                    <Check permission={["manageRoles"]}>
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn-icon" onClick={createRole}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      }
      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by name"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                        if (e.keyCode === 88) {
                          setSearchText("");
                        }
                      }}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                        ? handleNameSort("DESC", "name")
                        : handleNameSort("ASC", "name");
                    }}
                  >
                    Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "name");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "name");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "name"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "level"
                        ? handleNameSort("DESC", "level")
                        : handleNameSort("ASC", "level");
                    }}
                  >
                    Level
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "level");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "level"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "level");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "level"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Role Actions</span>
              </DataTableRow>
              <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
            </DataTableHead>

            {/*Head*/}
            {roleLoading ? (
              <></>
            ) : (
              currentItems.map((item, key) => {
                let otherRoles = 0;
                if (item.actions.length > 3) {
                  otherRoles = item.actions.length - 3;
                }
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <div
                        className="user-card dropdown-toggle"
                        onClick={() => {
                          viewRole(item.id);
                        }}
                      >
                        <div className="user-name">
                          <span data-toggle="tooltip"
                            title={item?.name}
                            style={{
                              width: item.name?.length > 50 ? "350px" : "",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }} className="tb-lead">{item?.name || "-"}</span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span className="noWrap">{item?.level || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      {item.actions[0] && (
                        <Badge pill color="primary" className="mr-1">
                          {item.actions[0].replace(/^./, function (str) {
                            return str.toUpperCase();
                          })}
                        </Badge>
                      )}
                      {item.actions[1] && (
                        <Badge pill color="primary" className="mr-1">
                          {item.actions[1].replace(/^./, function (str) {
                            return str.toUpperCase();
                          })}
                        </Badge>
                      )}
                      {item.actions[2] && (
                        <Badge pill color="primary" className="mr-1">
                          {item.actions[2].replace(/^./, function (str) {
                            return str.toUpperCase();
                          })}
                        </Badge>
                      )}
                      {otherRoles !== 0 && (
                        <Badge pill color="light-secondary">
                          +{otherRoles}
                        </Badge>
                      )}
                    </DataTableRow>
                    <Check permission={["manageRoles"]}>
                      <DataTableRow className="d-flex justify-content-center">
                        <Button
                          onClick={() => {
                            editRole(item.id);
                          }}
                        >
                          {" "}
                          <Icon name="edit"></Icon>
                          <span>Edit</span>
                        </Button>
                      </DataTableRow>
                    </Check>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          <div className="card-inner">
            {rolesList.pagination && rolesList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={rolesList.pagination.totalCount}
                paginate={roleManagementContext.updateShowListPage}
                currentPage={rolesList.pagination.currentPage}
                paginatePage={roleManagementContext.updateShowListSize}
              />
            ) : (
              !roleLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {roleLoading && <Skeleton count={10} className="w-100" />}
      </Block>
      <ModalViewer title="Role" isOpen={isOpen} size="md" toggleModal={toggleModal} component={getComponent()} />
    </React.Fragment>
  );
};
export default RoleListCompact;
