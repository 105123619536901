/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useContext } from "react"
import EquipmentAndSpareView from "../components/EquipmentAndSpareView"
import { EquipmentAndSpareContext } from "../EquipmentAndSpareProvider"

const EquipmentAndSpareViewContainer = ({ equipmentId, onSuccessfulModal , setMode}) => {

    const { loadEquipment } = useContext(EquipmentAndSpareContext)
    useEffect(() => {
        if (equipmentId) {
            loadEquipment(equipmentId)
        }
    }, [equipmentId])

    return <>
        <EquipmentAndSpareView setMode={setMode} onSuccessfulModal={onSuccessfulModal}/>
    </>
}
export default EquipmentAndSpareViewContainer