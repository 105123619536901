import React, { Suspense, useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";
// import { CustomerProvider } from "../pages/panel/e-commerce/customer/CustomerContext";
// import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
// import { UserContextProvider } from "../pages/user_management/user_profile/UserContext";
import { RedirectAs404 } from "../utils/Utils";

// import Homepage from "../pages/Homepage";
// import Sales from "../pages/Sales";
// import Analytics from "../pages/Analytics";

// import EcomOrder from "../pages/panel/e-commerce/order/OrderDefault";
// import EcomSupport from "../pages/panel/e-commerce/support/Messages";
// import EcomProducts from "../pages/panel/e-commerce/product/ProductList";
// import EcomCustomer from "../pages/panel/e-commerce/customer/CustomerList";
// import EcomCustomerDetails from "../pages/panel/e-commerce/customer/CustomerDetails";
// import EcomIntegration from "../pages/panel/e-commerce/integration/Integration";
// import EcomSettings from "../pages/panel/e-commerce/settings/Settings";
// import EcomDashboard from "../pages/panel/e-commerce/index";

// import Component from "../pages/components/Index";
// import Accordian from "../pages/components/Accordions";
// import Alerts from "../pages/components/Alerts";
// import Badges from "../pages/components/Badges";
// import Breadcrumbs from "../pages/components/Breadcrumbs";
// import ButtonGroup from "../pages/components/ButtonGroup";
// import Buttons from "../pages/components/Buttons";
// import Cards from "../pages/components/Cards";
// import Carousel from "../pages/components/Carousel";
// import Dropdowns from "../pages/components/Dropdowns";
// import FormElements from "../pages/components/forms/FormElements";
// import FormLayouts from "../pages/components/forms/FormLayouts";
// import FormValidation from "../pages/components/forms/FormValidation";
// import DateTimePicker from "../pages/components/forms/DateTimePicker";
// import Modals from "../pages/components/Modals";
// import Pagination from "../pages/components/Pagination";
// import Popovers from "../pages/components/Popovers";
// import Progress from "../pages/components/Progress";
// import Spinner from "../pages/components/Spinner";
// import Tabs from "../pages/components/Tabs";
// import Toast from "../pages/components/Toast";
// import Tooltips from "../pages/components/Tooltips";
// import UtilBorder from "../pages/components/UtilBorder";
// import UtilColors from "../pages/components/UtilColors";
// import UtilDisplay from "../pages/components/UtilDisplay";
// import UtilEmbeded from "../pages/components/UtilEmbeded";
// import UtilFlex from "../pages/components/UtilFlex";
// import UtilOthers from "../pages/components/UtilOthers";
// import UtilSizing from "../pages/components/UtilSizing";
// import UtilSpacing from "../pages/components/UtilSpacing";
// import UtilText from "../pages/components/UtilText";

// import Blank from "../pages/others/Blank";
// import Faq from "../pages/others/Faq";
// import Regularv1 from "../pages/others/Regular-1";
// import Regularv2 from "../pages/others/Regular-2";
// import Terms from "../pages/others/Terms";
// import BasicTable from "../pages/components/table/BasicTable";
// import SpecialTablePage from "../pages/components/table/SpecialTable";
// import ChartPage from "../pages/components/charts/Charts";
// import EmailTemplate from "../pages/components/email-template/Email";
// import NioIconPage from "../pages/components/crafted-icons/NioIcon";
// import SVGIconPage from "../pages/components/crafted-icons/SvgIcons";

// import ProjectCardPage from "../pages/pre-built/projects/ProjectCard";
// import ProjectListPage from "../pages/pre-built/projects/ProjectList";
// import UserListDefaultPage from "../pages/pre-built/user-manage/UserListDefault";
// import UserListRegularPage from "../pages/pre-built/user-manage/UserListRegular";
// import UserContactCardPage from "../pages/pre-built/user-manage/UserContactCard";
// import UserDetailsPage from "../pages/pre-built/user-manage/UserDetailsRegular";
// import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
// import UserProfileLayout from "../pages/pre-built/user-manage/UserProfileLayout";
// import OrderDefault from "../pages/pre-built/orders/OrderDefault";
// import OrderRegular from "../pages/pre-built/orders/OrderRegular";
// import OrderSales from "../pages/pre-built/orders/OrderSales";
// import KycListRegular from "../pages/pre-built/kyc-list-regular/KycListRegular";
// import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
// import ProductCard from "../pages/pre-built/products/ProductCard";
// import ProductList from "../pages/pre-built/products/ProductList";
// import ProductDetails from "../pages/pre-built/products/ProductDetails";
// import InvoiceList from "../pages/pre-built/invoice/InvoiceList";
// import InvoiceDetails from "../pages/pre-built/invoice/InvoiceDetails";
// import PricingTable from "../pages/pre-built/pricing-table/PricingTable";
// import Gallery from "../pages/pre-built/gallery/GalleryCard";

// import AppMessages from "../pages/app/messages/Messages";
// import Chat from "../pages/app/chat/ChatContainer";
// import Calender from "../pages/app/calender/Calender";
import {
  ASSET_CATEGORY_PAGE,
  ASSET_PAGE,
  CLIENT_MANAGEMENT_CREATE_PAGE,
  CLIENT_MANAGEMENT_EDIT_PAGE,
  CLIENT_MANAGEMENT_PAGE,
  CLIENT_MANAGEMENT_VIEW_PAGE,
  CORRECTIVE_MAINTENANCE_PAGE,
  CORRECTIVE_MAINTENANCE_VIEW_PAGE,
  DNO_PAGE,
  DOCUMENTS_PAGE,
  EQUIPMENT_SPARE_LIST,
  FAILURE_REASON_PAGE,
  FORM_CREATE_PAGE,
  FORM_EDIT_PAGE,
  FORM_LIST_PAGE,
  FORM_PAGE,
  FORM_SUBMISSION,
  FORM_VIEW_PAGE,
  GLOBAL_SEARCH_MANAGEMENT_PAGE,
  HOME_PAGE,
  MANUFACTURER_PAGE,
  MEDICAL_CENTER_PAGE,
  NOTIFICATION_MANAGEMENT_PAGE,
  PO_INVENTORY_PAGE,
  PREVENTATIVE_GENERATOR_PAGE,
  PREVENTATIVE_MASTERS_PAGE,
  PREVENTATIVE_MASTER_LIST,
  PREVENTATIVE_SCHEDULE,
  PREVENTATIVE_SCHEDULE_EDIT,
  PREVENTATIVE_SCHEDULE_VIEW,
  PREVENTATIVE_TASKS_PAGE,
  PVPLANT_MANAGEMENT_CREATE_PAGE,
  PVPLANT_MANAGEMENT_EDIT_PAGE,
  PVPLANT_MANAGEMENT_PAGE,
  PVPLANT_MANAGEMENT_VIEW_PAGE,
  REMEDIAL_MAINTENANCE_PAGE,
  REMEDIAL_MAINTENANCE_VIEW_PAGE,
  ROLE_MANAGEMENT_PAGE,
  SLA_PAGE,
  SO_INVENTORY_PAGE,
  STRING_IMPORT,
  STRING_MAINTENANCE,
  USER_MANAGEMENT_CREATE_PAGE,
  USER_MANAGEMENT_EDIT_PAGE,
  USER_MANAGEMENT_PAGE,
  USER_MANAGEMENT_VIEW_PAGE,
  USER_PROFILE_PAGE,
  USER_PROFILE_SETTING,
  USER_PROFILE_UPDATE,
  VIEW_FORM,
  PREVENTATIVE_TASK_VIEW_PAGE
} from "../constants/routes";
import ClientManagementContainer from "../pages/client_management/containers/ClientManagementContainer";
import ClientManagementFormContainer from "../pages/client_management/containers/ClientManagementFormContainer";
import ClientManagementViewContainer from "../pages/client_management/containers/ClientManagementViewContainer";
import DocumentContainer from "../pages/documents/DocumentContainer";
import AssetContainer from "../pages/entity_management/asset/containers/AssetContainer";
import AssetCategoryContainer from "../pages/entity_management/asset_category/containers/AssetCategoryContainer";
import DnoContainer from "../pages/entity_management/dno/containers/DnoContainer";
import FailureReasonContainer from "../pages/entity_management/failure_reason/containers/FailureReasonContainer";
import HealthAndSafetyContainer from "../pages/entity_management/home_and_safety/containers/HealthAndSafetyContainer";
import ManufacturerContainer from "../pages/entity_management/manufacturer/containers/ManufacturerContainer";
import PreventativeMasterListContainer from "../pages/entity_management/preventative_master_list/containers/PreventativeMasterListContainer";
import PreventiveMaintenanceContainer from "../pages/entity_management/preventative_masters/containers/PreventativeMaintenanceContainer";
import SlaContainer from "../pages/entity_management/sla/containers/SlaContainer";
import EquipmentAndSpareListContainer from "../pages/equipment_spare/containers/EquipmentAndSpareListContainer";
import ViewForm from "../pages/form/components/ViewForm";
import FormEditManagementContainer from "../pages/form/containers/EditFormContainer";
import FormManagementAddContainer from "../pages/form/containers/FormManagementAddContainer";
import FormManagementContainer from "../pages/form/containers/FormManagementContainer";
import FormManagementListContainer from "../pages/form/containers/FormManagementListContainer";
import FormSubmissionContainer from "../pages/form/containers/FormSubmissionContainer";
import FormManagementViewContainer from "../pages/form/containers/ViewFormContainer";
import GlobalSearchContainer from "../pages/global_search/GlobalSearchContainer";
import HomeContainer from "../pages/home/HomeContainer";
import StringImportContainer from "../pages/imports/strings/StringImportContainer";
import PlantOwnedContainer from "../pages/inventory/plant_owned/containers/PlantOwnedContainer";
import SelfOwnedContainer from "../pages/inventory/self_owned/containers/SelfOwnedContainer";
import CorrectiveMaintenanceContainer from "../pages/maintenance/corrective/containers/CorrectiveMaintenanceContainer";
import CorrectiveViewContainer from "../pages/maintenance/corrective/containers/CorrectiveViewContainer";
import PreventiveTaskContainer from "../pages/maintenance/preventative/preventativeTasks/containers/PreventativeTaskContainer";
import PreventativeTaskViewContainer from "../pages/maintenance/preventative/preventativeTasks/containers/PreventativeTaskViewContainer";
import PreventiveGeneratorContainer from "../pages/maintenance/preventative/preventativeTasks/containers/PreventativeTaskFormContainer";
import RemedialMaintenanaceContainer from "../pages/maintenance/remedial/containers/RemedialMaintenanaceContainer";
import RemedialViewContainer from "../pages/maintenance/remedial/containers/RemedialViewContainer";
import StringListContainer from "../pages/maintenance/string/containers/StringListContainer";
import NotificationContainer from "../pages/notification/NotificationContainer";
import PreventativeScheduleEditContainer from "../pages/preventative_schedule/containers/PreventativeScheduleEditContainer";
import PreventativeScheduleFormContainer from "../pages/preventative_schedule/containers/PreventativeScheduleFormContainer";
import PreventativeScheduleViewContainer from "../pages/preventative_schedule/containers/PreventativeScheduleViewContainer";
import PvPlantManagementContainer from "../pages/pv_plant_management/containers/PvPlantManagementContainer";
import PvPlantManagementFormContainer from "../pages/pv_plant_management/containers/PvPlantManagementFormContainer";
import PvPlantManagementViewContainer from "../pages/pv_plant_management/containers/PvPlantManagementViewContainer";
import RoleContainer from "../pages/role_management/containers/RoleContainer";
import UserManagementContainer from "../pages/user_management/containers/UserManagementContainer";
import UserManagementFormContainer from "../pages/user_management/containers/UserManagementFormContainer";
import UserManagementProfileUpdateConatiner from "../pages/user_management/containers/UserManagementProfileUpdateConatiner";
import UserManagementViewContainer from "../pages/user_management/containers/UserManagementViewContainer";
import UserProfileLayout from "../pages/user_management/user_profile/UserProfileLayout";
import UserProfileSettingPage from "../pages/user_management/user_profile/UserProfileSetting";
// import ViewForm from "../pages/form/components/ViewForm";
const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  // const renderThemeRoutes = () => <Switch>
  //   {/*Panel */}
  //   <Route exact path={`${process.env.PUBLIC_URL}/ecommerce/index`} component={EcomDashboard}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/ecommerce/orders`} component={EcomOrder}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/ecommerce/products`} component={EcomProducts}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/ecommerce/support`} component={EcomSupport}></Route>
  //   <Route
  //     exact
  //     path={`${process.env.PUBLIC_URL}/ecommerce/customer`}
  //     render={() => (
  //       <CustomerProvider>
  //         <EcomCustomer />
  //       </CustomerProvider>
  //     )}
  //   ></Route>
  //   <Route
  //     exact
  //     path={`${process.env.PUBLIC_URL}/ecommerce/customer-details/:id`}
  //     render={(props) => (
  //       <CustomerProvider>
  //         <EcomCustomerDetails {...props} />
  //       </CustomerProvider>
  //     )}
  //   ></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/ecommerce/settings`} component={EcomSettings}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/ecommerce/integration`} component={EcomIntegration}></Route>

  //   {/*Dashboards*/}
  //   <Route exact path={`${process.env.PUBLIC_URL}/sales`} component={Sales}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/analytics`} component={Analytics}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/_blank`} component={Blank}></Route>

  //   {/*Pre-built Pages*/}
  //   <Route exact path={`${process.env.PUBLIC_URL}/project-card`} component={ProjectCardPage}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/project-list`} component={ProjectListPage}></Route>
  //   <Route //Context Api added
  //     exact
  //     path={`${process.env.PUBLIC_URL}/user-list-regular`}
  //     render={() => (
  //       <UserContextProvider>
  //         <UserListRegularPage />
  //       </UserContextProvider>
  //     )}
  //   ></Route>
  //   <Route //Context Api added
  //     exact
  //     path={`${process.env.PUBLIC_URL}/user-list-default`}
  //     render={() => (
  //       <UserContextProvider>
  //         <UserListDefaultPage />
  //       </UserContextProvider>
  //     )}
  //   ></Route>
  //   <Route //Context Api added
  //     exact
  //     path={`${process.env.PUBLIC_URL}/user-list-compact`}
  //     render={() => (
  //       <UserContextProvider>
  //         <UserListCompact />
  //       </UserContextProvider>
  //     )}
  //   ></Route>
  //   <Route //Context Api added
  //     exact
  //     path={`${process.env.PUBLIC_URL}/user-details-regular/:id`}
  //     render={(props) => (
  //       <UserContextProvider>
  //         <UserDetailsPage {...props} />
  //       </UserContextProvider>
  //     )}
  //   ></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/user-profile-regular/`} component={UserProfileLayout}></Route>
  //   <Route
  //     exact
  //     path={`${process.env.PUBLIC_URL}/user-profile-notification/`}
  //     component={UserProfileLayout}
  //   ></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/user-profile-activity/`} component={UserProfileLayout}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/user-profile-setting/`} component={UserProfileLayout}></Route>
  //   <Route //Context api added
  //     exact
  //     path={`${process.env.PUBLIC_URL}/user-contact-card`}
  //     render={() => (
  //       <UserContextProvider>
  //         <UserContactCardPage />
  //       </UserContextProvider>
  //     )}
  //   ></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/order-list-default`} component={OrderDefault}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/order-list-regular`} component={OrderRegular}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/order-list-sales`} component={OrderSales}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/kyc-list-regular`} component={KycListRegular}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/kyc-details-regular/:id`} component={KycDetailsRegular}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/product-list`} component={ProductList}></Route>

  //   <Route // context api added
  //     exact
  //     path={`${process.env.PUBLIC_URL}/product-card`}
  //     render={(props) => (
  //       <ProductContextProvider>
  //         <ProductCard />
  //       </ProductContextProvider>
  //     )}
  //   ></Route>
  //   <Route
  //     exact
  //     path={`${process.env.PUBLIC_URL}/product-details/:id`}
  //     render={(props) => (
  //       <ProductContextProvider>
  //         <ProductDetails {...props} />
  //       </ProductContextProvider>
  //     )}
  //   ></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/invoice-list`} component={InvoiceList}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/invoice-details/:id`} component={InvoiceDetails}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/image-gallery`} component={Gallery}></Route>

  //   {/*Demo Pages*/}
  //   <Route exact path={`${process.env.PUBLIC_URL}/pages/terms-policy`} component={Terms}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/pages/regular-v1`} component={Regularv1}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/pages/regular-v2`} component={Regularv2}></Route>

  //   {/*Application*/}
  //   <Route exact path={`${process.env.PUBLIC_URL}/app-messages`} component={AppMessages}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/app-chat`} component={Chat}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/app-calender`} component={Calender}></Route>

  //   {/*Components*/}
  //   <Route exact path={`${process.env.PUBLIC_URL}/components`} component={Component}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/accordions`} component={Accordian}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/alerts`} component={Alerts}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/badges`} component={Badges}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/breadcrumbs`} component={Breadcrumbs}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/button-group`} component={ButtonGroup}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/buttons`} component={Buttons}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/cards`} component={Cards}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/carousel`} component={Carousel}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/dropdowns`} component={Dropdowns}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/datetime-picker`} component={DateTimePicker}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/form-elements`} component={FormElements}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/form-layouts`} component={FormLayouts}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/form-validation`} component={FormValidation}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/modals`} component={Modals}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/pagination`} component={Pagination}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/popovers`} component={Popovers}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/progress`} component={Progress}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/spinner`} component={Spinner}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/tabs`} component={Tabs}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/toast`} component={Toast}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/tooltips`} component={Tooltips}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-border`} component={UtilBorder}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-colors`} component={UtilColors}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-display`} component={UtilDisplay}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-embeded`} component={UtilEmbeded}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-flex`} component={UtilFlex}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-others`} component={UtilOthers}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-sizing`} component={UtilSizing}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-spacing`} component={UtilSpacing}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/components/util-text`} component={UtilText}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/table-basic`} component={BasicTable}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/table-special`} component={SpecialTablePage}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/charts/chartjs`} component={ChartPage}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/email-template`} component={EmailTemplate}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/nioicon`} component={NioIconPage}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/svg-icons`} component={SVGIconPage}></Route>
  //   <Route exact path={`${process.env.PUBLIC_URL}/`} component={Homepage}></Route>
  //   <Route component={RedirectAs404}></Route>
  // </Switch>

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route exact path={HOME_PAGE} component={HomeContainer}></Route>
        <Route exact path={USER_PROFILE_PAGE} component={UserProfileLayout}></Route>

        <Route exact path={USER_PROFILE_SETTING} component={UserProfileSettingPage}></Route>
        <Route exact path={USER_PROFILE_UPDATE} component={UserManagementProfileUpdateConatiner}></Route>

        {/* Maintenance */}
        <Route exact path={CORRECTIVE_MAINTENANCE_PAGE} component={CorrectiveMaintenanceContainer}></Route>
        <Route exact path={CORRECTIVE_MAINTENANCE_VIEW_PAGE} component={CorrectiveViewContainer}></Route>
        <Route exact path={PREVENTATIVE_MASTERS_PAGE} component={PreventiveMaintenanceContainer}></Route>
        <Route exact path={PREVENTATIVE_TASKS_PAGE} component={PreventiveTaskContainer}></Route>
        <Route exact path={PREVENTATIVE_GENERATOR_PAGE} component={PreventiveGeneratorContainer}></Route>
        <Route exact path={STRING_MAINTENANCE} component={StringListContainer}></Route>
        <Route exact path={STRING_IMPORT} component={StringImportContainer}></Route>
        <Route exact path={PREVENTATIVE_TASK_VIEW_PAGE} component={PreventativeTaskViewContainer} />

        <Route exact path={PREVENTATIVE_MASTER_LIST} component={PreventativeMasterListContainer} />
        <Route exact path={REMEDIAL_MAINTENANCE_PAGE} component={RemedialMaintenanaceContainer}></Route>
        <Route exact path={REMEDIAL_MAINTENANCE_VIEW_PAGE} component={RemedialViewContainer}></Route>

        {/* DOCUMENt */}
        <Route exact path={DOCUMENTS_PAGE} component={DocumentContainer}></Route>

        {/* USER MANAGEMENT */}
        <Route exact path={USER_MANAGEMENT_PAGE} component={UserManagementContainer}></Route>
        <Route exact path={USER_MANAGEMENT_CREATE_PAGE} component={UserManagementFormContainer}></Route>
        <Route exact path={USER_MANAGEMENT_VIEW_PAGE} component={UserManagementViewContainer} />
        <Route exact path={USER_MANAGEMENT_EDIT_PAGE} component={UserManagementFormContainer} />

        {/* PV-PLANT MANAGEMENT */}
        <Route exact path={PVPLANT_MANAGEMENT_PAGE} component={PvPlantManagementContainer} />
        <Route exact path={PVPLANT_MANAGEMENT_CREATE_PAGE} component={PvPlantManagementFormContainer}></Route>
        <Route exact path={PVPLANT_MANAGEMENT_EDIT_PAGE} component={PvPlantManagementFormContainer} />
        <Route exact path={PVPLANT_MANAGEMENT_VIEW_PAGE} component={PvPlantManagementViewContainer} />
        <Route exact path={PREVENTATIVE_SCHEDULE} component={PreventativeScheduleFormContainer} />
        <Route exact path={PREVENTATIVE_SCHEDULE_EDIT} component={PreventativeScheduleEditContainer} />
        <Route exact path={PREVENTATIVE_SCHEDULE_VIEW} component={PreventativeScheduleViewContainer} />

        {/* CLIENT MANAGEMENT CLIENT_MANAGEMENT_VIEW_PAGE*/}
        <Route exact path={CLIENT_MANAGEMENT_PAGE} component={ClientManagementContainer} />
        <Route exact path={CLIENT_MANAGEMENT_CREATE_PAGE} component={ClientManagementFormContainer}></Route>
        <Route exact path={CLIENT_MANAGEMENT_EDIT_PAGE} component={ClientManagementFormContainer} />
        <Route exact path={CLIENT_MANAGEMENT_VIEW_PAGE} component={ClientManagementViewContainer} />

        {/*HOME_AND_SAFETY_PAGE */}
        <Route exact path={MEDICAL_CENTER_PAGE} component={HealthAndSafetyContainer} />

        {/* SLA */}
        <Route exact path={SLA_PAGE} component={SlaContainer} />

        {/* MANUFACTURER */}
        <Route exact path={MANUFACTURER_PAGE} component={ManufacturerContainer} />

        {/* ASSET_CATEGORY */}
        <Route exact path={ASSET_CATEGORY_PAGE} component={AssetCategoryContainer}></Route>

        <Route exact path={FAILURE_REASON_PAGE} component={FailureReasonContainer}></Route>

        {/* DNO*/}
        <Route exact path={DNO_PAGE} component={DnoContainer} />

        {/* INVENTORY */}
        <Route exact path={SO_INVENTORY_PAGE} component={SelfOwnedContainer}></Route>
        <Route exact path={PO_INVENTORY_PAGE} component={PlantOwnedContainer}></Route>

        {/* ASSET MANAGEMENT */}
        <Route exact path={ASSET_PAGE} component={AssetContainer} />

        {/* ROLE MANAGEMENT */}
        <Route exact path={ROLE_MANAGEMENT_PAGE} component={RoleContainer} />

        {/* NOTIFICATION MANAGEMENT */}
        <Route exact path={NOTIFICATION_MANAGEMENT_PAGE} component={NotificationContainer} />

        {/* FORM MANAGEMENT */}
        <Route exact path={FORM_LIST_PAGE} component={FormManagementListContainer} />
        <Route exact path={FORM_PAGE} component={FormManagementContainer} />
        <Route exact path={FORM_CREATE_PAGE} component={FormManagementAddContainer} />
        <Route exact path={FORM_VIEW_PAGE} component={FormManagementViewContainer} />
        <Route exact path={FORM_EDIT_PAGE} component={FormEditManagementContainer} />
        <Route exact path={FORM_SUBMISSION} component={FormSubmissionContainer} />
        <Route exact path={VIEW_FORM} component={ViewForm} />

        {/* GLOBAL_SEARCH*/}
        <Route exact path={GLOBAL_SEARCH_MANAGEMENT_PAGE} component={GlobalSearchContainer} />

        <Route exact path={EQUIPMENT_SPARE_LIST} component={EquipmentAndSpareListContainer} />
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
