import { useContext } from "react"
import { FormGroup } from "reactstrap"
import Attachments from "../../../../components/attachments/Attachment"
import { EquipmentAndSpareContext } from "../../EquipmentAndSpareProvider"

const AttachmentComponent = () => {
    const { currentEquipment } = useContext(EquipmentAndSpareContext)

    return <>
        {currentEquipment?.id ? <div className='ml-4'> 
            <FormGroup className="form-group">
                <div className="form-control-wrap">
                    <Attachments
                        module={'STOCK'}
                        id={currentEquipment?.id}
                        attachments={currentEquipment.attachment}
                        subModule={""}
                    />
                </div>
            </FormGroup>
        </div> : null}</>
}
export default AttachmentComponent