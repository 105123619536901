/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect, Icon } from "../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../hooks/useBoolean";
import { FormManagementContext } from "../FormManagementProvider";
import { UserManagementContext } from "../../user_management/UserManagementProvider";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { FORM_EDIT_PAGE } from "../../../constants/routes";
import Head from "../../../layout/head/Head";

const createSchema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  roles: yup.array().of(yup.number()).min(1).required(),
  status: yup.string().required(),
});

const updateSchema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  roles: yup.array().of(yup.number()).min(1).required(),
  status: yup.string().required(),
});

const AddForm = (props) => {
  const { createForm } = useContext(FormManagementContext);

  const [isEditMode, updateEditMode] = useBoolean(false);
  const [isLoading, setIsLoading] = useState(false);
  const { roleList } = useContext(UserManagementContext);

  const addFormRef = useForm({
    resolver: yupResolver(props.formId ? updateSchema : createSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = addFormRef;

  useEffect(() => {
    reset(props.currentViewForm);
    if (props.currentViewForm) updateEditMode.on();
  }, [props.currentViewForm]);

  const statusOptions = [
    { label: "DRAFT", value: "DRAFT" },
    { label: "PUBLISHED", value: "PUBLISHED" },
    { label: "UNPUBLISHED", value: "UNPUBLISHED" },
  ];

  const handleFormSubmit = (formData) => {
    if (props.formId) {
      setIsLoading(true);
      props
        .updateForm(props.formId, { ...formData })
        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
          setIsLoading(false);
        })
        .catch((e) => {
          errorToast({ description: `${e.message}` });
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      createForm({ ...formData })
        .then((res) => {
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
          setIsLoading(false);
        })
        .catch((e) => {
          errorToast({ description: `${e.message}` });
          setIsLoading(false);
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <>
        <Button color="outline-primary" className="mr-2" onClick={() => props.onSuccessfulModal()}>
          Cancel
        </Button>
        <Button color="primary" size="lg" isLoading={isLoading} onClick={handleSubmit(handleFormSubmit)}>
          Save Information
        </Button>
      </>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-550px">
      <Head title={props.formId ? "Brighter App | Form | Edit" : "Brighter App | Form | Create"} />
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="12" md="12">
                <FormGroup>
                  <span style={{ color: "indianred" }}>&#42;</span>
                  <Label className="form-label" htmlFor="title">
                    Title
                  </Label>
                  <div className="form-control-wrap">
                    <input {...register("title")} type="text" name="title" className="form-control" />
                  </div>
                  {errors.title && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.title?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup>
                  <span style={{ color: "indianred" }}>&#42;</span>
                  <Label className="form-label" htmlFor="description">
                    Description
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field, ref }) => {
                        return (
                          <textarea
                            name="description"
                            value={field.value}
                            {...register("description")}
                            className="form-control"
                          />
                        );
                      }}
                    />
                  </div>
                  {errors.description && (
                    <p className="invalid" style={{ color: "red" }}>
                      This field is required
                    </p>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup>
                  <span style={{ color: "indianred" }}>&#42;</span>
                  <Label className="form-label" htmlFor="status">
                    Status
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="status"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = statusOptions;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            placeholder="Select the Status "
                            options={options}
                            onChange={(o) => setValue("status", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors.status && (
                      <span className="invalid" style={{ color: "red" }}>
                        {errors.status?.message}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="form-group">
                  <span style={{ color: "indianred" }}>&#42;</span>
                  <label className="form-label" htmlFor="roles">
                    User Role
                  </label>
                  <Controller
                    control={control}
                    name="roles"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = roleList;
                      const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          isMulti
                          options={options}
                          onChange={(o) => {
                            setValue(
                              "roles",
                              o.map((item) => item.value),
                              { shouldValidate: true }
                            );
                          }}
                        />
                      );
                    }}
                  />
                  {errors.roles && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.roles?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" md="12" className="d-flex justify-content-end">
                {isEditMode && (
                  <Link to={FORM_EDIT_PAGE.replace(":formId", props.formId)}>
                    <Button color="primary">
                      <Icon name="edit" />
                      Edit Layout
                    </Button>
                  </Link>
                )}
              </Col>
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default AddForm;
