import apiService from "../../../services/ApiService";
import {
  CORRECTIVE_ALL_TASKS_API,
  CORRECTIVE_DELETE,
  CORRECTIVE_LIST_API,
  CORRECTIVE_LIST_FILTER_OPTIONS_API,
  CORRECTIVE_LIST_VIEW_API,
  CORRECTIVE_SUBTASK_CREATE,
  CORRECTIVE_SUBTASK_DELETE,
  CORRECTIVE_SUBTASK_LIST_API,
  CORRECTIVE_SUBTASK_LIST_VIEW_API,
  CORRECTIVE_SUBTASK_UPDATE,
  CORRECTIVE_UPDATE,
  EXPORT_PARTICULAR_TASK_API,
  EXPORT_TASK_API,
  ADMIN_TASK_ARCHIVE_API,
  ADMIN_TASK_UNARCHIVE_API,
} from "../../../constants/endpoints";

export const getCorrectiveList = async (filterParams,payload) => {
  const response = await apiService.post(CORRECTIVE_LIST_API, {
    queryParams: filterParams,
    // pathParams: { params },
    body:payload
  });
  return response;
};

export const getCorrectiveListFilterOptions = async (filterParams) => {
  const response = await apiService.get(CORRECTIVE_LIST_FILTER_OPTIONS_API);
  return response;
};

export const getTaskAPI = async (taskId) => {
  const response = await apiService.get(CORRECTIVE_LIST_VIEW_API, {
    pathParams: { taskId },
  });
  return response;
};

export const updateTaskAPI = async (taskId, params) => {
  const response = await apiService.put(CORRECTIVE_UPDATE, {
    pathParams: { taskId },
    body: params,
  });
  return response;
};

export const deleteTaskAPI = async (taskId) => {
  const response = await apiService.delete(CORRECTIVE_DELETE, {
    pathParams: { taskId },
  });
  return response;
};

export const getCorrectiveSubtaskList = async (filterParams) => {
  const response = await apiService.get(CORRECTIVE_SUBTASK_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};

export const updateSubtaskAPI = async (taskId, params) => {
  const response = await apiService.put(CORRECTIVE_SUBTASK_UPDATE, {
    pathParams: { taskId },
    body: params,
  });
  return response;
};

export const deleteSubtaskAPI = async (taskId) => {
  const response = await apiService.delete(CORRECTIVE_SUBTASK_DELETE, {
    pathParams: { taskId },
  });
  return response;
};

export const getSubtaskAPI = async (taskId) => {
  const response = await apiService.get(CORRECTIVE_SUBTASK_LIST_VIEW_API, {
    pathParams: { taskId },
  });
  return response;
};
export const createCorrectiveSubtask = async (params) => {
  const response = await apiService.post(CORRECTIVE_SUBTASK_CREATE, {
    body: params,
  });
  return response;
};
export const getAllCorrectivetasks = async (filter) => {
  const response = await apiService.get(CORRECTIVE_ALL_TASKS_API, { queryParams: filter });
  return response;
};
export const exportTask = async (filterParams) => {
  const response = await apiService.get(EXPORT_TASK_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};

export const exportParticularTask = async (filterParams) => {
  const taskId = filterParams.taskId;
  const response = await apiService.get(EXPORT_PARTICULAR_TASK_API, {
    queryParams: { category: filterParams.category },
    pathParams: { taskId },
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
export const archiveTaskAPI = async (taskId) => {
  const response = await apiService.put(ADMIN_TASK_ARCHIVE_API, {
    pathParams: { taskId },
  });
  return response;
};
export const unarchiveTaskAPI = async (taskId) => {
  const response = await apiService.put(ADMIN_TASK_UNARCHIVE_API, {
    pathParams: { taskId },
  });
  return response;
};
