/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../../components/Component";
import download from "js-file-download";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { exportAsset } from "../AssetRepository";

const ASSET_FIELDS = [
  {
    label: "Model",
    value: "model",
  },
  {
    label: "Description",
    value: "description",
  },
  {
    label: "Asset Category",
    value: "assetCategories.name",
  },
  {
    label: "Manufacturer",
    value: "manufacturers.name",
  },
  {
    label: "Factory Barcode",
    value: "factoryBarcode",
  },
  {
    label: "Product Id",
    value: "identifier",
  },
  {
    label: "Rating",
    value: "rating",
  },
  {
    value: "attachments",
    label: "Attachments",
  },
];

const ExportForm = (props) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (ASSET_FIELDS) {
      const data = ASSET_FIELDS.map((asset) => {
        if(asset.label === "Description"){
          return <p dangerouslySetInnerHTML={{ __html: asset.value ? asset.value : "-" }}/>
        }
        else{
        return asset.value;
        }
      });
      setValue(data);
    }
  }, [ASSET_FIELDS]);

  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async (formData) => {
    try {
      setExportLoading(true);
      const response = await exportAsset({ ...props.filterParams, headerData: value });
      download(response, `asset.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting asset" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="export-asset"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Asset Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0 ">
                {ASSET_FIELDS &&
                  ASSET_FIELDS.map((asset, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${asset} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0">
                              <input
                                key={`${asset}-${key}`}
                                type="checkbox"
                                checked={value.includes(asset.value)}
                                id={`${asset}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([asset.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === asset.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${asset}-${key}`} check>
                                {asset.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;
