import React, { useContext, useEffect, useState } from "react";
import { ReactFormBuilder } from "react-form-builder2";
import { useHistory, useParams } from "react-router-dom";
import { Badge, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewCard,
  Row,
} from "../../../components/Component";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { FORM_PAGE } from "../../../constants/routes";
import { UserManagementContext } from "../../user_management/UserManagementProvider";
import { FormManagementContext } from "../FormManagementProvider";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";

const MultiFileUpload = (props) => {
  const formManagementContext = useContext(FormManagementContext);
  const { multiData, setMultidata, compressedFile, loadFormSubmissionList, submittedForms } = formManagementContext;
  const fileInputRef = React.useRef(null);

  const [fileUploads, setFileUploads] = useState([]);
  const { formId, id } = useParams();
  useEffect(() => {
    loadFormSubmissionList(formId, id);
  }, []);
  let previewURL;
  if (submittedForms?.items?.length > 0) {
    previewURL = submittedForms?.items[id]?.rawData?.find((data) => data?.name?.includes("my_input") === true);
  }

  const displayFileUpload = () => {
    const files = Array.from(fileInputRef.current.files);

    setFileUploads(files);
    setMultidata(files);
  };

  const clearFileUpload = () => {
    setFileUploads([]);
    fileInputRef.current.value = null;
  };

  const saveFiles = async (e) => {
    e.preventDefault();
    const sourceUrl = props.defaultValue;

    if (fileUploads.length > 0) {
      for (let i = 0; i < fileUploads.length; i++) {
        const file = fileUploads[i];
      }
    }
  };

  let baseClasses = "SortableItem rfb-item";
  const name = props.data.field_name;
  const fileInputStyle = fileUploads.length > 0 ? { display: "none" } : null;
  if (props.data.pageBreakBefore) {
    baseClasses += " alwaysbreak";
  }

  const downloadFile = (event) => {
    event.preventDefault();
    const url = previewURL?.value;

    fetch(url)
      .then((response) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "filename";
        link.click();
      })
      .catch((error) => {
        errorToast({ description: "Error while downloading file" });
      });
  };

  return (
    <div style={{ ...props.style }} className={baseClasses}>
      <div className="form-group">
        <div className="image-upload-container">
          <div style={fileInputStyle}>
            {!props?.disabled && (
              <input
                ref={fileInputRef}
                name={name}
                type="file"
                accept={props.data.fileType || "*"}
                className="image-upload"
                onChange={displayFileUpload}
                multiple
              />
            )}

            {!props?.disabled ? (
              <div className="image-upload-control">
                <div className="btn btn-default">
                  <i className="fas fa-file"></i> Upload Files
                </div>
                <p>Select one or more files from your computer or device.</p>
              </div>
            ) : previewURL?.value ? (
              <a href={previewURL?.value}>{previewURL?.value}</a>
            ) : (
              "no data found"
            )}
          </div>

          {fileUploads.length > 0 && (
            <div>
              {fileUploads.map((file, index) => (
                <div key={index} className="file-upload-preview">
                  <div style={{ display: "inline-block", marginRight: "5px" }}>{`Name: ${file.name}`}</div>
                  <div style={{ display: "inline-block", marginLeft: "5px" }}>
                    {file.size > 1024 * 1024
                      ? `Size:  ${Math.ceil(file.size / (1024 * 1024))} mb`
                      : `Size:  ${Math.ceil(file.size / 1024)} kb`}
                  </div>
                </div>
              ))}
              <br />
              <div className="btn btn-file-upload-clear" onClick={clearFileUpload}>
                <i className="fas fa-times"></i> Clear Files
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SingleFileUpload = (props) => {
  const formManagementContext = useContext(FormManagementContext);
  const {
    multiData,
    setMultidata,
    compressedFile,
    loadFormSubmissionList,
    submittedForms,
    setSingleFileUploads,
    singleFileUpload,
  } = formManagementContext;
  const fileInputRef = React.useRef(null);

  const [fileUpload, setFileUpload] = useState(null);
  const { formId, id } = useParams();
  useEffect(() => {
    loadFormSubmissionList(formId, id);
  }, []);
  let previewURL;
  if (submittedForms?.items?.length > 0) {
    previewURL = submittedForms?.items[id]?.rawData?.find((data) => data?.name?.includes("my_fileupload") === true);
  }

  const displayFileUpload = () => {
    const file = fileInputRef.current.files[0];
    setFileUpload(file);
    if (file) {
      const modifyProps = props?.data;
      modifyProps["required"] = false;
    }
    setSingleFileUploads(file);
  };

  const clearFileUpload = () => {
    const modifyProps = props?.data;
    modifyProps["required"] = true;
    setFileUpload(null);
    fileInputRef.current.value = null;
  };

  const saveFile = async (e) => {
    e.preventDefault();
    const sourceUrl = props.defaultValue;
  };

  let baseClasses = "SortableItem rfb-item";
  const name = props.data.field_name;
  const fileInputStyle = fileUpload ? { display: "none" } : null;
  if (props.data.pageBreakBefore) {
    baseClasses += " alwaysbreak";
  }

  const downloadFile = (event) => {
    event.preventDefault();
    const url = previewURL?.value;

    fetch(url)
      .then((response) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "filename";
        link.click();
      })
      .catch((error) => {
        errorToast({ description: "Error while downloading file" });
      });
  };

  return (
    <div style={{ ...props.style }} className={baseClasses}>
      <div className="form-group">
        {props.read_only === true && props.defaultValue && props.defaultValue.length > 0 ? (
          <div>
            <button className="btn btn-default" onClick={saveFile}>
              <i className="fas fa-download"></i> Download File
            </button>
          </div>
        ) : (
          <div className="image-upload-container">
            <div style={fileInputStyle}>
              {!props?.disabled && (
                <input
                  ref={fileInputRef}
                  name={name}
                  type="file"
                  accept={props.data.fileType || "*"}
                  className="image-upload"
                  onChange={displayFileUpload}
                />
              )}

              {!props?.disabled ? (
                <div className="image-upload-control">
                  <div className="btn btn-default">
                    <i className="fas fa-file"></i> Upload File
                  </div>
                  <p>Select a file from your computer or device.</p>
                </div>
              ) : previewURL?.value ? (
                <a href={previewURL?.value}>{previewURL?.value}</a>
              ) : (
                "no data found"
              )}
            </div>

            {fileUpload && (
              <div>
                <div className="file-upload-preview">
                  <div style={{ display: "inline-block", marginRight: "5px" }}>{`Name: ${fileUpload.name}`}</div>
                  <div style={{ display: "inline-block", marginLeft: "5px" }}>
                    {fileUpload.size > 1024 * 1024
                      ? `Size:  ${Math.ceil(fileUpload.size / (1024 * 1024))} mb`
                      : `Size:  ${Math.ceil(fileUpload.size / 1024)} kb`}
                  </div>
                </div>
                <br />
                <div className="btn btn-file-upload-clear" onClick={clearFileUpload}>
                  <i className="fas fa-times"></i> Clear File
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const DatePicker = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled } = props;
  return (
    <input
      type="date"
      name={name}
      ref={ref}
      dateFormat="dd/MM/yyyy"
      defaultValue={defaultValue}
      disabled={disabled}
      className="form-control"
    />
  );
});

const items = [
  {
    key: "MultifileUpload",
    element: "CustomElement",
    component: MultiFileUpload,
    type: "custom",
    forwardRef: true,
    field_name: "my_input_",
    name: "Multi file Upload",
    icon: "fa fa-cog",
    label: "Multi File Upload",
  },
  {
    key: "SingleFileUpload",
    element: "CustomElement",
    component: SingleFileUpload,
    type: "custom",
    forwardRef: true,
    field_name: "my_fileupload_",
    name: "Single File Upload",
    icon: "fa fa-cog",
    required: false,
    label: "Single File Upload",
  },

  {
    key: "datepicker",
    element: "CustomElement",
    component: DatePicker,
    type: "custom",
    forwardRef: true,
    field_name: "my_date_picker",
    name: "Date",
    icon: "fa fa-cog",
    required: false,
    label: "Date",
  },
  {
    key: "Header",
  },
  {
    key: "TextInput",
  },
  {
    key: "Label",
  },

  {
    key: "TextArea",
  },
  {
    key: "RadioButtons",
  },
  {
    key: "Checkboxes",
  },
  {
    key: "TwoColumnRow",
  },
  {
    key: "ThreeColumnRow",
  },
  {
    key: "PhoneNumber",
  },
  {
    key: "Range",
  },

  {
    key: "Camera",
  },
  {
    key: "LineBreak",
  },
  {
    key: "Dropdown",
  },
  {
    key: "Tags",
  },
  {
    key: "NumberInput",
  },
  {
    key: "Rating",
  },

  {
    key: "EmailInput",
  },
];
const EditForm = ({ sm, updateSm }) => {
  const history = useHistory();

  const formManagementContext = useContext(FormManagementContext);
  const { currentViewForm,submittedForms } = formManagementContext;

  const { roleList } = useContext(UserManagementContext);

  const [formContent, setFormContent] = useState({});
  if (!currentViewForm) return <Spinner />;

  let roleIds = {};

  for (let i = 0; i < roleList?.length; i++) {
    roleIds[roleList[i].value] = roleList[i].label;
  }
  const formData = {
    title: currentViewForm?.title,
    status: currentViewForm?.status,
    description: currentViewForm?.description,
    data: Object.keys(formContent).length ? formContent : currentViewForm?.data,
  };

  const handleFormSubmit = () => {
    formManagementContext
      .updateForm(currentViewForm.id, formData)
      .then((res) => {
        history.replace(FORM_PAGE);
        successToast({ description: "Successfully Updated" });
      })
      .catch((e) => {
        errorToast({ description: `${e.message}` });
      });
  };
  const onPost = (data) => {
    setFormContent(data.task_data);
  };

  const handleConfirmationEditFormLayout = async (file) => {
    if(submittedForms?.items?.length>0){
      confirmationSweetAlert({
        handleConfirmation: handleFormSubmit,
        text: "Making this change will prevent users from submitting response for the previous versions, do you accept?",
        confirmButtonText: "Confirm",
      });
    } else{
      handleFormSubmit()
    }
  };

  return (
    <>
      <Block className={"bg-white p-2"}>
        {/* <Head title="Brighter App | Form | Edit" /> */}

        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
            <BlockTitle tag="h4">Form Information</BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block size="lg" className={"bg-white p-1"}>
          <BlockHead>
            <BlockHeadContent>
              <Row md="12" lg="12">
                <Col md="7" lg="7" className="mt-1">
                  <h4>{`${currentViewForm?.title}'s Layout `} <Badge>V{currentViewForm?.version}</Badge></h4>
                </Col>
                <Col>
                  <Button color="outline-primary" className={"my-3 mr-3"} onClick={() => history.replace(FORM_PAGE)}>
                    Cancel
                  </Button>
                  <Button className={"my-3 "} onClick={() => handleConfirmationEditFormLayout()} color="primary">
                    Save Form
                  </Button>
                </Col>
              </Row>
            </BlockHeadContent>
          </BlockHead>
          <Block size="lg">
            <PreviewCard>
              <div className="clearfix">
                <ReactFormBuilder
                  toolbarItems={items}
                  download_path=""
                  back_action="/"
                  back_name="Back"
                  answer_data={{}}
                  action_name="Save"
                  form_action="/"
                  form_method="POST"
                  data={currentViewForm.data}
                  onPost={onPost}
                />

                <BlockHeadContent>
                  <Row md="12" lg="12">
                    <Col md="7" lg="7" className="mt-1"></Col>
                    <Col>
                      <Button
                        color="outline-primary"
                        className={"my-3 mr-3"}
                        onClick={() => history.replace(FORM_PAGE)}
                      >
                        Cancel
                      </Button>
                      <Button className={"my-3 "} onClick={() => handleConfirmationEditFormLayout()} color="primary">
                        Save Form
                      </Button>
                    </Col>
                  </Row>
                </BlockHeadContent>
              </div>
            </PreviewCard>
          </Block>
        </Block>
      </Block>
    </>
  );
};

export default EditForm;
