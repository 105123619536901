import apiService from "../services/ApiService";
import { GET_FILE_API, SINGLE_FILE_UPLOAD_API, SINGLE_FILE_UPLOAD_ON_CREATION_API } from "../constants/endpoints";

export const singleUpload = async (file, data) => {
  const response = await apiService.post(SINGLE_FILE_UPLOAD_API, {
    formData: {
      file,
      ...data,
    },
  });
  return response;
};
export const singleUploadOnCreation = async (file, data) => {
  const response = await apiService.post(SINGLE_FILE_UPLOAD_ON_CREATION_API, {
    formData: { file },
  });
  return response;
};

export const getFile = async (key) => {
  const response = await apiService.get(GET_FILE_API, {
    pathParams: {
      key,
    },
  });
  return response;
};
export const deleteFile = async (key) => {
  const response = await apiService.delete(GET_FILE_API, {
    pathParams: {
      key,
    },
  });
  return response;
};
