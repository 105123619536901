/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import SelfOwnedForm from "../components/SelfOwnedForm";
import { SelfOwnedInventoryContext, withSelfOwnedInventoryProvider } from "../SelfOwnedProvider";

const SelfOwnedFormContainer = props => {

    const selfOwnedInventoryContext = useContext(SelfOwnedInventoryContext)
    const { loadInventory, loadFilterOptions, currentViewInventory } = selfOwnedInventoryContext

    useEffect(() => {
        loadFilterOptions()
        if (props.inventoryId) loadInventory(props.inventoryId)
    }, [props.inventoryId])

    return (
        <React.Fragment>
            <SelfOwnedForm
                inventoryId={props.inventoryId}
                currentViewInventory={currentViewInventory}
                onSuccessfulModal={props.onSuccessfulModal}
            />
        </React.Fragment>
    );
}

export default withSelfOwnedInventoryProvider(SelfOwnedFormContainer);