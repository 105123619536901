import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../components/Component";

import "react-datepicker/dist/react-datepicker.css";
import { REMEDIAL_FILTERS } from "../../../../constants/PreferenceKeys";
import { sortOption } from "../../../../utils/Utils";
import { RemedialContext } from "../RemedialProvider";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";

const RemedialListFilter = (props) => {
  const remedialContext = React.useContext(RemedialContext);
  const { filterOptions, subList, taskPlant } = props;
  const { pagination } = remedialContext;
  const maintenanceContext = React.useContext(MaintenanceContext)
  const {
    filterParams,
    handleApplyFilter,
    setDashboardFilterStatus,
    checkIsFilterApplied,
    sfState,
    showFilter,
    subCheckIsFilterApplied,
  } = remedialContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const itemPerPage = pagination.size;

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    setDashboardFilterStatus.on();
    const taskSettings = remedialContext.tasksColumnSettings;
    const settings = {
      ...taskSettings,
      filters: {
        ...(taskSettings.filters.size && { size: taskSettings.filters.size }),
        ...(taskSettings.filters.page && {page: taskSettings.filters.page}),
        ...(taskSettings.filters.sortingField && { sortingField: taskSettings.filters.sortingField }),
        ...(taskSettings.filters.sortingOrder && { sortingOrder: taskSettings.filters.sortingOrder }),
      },
    };
    handleApplyFilter(selectedFilter);
    remedialContext.setTasksColumnSettings(settings)
    maintenanceContext.updateTableSettings(settings);
    remedialContext.resetClientListFilter();
    remedialContext.loadRemedialList({
      ...settings,
      filters: {
        ...settings.filters,
        taskStatus: "",
        taskAssignedEngineer: [],
        taskPlant: taskPlant?[taskPlant]:"",
        taskArchived: false,
        taskStartDate: "",
        taskResolvedDate: "",
        table: "",
      },
    });
  };

  const handleApply = () => {
    const settings = {
      ...remedialContext.tasksColumnSettings,
      filters: {
        ...remedialContext.tasksColumnSettings.filters,
        taskStatus: selectedFilter.selectedTaskStatus ?? "",
        taskAssignedEngineer: selectedFilter.selectedAssignedEngineer ?? [],
        taskPlant:taskPlant?[taskPlant]: selectedFilter.selectedPlant ?? [],
        taskArchived: selectedFilter.isArchiveSelected ?? "",
        taskStartDate: selectedFilter.startDate ?? "",
        taskResolvedDate: selectedFilter.resolvedDate ?? "",
        table: selectedFilter.table ?? "",
      },
    }
    handleApplyFilter(selectedFilter);

    checkIsFilterApplied();
    subCheckIsFilterApplied();
    setDashboardFilterStatus.on();
    maintenanceContext.updateTableSettings(settings)
    remedialContext.setTasksColumnSettings(settings)
    updateTableSm(false);
    sfState.off();
    remedialContext.loadRemedialList(settings)
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleStateFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedTaskStatus: statusFilter });
  };

  const handleAssignedFilterSelection = (selectedOption) => {
    const assignedFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedAssignedEngineer: assignedFilter });
  };
  const handlePlantSelection = (selectedOption) => {
    const assignedFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedPlant: assignedFilter });
  };

  const handleArchiveFilter = (e) => {
    const isChecked = e.target.checked;
    handleFilterSelection({ isArchiveSelected: isChecked });
  };

  const handleStartDateSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ startDate: "" });
    } else {
      handleFilterSelection({ startDate: selectedOption });
    }
  };

  const handleResolvedDateSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ resolvedDate: "" });
    } else {
      handleFilterSelection({ resolvedDate: selectedOption });
    }
  };

  const statusSelectedFilter = selectedFilter.selectedTaskStatus && selectedFilter.selectedTaskStatus.map((item, key) => {
    return filterOptions.taskStatuses?.[filterOptions.taskStatuses?.findIndex((status) => status.value === item)];
  });

  const assignedEngineerSelectedFilter = selectedFilter.selectedAssignedEngineer && selectedFilter.selectedAssignedEngineer.map((item, key) => {
    return filterOptions.assignedEngineer?.[
      filterOptions.assignedEngineer?.findIndex((status) => status.value === item)
    ];
  });

  const plantSelectedFilter = selectedFilter.selectedPlant && selectedFilter.selectedPlant.map((item, key) => {
    return filterOptions.plants?.[filterOptions.plants?.findIndex((status) => status.value === item)];
  });

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={filterOptions.taskStatuses}
                  placeholder="Any Status"
                  onChange={handleStateFilterSelection}
                  isMulti
                  value={statusSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Assigned Engineer</label>
                <RSelect
                  options={sortOption(filterOptions.assignedEngineer)}
                  placeholder="Any Engineer"
                  onChange={handleAssignedFilterSelection}
                  isMulti
                  value={assignedEngineerSelectedFilter}
                />
              </FormGroup>
            </Col>
            {subList ? null : (
              <Col size={12}>
                {props.isPlantName && 
                  <FormGroup>
                    <label className="overline-title overline-title-alt">Plant Name</label>
                    <RSelect
                      options={sortOption(filterOptions.plants)}
                      placeholder="Any Plant"
                      onChange={handlePlantSelection}
                      isMulti
                      value={plantSelectedFilter}
                    />
                  </FormGroup>
                }
              </Col>
            )}
            <Col size="6">
              <FormGroup>
                <label className="overline-title overline-title-alt">Start Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.startDate ? new Date(selectedFilter.startDate) : null}
                  onChange={(date) => handleStartDateSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <FormGroup>
                <label className="overline-title overline-title-alt">Resolved Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.resolvedDate ? new Date(selectedFilter.resolvedDate) : null}
                  onChange={(date) => handleResolvedDateSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <div className="custom-control custom-control-sm custom-checkbox" style={{ zIndex: 0 }}>
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchiveChecked"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isArchiveChecked">
                  Archived
                </label>
              </div>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default RemedialListFilter;
