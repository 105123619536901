import React, { useContext, useState } from "react";
import { Spinner } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, PreviewCard } from "../../../components/Component";
import { FormManagementContext } from "../FormManagementProvider";
import { FORM_LIST_PAGE, FORM_SUBMISSION } from "../../../constants/routes";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { ReactFormGenerator } from "react-form-builder2";
import { useHistory, useParams } from "react-router-dom";
import { FORM_SUBMISSION_CREATE_API } from "../../../constants/endpoints";
import { ACCESS_TOKEN } from "../../../constants/PreferenceKeys";
import { singleUpload } from "../../../repositories/FileRepository";
import JSZip from "jszip";
import FileSaver from "file-saver";
import useBoolean from "../../../hooks/useBoolean";

const FormSubmission = ({}) => {
  const formManagementContext = useContext(FormManagementContext);
  const { currentViewForm, multiData, setCompressedFile, singleFileUpload } = formManagementContext;
  const [isFormSubmitted, ifsState] = useBoolean(false);
  const history = useHistory();
  const convertToZip = async () => {
    if (multiData.length > 0) {
      const zip = new JSZip();

      multiData.forEach((file, index) => {
        zip.file(file.name, file);
      });

      try {
        const content = await zip.generateAsync({ type: "blob" });
        const zipFile = new File([content], "files.zip", { type: "application/zip" });
        setCompressedFile(zipFile);
        return zipFile;
      } catch (error) {
        errorToast({ description: "Error while downloading file" });
      }
    }
  };

  const { formId } = useParams();

  if (!currentViewForm) return <Spinner />;
  const handleFormSubmit = async (datas) => {
    ifsState.on();

    try {
      const res = await Promise.all(
        datas.map(async (item) => {
          const zidformData = await convertToZip();
          if (item)
            if (item?.name?.includes("my_input") && zidformData?.name) {
              const data = await singleUpload(zidformData);
              return {
                ...item,
                value: process.env.REACT_APP_BASE_URL + "/api/files/redirect/" + data.files.key,
              };
            }
          if (item?.name?.includes("my_fileupload") && singleFileUpload?.name) {
            const data = await singleUpload(singleFileUpload);
            return {
              ...item,
              value: process.env.REACT_APP_BASE_URL + "/api/files/redirect/" + data.files.key,
            };
          }
          if (item.value instanceof File && item.value?.name) {
            const data = await singleUpload(item.value);
            return {
              ...item,
              value: process.env.REACT_APP_BASE_URL + "/api/files/redirect/" + data.files.key,
            };
          } else {
            return item;
          }
        })
      );

      const formData = { data: res, formId: currentViewForm.id };

      formManagementContext
        .createFormSubmission(formData)
        .then((res) => {
          history.replace(FORM_LIST_PAGE);
          successToast({ description: "Form Successfully Submitted" });
        })
        .catch((e) => {
          errorToast({ description: `${e.message}` });
        });
    } catch (error) {
    } finally {
      ifsState.off();
    }
  };

  return (
    <div className="overflow-auto ">
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md" className={"d-flex justify-content-center border-bottom pb-0 !important"}>
          <BlockBetween>
            <BlockHeadContent className="ml-3">
              <h4 className="my-3"> {currentViewForm.title}</h4>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div>
            {currentViewForm.data && (
              <PreviewCard>
                <ReactFormGenerator
                  data={currentViewForm.data}
                  onSubmit={(data) => handleFormSubmit(data)}
                  actionName="Set this to change the default submit button text"
                  submitButton={
                    <button type={"submit"} disabled={isFormSubmitted} className="btn btn-primary">
                      {isFormSubmitted ? <Spinner style={{ width: "20px", height: "20px" }} /> : "Submit"}
                    </button>
                  }
                  backButton={
                    <a href="/" className="btn btn-default btn-cancel btn-big">
                      Back
                    </a>
                  }
                />
              </PreviewCard>
            )}
          </div>
        </Block>
      </Block>
    </div>
  );
};
export default FormSubmission;
