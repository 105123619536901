/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import UserListCompact from "../components/UserList";
import { UserManagementContext, withUserManagementProvider } from "../UserManagementProvider";
import { RoleManagementContext } from "../../role_management/RoleManagementProvider";

const UserManagementContainer = (props) => {
  const { loadFilterOptions, loadUserList } = useContext(UserManagementContext);
  const { pagination, filterParams, loadAllRoles } = useContext(UserManagementContext);
  const { loadRoles } = useContext(RoleManagementContext);

  useEffect(() => {
    loadUserList();
    loadAllRoles();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
    loadRoles();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | User Management" />
      <Content>
        <UserListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withUserManagementProvider(UserManagementContainer);
