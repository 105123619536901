import apiService from "../../../services/ApiService";
import {
    BGE_OWNED_INVENTORY_CREATE,
    BGE_OWNED_INVENTORY_DELETE,
    BGE_OWNED_INVENTORY_LIST_API,
    BGE_OWNED_INVENTORY_LIST_FILTER_OPTIONS_API,
    BGE_OWNED_INVENTORY_UPDATE,
    BGE_OWNED_INVENTORY_VIEW_API,
    EXPORT_BGE_OWNED_INVENTORY_API,
    MOVE_TO_PLANT_API,
} from '../../../constants/endpoints';

export const getBgeOwnedInventoryList = async filterParams => {
    const response = await apiService.get(BGE_OWNED_INVENTORY_LIST_API, {
        queryParams: filterParams,
    })
    return response
}

export const getBgeOwnedInventoryFilterOptions = async filterParams => {
    const response = await apiService.get(BGE_OWNED_INVENTORY_LIST_FILTER_OPTIONS_API)
    return response
}

export const getInventoryAPI = async inventoryId => {
    const response = await apiService.get(BGE_OWNED_INVENTORY_VIEW_API, {
        pathParams: { inventoryId }
    })
    return response
}

export const createBgeOwnedInventoryAPI = async (params) => {
    const response = await apiService.post(BGE_OWNED_INVENTORY_CREATE, {
        body: params,
    })
    return response
}

export const updateBgeOwnedInventoryAPI = async (inventoryId, params) => {
    const response = await apiService.put(BGE_OWNED_INVENTORY_UPDATE, {
        body: params,
        pathParams: { inventoryId }
    })
    return response
}

export const moveToPlantInventoryAPI = async (inventoryId, params) => {
    const response = await apiService.put(MOVE_TO_PLANT_API, {
        body: params,
        pathParams: { inventoryId }
    })
    return response
}
export const deleteInventoryAPI = async inventoryId => {
    const response = await apiService.delete(BGE_OWNED_INVENTORY_DELETE, {
      pathParams: { inventoryId }
    })
    return response
  }
  export const exportBgeOwnedInventory = async (filterParams) => {
    const response = await apiService.get(EXPORT_BGE_OWNED_INVENTORY_API, {
        queryParams: filterParams,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf',
        }
    })
    return response
}