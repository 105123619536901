/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { RoleManagementContext, withRoleManagementProvider } from "../RoleManagementProvider";
import RoleListCompact from "../components/RoleList";

const RoleManagementContainer = (props) => {
  const { loadRoleList } = useContext(RoleManagementContext);
  const { pagination, filterParams } = useContext(RoleManagementContext);

  useEffect(() => {
    loadRoleList();
  }, [pagination, filterParams]);

  return (
    <React.Fragment>
      <Head title="Brighter App | Role" />
      <Content>
        <RoleListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withRoleManagementProvider(RoleManagementContainer);
